<template>
  <div class="wrapper">
    <div class="dropdown" :class="{ active: target }" @focusout="handleBlur">
      <div class="selected" v-if="selected" @click="target = !target;" v-for="item in selectItem">
        <div class="title">
          {{ item.title }}
        </div>
        <svg class="arrow-list" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
             fill="none">
          <path
              d="M13.2372 14.1958C12.8408 14.6282 12.1592 14.6282 11.7628 14.1958L8.53608 10.6757C7.94813 10.0343 8.40313 9 9.27323 9L15.7268 9C16.5969 9 17.0519 10.0343 16.4639 10.6757L13.2372 14.1958Z"
              fill="#5A5E62" />
        </svg>
      </div>
      <div class="dropdown-list" v-if="target">
        <div class="list-item" v-for="item, index in object" @click="changeValue(index); target = false;">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select',
  props: {
    object: Array,
    value: String,
  },
  data() {
    return {
      selectItem: [],
      target: false,
      selected: '',
    }
  },
  methods: {
    changeValue(index) {
      console.log('click');
      this.selectItem[0] = this.object[index];
      this.$emit('update:modelValue', this.object[index].value);
    },
    handleBlur() {

      setTimeout(() => {
        if (!this.$el.contains(document.activeElement)) {
          this.target = false;
        }
      }, 100);
    },
  },
  mounted() {
    if (this.value == null) {
      this.changeValue(0);
    }
  },
  watch: {
    value: {
      handler(newData) {
        if (this.value != null) {
          this.selected = this.value;
          this.selectItem[0] = this.object[this.value-1];
          // this.$emit('update:modelValue', this.object[this.value-1]);
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped>
.dropdown {
  position: relative;
  display: flex;
  gap: 12px;
  height: 50px;
  padding: 8px;
  padding-left: 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  width: 100%;
}

.dropdown.active {
  border-radius: 8px 8px 0 0;
}

.selected {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  justify-content: space-between;
  width: 100%;
}

.selected .arrow-list {
  margin-left: 19px;
}

.dropdown-list {
  position: absolute;
  top: 87%;
  left: -1.3px;
  width: calc(100% + 2px);
  background-color: #fff;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  border-top: unset;
  max-height: 150px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  border-radius: 0px 0px 8px 8px;
}

.dropdown-list .list-item {
  display: flex;
  cursor: pointer;
  padding: 0 10px;
  gap: 4px;
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.dropdown-list .list-item:last-child {
  padding-bottom: 10px;
}
</style>