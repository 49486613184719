<template>
    <div class="block_error mobile-none" v-if="value.state == 'close'">
        <div class="error_text">{{ value.message }}</div>
        <router-link  :to="{ name: 'BusinesDraftEvent', params: { id: id_page } }">
            <button class="edit_error">Edit</button>
        </router-link>

    </div>
    <div class="alert-icon mobile" v-if="value.state == 'close'" @click="showModal = !showModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="55" viewBox="0 0 54 55" fill="none">
        <g filter="url(#filter0_dd_6303_53124)">
            <path d="M8 8C8 5.79086 9.79086 4 12 4H42C44.2091 4 46 5.79086 46 8V32C46 34.2091 44.2091 36 42 36H32.986C32.6792 36 32.3893 36.1409 32.1997 36.3822L27.7863 41.9992C27.3859 42.5088 26.6141 42.5088 26.2137 41.9992L21.8003 36.3822C21.6107 36.1409 21.3208 36 21.014 36H12C9.79086 36 8 34.2091 8 32V8Z" fill="white"/>
            <path d="M8.75 8C8.75 6.20507 10.2051 4.75 12 4.75H42C43.7949 4.75 45.25 6.20507 45.25 8V32C45.25 33.7949 43.7949 35.25 42 35.25H32.986C32.449 35.25 31.9418 35.4966 31.61 35.9188L27.1966 41.5359C27.0965 41.6633 26.9035 41.6633 26.8034 41.5359L22.39 35.9188C22.0582 35.4966 21.551 35.25 21.014 35.25H12C10.2051 35.25 8.75 33.7949 8.75 32V8Z" stroke="#E5E5EA" stroke-width="1.5"/>
        </g>
        <defs>
            <filter id="filter0_dd_6303_53124" x="0" y="0" width="54" height="54.3828" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="4"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6303_53124"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_6303_53124" result="effect2_dropShadow_6303_53124"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6303_53124" result="shape"/>
            </filter>
        </defs>
        </svg>
        <div class="symbhol">!</div>
    </div>
    <modal-dialog v-model:show="showModal" :modal="true">
            <div>
              <div class="title">Important</div>
              <div class="text">Please correct spelling mistakes in the text</div>
            </div>
            
            <div class="btn">
              <Button class="white" @click="showModal = false;openDraft();">Edit</Button>
            </div>
            <button class="exit-dialog" @click="showModal = false; ">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                      fill="#292929"/>
              </svg>
            </button>
    </modal-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: [Array, Object]
        },
    },
    data() {
        return {
            id_page: this.$route.params.id,
            showModal: false,
        }
    },
    methods: {
        openDraft() {
            console.log(111);
            setTimeout(() => {
                      this.$router.push({ name: "BusinesDraftEvent", params: { id: this.id_page } });
                    }, 200);
        }
    },
}
</script>

<style scoped>
.title{
    color: var(--Red-200, #EA2313);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
}
.text{
    color: var(--Gray-400, #606060);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
}
.alert-icon {

  position: absolute;
  top: 16px;
  right: 16px;
    max-width: 38px;
    max-height: 38px;
}
.alert-icon svg{
    width: 100%;
    height: 100%;
}
.symbhol{
    color: var(--Red-200, #EA2313);
font-family: Standerd;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 83.333% */
letter-spacing: 0.144px;
position: absolute;
left: 50%;
transform: translateX(-50%);
transform: translate(-50%,-50%);
top: 50%;
}
.mobile{
    display: none;
}
.block_error {
    padding: 16px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
}

.error_text {
    color: var(--Red-200, #EA2313);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 23.8px */
    letter-spacing: 0.102px;
    padding-bottom: 24px;
}

.edit_error {
    display: flex;
    width: 120px;
    height: 44px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}
@media screen and (max-width: 1024px) {
    .block_error{
        position: absolute;
        top: 16px;
        right: 16px;;
    }
    .mobile-none{
    display: none;
    }
    .mobile{
    display: flex;
    }   
}
</style>