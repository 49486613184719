<template>
  <div class="info_block">
    <div :class="{ unactive: !loading }" class="left">
      <slot name="left"></slot>
    </div>
    <div class="right" v-if="$slots.right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean
  },
}
</script>

<style scoped>
.unactive {
  min-height: 300px;
  width: 100%;
  border-radius: 16px;
  -webkit-animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
  animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }

  100% {
    background: #D3D3D3;
  }
}

@keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }

  100% {
    background: #D3D3D3;
  }
}

.info_block .left {
  width: 100%;
  padding: 24px;
  padding-bottom: 32px;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
</style>