<template>
    <section class="post">
        <div class="post__container">
            <div class="post__content">
                <slot></slot>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
}
</script>
  
<style scoped>

.post__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
</style>