<template>
  <div class="input__filter__wrapper box" :class="{ open: open }">
    <label class="button_filter box" :class="class">
      <svg class="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_3096_68038)">
          <path
              d="M12.9168 11.6664H12.2585L12.0251 11.4414C13.0251 10.2747 13.5418 8.68303 13.2585 6.99136C12.8668 4.6747 10.9335 2.8247 8.60012 2.54136C5.07512 2.10803 2.10846 5.0747 2.54179 8.5997C2.82512 10.933 4.67513 12.8664 6.99179 13.258C8.68346 13.5414 10.2751 13.0247 11.4418 12.0247L11.6668 12.258V12.9164L15.2085 16.458C15.5501 16.7997 16.1085 16.7997 16.4501 16.458C16.7918 16.1164 16.7918 15.558 16.4501 15.2164L12.9168 11.6664ZM7.91679 11.6664C5.84179 11.6664 4.16679 9.99136 4.16679 7.91636C4.16679 5.84136 5.84179 4.16636 7.91679 4.16636C9.99179 4.16636 11.6668 5.84136 11.6668 7.91636C11.6668 9.99136 9.99179 11.6664 7.91679 11.6664Z"
              fill="#333333" />
        </g>
        <defs>
          <clipPath id="clip0_3096_68038">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <input :class="{ open: open }" @focus="open = true" @blur="handleBlur" class="search box"
             @input="selected = $event.target.value; this.$emit('search', selected);" :placeholder="placeholder_title"
             :value="selected" type="text">
    </label>
    <div class="items box" v-if="sort.length > 0" :class="{ open: open }">
      <template v-for="option in sort" :key="option.id">
        <div class="item__wrapper" @click="selected = (option.type === 1 || option.type === 3)
          ? (option.last_name ? `${option.name} ${option.last_name}` : option.name)
          : (option.type === 2 ? option.company_name : '');
          open = false;
          $emit('value', option.id);">
          <div class="image_letter"
          :style="{ background: getBackgroundColor(option[data_name]) }">{{
              option[data_name].substr(0, 1) }}</div>
          <div v-if="option.type == 1">
            {{ option.name }} {{ option.last_name }}
          </div>
          <div v-else-if="option.type == 2">
            {{ option.company_name }}
          </div>
          <div v-if="option.type == 3">
            {{ option.name }} {{ option.last_name }}
          </div>
        </div>
      </template>
    </div>
    <div class="clear" v-if="selected" @click="selected = ''; this.$emit('value', ''); this.$emit('search', '');">
      <svg class="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.4124 5.70711C15.803 5.31658 15.803 4.68342 15.4124 4.29289C15.0219 3.90237 14.3887 3.90237 13.9982 4.29289L9.85222 8.43889L5.70735 4.29403C5.31683 3.90351 4.68366 3.90351 4.29314 4.29403C3.90262 4.68455 3.90262 5.31772 4.29314 5.70824L8.438 9.85311L4.29314 13.998C3.90261 14.3885 3.90261 15.0217 4.29314 15.4122C4.68366 15.8027 5.31683 15.8027 5.70735 15.4122L9.85222 11.2673L13.9982 15.4133C14.3887 15.8039 15.0219 15.8039 15.4124 15.4133C15.803 15.0228 15.803 14.3896 15.4124 13.9991L11.2664 9.85311L15.4124 5.70711Z"
              fill="#292929" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputFilter',
  props: {
    class: String,
    value: {
      type: String
    },
    sort: {
      type: Array,
      default: () => []
    },
    data_name: String,
    placeholder_title: String,
    selected: [String, Number],
  },
  data() {
    return {
      arr_sort: [],
      open: false,
    }
  },
  methods: {
    getBackgroundColor(name) {
      // Простейшая хэш-функция для генерации цвета на основе имени
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },
    handleItemClick(option) {
    if (option.type === 1 || option.type === 3) {
      this.selected = option.last_name ? `${option.name} ${option.last_name}` : `${option.name}`;
    } else if (option.type === 2) {
      this.selected = option.company_name;
    }
    this.open = false;
    this.$emit('value', option.id);
    
    // Вернуть true или другое положительное значение
    return true;
  },
    handleBlur() {
      setTimeout(() => {
        if (!this.$el.contains(document.activeElement)) {
          this.open = false;
        }
      }, 100);
    },

  },
}
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}

.input__filter__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: max-content;
}

.items {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.input__filter__wrapper .items img {
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.input__filter__wrapper .items .item__wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.input__filter__wrapper .items .image_letter {
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #FFF;
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}

.input__filter__wrapper.open {
  width: 100%;
}

.input__filter__wrapper .clear {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input__filter__wrapper .items {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.input__filter__wrapper .items.open {
  opacity: 1;
  visibility: visible;
}

.input__filter__wrapper .items p {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 22.5px */
  letter-spacing: -0.33px;
  padding: 10px 8px;
}

.button_filter {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  height: 36px;
  border-radius: 8px;
  border: 1.5px solid rgb(229, 229, 234);
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 8px 0px, rgba(0, 0, 0, 0.04) 0px 0px 4px 0px;
  width: max-content;
}

.button_filter input {
  height: 100%;
  border: 0px;
  width: 100%;
  transition: all 0.3s;
}

.button_filter input:focus {
  outline: none;
}

.input__filter__wrapper.open .button_filter {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .button_filter {
    width: 100%;
    height: 48px;
    z-index: 2;
  }

  .input__filter__wrapper {
    width: 100%;
    transition: 0.4s;
  }

  .input__filter__wrapper.open .bg_wrapper {
    opacity: 0.32;
    background: var(--gray-400, #606060);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .button_filter i {
    width: 100%;
  }

  .input__filter__wrapper .clear {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}</style>