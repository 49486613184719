<template>
<Header></Header>
<main>
    <BreadCrumbs class="mobile-none"></BreadCrumbs>
   
    <section class="reservation__container">
        <div class="content">
            <div class="reservation__form">
                <div>
                    <div class="title">Your Experience</div>
                    <div class="event__name mobile" v-if="loadingСompleted">{{ arr_data[0].event_name}}</div>
                    <div class="block" v-show="step == 1">
                        <div class="datetime__wrapper">
                            <div class="text">Date</div>
                            <div class="current__datetime" v-if="form.data">{{ formattedDate() }}</div>
                        </div>
                        <div class="form__wrapper">
                            <div class="form__main">
                                <div class="title">Main Guest</div>
                                <div class="inputs__wrapper">
                                    <div class="input__wrapper">
                                        <div class="text">First name</div>
                                        <Input-text :placeholder="'First name'" :text="v$.contact_data.name.$model"
                                                    @update:text="v$.contact_data.name.$model = $event"></Input-text>
                                        <span v-if="v$.contact_data.name.$invalid && submitted">This field is required</span>
                                    </div>
                                    <div class="input__wrapper">
                                        <div class="text">Surname</div>
                                        <Input-text :placeholder="'Surname'" :text="v$.contact_data.last_name.$model"
                                                    @update:text="v$.contact_data.last_name.$model = $event"></Input-text>
                                        <span v-if="v$.contact_data.last_name.$invalid && submitted">This field is required</span>
                                    </div>

                                    <div class="input__wrapper">
                                        <div class="text">Email</div>
                                        <Input-email :placeholder="'Email'" :email="v$.contact_data.email.$model"
                                                    @update:email="v$.contact_data.email.$model = $event"></Input-email>
                                        <span v-if="v$.contact_data.email.$invalid && submitted">This field is required</span>
                                    </div>                                
                                    <div class="input__wrapper">
                                        <div class="text">Phone number</div>
                                        <InputPhone
                                        v-model="v$.contact_data.phone.$model" :countries="countries"></InputPhone>
                                        <span v-if="v$.contact_data.phone.$invalid && submitted">This field is required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form__main" v-for="(value,index) in additional_guests">
                                <div class="title">Additional Guests {{ index+1}} </div>
                                <div class="inputs__wrapper">
                                    <div class="input__wrapper">
                                        <div class="text">First name</div>
                                        <Input-text :placeholder="'First name'" :text="value.name"
                                                    @update:text="value.name = $event"></Input-text>
                                        <span v-if="v$.additional_guests.$each.$response.$data[index].name.$invalid && submitted">This field is required</span>
                                    </div>
                                    <div class="input__wrapper">
                                        <div class="text">Surname</div>
                                        <Input-text :placeholder="'Surname'" :text="value.last_name"
                                                    @update:text="value.last_name = $event"></Input-text>
                                        <span v-if="v$.additional_guests.$each.$response.$data[index].last_name.$invalid && submitted">This field is required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form__main" v-for="(value,index) in additional_child">
                                <div class="title">Additional Childs {{ index+1}} </div>
                                <div class="inputs__wrapper">
                                    <div class="input__wrapper">
                                        <div class="text">First name</div>
                                        <Input-text :placeholder="'First name'" :text="value.name"
                                                    @update:text="value.name = $event"></Input-text>
                                        <span v-if="v$.additional_child.$each.$response.$data[index].name.$invalid && submitted">This field is required</span>
                                    </div>
                                    <div class="input__wrapper">
                                        <div class="text">Surname</div>
                                        <Input-text :placeholder="'Surname'" :text="value.last_name"
                                                    @update:text="value.last_name = $event"></Input-text>
                                        <span v-if="v$.additional_child.$each.$response.$data[index].last_name.$invalid && submitted">This field is required</span>
                                    </div>
                                </div>
                            </div>
                            <TransfersSelect v-if="loadingСompleted && arr_data[0].meeting_point_type == 1"></TransfersSelect>
                        </div>

                    </div>
                    <div class="check" v-if="step == 2">
                        <!-- <div class="event__name">{{ arr_data[0].event_name}}</div> -->
                        <div class="guests">
                            <div class="text">Guests</div>
                            <div class="users">{{ contact_data.name}} {{ contact_data.last_name}}</div>
                            <div class="users" v-for="item in additional_guests">{{ item.name }} {{ item.last_name }}</div>
                        </div>
                        <div class="flex__wrapper">
                            <div class="left wrapper">
                                <div class="text">Date</div>
                                <div class="value">{{ formattedDate() }}</div>
                            </div>
                            <div class="right wrapper">
                                <div class="text">Cancelation Policy</div>
                                <div class="value">{{ formattedCancelationPolicy() }}</div>
                            </div>
                        </div>
                        <div class="flex__wrapper">
                            <div class="left wrapper">
                                <div class="text">Email</div>
                                <div class="value">{{ contact_data.email }}</div>
                            </div>
                            <div class="right wrapper">
                                <div class="text">Contact Number</div>
                                <div class="value">{{ contact_data.phone }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button__wrapper" :class="{ reservation: step == 1, check: step == 2}">
                        <div class="in__total mobile">
                            <div class="title">In total</div>
                            <div class="price" v-if="loadingСompleted">{{ priceResult() }}</div>
                        </div>
                        <div class="buttons__wrapper">
                            <div class="back" :class="{ none:step ==  1 }" @click="step--">Back</div>
                            <div class="next" :class="{ none:step ==  2 }" @click="nextStep">Next</div>
                            <div class="book__now mobile" :class="{ none:step ==  1 }" @click="callChildFunction">Book now</div>
                        </div>
                </div>
            </div>
            <BookingForm v-if="loadingСompleted"
            ref="bookingFormRef"
            :steps="step" 
            v-model:filters="formParams" 
            :event="arr_data" 
            :loading="loadingСompleted"
            :additional_guests="additional_guests"
            :additional_child="additional_child"
            :contact_data="contact_data"
            ></BookingForm>
        </div>
    </section>
</main>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode';
import BreadCrumbs from "@/components/MainPages/BreadCrumbs.vue";
import Header from "@/components/MainPages/Header.vue";
import BookingForm from "@/components/MainPages/ReservationPage/BookingForm.vue";
import { mapState } from 'vuex';
import axios from "axios";
import InputText from "@/components/UI/InputText.vue";
import InputEmail from "@/components/UI/InputEmail.vue";
import InputPhone from "@/components/MainPages/ReservationPage/InputPhone.vue";
import TransfersSelect from "@/components/MainPages/ReservationPage/TransfersSelect.vue";
import { useVuelidate } from '@vuelidate/core'
import countryCodeData from '@/assets/json/CountryPhoneMask.json';
import { required, email, minLength, helpers, numeric, url } from '@vuelidate/validators'
export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        BreadCrumbs,Header,BookingForm,InputText,InputEmail,InputPhone,VueBarcode,TransfersSelect
    },
    data() {
        return {
            barcodeValue: 'test',
            formParams: {},
            step: 1,
            countries: countryCodeData,
            form: {
                event_id: null,
                data: null,
                id: null,
                adults: null,
                child: null,
            },
            contact_data: {
                name: '',
                last_name: '',
                phone: '',
                email: '',
            },
            additional_guests:[],
            additional_child: [],
            loadingСompleted: false,
            arr_data: [],
        }   
    },
    validations() {
        return {
            contact_data:{
                name: { required },
                last_name: { required },
                phone: { required },
                email: { required, email }
            },
            additional_guests:{
                $each: helpers.forEach({
                    name: { required },
                    last_name: { required },
                })
            },
            additional_child:{
                $each: helpers.forEach({
                    name: { required },
                    last_name: { required },
                })
            },
        }
    },
    methods: {
        priceResult(){
        return this.$store.state.currency.symbol+' '+this.formParams.updateTravelers.adults*parseFloat(this.arr_data[0].price);
        },
        callChildFunction() {
        // Вызываем функцию, определенную в дочернем компоненте
        this.$refs.bookingFormRef.submitForm();
        },
        formattedCancelationPolicy() {
            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const days = [
                "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
            ];

            const dateParts = this.formParams.updateData.split('-');
            const year = dateParts[0];
            const month = months[parseInt(dateParts[1], 10) - 1];
            const day = parseInt(dateParts[2], 10);
            
            const timeParts = this.formParams.updateTime.id.split(':');
            const hour = parseInt(timeParts[0], 10);
            const minute = parseInt(timeParts[1], 10);

            const ampm = hour >= 12 ? 'PM' : 'AM';
            const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

            const localDay = new Date(this.formParams.updateData).getDay();
            const localDate = new Date(this.formParams.updateData);

            const localTime = `${formattedHour}:${minute < 10 ? '0' + minute : minute} ${ampm}`;
            const localDayOfWeek = days[localDay];
            const localMonth = months[localDate.getMonth()];

            return `Free cancellation before ${localTime} (local time) on ${localMonth} ${day}, ${year}`;
        },
        formattedDate() {
            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const days = [
                "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
            ];

            const dateParts = this.formParams.updateData.split('-');
            const year = dateParts[0];
            const month = months[parseInt(dateParts[1], 10) - 1];
            const day = parseInt(dateParts[2], 10);
            
            const timeParts = this.formParams.updateTime.id.split(':');
            const hour = parseInt(timeParts[0], 10);
            const minute = parseInt(timeParts[1], 10);

            const ampm = hour >= 12 ? 'PM' : 'AM';
            const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

            return `${days[new Date(this.formParams.updateData).getDay()]}, ${month} ${day} ${formattedHour}:${minute < 10 ? '0' + minute : minute} ${ampm}`;
        },
        nextStep(){
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.contact_data.$invalid || this.v$.additional_guests.$invalid || this.v$.additional_child.$invalid) {
            } else {
                this.step++;
            }
        },
        getData() {
            this.loadingСompleted = false;
            let fData = new FormData();
            fData.append("currency", this.currency.currency_code);
            fData.append("id", this.$route.params.id);
            axios.post("/get/reservation/init", fData).then(resp => {
                if (resp.data.result == true) {
                this.loadingСompleted = true;
                this.arr_data = resp.data.data;
                }
            }).catch(() => {
            }).finally(() => {

            });
            },
    },
    mounted () {
        const urlParams = new URLSearchParams(window.location.search);
        for (const key in this.form) {
            if (urlParams.has(key)) {
                const value = urlParams.get(key);
                this.form[key] = value;
            }
        }

        // Создаем новый объект для formParams
        this.formParams = {
            updateData: this.form.data,
            updateTime: {
                event_id: this.form.event_id,
                id: this.form.id // Вы должны уточнить, откуда брать значение для этого ключа
            },
            updateTravelers: {
                adults: this.form.adults,
                child: this.form.child
            }
        };
        for (let i = 0; i < this.formParams.updateTravelers.adults; i++) {
            this.additional_guests.push({name: '',last_name: ''})
        }
        for (let i = 0; i <= this.formParams.updateTravelers.child; i++) {
            this.additional_child.push({name: '',last_name: ''})
        }
        this.getData();
    },
    computed: {
    ...mapState(['currency'])
    },
    watch: {
        currency(newVal, oldVal) {
        this.getData();
        },
        'formParams.updateTravelers.adults': {
            handler(newVal, oldVal) {
                if(newVal>oldVal){
                    this.additional_guests.push({name: '',last_name: ''})
                }else this.additional_guests.pop()
            },
            deep: true
        },
        'formParams.updateTravelers.child': {
            handler(newVal, oldVal) {
                if(newVal>oldVal){
                    this.additional_child.push({name: '',last_name: ''})
                }else this.additional_child.pop()
            },
            deep: true
        },
    },

}
</script>

<style scoped>
.none{
    opacity: 0;
    visibility: hidden;
}
.mobile{
    display: none !important;
}
.flex__wrapper .left{
    width: calc(40% - 8px);
}
.flex__wrapper .right{
    width: calc(60% - 8px);
}
.flex__wrapper{
    display: flex;
    gap: 16px;
}
.flex__wrapper .wrapper{
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
}
.flex__wrapper .wrapper .text{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.108px;
    padding-bottom: 4px;
}
.flex__wrapper .wrapper .value{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.108px;
}
.check{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.event__name{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.108px;

        border-radius: 8px;
        border: 1px solid var(--Gray-stroke, #E5E5EA);
        background: var(--White-100, #FFF);
        padding: 16px;
}
.check .guests{
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
}
.check .guests .text{
        color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.108px;
    padding-bottom: 4px;
}
.check .guests .users{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.108px;
}
.button__wrapper{
    margin-top: 72px;
}
.button__wrapper .buttons__wrapper {
    display: flex;
    justify-content: space-between;
}
.buttons__wrapper > div{
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    height: 44px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
}
.content{
    display: flex;
    gap: 24px;
}
.reservation__form{
    width: 100%;
    max-width: 920px;
    padding: 24px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    min-height: 652px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.reservation__form div > .title{
    color: #000;
font-family: Standerd;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.144px;
padding-bottom: 20px;
}
.datetime__wrapper{
    margin-bottom: 24px;
}
.datetime__wrapper .text{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.108px;
    padding-bottom: 4px;
}
.datetime__wrapper .current__datetime{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.108px;
}
.form__main{
    padding: 24px 0;
    border-top: 1px solid#E5E5EA;
    border-bottom: 1px solid#E5E5EA;
}
.form__main:last-child{
    border-bottom: unset;
}
.form__main .title{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.108px;
    padding-bottom: 20px;
}
.inputs__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.input__wrapper {
  flex-grow: 1;
  flex-basis: calc(50% - 12px);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}
.input__wrapper .text {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  padding-bottom: 8px;

}
.country-input-wrapper {
  display: flex;
  gap: 12px;
}
.input__wrapper span {
  color: var(--Red-100, #F72314);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
@media screen and (max-width: 1024px) {
    
    .button__wrapper.reservation{
        flex-direction: row;
    }
    .button__wrapper{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #FFF;
        border-top: 1.5px solid var(--Gray-stroke, #E5E5EA);
        padding: 16px;
        justify-content: space-between;
        display: flex;
    }
    .button__wrapper.check{
        flex-direction: column;
    }
    .button__wrapper .none{
        display: none !important;
    }
    .button__wrapper.check .in__total{
        width: 100%;
    }
    .button__wrapper.check .in__total{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .button__wrapper.check .in__total .title{
        color: var(--Black-400, #292929);
        font-family: Standerd;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 28px */
        letter-spacing: 0.12px;
    }
    .button__wrapper.check .in__total .price{
        color: var(--Black-400, #292929);
        text-align: right;
        font-family: Standerd;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: 0.12px;
    }
    .button__wrapper .in__total{
        display: flex;
        flex-direction: column;
        border: unset;
        align-items: unset;
    }
    .button__wrapper .in__total .title{
        color: var(--Gray-400, #606060);
        font-family: Standerd;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.084px;
        padding-bottom: 0;
    }
    .button__wrapper .in__total .price{
        color: var(--Black-400, #292929);
        font-family: Standerd;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: 0.12px;
    }
    .buttons__wrapper > div{
        width: 140px;
    }
    .buttons__wrapper .back{
        border-radius: 8px;
        background: var(--Gray-stroke, #E5E5EA);
        color: var(--Gray-400, #606060);
        font-family: Standerd;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
        border: unset;
    }
    .buttons__wrapper .next{
        border-radius: 8px;
        background: var(--Green-0, rgba(0, 211, 72, 0.24));
        color: var(--Green-400, #39766A);
        font-family: Standerd;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
        border: unset;
    }
    .buttons__wrapper .book__now{
        border-radius: 8px;
        background: var(--Green-0, rgba(0, 211, 72, 0.24));
        color: var(--Green-400, #39766A);
        font-family: Standerd;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
        border: unset;
    }
    .mobile{
    display: flex !important;
    }
    .event__name.mobile{
        margin-bottom: 16px;
    }
    .mobile-none{
        display: none;
    }
    .content{
        flex-direction: column;
    }
    .reservation__form{
        padding: 0;
        border: unset;
        border-radius: unset;
        background: unset;
        box-shadow: unset;
        max-width: unset;
    }
    .reservation__form div > .title{
        color: var(--Black-400, #292929);
        font-family: Standerd;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.12px;
    }
    .datetime__wrapper{
        padding: 16px;
        border-radius: 10px;
        border: 1.5px solid var(--Gray-stroke, #E5E5EA);
        background: var(--White-100, #FFF);
    }
    .datetime__wrapper .text{
        color: var(--Black-400, #292929);
        font-family: Standerd;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.108px;
    }
    .datetime__wrapper .current__datetime{
        color: var(--Black-400, #292929);
        font-family: Standerd;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.108px;
    }
    .form__main{
        padding-top: 0;
        padding-bottom: 16px;
        border-top: unset;
        border-bottom: unset;
    }
    .input__wrapper{
        width: 100%;
        flex-basis: unset;
    }
}
@media screen and (max-width: 768px) {
    .flex__wrapper{
        flex-direction: column;
        gap: 12px;
    }
    .flex__wrapper .left{
    width: 100%;
    }
    .flex__wrapper .right{
        width: 100%;
    }
}
</style>