<template>
    <div class="tab-bar">
        <div class="percent-wrapper">
            <div class="percent">{{ percent[step] }}% Complete</div>
            <progress class="progress" :value="percent[step]" max="100"></progress>
        </div>
        <div class="tabs" v-if="values.length == 0">
            <div class="tab" v-for="(value, index) in tabList" :class="{ 'active': step === index || step > index }"
                :key="index" @click="step != index && step > index ? this.$emit('tab', index) : false;">{{ value }}
            </div>
        </div>
        <div class="tabs" v-if="values.length > 0">
            <div class="tab" v-for="(value, index) in tabList"
                :class="{ 'active': step === index || step > index, 'red': values.includes(index), 'green': !values.includes(index) }"
                :key="index" @click="values.includes(index) ? this.$emit('tab', index) : false;">
                {{ value }}
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "tab-bar",
    props: {
        step: Number,
        values: Array,
    },
    data() {
        return {
            tabList: [
                "General information",
                "Experience description",
                "Photos & Price",
                "Date",
                "Time",
                "Additional Information",
            ],
            percent: [
                20,
                40,
                60,
                80,
                80,
                100,
            ],  

        }
    },
}
</script>

<style scoped>
.tab-bar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    max-width: 326px;
    height: 100%;
    min-height: 809px;
    width: 39%;
    min-width: 240px;
    position: sticky;
    top: 120px;
}

.percent-wrapper .percent {
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    padding-bottom: 8px;
}

.percent-wrapper progress {
    width: 100%;
    height: 8px;
    border-radius: 500px;
    overflow: hidden;
}

.percent-wrapper progress[value]::-webkit-progress-bar {
    background: var(--Gray-stroke, #E5E5EA);
}

.percent-wrapper progress[value]::-webkit-progress-value {
    background: var(--Green-400, #39766A);
    border-radius: 500px;
}

.tabs {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.tabs .tab {
    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;

    cursor: pointer;
}

.tabs .tab.active {
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    border-radius: 8px;
    border: 1.5px solid var(--Green-400, #39766A);
    background: var(--White-100, #FFF);

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.tabs .tab.red {
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    border-radius: 8px;
    border: 1.5px solid var(--Red-200, #EA2313);
    background: var(--White-100, #FFF);

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.tabs .tab.green {
    color: var(--White-100, #FFF);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    border-radius: 8px;
    border: 1.5px solid var(--Green-400, #39766A);
    background: var(--Green-400, #39766A);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 1024px) {
    .tab-bar {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .tab-bar {
        display: none;
    }
}
</style>