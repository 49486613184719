<template>
    <div class="wrapper">
        <div class="bg"></div>
        <Button class="exit-dialog" @click="this.$emit('exit', false);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
          </svg>
        </Button>
        <div class="slide-counter">
                            {{ currentSlide + 1 }}/{{ totalSlides }}
        </div>
        <div class="content" v-if="images.length > 0">
            <Splide @splide:moved="handleMainSlideMoved" :has-track="false" :options="mainOptions" ref="main">
                <SplideTrack>
                    <SplideSlide v-for="(slide, index) in images" :key="index">
                            <img :src='this.$store.state.base_directory + "reviews/"+ this.id + "/"+ slide' alt="">
                    </SplideSlide>
                </SplideTrack>
            </Splide>
        </div>
    </div>
</template>

<script>
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';
import '@splidejs/vue-splide/css/core';
import '@splidejs/vue-splide/css';
import axios from 'axios';
    export default {
        components: { Splide, SplideSlide, SplideTrack },
        props: {
            id: Number,
            viewCurrentImage: Number,
        },
        data() {
            return {
                step: 2,
                mainOptions: {
                padding: '0px',
                perPage: 1,
                perMove: 1,
                gap: 12,
                focus    : 'center',
                width : '100vw',
                height: '100vh',
                pagination: false,
                arrows: false,
            },
            currentSlide: 0,
            totalSlides: 0,
            images: [],
            }
        },
        methods: {
            handleMainSlideMoved() {
                this.currentSlide = this.$refs.main.splide.index;
                this.totalSlides = this.$refs.main.splide.length;
            },
            changeImage(index){
                this.$refs.main.splide.go(index);
                this.handleMainSlideMoved();
            },
            changeStatus(){
                let fData = new FormData();
                fData.append("id", this.id);
                fData.append("status", this.step);
                axios.post("/get/event/all-photo", fData).then(resp => {
                    if (resp.data.result == true) {
                    this.images = resp.data.data;
                    }
                }).catch(() => {
                }).finally(() => {
                });
            },
        },
        mounted () {
            this.changeStatus();
        },
        watch: {
            viewCurrentImage: {
            handler(val, oldVal) {
                this.changeImage(val);
            },
            deep: true
        },
        },
    }
</script>

<style scoped>
.exit-dialog{
    z-index: 10;
}
.splide{
    padding: 0;
}
.splide__slide{
    display: flex;
    align-items: center;
    justify-content: center;
}
.splide img {
    max-width: 100%;
    max-height: 80vh; /* 80% of the viewport height */
    height: auto;
    object-fit: contain;
}
.slide-counter{
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFF;
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 1;
}
.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: all .3s;
    display: none !important;
    opacity: 0;
    visibility: hidden;
    align-items: center;
}
.wrapper.active {
    display: flex;
    visibility: visible;
    opacity: 1;
}
.wrapper .bg{
    background: rgba(0, 0, 0, 0.90);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.tablet{
  display: none !important;
}
.wrapper .content{
    width: 100%;
}
@media screen and (max-width: 1024px) {
    .tablet-none {
    display: none;
  }
  .tablet.active{
  display: flex !important;
}
}
</style>