<template>
  <router-view/>
</template>

<style>

</style>
<script>
import axios from "axios";

export default {
  data() {
    return {
      socket: null,
    }
  },
  methods: {
    sendOnlineStatus(status) {
      axios.post('/update-online-status', {
        status: status,
      })
          .then(response => {
          })
          .catch(error => {
          });
    },
  },
  mounted() {

  },
}
</script>