<template>
    <div class="wrapper" :class="{ active: show }" @click.stop="hideDialog">
        <Button class="exit-dialog" @click.prevent="hideDialog">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                fill="#292929"/>
        </svg>
        </Button>
        <div class="bg"></div>
        <div class="dialog__content" @click.stop>
        <div class="title">Choose travelers</div>
        <div class="description">You can select up to 14 travelers in total</div>
        <div class="form">
            <div class="input__wrapper">
                <div class="text">Adults</div>
                <NumberPicker v-model:value="trevelers.adults"></NumberPicker>
            </div>
            <div class="input__wrapper">
                <div class="text">Child (0-3)</div>
                <NumberPicker v-model:value="trevelers.child"></NumberPicker>
            </div>
        </div>
        <div class="btn">
              <Button class="white" @click.prevent="cancelDate">Cancel</Button>
              <Button class="green" @click.prevent="confirmDate">Confirm</Button>
        </div>
        </div>
    </div>
</template>

<script>
import NumberPicker from "@/components/MainPages/EventPage/NumberPicker.vue";
    export default {
        components: {
            NumberPicker,
            
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            value: Array,
        },
        data() {
            return {
                trevelers: {
                    adults: 0,
                    child: 0,
                }
            }
        },
        methods: {
            hideDialog() {
                this.$emit('update:show', false)
            },
            cancelDate(){
                this.hideDialog();
                this.trevelers = {adults: 0,child: 0};
                this.$emit('update:value', []);
            },
            confirmDate(){
                this.$emit('update:value', this.trevelers);
                this.hideDialog();
            }
        },
        watch: {
        },
    }
</script>

<style scoped>
.wrapper{
    position: absolute;
    left: 0;
    top: 125%;

    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    z-index: 30;
    width: 100%;
}
.dialog__content{
    width: 100%;
    padding: 16px;
    padding-top: 24px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.wrapper.active{
  visibility: visible;
  opacity: 1;
}
.wrapper .title{
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    padding-bottom: 20px;
}
.wrapper .description{
    color: var(--Gray-400, #606060);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    padding-bottom: 16px;
}
.form{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
}
.input__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 1024px) {
  .wrapper .bg{
  background: #606060;
  opacity: 0.32;
  width: 100%;
  height: 100%;
  }
  .wrapper{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
  }
  .dialog__content{
    position: absolute;
    top: 72px;
  }
}
</style>