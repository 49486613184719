<template>
  <div>
    <PreloaderBanner v-show="isLoading"></PreloaderBanner>
    <div v-show="!isLoading">
      <Splide @splide:moved="handleMainSlideMoved"
              @splide:autoplay:play="handleAutoplayPlay"
              @splide:autoplay:pause="handleAutoplayPause"
              :has-track="false" :options="mainOptions" ref="main">
        <SplideTrack>
          <SplideSlide v-for="(slide, index) in array" :key="index">
            <img data-load :src="this.$store.state.base_directory + slide.image" alt="">
            <div class="content">
              <div class="title">{{slide.title}}</div>
              <div class="description">{{slide.description}}</div>
            </div>
          </SplideSlide>
        </SplideTrack>
        <ul class="custom-pagination">
          <li v-for="(slide, index) in array" :key="index" :class="{ active: activeSlide === index }" @click="goToSlide(index)">
          </li>
        </ul>
        <SliderSectionSearch :placeholder_title="'Where are you going ? '"></SliderSectionSearch>
      </Splide>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';
import '@splidejs/vue-splide/css/core';
import '@splidejs/vue-splide/css';
import SliderSectionSearch from "@/components/MainPages/HomePage/SliderSectionSearch.vue";
import PreloaderBanner from "@/components/MainPages/HomePage/PreloaderBanner.vue";
export default {
  components: { Splide, SplideSlide,SplideTrack,SliderSectionSearch,PreloaderBanner },
  props: {
    array: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      activeSlide: 0,
      mainOptions: {
        type   : 'loop',
        padding: '0px',
        gap: 12,
        rewind: true,
        height: '448px',
        arrows: false,
        cover: true,
        pagination: false,
      },
    }
  },
  methods: {
    handleMainSlideMoved() {
      this.activeSlide = this.$refs.main.splide.index;
    },
    handleAutoplayPlay() {
      this.activeSlide = this.$refs.main.splide.index;
    },
    handleAutoplayPause() {
      this.activeSlide = this.$refs.main.splide.index;
    },
    goToSlide(index) {
      this.$refs.main.go(index);
    },
  },
}
</script>

<style scoped>
.none{
  visibility: hidden;
  opacity: 0;
}
.custom-pagination{
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;
  gap: 12px;
}
.custom-pagination li{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.50);
}

.custom-pagination li.active{
  background: #FFF;
}
.splide{
  border-radius: 16px;
  padding: 0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  visibility: visible;
  opacity: 1;
}
.splide__slide{
  border-radius: 16px;
}
.splide__pagination__page{
  height: 10px !important;
  width: 10px !important;
  margin: 0 !important;
}
.splide__pagination__page.is-active{
  width: 10px;
  height: 10px;
  transform: scale(1);
}
.wrapper .content .left {
  width: calc(67.45%);
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.splide__pagination{
  top: 30px;
  left: 30px;
  bottom: unset;
  right: unset;
  gap: 12px;
  padding: 0;
}
.splide__pagination__page{
  margin: 0 6px !important;
}

.splide .content{
  position: absolute;
  bottom: 30px;
  left: 30px;
  margin-bottom: 48px;
}
.splide .content .title{
  color: #FFF;
  text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  font-family: Standerd;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.276px;
  padding-bottom: 10px;
}
.splide .content .description{
  color: #FFF;
  text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 24px;
}
@media screen and (max-width: 768px) {

}
</style>