<template>
    <div class="input-wrapper">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'input-wrapper',
}
</script>

<style scoped>
.input-wrapper :slotted(.input-title){
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.144px;
    padding-bottom: 12px;
}

.input-wrapper :slotted(.input-text){
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.102px;
    padding-bottom: 24px;
}
</style>