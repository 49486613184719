<template>
    <div class="activity-wrapper">
        <label class="activities-take" for="activities-take">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                    fill="#606060" />
            </svg>
            <input class="input-style-none" @focus="showDropDown = true" @blur="handleBlur"
                @input="getSearchActivities($event); checkText($event); this.$emit('update:modelValue', { value: $event.target.value, id: id });"
                v-model="selectedActiviti"
                type="text" id="activities-take"
                :placeholder="placeholder">

        </label>
        <div class="drop-down" v-if="showDropDown && textCleared">
            <div v-for="activity in activitiesData" :key="activity.id" @click="showDropDown = false;
            selectedActiviti = `${activity.name}, `;
            textCleared = false;
            id =activity.id;">
                {{ activity.name }}, {{ activity.country.name }}</div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props: {
        placeholder: String,
        city: String,
        show: Boolean,
    },
    data() {
        return {
            id: '',
            showDropDown: false,
            selectedActiviti: "",
            activitiesData: [],
            textCleared: this.show,
        }
    },
    methods: {
        handleBlur() {
            // Introduce a small delay before hiding the drop-down
            setTimeout(() => {
                if (!this.$el.contains(document.activeElement)) {
                    this.showDropDown = false;
                }
            }, 100);
        },
        getSearchActivities(e) {
            let fData = new FormData();
            let name = e;
            if (name) fData.append("name", e.target.value);
            else fData.append("name", e);
            axios.post("/busines/register/get-activities", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.activitiesData = resp.data.cities;
                }
            }).catch(() => { }).finally(() => { });
        },
        checkText(e) {
            if(!e.target.value.includes(",")){
            this.textCleared = !e.target.value.includes(",");
            this.$emit('show', true);
            } else this.textCleared = false;
        }
    },
    mounted() {
        this.getSearchActivities('');
    },
    watch: {
        selectedActiviti: {
            handler(val, oldVal) {
                if(!val.includes(",")){
                    this.textCleared = !val.includes(",");
                    this.$emit('show', true);
                }
            },
            deep: true
        }
    },
}
</script>

<style scoped>
.activities-take {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 36px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 12px;
    height: 50px;
    max-width: 764px;
}

.activity-wrapper {
    position: relative;
}

.activity-wrapper .drop-down {
    position: absolute;
    top: 113%;
    left: 0;
    width: 100%;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 8px;
    z-index: 10;
}

.activity-wrapper .drop-down div {
    padding: 0 8px;
    height: 40px;
    display: flex;
    align-items: center;

    color: #232323;
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 133.333% */
}

.activities-take.error {
    border-radius: 8px;
    border: 1.5px solid var(--Red-200, #EA2313);
}

.input-style-none {
    border-radius: unset;
    border: unset;
    padding-left: unset;
    height: unset;
}

input {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 36px;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
    outline: none;
}
</style>