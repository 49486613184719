<template>
    <div class="wrapper">
        <slot></slot>
        <div class="wrapper__gradient">
            <div class="companies__wrapper" @scroll="handleScroll" :style="{ 'max-height': `${maxHeight}px` }">
                <div class="block" v-for="item in data">
                    <div class="count_users">{{ item.totalParticipants + ' / ' + item.max_participants }}</div>
                    <div class="date_time">{{ item.day_execution }}</div>
                    <div class="name_event">{{ item.event_name }}</div>
                    <div class="users">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12" fill="none">
                            <path
                                d="M12 6.75C13.63 6.75 15.07 7.14 16.24 7.65C17.32 8.13 18 9.21 18 10.38V12H6V10.39C6 9.21 6.68 8.13 7.76 7.66C8.93 7.14 10.37 6.75 12 6.75ZM4 7C5.1 7 6 6.1 6 5C6 3.9 5.1 3 4 3C2.9 3 2 3.9 2 5C2 6.1 2.9 7 4 7ZM5.13 8.1C4.76 8.04 4.39 8 4 8C3.01 8 2.07 8.21 1.22 8.58C0.48 8.9 0 9.62 0 10.43V12H4.5V10.39C4.5 9.56 4.73 8.78 5.13 8.1ZM20 7C21.1 7 22 6.1 22 5C22 3.9 21.1 3 20 3C18.9 3 18 3.9 18 5C18 6.1 18.9 7 20 7ZM24 10.43C24 9.62 23.52 8.9 22.78 8.58C21.93 8.21 20.99 8 20 8C19.61 8 19.24 8.04 18.87 8.1C19.27 8.78 19.5 9.56 19.5 10.39V12H24V10.43ZM12 0C13.66 0 15 1.34 15 3C15 4.66 13.66 6 12 6C10.34 6 9 4.66 9 3C9 1.34 10.34 0 12 0Z"
                                fill="#292929" />
                        </svg>
                        {{
                            item.additional_customers_data.length >= 1 ? (item.additional_customers_data.length + 1)
                        + ' Adults' :
                            item.additional_customers == null ? 1 + ' Adult' : '' }}
                        {{
                            item.additional_child.length >= 2 ? '+ ' +
                            item.additional_child.length + ' Childs' :
                            item.additional_child.length == 0 ? '' : ' + ' + item.additional_child.length + ' Child' }}
                    </div>
                    <div class="status">NEW RESERVATION</div>
                    <button class="details_info" @click="this.$emit('details', item)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_4718_62063)">
                                <path
                                    d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z"
                                    fill="#292929" />
                            </g>
                            <defs>
                                <clipPath id="clip0_4718_62063">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>

            </div>
            <div v-if="data.length > 2" :class="{ active: showTopGradient }" class="gradient-top"></div>
            <div v-if="data.length > 2" :class="{ active: showBottomGradient }" class="gradient-bottom"></div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        data: [Object, Array],
        maxHeight: Number,
    },
    data() {
        return {
            showTopGradient: false,
            showBottomGradient: true
        }
    },
    methods: {
        handleScroll(event) {
            const scrollTop = event.target.scrollTop;
            const scrollHeight = event.target.scrollHeight;

            const clientHeight = event.target.clientHeight;
            if (scrollTop === 0) this.showTopGradient = false;
            else if (scrollTop >= 30) this.showTopGradient = true;

            if (scrollTop + clientHeight > scrollHeight - 5) this.showBottomGradient = false;
            else if (scrollTop + clientHeight <= scrollHeight - 30) this.showBottomGradient = true;
        },
    },
    mounted() {
    },
}
</script>
  
<style scoped>
.content__wrapper {
    max-height: 568px;
}

.wrapper {
    padding: 16px;
    border-radius: 12px;
    border: 1.5px solid #E5E5EA;
    background: #FFF;
    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    height: 100%;
    overflow: hidden;
    padding-bottom: 0;
}

.companies__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 492px;
    overflow-y: auto;
    scrollbar-width: none;
    /* Для Firefox */
    -ms-overflow-style: none;
    padding-bottom: 16px;
}

.wrapper__gradient {
    position: relative;
}

.gradient-top,
.gradient-bottom {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    width: 100%;
    height: 60px;
    transition: 0.5s;
}

.gradient-top.active,
.gradient-bottom.active {
    opacity: 1;
    visibility: visible;
}

.gradient-top {
    top: 0;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.00) -8.04%, #FFF 100%);
}

.gradient-bottom {
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -8.04%, #FFF 100%);
}

.companies__wrapper::-webkit-scrollbar {
    width: 0;
    /* Для WebKit (Chrome, Safari) */
    display: none;
    /* Для WebKit (Chrome, Safari) */
}

.block {
    padding: 16px;
    position: relative;

    border-radius: 6px;
    border: 1.5px solid #E5E5EA;
    background: #FFF;

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.title-two {
    color: #292929;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 7.5px;
}

.block .count_users {
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: 4px;
    background: #F2F2F2;

    color: #323232;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    padding: 4px 10px;
}

.block .date_time {
    color: rgba(41, 41, 41, 0.80);
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.09px;
    padding-bottom: 16px;
}

.block .name_event {
    color: #292929;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 16px;
}

.block .users {
    height: 24px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #292929;
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.09px;
    margin-bottom: 16px;
}

.block .status {
    padding: 4px 8px;
    border-radius: 4px;
    background: var(--Green-100, #AFF4C6);

    color: var(--Green-400, #39766A);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    width: max-content;
}

.block .details_info {
    position: absolute;
    right: 16px;
    bottom: 16px;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    /* last */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}

@media screen and (max-width: 768px) {}
</style>