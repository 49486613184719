<template>
    <input type="text" v-model="currentValue" @input="this.$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder">
</template>

<script>
export default {
    name: 'input-text',
    props: {
        placeholder: String,
        value: String,
    },
    data() {
        return {
            currentValue: '',
        }
    },
    watch: {
        value: {
            handler(newData) {
                this.currentValue = this.value;
                this.$emit('update:modelValue', this.currentValue);
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>

<style scoped>
input {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */

    padding-left: 12px;
    height: 50px;
    width: 100%;
    outline: none;
}
</style>