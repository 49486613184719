<template>
    <div class="confirmation__block">
      <div class="btn">
            <slot></slot>
      </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.confirmation__block{
  position: fixed;
  bottom: 0;
  margin-top: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  width: 100%;
  padding: 34px 58px;
}
.confirmation__block :slotted(button){
    max-width: 239px;
    width: 100%;
}
.confirmation__block .btn{
  justify-content: space-between;
}
</style>