<template>
    <div class="transfer__wrapper">
        <div class="title">Add hotels from which transfers are possible</div>
        <div class="content">
            <div class="input-wrapper">            
                <label for="transfer_input">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_6508_67403)">
                        <path d="M15.4795 14H14.6895L14.4095 13.73C15.3895 12.59 15.9795 11.11 15.9795 9.5C15.9795 5.91 13.0695 3 9.47949 3C5.88949 3 2.97949 5.91 2.97949 9.5C2.97949 13.09 5.88949 16 9.47949 16C11.0895 16 12.5695 15.41 13.7095 14.43L13.9795 14.71V15.5L18.9795 20.49L20.4695 19L15.4795 14ZM9.47949 14C6.98949 14 4.97949 11.99 4.97949 9.5C4.97949 7.01 6.98949 5 9.47949 5C11.9695 5 13.9795 7.01 13.9795 9.5C13.9795 11.99 11.9695 14 9.47949 14Z" fill="#292929"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_6508_67403">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>
                </label>
                <input id="transfer_input" type="text" placeholder="Enter hotel name" @focusin="target = true" @blur="handleBlur">
            </div>
            <div class="list__wrapper" v-if="target">
                <div class="lists">
                    <div class="item" v-for="item in hotel_data">
                            <input :id="'hotel'+item.id" class="checkbox_input" :value="item" type="checkbox" v-model="selectedItems" />
                            <label :for="'hotel'+item.id">
                                <div class="wrapper__info">
                                    <div class="title">{{ item.name }}</div>
                                    <div class="text">{{ item.address }}</div>
                                </div>
                            </label>
                    </div>
                </div>
                <div class="line"></div>
                <div class="button" @click="target = false; handleCheckboxChange()"> 
                    Add
                </div>
            </div>
        </div>
        <div class="selected__items" v-show="itemsAdd.length > 0">
            <div class="item" v-for="(item,index) in itemsAdd">
                <div class="title">{{ item.name }}</div>
                <div class="address">{{ item.address }}</div>
                <div class="delete__item" @click="deleteItems(item.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 1V2H18V4H17V17C17 18.1 16.1 19 15 19H5C3.9 19 3 18.1 3 17V4H2V2H7V1H13ZM5 17H15V4H5V17ZM7 6H9V15H7V6ZM13 6H11V15H13V6Z" fill="#292929"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                target: false,
                hotel_data: [
                    {
                        id: 1,
                        name: 'Hilton Garden Inn Bali Ngurah Rai Airport',
                        address: 'Jalan Airport Ngurah Rai No. 7, Tuban, 80361'
                    },
                    {
                        id: 2,
                        name: 'The Vira Bali Boutique Hotel & Suite',
                        address: 'Jalan Kartika Plaza No.127, 80361'
                    },
                    {
                        id: 3,
                        name: 'Anima Retreat Bali',
                        address: 'Jalan I Ketut Kobot, 82162 Krambitan'
                    },
                    {
                        id: 4,
                        name: 'Bali Beach Glamping',
                        address: 'Jalan Kebo Iwa Banjar Batu Tampih Kangin, 82121'
                    },
                    {
                        id: 5,
                        name: 'Volcano Terrace Bali',
                        address: 'Jalan Raya Pendakian Gunung Batur, Kintamani, 80652'
                    },
                    {
                        id: 6,
                        name: 'Bali Cliff Glamping',
                        address: 'Jalan Karangasem - Seraya, 80811'
                    },
                    {
                        id: 7,
                        name: 'Bali Cliff Glamping',
                        address: 'Jalan Karangasem - Seraya, 80811'
                    },
                ],
                itemsAdd:[],
                selectedItems: [],
            }
        },
        methods: {
            deleteItems(id){
                this.itemsAdd = this.itemsAdd.filter(item => item.id !== id);
            },
            handleBlur() {
                setTimeout(() => {
                    if (!this.$el.contains(document.activeElement)) {
                    this.target = false;
                    }
                }, 100);
            },
            handleCheckboxChange() {
                this.itemsAdd = this.selectedItems;
                this.$emit('update:modelValue', this.selectedItems);
            }
        },
        watch: {
            itemsAdd: {
                handler(val, oldVal) {
                    this.selectedItems = this.itemsAdd;
                    this.$emit('update:modelValue', this.selectedItems);
                },
            deep: true
        },
    },
    }
</script>

<style  scoped>
.delete__item{
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.selected__items{
    display: flex;
    flex-direction: column;
}
.selected__items .item{
    height: 56px;
    padding: 8px 0;
    border-bottom: 1px solid #E5E5EA;
    position: relative;
}
.selected__items .item:last-child{
    border-bottom: unset;
}
.selected__items .item .title{
    color: var(--Black-400, #292929);
    leading-trim: both;
    text-edge: cap;
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.102px;
    padding-bottom: 8px;
}
.selected__items .item .address{
    color: var(--Black-400, #292929);
    leading-trim: both;
    text-edge: cap;
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.078px;
}
.line{
    background: #E5E5EA;
    width: 100%;
    height: 1px;
    margin-top: 4px;
    margin-bottom: 20px;
}
.checkbox__wrapper{
  display: flex;
  flex-wrap: wrap;
  max-width: 413px;
  gap: 16px;
}
.checkbox{
  width: calc(100%);
  display: flex;
  align-items: center;
  gap: 12px;

  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}
.checkbox_input label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;

}

.checkbox_input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox_input + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox_input + label:hover::before {
  border-radius: 4px;
  border: 2px solid #606060;
}

.checkbox_input + label::before {
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  border: 2px solid #292929;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  transition: 0.3s;
}

.checkbox_input:checked + label::before {
  border-color: #292929;
  background-color: #292929;
}

.transfer__wrapper{
    padding: 24px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.transfer__wrapper > .title{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.102px;
}
.transfer__wrapper .content{
    position: relative;
}
.content .input-wrapper{
    border-radius: 10px;
    border: 1px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0px 12px;
}
.content .input-wrapper label{
    height: 24px;
}
.content .input-wrapper > input{
    width: 100%;
    color: rgba(41, 41, 41, 0.72);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.102px;
    height: 100%;
    outline: none;

}
.list__wrapper{
    position: absolute;
    top: calc(100% + 4px);
    border-radius: 10px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 12px;
    padding-bottom: 24px;
    width: 100%;
    z-index: 10;
}
.content .lists{
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow-y: auto;
    max-height: 300px;
}
.content .lists .item{

    display: flex;
    align-items: center;
}
.content .lists .item .title{
    color: var(--Black-400, #292929);
    leading-trim: both;
    text-edge: cap;
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.102px;
    padding-bottom: 8px;
}
.content .lists .item .text{
    color: var(--Black-400, #292929);
    leading-trim: both;
    text-edge: cap;
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.078px;
}
.content .lists .item label{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 49px;
    width: 100%;
    padding: 10px 10px;
}
.list__wrapper .button{
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    display: flex;
    width: 120px;
    height: 44px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.checkbox_input:checked + label{
    border-radius: 4px;
    background: #F5F5F5;
}
.content .lists .item label .wrapper__info{
    display: flex;
    flex-direction: column;
    width: 90%;
}
@media screen and (max-width: 768px) {
    .content .lists .item .title{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    }
    .content .lists .item .text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .selected__items .item .title{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 82%;
    }
    .selected__items .item .address{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 82%;
    }
}
</style>