<template>
    <div class="wrapper">
        <button @click="decrement">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5 12H19" stroke="#292929" stroke-width="2" stroke-linecap="round" />
            </svg>
        </button>
        <input type="text" v-model="selectedNumber" />
        <button @click="increment"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none">
                <path
                    d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5Z"
                    fill="#333333" />
            </svg></button>
    </div>
</template>

<script>
export default {
    name: 'number-picker',
    props: {
        value: String,
    },
    data() {
        return {
            selectedNumber: 0
        };
    },
    methods: {
        increment() {
            this.selectedNumber++;
            this.$emit('update:modelValue', this.selectedNumber);
        },
        decrement() {
            if (this.selectedNumber > 0) {
                this.selectedNumber--;
                this.$emit('update:modelValue', this.selectedNumber);
            }
        }
    },
    watch: {
        value: {
            handler(newData) {
                this.selectedNumber = newData;
                this.$emit('update:modelValue', this.selectedNumber);
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>

<style scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 50px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    max-width: 414px;

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.wrapper input {
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 23.8px */
    letter-spacing: 0.102px;
}

.wrapper input {
    text-align: center;
    outline: none;
}</style>