<template>
  <div class="preloader">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'list-preloader'
}
</script>

<style scoped>
.preloader{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.preloader div{
  border-radius: 12px;
  background-color: #808080;
  height: 50px; /* Цвет волны соответствует цвету фона */
}

.preloader div{
  -webkit-animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
  animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }
  100% {
    background: #D3D3D3;
  }
}
@keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }
  100% {
    background: #D3D3D3;
  }
}
</style>