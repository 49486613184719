<template>
  <PreloaderBanner v-show="isLoading"></PreloaderBanner>
  <div v-show="!isLoading" class="first__banner" v-for="item in array">
    <img data-load :src="this.$store.state.base_directory + item.image"  alt="">
    <div class="content">
      <div class="title">{{item.title}}</div>
      <div class="description">{{item.description}}</div>
      <SliderSectionSearch v-if="windowWidth <= 768" class="mobile" :placeholder_title="'Where are you going ? '"></SliderSectionSearch>
    </div>
    <SliderSectionSearch v-if="windowWidth >= 768" class="mobile-none" :additionalClass="'mobile-none'" :placeholder_title="'Where are you going ? '"></SliderSectionSearch>
  </div>

</template>

<script>

import SliderSectionSearch from "@/components/MainPages/HomePage/SliderSectionSearch.vue";
import PreloaderBanner from "@/components/MainPages/HomePage/PreloaderBanner.vue";
export default {
  components: {SliderSectionSearch,PreloaderBanner},
  props: {
    array: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.mobile{
  display: none;
}
.first__banner{
  position: relative;
  width: 100%;
}
.first__banner img{
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(41, 41, 41, 0.16) 0%, rgba(41, 41, 41, 0.16) 100%), lightgray 0px -133.059px / 100% 181.723% no-repeat;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  max-height: 448px;
  object-fit: cover;
}
.first__banner .content{
  position: absolute;
  bottom: 30px;
  left: 30px;
  max-width: 500px;
  margin-bottom: 48px;
}
.first__banner .content .title{
  color: #FFF;
  text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  font-family: Standerd;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.276px;
  padding-bottom: 10px;
}
.first__banner .content .description{
  color: #FFF;
  text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 24px;
}
@media screen and (max-width: 768px){

  .first__banner img{
    aspect-ratio: 1 / 1;
    width: 100%;
    border-radius: 0px 0px 32px 32px;
    max-height: 390px;
  }
  .first__banner .content{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 16px;
    max-width: unset;
    bottom: 0;
    left: 0;
  }
  .first__banner .content .title{
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 13px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    font-family: Standerd;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.204px;
    padding-bottom: 24px;
  }
  .first__banner .content .description{
    display: none;
  }
  .mobile-none{
    display: none;
  }
  .mobile{
    display: flex;
  }
}

</style>