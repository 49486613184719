<template>
  <section class="reviews__container">
    <div class="title__wrapper">
      <div class="title">Customer reviews</div>
      <div class="reviews__rate__wrapper">
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
          <path
              d="M9.23769 0.966217C9.57241 0.344594 10.4276 0.344595 10.7623 0.966217L13.4141 5.89089C13.539 6.1228 13.754 6.28636 14.0033 6.33902L19.2973 7.4571C19.9655 7.59824 20.2297 8.44958 19.7684 8.9749L16.1134 13.1366C15.9413 13.3326 15.8591 13.5972 15.8883 13.8617L16.5083 19.4774C16.5866 20.1862 15.8947 20.7124 15.2749 20.4154L10.3642 18.0628C10.1329 17.952 9.86708 17.952 9.63583 18.0628L4.72512 20.4154C4.10526 20.7124 3.4134 20.1862 3.49167 19.4774L4.1117 13.8617C4.1409 13.5972 4.05875 13.3326 3.88663 13.1366L0.231614 8.9749C-0.229745 8.44958 0.0345214 7.59824 0.702751 7.4571L5.99666 6.33902C6.24595 6.28636 6.46103 6.1228 6.5859 5.89089L9.23769 0.966217Z"
              fill="#292929"/>
        </svg>
        {{ average.average_rating }}
        </div>
        ({{ average.total_ratings }} reviews)
        
      </div>
    </div>
    <div class="mobile__filters" @click="showModal = !showModal">
      Sort by
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.5 13.125C7.5 12.9592 7.56585 12.8003 7.68306 12.6831C7.80027 12.5658 7.95924 12.5 8.125 12.5H11.875C12.0408 12.5 12.1997 12.5658 12.3169 12.6831C12.4342 12.8003 12.5 12.9592 12.5 13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75H8.125C7.95924 13.75 7.80027 13.6842 7.68306 13.5669C7.56585 13.4497 7.5 13.2908 7.5 13.125ZM5 9.375C5 9.20924 5.06585 9.05027 5.18306 8.93306C5.30027 8.81585 5.45924 8.75 5.625 8.75H14.375C14.5408 8.75 14.6997 8.81585 14.8169 8.93306C14.9342 9.05027 15 9.20924 15 9.375C15 9.54076 14.9342 9.69973 14.8169 9.81694C14.6997 9.93415 14.5408 10 14.375 10H5.625C5.45924 10 5.30027 9.93415 5.18306 9.81694C5.06585 9.69973 5 9.54076 5 9.375ZM2.5 5.625C2.5 5.45924 2.56585 5.30027 2.68306 5.18306C2.80027 5.06585 2.95924 5 3.125 5H16.875C17.0408 5 17.1997 5.06585 17.3169 5.18306C17.4342 5.30027 17.5 5.45924 17.5 5.625C17.5 5.79076 17.4342 5.94973 17.3169 6.06694C17.1997 6.18415 17.0408 6.25 16.875 6.25H3.125C2.95924 6.25 2.80027 6.18415 2.68306 6.06694C2.56585 5.94973 2.5 5.79076 2.5 5.625Z" fill="#292929"/>
      </svg>
    </div>
    <div class="reviews__content">
      <div class="review__filters">
        <div class="filters__wrapper">
          <div class="title">Sort by:</div>
          <div class="sort__filters">
              <div class="checkbox" v-for="item in event__duration">
                <input :id="item.name+'-'+item.value" name="124" v-model="filters.sortFilter" @change="changeRadio" :value="item.value" class="checkbox_input" type="checkbox"/>
                <label :for="item.name+'-'+item.value"></label>
                {{ item.name }}
              </div>
          </div>
        </div>
        <div class="filters__wrapper">
          <div class="title">Star rating: </div>
          <div class="rate__filters">
            <template v-for="rate in ratings">
              <div class="checkbox">
                 <input :id="rate.count_star" v-model="filters.starsFilter" @change="changeRadio" class="checkbox_input" :value="rate" type="checkbox"/>
                  <label :for="rate.count_star"></label>
               <div class="count_stars">
                  <svg v-for="n in rate.count_star" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" fill="#292929" stroke="#292929" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <svg v-for="n in 5-rate.count_star" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" stroke="#292929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                ({{rate.count}}) 
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="reviews__posts">
        <div class="reviews__post__wrapper">
          <div class="reviews__post" v-for="(post,postIndex) in visibleItems">
            <div class="contact__info">
              <div class="top">
                <img :src="require('@/assets/image/1.png')" alt="">
                <div class="right">
                  <div class="name">{{ post.name }}</div>
                  <div class="date">{{ post.date }}</div>
                </div>
              </div>
              <div class="bottom">
                <svg v-for="stars in post.rate" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11.6586 3.73742C11.6886 3.67237 11.7367 3.61728 11.797 3.57867C11.8574 3.54005 11.9275 3.51953 11.9991 3.51953C12.0708 3.51953 12.1409 3.54005 12.2013 3.57867C12.2616 3.61728 12.3096 3.67237 12.3396 3.73742L14.6624 8.77292C14.6892 8.83113 14.7306 8.88148 14.7824 8.91916C14.8343 8.95685 14.895 8.9806 14.9586 8.98817L20.4644 9.64067C20.5356 9.64913 20.6028 9.67781 20.6582 9.72332C20.7136 9.76883 20.7547 9.82925 20.7768 9.89744C20.7989 9.96562 20.801 10.0387 20.7829 10.108C20.7647 10.1774 20.7271 10.2401 20.6744 10.2887L16.6041 14.0537C16.5572 14.0972 16.5223 14.1521 16.5026 14.213C16.4829 14.2739 16.4791 14.3389 16.4916 14.4017L17.5716 19.8399C17.5857 19.9102 17.5793 19.9831 17.5532 20.0499C17.5271 20.1167 17.4823 20.1746 17.4243 20.2168C17.3663 20.2589 17.2974 20.2836 17.2258 20.2878C17.1542 20.2919 17.0829 20.2755 17.0204 20.2404L12.1829 17.5322C12.1268 17.5006 12.0635 17.4841 11.9991 17.4841C11.9348 17.4841 11.8715 17.5006 11.8154 17.5322L6.97788 20.2397C6.9154 20.2747 6.84419 20.2911 6.77269 20.2869C6.70118 20.2827 6.63237 20.2582 6.57438 20.2161C6.5164 20.1741 6.47167 20.1163 6.44548 20.0496C6.4193 19.983 6.41276 19.9102 6.42663 19.8399L7.50663 14.4017C7.51928 14.3388 7.51558 14.2737 7.49588 14.2126C7.47618 14.1516 7.44115 14.0966 7.39413 14.0529L3.32313 10.2879C3.27068 10.2393 3.23324 10.1767 3.21522 10.1074C3.1972 10.0382 3.19935 9.96527 3.22143 9.89723C3.24351 9.82919 3.28458 9.76888 3.33981 9.72342C3.39504 9.67796 3.46212 9.64925 3.53313 9.64067L9.03963 8.98817C9.10342 8.98073 9.16424 8.95702 9.21624 8.91933C9.26825 8.88164 9.3097 8.83123 9.33663 8.77292L11.6586 3.73742Z" fill="#292929" stroke="#292929" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <div class="review__info">
              <div v-if="post.text" class="text">{{ post.text }}</div>
              <div v-if="post.video" class="video-wrapper">
                <video :ref="'video-' + postIndex" :src='this.$store.state.base_directory + "reviews/"+ post.event_id + "/"+ post.video' @click="togglePlay(postIndex)" @play="onPlay(postIndex)" @pause="onPause(postIndex)"></video>
                <div :ref="'playButton-' + postIndex" class="play-button" @click="playVideo(postIndex)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="44" viewBox="0 0 42 44" fill="none">
                  <path d="M38.2987 16.1768C39.3553 16.7387 40.2391 17.5775 40.8554 18.6033C41.4718 19.6291 41.7974 20.8033 41.7974 22C41.7974 23.1968 41.4718 24.3709 40.8554 25.3968C40.2391 26.4226 39.3553 27.2614 38.2987 27.8232L10.1131 43.1502C5.57463 45.6207 0 42.4088 0 37.3292V6.67308C0 1.59123 5.57463 -1.61848 10.1131 0.847651L38.2987 16.1768Z" fill="#FFFFFFCC"/>
                </svg>
                </div>
                <div v-if="post.video" class="range-wrap">
                <input v-if="post.video" type="range" min="0" max="1" step="0.01" 
                :ref="'volume-' + postIndex" @input="setVolume(postIndex, $event)" 
                class="volume-slider">
                </div>
              </div>

              
              <div v-if="post.image" class="image__list mobile-none">
                <template v-for="(image,index) in post.image">
                  <img v-if="index<6" 
                  :src='this.$store.state.base_directory + "reviews/"+ post.event_id + "/"+ image' alt=""
                  @click="handleImageClick(postIndex, index)">
                  <div v-if="index == 6">
                    <img :src='this.$store.state.base_directory + "reviews/"+ post.event_id + "/"+ image' alt="">
                    <div @click="handleImageClick(postIndex, index)" class="image__more">+{{ post.image.length - 6}}</div>
                  </div>
                  </template>
                  
              </div>
              <div v-if="post.image" class="image__list mobile">
                <template v-for="(image,index) in post.image">
                  <img v-if="index<2" 
                  :src='this.$store.state.base_directory + "reviews/"+ post.event_id + "/"+ image' alt=""
                  @click="handleImageClick(postIndex, index)">
                  <div v-if="index == 2">
                    <img :src='this.$store.state.base_directory + "reviews/"+ post.event_id + "/"+ image' alt="">
                    <div @click="handleImageClick(postIndex, index)" class="image__more">+{{ post.image.length - 2}}</div>
                  </div>
                  </template>
                  
              </div>
            </div>
          </div>
          </div>
        <div class="view__more" v-if="!showAll && reviews.length >4" @click="showAllItems">
          View more
        </div>
      </div>
    </div>
    <ModalReviewsFilter :ratings="ratings" v-model:filters="filters" @filters="changeRadio" v-model:show="showModal" :modal="true"></ModalReviewsFilter>
  </section>
</template>

<script>
import ModalReviewsFilter from "@/components/MainPages/EventPage/ModalReviewsFilter.vue";
export default {
  components: {
    ModalReviewsFilter,
  },
  props: {
    ratings: Object,
    reviews: [Object,Array],
    average: [Object,Array],
    filters: Object,
  },
  data() {
    return {
      event__duration: [
        {name: 'Video Reviews', value: '1'},
        {name: 'Text reviews', value: '2'},
      ],
      rates:[
        {count_stars:5, count:5},
        {count_stars:4, count:4},
        {count_stars:3, count:3},
        {count_stars:2, count:2},
        {count_stars:1, count:1}
      ],
      showAll: false,
      limit: 4,
      filters: {
      sortFilter: [],
      starsFilter: [],
      },
      showModal: false,
    }
  },
  methods: {
    getVideoRef(index) {
      return this.$refs[`video-${index}`][0];
    },
    getPlayButtonRef(index) {
      return this.$refs[`playButton-${index}`][0];
    },
    getVolumeRef(index) {
      return this.$refs[`volume-${index}`][0];
    },
    playVideo(index) {
      const video = this.getVideoRef(index);
      if (video) {
        video.play();
      }
    },
    togglePlay(index) {
      const video = this.getVideoRef(index);
      if (video) {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
      }
    },
    onPlay(index) {
      const playButton = this.getPlayButtonRef(index);
      if (playButton) {
        playButton.classList.add('hidden');
      }
    },
    onPause(index) {
      const playButton = this.getPlayButtonRef(index);
      if (playButton) {
        playButton.classList.remove('hidden');
      }
    },
    setVolume(index, event) {
      const video = this.getVideoRef(index);
      if (video) {
        video.volume = event.target.value;
      }
    },
    updateRangeValue(index, event) {
      const input = this.getVolumeRef(index);
      const value = input.value;
    },
    handleImageClick(postIndex, imageIndex) {
      let globalIndex = 0;
      for (let i = 0; i < postIndex; i++) {
        globalIndex += this.visibleItems[i].image ? this.visibleItems[i].image.length : 0;
      }
      globalIndex += imageIndex;
      console.log('Global clicked image index:', globalIndex);
      this.$emit('openPhotos',globalIndex)
    },
    showAllItems() {
      this.showAll = true;
    },
    changeRadio() {
      this.$emit('update:filters', this.filters);
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.visibleItems.forEach((post, postIndex) => {
        const video = this.getVideoRef(postIndex);
        const volumeSlider = this.getVolumeRef(postIndex);
        if (video && volumeSlider) {
          video.volume = 0.5;
          volumeSlider.value = 0.5;
        }
      });
    });
  },
  computed: {
    visibleItems() {
      if (this.showAll) {
        return this.reviews;
      } else {
        return this.reviews.slice(0, this.limit);
      }
    }
  },
  watch: {
  },
}
</script>

<style scoped>
.mobile{
  display: none !important;
}
.review__info {
    max-width: 100%;
}
.range-wrap {
  position: relative;
  width: 100%;
}
.volume-slider {
width: 100%;
accent-color: #333333;
background-color: #E5E5EA;
height: 6px;
}
.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #333333;
  border-radius: 8px;
  height: 0;
  width: 0;
}
.video-wrapper {
  position: relative;
  width: 100%;
}
video {
  width: 100%;
  cursor: pointer;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.play-button.hidden {
  display: none;
}
.play-button svg{
  fill: rgba(255, 255, 255, 0.80);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
  backdrop-filter: blur(17px);
}


.image__list{
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  overflow-x: overlay;
}
.image__list img{
  border-radius: 16px;
  -o-object-fit: cover;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  max-width: 100px;
}
.image__list > div{
  position: relative;
  display: inline-block;
  min-width: 100px;
}
.image__list .image__more {
  border-radius: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  color: rgba(255, 255, 255, 0.80);
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  letter-spacing: 0.192px;
}
.image__more::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
  border-radius: 16px; /* Скругленные углы для псевдоэлемента */
  z-index: -1; /* Позади текста */
}
.mobile__filters{
  display: none;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);

  /* 2 */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.352px;
  padding-left: 16px;
  padding-right: 8px;
}
.view__more{
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Gray-400, #606060);
font-family: Standerd;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.36px;
margin-top: 32px;
height: 50px;

border-radius: 8px;
border: 1.5px solid var(--Gray-stroke, #E5E5EA);
}
.reviews__content{
  display: flex;
  gap: 32px;
}
.reviews__posts{
  width: 100%;
}
.reviews__posts .review__info .text{
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  letter-spacing: 0.12px;
  padding-bottom: 16px;
}
.reviews__posts .review__info video{
  width: 180px;
  height: 180px;
  border-radius: 180px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray -502.204px -176.479px / 459.222% 321.387% no-repeat;
  object-fit: cover;
}
.reviews__post__wrapper{
  border-radius: 16px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 24px;
  padding-bottom: 32px;

}
.reviews__post{
  display: flex;
  gap: 16px;
  padding: 24px 0;
  border-bottom: 1px solid #E5E5EA;
}
.reviews__post:first-child{
  padding-top: 0;
}
.reviews__post:last-child{
  padding-bottom: 0;
  border-bottom: unset;
}
.reviews__post .contact__info{
 display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 192px;
  width: 100%;
}
.contact__info .top{
  display: flex;
  gap: 16px;
}
.contact__info .bottom{

}
.contact__info .top img{
  border-radius: 52px;
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  object-fit: cover;
}
.contact__info .top .right{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact__info .top .right .name{
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 30.8px */
  letter-spacing: 0.132px;
  text-wrap: nowrap;

}
.contact__info .top .right .date{
  color: var(--Gray-400, #606060);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.108px;
  text-wrap: nowrap;
}
.sort__filters{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.checkbox{
  display: flex;
  align-items: center;
  gap: 12px;

  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}
.checkbox_input label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;

}

.checkbox_input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox_input + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox_input + label:hover::before {
  border-radius: 4px;
  border: 2px solid #606060;
}

.checkbox_input + label::before {
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  border: 2px solid #E5E5EA;
  width: 20px;
  height: 20px;
  transition: 0.3s;
}

.checkbox_input:checked + label::before {
  border-color: #292929;
  background-color: #292929;
}
.review__filters{
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  padding-bottom: 30px;
  width: 322px;
  min-width: 322px;

  border-radius: 16px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: #FFF;

  height: max-content;
  /* 2 */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.review__filters .filters__wrapper .title{
  color: #292929;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.108px;
  padding-bottom: 16px;
}
.title__wrapper {
  display: flex;
  gap: 30px;
  align-items: center;
  padding-bottom: 20px;
}

.title__wrapper .title {
  color: #292929;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.192px;
}

.title__wrapper .reviews__rate__wrapper {
  color: #292929;
  font-family: Standerd;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.144px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.title__wrapper .reviews__rate__wrapper > div {

}
@media screen and (max-width: 1024px) {
  .mobile__filters{
    display: flex;
    margin-bottom: 16px;
  }
  .reviews__content{
    flex-direction: column;
  }
  .title__wrapper .reviews__rate__wrapper{
    flex-direction: column;
    align-items: end;
    gap: 8px;
    color: #292929;
font-family: Standerd;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.102px;
  }
  .title__wrapper .reviews__rate__wrapper > div{
    color: #292929;
font-family: Standerd;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.12px;
  }
  .title__wrapper .title{
    color: #292929;
font-family: Standerd;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.12px;
  }
  .title__wrapper{
    justify-content: space-between;
    gap: 0;
    padding: 24px;
    border-radius: 16px;
border: 1.5px solid var(--Gray-stroke, #E5E5EA);
background: #FFF;
align-items: unset;
/* 2 */
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
margin-bottom: 16px;
  }
  .review__filters{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .mobile{
  display: flex !important;
}
.mobile-none{
  display: none !important;
}
  .review__info {
    width: max-content;
    }
  .reviews__post{
    flex-direction: column;
    border-radius: 16px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: #FFF;

  /* 2 */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 24px;
  }
  .reviews__post__wrapper{
    border-radius: unset;
    border: unset;
    background: #FFF;
    box-shadow: unset;
    padding: unset;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .reviews__post:first-child{
  padding-top: 24px;
}
.reviews__post:last-child{
  padding-bottom: 24px;
  border-bottom: unset;
}
}
</style>