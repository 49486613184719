<template>
  <div class="dialog__currency" :class="{ active: show }" @click.stop="hideDialog">
    <div class="bg_color"></div>
    <div  class="login_content">
      <Button class="exit-dialog" @click="hideDialog">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
        </svg>
      </Button>
      <div class="title">Select a language and region</div>
      <div class="content">
        <div v-for="(value,index) in data" :class="{ selected: value.code ==  select}" class="block" @click="changeLang(value)">
          <div class="currency__flag">
            <img class="flag" :src='this.$store.state.base_directory + "flags/"+value.country+".png"' alt="">
            <div class="currency__name">{{ value.name }}</div>
          </div>
          
          <div class="currency__code">{{ value.country }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  props: {
    data: Object,
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      select: this.$route.query.lang,
    }
  },
  methods: {
    changeLang(value){
      const url = new URL(window.location.href);
      url.searchParams.set("lang", value.code);
      this.select = value.code;
      window.history.pushState({}, "", url.toString());
      store.dispatch('updateLang', value);
      // window.location.reload();
    },
    hideDialog() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style scoped>
button.exit-dialog{
  top: 24px;
  right: 24px;
}
.bg_color{
  opacity: 0.32;
  background: #292929;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transition: opacity 1s cubic-bezier(.16,1,.3,1);
}
.dialog__currency{
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  position: fixed;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.dialog__currency.active{
  visibility: visible;
  opacity: 1;
}
.login_content {
  padding: 24px;
  width: 100%;
  max-width: 606px;
  z-index: 999;
  position: relative;
  border-radius: 16px;
  background: var(--White-100, #FFF);

  /* 2 */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.login_content .title{
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom:  24px;
}
.login_content .content{
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.login_content .block{

  width: calc((100% / 3) - 16px);
  border-radius: 8px;
  border: 2px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.login_content .block.selected{
  border: 2px solid var(--Black-400, #292929);
}
.login_content .block .currency__flag{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.login_content .block .currency__flag .flag{
  max-width: 20px;
  max-height: 15px;
  min-width: 20px;
  min-height: 15px;
}
.block .currency__name{
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.block .currency__code{
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 1024px) {
  .login_content {
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0;
  padding: 16px;
  max-width: unset;
  }
  .login_content .block .currency__flag{
  flex-direction: row;
  gap: 8px;
  }
  .login_content .block{
    width: 100%;
    height: 50px;
    flex-direction: row;
    justify-content: space-between;
  }
  .login_content .block .wrapper{
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .login_content .content{
    gap: 12px;
  }
  button.exit-dialog{
    top: 16px;
    right: 16px;
  }
}
</style>