<template>
  <div class="password">
    <input  :type="type" type="password" id="password-input" @input="this.$emit('update:password', $event.target.value)" :placeholder='placeholder' name="password" >
    <a href="#" class="password-control" @click="showHidePassword">
      <svg :class="{'show': show}" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10.0002 7.5C10.6632 7.5 11.2991 7.76339 11.7679 8.23223C12.2368 8.70107 12.5002 9.33696 12.5002 10C12.5002 10.663 12.2368 11.2989 11.7679 11.7678C11.2991 12.2366 10.6632 12.5 10.0002 12.5C9.33712 12.5 8.70124 12.2366 8.23239 11.7678C7.76355 11.2989 7.50016 10.663 7.50016 10C7.50016 9.33696 7.76355 8.70107 8.23239 8.23223C8.70124 7.76339 9.33712 7.5 10.0002 7.5ZM10.0002 3.75C14.1668 3.75 17.7252 6.34167 19.1668 10C17.7252 13.6583 14.1668 16.25 10.0002 16.25C5.8335 16.25 2.27516 13.6583 0.833496 10C2.27516 6.34167 5.8335 3.75 10.0002 3.75ZM2.65016 10C3.32371 11.3753 4.36958 12.534 5.66889 13.3444C6.96821 14.1548 8.46883 14.5844 10.0002 14.5844C11.5315 14.5844 13.0321 14.1548 14.3314 13.3444C15.6307 12.534 16.6766 11.3753 17.3502 10C16.6766 8.62474 15.6307 7.46604 14.3314 6.65562C13.0321 5.8452 11.5315 5.41557 10.0002 5.41557C8.46883 5.41557 6.96821 5.8452 5.66889 6.65562C4.36958 7.46604 3.32371 8.62474 2.65016 10Z" fill="#888888"/>
      </svg>
      <svg style="max-width: 20px; max-height: 20px" :class="{'show': !show}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_1239_16742)">
          <path d="M12 7C14.76 7 17 9.24 17 12C17 12.65 16.87 13.26 16.64 13.83L19.56 16.75C21.07 15.49 22.26 13.86 22.99 12C21.26 7.61 16.99 4.5 11.99 4.5C10.59 4.5 9.25 4.75 8.01 5.2L10.17 7.36C10.74 7.13 11.35 7 12 7ZM2 4.27L4.28 6.55L4.74 7.01C3.08 8.3 1.78 10.02 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.8 19.08L19.73 22L21 20.73L3.27 3L2 4.27ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.78 9 12C9 13.66 10.34 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C9.24 17 7 14.76 7 12C7 11.21 7.2 10.47 7.53 9.8ZM11.84 9.02L14.99 12.17L15.01 12.01C15.01 10.35 13.67 9.01 12.01 9.01L11.84 9.02Z" fill="#888888"/>
        </g>
        <defs>
          <clipPath id="clip0_1239_16742">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'input-password',
  props: {
    password: String,
    placeholder: String,
  },
  data() {
    return {
      type: 'password',
      show: false,
    }
  },
  methods: {
    showHidePassword() {
      this.show = !this.show;
      if(this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    }
  },
}
</script>

<style scoped>
input::placeholder {
  color: var(--gray-300, #888);
}
input{
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  height: 40px;
  color: var(--gray-300, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  padding-left: 12px;
}
.password {
  position: relative;
}

.password input{
  position: relative;
  width: 100%;
}
.password.error input{
  border-radius: 8px;
  border: 1.5px solid var(--red-200, #EA2313);
}
.password-control svg{
  display: none;
}
.password-control svg.show{
  display: block;
}
.password-control {
  position: absolute;
  top: 10px;
  right: 12px;
  display: inline-block;
  width: 20px;
  height: 20px;
}
</style>