<template>
    <div class="container">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 16px;

}
</style>