<template>
  <section class="bread__crumb">
    <div class="bread__crumb__container">
      <div :class="{ unactive: !loading}" >
      <div v-if="loading" class="bread__crumb_content">
        <router-link v-if="exit" :to="{name: 'AdminEvents'}">
        <Button class="exit-dialog">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
          </svg>
        </Button>
        </router-link>
        <div class="arrow__back"><svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
          <path d="M0.707107 5.70711C0.316582 5.31658 0.316582 4.68342 0.707106 4.29289L4.29289 0.707107C4.92286 0.0771425 6 0.523308 6 1.41421L6 8.58579C6 9.47669 4.92286 9.92286 4.29289 9.29289L0.707107 5.70711Z" fill="#333333"/>
        </svg>
        </div>
        <a href="">Homepage</a>
        <div class="circle"></div>
        <a href="">{{post[0].country_name}}</a>
        <div class="circle"></div>
        <a href="">{{ post[0].city_name }}</a>
        <div class="circle"></div>
        <a href="">{{ post[0].event_name }}</a>
        </div>
      </div>
      <div v-if="loading" class="post_title">{{ post[0].event_name }}</div>
      <div v-if="loading" class="post_inf">
        <div class="post_rate">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M8.31392 0.419595C8.61517 -0.139865 9.38483 -0.139865 9.68608 0.419596L12.0727 4.8518C12.1851 5.06052 12.3786 5.20773 12.603 5.25511L17.3675 6.26139C17.9689 6.38841 18.2068 7.15463 17.7915 7.62741L14.502 11.3729C14.3471 11.5493 14.2732 11.7875 14.2995 12.0255L14.8575 17.0796C14.9279 17.7176 14.3053 18.1911 13.7474 17.9239L9.32775 15.8065C9.11963 15.7068 8.88037 15.7068 8.67225 15.8065L4.25261 17.9239C3.69474 18.1911 3.07206 17.7176 3.1425 17.0796L3.70053 12.0255C3.72681 11.7875 3.65287 11.5493 3.49797 11.3729L0.208452 7.62741C-0.206771 7.15463 0.0310692 6.38841 0.632476 6.26139L5.39699 5.25511C5.62136 5.20773 5.81493 5.06052 5.92731 4.8518L8.31392 0.419595Z" fill="#292929"/>
          </svg>
          <div class="number">{{ post[0].rate == null ? '4.94': post[0].rate  }}</div>
          <div class="count">(100)</div>
        </div>
        <div class="post_country">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.99944 1.6073C11.8465 1.6073 13.6179 2.34104 14.9239 3.64709C16.23 4.95315 16.9637 6.72454 16.9637 8.57158C16.9637 11.5144 14.8959 14.7216 10.8137 18.2273C10.5867 18.4223 10.2973 18.5294 9.99811 18.5291C9.69887 18.5289 9.40966 18.4213 9.18301 18.2259L8.91301 17.9916C5.01159 14.5773 3.03516 11.4487 3.03516 8.57158C3.03516 6.72454 3.76889 4.95315 5.07495 3.64709C6.38101 2.34104 8.1524 1.6073 9.99944 1.6073ZM9.99944 5.89301C9.28904 5.89301 8.60774 6.17522 8.10541 6.67755C7.60308 7.17988 7.32087 7.86118 7.32087 8.57158C7.32087 9.28199 7.60308 9.96329 8.10541 10.4656C8.60774 10.9679 9.28904 11.2502 9.99944 11.2502C10.7098 11.2502 11.3911 10.9679 11.8935 10.4656C12.3958 9.96329 12.678 9.28199 12.678 8.57158C12.678 7.86118 12.3958 7.17988 11.8935 6.67755C11.3911 6.17522 10.7098 5.89301 9.99944 5.89301Z" fill="#292929"/>
          </svg>
          <div class="name">{{ post[0].city_name }}, {{post[0].country_name}}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'bread-crumb',
  props: {
    post: Array,
    exit: Boolean,
    loading: Boolean,
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.unactive{
  min-height: 100px;
  width: 100%;
  border-radius: 16px;

  -webkit-animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
  animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }
  100% {
    background: #D3D3D3;
  }
}
@keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }
  100% {
    background: #D3D3D3;
  }
}
.bread__crumb_content{
  position: relative;
}
.bread__crumb_content button.exit-dialog{
  right: 0;
  top: 0;
}
.bread__crumb_content {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.bread__crumb_content .arrow__back {
  margin-right: 10px;
}
.bread__crumb_content a{
  margin-right: 15px;
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.9%; /* 22.064px */
  letter-spacing: 0.4px;
  opacity: 0.8;
}
.bread__crumb_content a:last-of-type{
  margin-right: 0px;
  opacity: 1;
}
.bread__crumb_content .circle {
  margin-right: 15px;
  width: 6px;
  height: 6px;
  background: #333333;
  opacity: 0.8;
  border-radius: 50%;
}
.bread__crumb_content .circle:last-of-type {
  opacity: 1;
}

.bread__crumb{
  margin-bottom: 24px;
}
.bread__crumb__container {
}
.bread__crumb__container .post_title {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.168px;
  padding-bottom: 16px;
  padding-top: 16px;
}
.bread__crumb__container .post_inf {
  display: flex;
  gap: 16px;
}
.post_inf .post_rate {
  display: flex;
  gap: 4px;
}
.post_inf .number {
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}
.post_inf .count {
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.102px;
}
.post_inf .post_country {
  display: flex;
  gap: 4px;
}
.post_inf .name {
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}
</style>