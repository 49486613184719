<template>
  <div class="diagrams__comparison">
    <slot></slot>
  <div class="comparison__diagrams" v-if="values && values.length > 0">
      <div class="total__wrapper">
        <div class="total">Total Visits</div>
        <div class="count">{{ values[0].count+values[1].count }}</div>
      </div>
      <div class="bg-line"></div>
      <div class="comparison__wrapper">
          <div class="comparison__stats__wrapper">
            <div class="space-between">
              <div class="wrapper__row">
                <div class="vertical__line" :style="{background: `#14AE5C`}"></div>
                <div class="title">Mobile</div>
              </div>
              <div class="wrapper__row">
                <div class="title">Desktop</div>
                <div class="vertical__line" :style="{background: `#0D99FF`}"></div>
              </div>
            </div>
            <div class="space-between">
                <div class="percent">{{ calculatePercentage(values[0].count) }}%</div>
                <div class="percent">{{ calculatePercentage(values[1].count) }}%</div>
            </div>
            <div class="space-between">
              <div class="count">{{ values[0].count }}</div>
              <div class="count">{{ values[1].count }}</div>
            </div>
          </div>
      </div>
      <div class="comparison"  v-if="values && values.length > 0">
        <div class="line" :style="{width: `calc(${ calculatePercentage(values[0].count)}% - 8px)`, background: `#14AE5C`}"></div>
        <div class="line" :style="{width: `calc(${ calculatePercentage(values[1].count)}% - 8px)`, background: `#0D99FF`}"></div>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'comparison-diagrams',
  props: {
    values: Object,
  },
  methods: {
    calculatePercentage(count) {
      if (count == 0) return 0;
      const total = this.values.reduce((sum, item) => sum + item.count, 0);
      return ((count / total) * 100).toFixed(2);
    }
  },
}
</script>

<style scoped>
.diagrams__comparison{
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: 16px;
  height: 100%;
}
.comparison__diagrams{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.total__wrapper .total{
  color: rgba(41, 41, 41, 0.80);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
  padding-bottom: 8px;
}
.total__wrapper .count{
  color: #292929;
  font-family: Standerd;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.144px;
}
.bg-line{
  height: 1.5px;
  background: #E5E5EA;
  width: 100%;
}
.comparison__stats__wrapper{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.comparison__stats__wrapper .space-between{
  display: flex;
  justify-content: space-between;
}
.comparison__stats__wrapper .wrapper__row{
  display: flex;
  gap: 8px;
  align-items: center;
}
.comparison__stats__wrapper .wrapper__row .title{
  color: #292929;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
}
.comparison__stats__wrapper .wrapper__row .vertical__line{
  width: 3px;
  height: 22px;
  border-radius: 2px;
}
.comparison__stats__wrapper .space-between .percent{
  color: #292929;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.096px;
}
.comparison__stats__wrapper .space-between .count{
  color: rgba(41, 41, 41, 0.80);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
}
.comparison__diagrams .comparison{
  display: flex;
  gap: 16px;
}
.comparison .line{
  height: 5px;
  border-radius: 3px;
}
@media screen and (max-width: 768px){

}
</style>