<template>
    <div class="activity-wrapper">
        <label class="activities-take" for="activities-take">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                    fill="#606060" />
            </svg>
            <input class="input-style-none" v-model="currentValue" @focus="showDropDown = true" @blur="handleBlur" @input="this.$emit('update:modelValue', $event.target.value)"
                 type="text" id="activities-take" :placeholder="placeholder">
        </label>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: String,
        value: String,
    },
    data() {
        return {
            showDropDown: false,
            activitiesData: [],
            title: '',
            currentValue: '',
        }
    },
    methods: {
        handleBlur() {
            // Introduce a small delay before hiding the drop-down
            setTimeout(() => {
                if (!this.$el.contains(document.activeElement)) {
                    this.showDropDown = false;
                }
            }, 100);
        },
    },
    watch: {
        value: {
            handler(newData) {
                this.currentValue = newData;
                this.$emit('update:modelValue', this.currentValue);
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>

<style scoped>
.activities-take {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 36px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 12px;
    height: 50px;
    max-width: 764px;
}

.activity-wrapper {
    position: relative;
}


.activities-take.error {
    border-radius: 8px;
    border: 1.5px solid var(--Red-200, #EA2313);
}

.input-style-none {
    border-radius: unset;
    border: unset;
    padding-left: unset;
    height: unset;
}

input {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 36px;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
    outline: none;
}
</style>