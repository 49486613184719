<template>
  <div class="diagrams_lines">
    <slot></slot>
  <div class="diagrams__lines">
      <div class="diagram__line__wrapper" >
        <div class="diagram__line" v-for="(item, index) in values" :key="index">
          <div class="line" :class="item.color" :style="{width: `${ calculatePercentage(item.count)}%`}">
            <div class="percent">{{ calculatePercentage(item.count) }}%</div>
          </div>
          <div class="line__info__wrapper">
            <div class="name">{{ item.status_name }}</div>
            <div class="number">{{ item.count }}</div>
          </div>
        </div>
      </div>
    <div class="result__wrappers">
      <div class="result__wrapper" v-for="(item, index) in values" :key="index">
        <div class="line" :class="item.color">
        </div>
        <div class="line__info__wrapper" >
          <div class="name">{{ item.status_name }}</div>
          <div class="number">{{ item.count }}</div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'lines-diagrams',
  props: {
      values: Object,
  },
  methods: {
    calculatePercentage(count) {
      if (count == 0) return 0;
      const total = this.values.reduce((sum, item) => sum + item.count, 0);
      return ((count / total) * 100).toFixed(1);
    }
  },
}
</script>

<style scoped>
.diagrams_lines{
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: 16px;
  height: 100%;
}
.diagrams__lines {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.diagram__line__wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.diagram__line{
  display: flex;
  gap: 18px;
}
.diagram__line .line{
  height: 46px;
  border-radius: 6px;
  padding: 4px;
  min-width: max-content;
}
.line.green{
  background: #14AE5C;
}
.line.orange{
  background: #FB9A41;
}
.line.red{
  background: #EE6A5F;
}
.diagram__line .line .percent{
  border-radius: 2px;
  background: #FFF;
  width: 58px;
  height: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #292929;
  text-align: center;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
}
.diagram__line .line__info__wrapper .name{
  color: rgba(41, 41, 41, 0.80);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.078px;
  text-wrap: nowrap;
  padding-bottom: 6px;
}
.diagram__line .line__info__wrapper .number{
  color: #292929;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.096px;
}
.diagram__stats_wrapper{
  display: flex;
  gap: 23px;
  align-items: center;
}
.diagram__line__stats{
  display: flex;
  gap: 12px;
}
.diagram__line__stats .percent{
  border-radius: 3px;
  background: #14AE5C;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.078px;
  text-transform: uppercase;
  padding: 0 4px;
}
.diagram__line__stats .name{
  display: flex;
  align-items: center;
  color: #292929;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
}
.result__wrappers{
  flex-direction: column;
  gap: 16px;
  display: none;
}
.result__wrapper{
  display: flex;
  gap: 12px;
}
.result__wrapper .line{
  border-radius: 4px;
  width: 30px;
  height: 39px;
}
.result__wrapper .line__info__wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.result__wrapper .name{
  color: rgba(41, 41, 41, 0.80);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.078px;
}
.result__wrapper .number{
  color: #292929;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.096px;
}
@media screen and (max-width: 768px){
  .result__wrappers{
    display: flex;
  }
  .diagram__line .line__info__wrapper{
    display: none;
  }
}
</style>