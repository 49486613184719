<template>
  <section class="login">
    <div class="form-check" v-if="check">
      <div>
        <div class="title__check">Forgot your password?</div>
        <div class="text__check">Follow the link to recover your password</div>
      </div>
      <div class="btn">
        <Button class="green" @click="ResPass">Reset password</Button>
      </div>
    </div>
    <div class="login_content" v-if="redirect">
      <div class="page_title">Reset password</div>
      <form action="" >
        <div class="title">Enter new password</div>
        <div class="inputs__wrapper">
          <div class="input__wrapper">
            <div class="text">New password</div>
            <Input-password :placeholder="'New password'" :password="reset_data.new_password" @update:password="reset_data.new_password = $event"></Input-password>
          </div>
          <div class="input__wrapper">
            <div class="text">Confirm password</div>
            <Input-password  :placeholder="'Confirm password'" :password="reset_data.confirm_password" @update:password="reset_data.confirm_password = $event"></Input-password>
          </div>
        </div>
        <button class="btn_login" type="submit" @click="ResetPass">Continue</button>
      </form>
    </div>
    <div class="error__content" v-else-if="redirect == false">
      <div>
        <div class="title__error">Time is up</div>
        <div class="text__error">Resend request</div>
      </div>
      <div class="btn">
        <Button class="white" @click="ReSend">Resend</Button>
      </div>
      <button class="exit-dialog" @click="ReSend">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
        </svg>
      </button>
    </div>
  </section>
  <StatusRequest ref="toast"></StatusRequest>
</template>
<script>
import InputPassword from "@/components/UI/InputPassword.vue";
import InputEmail from "@/components/UI/InputEmail.vue";
import axios from "axios";
import { useRoute } from 'vue-router'
import StatusRequest from "@/components/UI/StatusRequest.vue";
export default {
  components: {
    StatusRequest,
    InputPassword,
    InputEmail,
  },
  data() {
    return {
      check: true,
      view_form: null,
      redirect: null,
      arr_data: [],
      email: '',
      token: '',
      type:'',
      reset_data:{
      },
      dialogs:{
        ErrorItemDialog: true,
      }
    }
  },
  methods: {
    ResetPass(e){
      e.preventDefault();
      let current_arr = JSON.parse(JSON.stringify(this.reset_data));
      let fData = new FormData();
      fData.append("email", this.email);
      fData.append("token", this.token);
      fData.append("type", this.type);
      fData.append("password",current_arr.new_password);
      fData.append("password_confirmation",current_arr.confirm_password);
      axios.post("/reset-password",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.showMessage(true,"Successful");
          setTimeout(() => {
            if(this.type == 1) this.$router.push({name:"LoginView"});
            else if(this.type == 2) this.$router.push({name:"AuthBusines"});
            else if(this.type == 3) this.$router.push({name:"CustomerAuth"});
          },2000);
        }
        else if(resp.data.error) this.showMessage(false,resp.data.message);
        else {
          this.showMessage(false,"Произошла неизвесная ошибка, попробуйте позже");
        }
      }).catch(()=>{this.showMessage(false,"The password field confirmation does not match.")});

    },
    getResetPassword(){
      const route = useRoute();
      this.email = route.query.email;
      this.token = route.query.token;
      this.type = route.query.type;
      let fData = new FormData();

      fData.append("email",route.query.email);
      fData.append("token",route.query.token);
      fData.append("type",route.query.type);
      axios.post("/reset-password-init",fData).then(resp => {
        if (resp.data['result'] == true) {
            this.view_form = true;
        }
        else if(resp.data['result'] == false){
            this.view_form = false;
        }
      }).catch(()=>{

      });
    },
    ReSend(){
      setTimeout(() => {
        if(this.type == 1) this.$router.push({name:"EmailVerification"});
        else if(this.type == 2) this.$router.push({name:"BusinesEmailVerification"});
        else if(this.type == 3) this.$router.push({name:"CustomerEmailVerification"});
      },200);
    },
    ResPass(){
      this.check = false;
      this.redirect = this.view_form;
    },
    showMessage(type, title) {
      if (type) type = 'success';
      else type = "error";
      const toast = this.$refs.toast;
      toast.show(type, title, 5000);
    }

  },
  mounted() {
    this.getResetPassword();
  },
}
</script>

<style scoped>
.error__content{
  position: relative;
  max-width: 450px;
  width: 450px;
  padding: 16px;
  padding-top: 24px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;

  /* 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.title__error{
  color: var(--red-200, #EA2313);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  padding-bottom: 24px;
}
.text__error{
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
  padding-bottom: 24px;
}

.form-check{
  max-width: 358px;
  width: 358px;
  padding: 16px;
  padding-top: 24px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;

  /* 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.title__check{
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  padding-bottom: 24px;
}
.text__check{
  color: var(--gray-400, #606060);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  padding-bottom: 22px;
}
.checkbox{
  display: flex;
  gap: 8px;
  align-items: center;
}
.login {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_content .page_title{
  color: var(--black-400, #292929);
  text-align: center;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.192px;
  padding-bottom: 40px;
}
.login_content form{
  padding: 16px;
  padding-top: 35px;
  width: 562px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.inputs__wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}
.login_content .title {
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  padding-bottom: 16px;
}
.login_content .input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.input__wrapper .text {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
}
.input__wrapper input{
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  height: 40px;
  padding-left: 12px;
}

.login_content .btn_login{
  cursor: pointer;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  height: 44px;
  width: 100%;
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}
@media screen and (max-width: 768px) {
  .login_content form,
  .error__content,
  .form-check{
    width: calc(100% - 32px);
  }
}
</style>