<template>
  <div class="post__content__info">
    <div class="info_left">
        <slot name="left"></slot>
    </div>
    <div class="info_right" v-if="$slots.right">
        <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.post__content__info {
    display: flex;
    gap: 32px;
}

.info_left {
    width: 100%;
    display: flex;
    gap: 32px;
    flex-direction: column;
}
.info_right {
  width: 34.10%;
  min-width: 34.10%;
}

</style>