<template>
  <PreloaderBanner v-show="isLoading"></PreloaderBanner>
  <div v-show="!isLoading" class="banner__mobile__wrapper">
    <img data-load :src="this.$store.state.base_directory + array[0].image" alt="">
    <div class="content">
    <div class="title">{{ array[0].title }}</div>
      <SliderSectionSearch :placeholder_title="'Where are you going ? '"></SliderSectionSearch>
    </div>
  </div>
</template>

<script>
import SliderSectionSearch from "@/components/MainPages/HomePage/SliderSectionSearch.vue";
import PreloaderBanner from "@/components/MainPages/HomePage/PreloaderBanner.vue";
export default {
  components: {SliderSectionSearch,PreloaderBanner},
  props: {
    array: Array,
    isLoading: Boolean,
  },
}
</script>

<style scoped>

.banner__mobile__wrapper{
  position: relative;
  width: 100%;
  z-index: 8;
}
.banner__mobile__wrapper img{
  aspect-ratio: 1 / 1;
  width: 100%;
  border-radius: 0px 0px 32px 32px;
  max-height: 390px;
  object-fit: cover;
}
.banner__mobile__wrapper .content{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 0 16px;
}
.banner__mobile__wrapper .content .title{
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 13px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  font-family: Standerd;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.204px;
  padding-bottom: 24px;
}
</style>