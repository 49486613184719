<template>
    <textarea v-model="currentValue" @input="this.$emit('update:modelValue', $event.target.value)" cols="30" rows="10"
        :placeholder="placeholder"></textarea>
</template>

<script>
export default {
    name: 'input-text-area',
    props: {
        placeholder: String,
        value: String,
    },
    data() {
        return {
            currentValue: this.value,
        }
    },
    watch: {
        value: {
            handler(newData) {
                this.currentValue = newData;
                this.$emit('update:modelValue', this.currentValue);
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>

<style scoped>
textarea {
    width: 100%;
    resize: none;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    padding: 16px 12px;
    height: 111px;
    outline: none;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
}

@media screen and (max-width: 768px) {
    textarea {
        padding: 12px;
        height: 168px;
    }
}</style>