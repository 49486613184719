<template>
  <input type="email" :placeholder='placeholder' :value="email" @input="this.$emit('update:email', $event.target.value)">
</template>

<script>
export default {
  name: 'input-email',
  props: {
    email: String,
    placeholder: String,
  },
}
</script>

<style scoped>
input::placeholder {
  color: var(--gray-300, #888);
}
input {
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  height: 40px;
  color: #292929;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding-left: 12px;
  width: 100%;
  transition: 0.3s;
}
input.error{
  border-radius: 8px !important;
  border: 1.5px solid var(--red-200, #EA2313) !important;

}
</style>