<template>
    <div class="bread__crumb__container mobile-none">
      <div :class="{ unactive: !loading}">
        <div>
          <nav v-if="loading" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
                <path
                  d="M4.70711 13.7071C4.31658 13.3166 4.31658 12.6834 4.70711 12.2929L8.29289 8.70711C8.92286 8.07714 10 8.52331 10 9.41421L10 16.5858C10 17.4767 8.92286 17.9229 8.29289 17.2929L4.70711 13.7071Z"
                  fill="#333333"/>
              </svg>
              <li class="breadcrumb-item">
                <router-link :to="{
                    path:'/', 
                    query: { 
                      currency: $route.query.currency 
                    } 
                    }">Home</router-link>
              </li>
              <template v-for="(crumb, index) in bread__crumb.slice(0, -1)" :key="index">
                <div class="circle"></div>
                <li class="breadcrumb-item" v-if="index == 0">
                  <router-link :to="{
                    path: crumb.path, 
                    query: { 
                      currency: currency.currency_code,
                      lang: languages.code
                    } 
                    }">
                    {{ crumb.name }}
                  </router-link>
                </li>
                <li class="breadcrumb-item" v-else>
                  <router-link :to="{
                    path: crumb.path, 
                    query: {
                        currency: currency.currency_code,
                        type: 1,
                        lang: languages.code
                    }
                    }">{{ crumb.name }}</router-link>
                </li>
              </template>
              <div class="circle"></div>
              <li class="breadcrumb-item">
                <router-link to="#">{{ post[0].event_name }}</router-link>
              </li>
            </ol>
          </nav>
        </div>

        <div v-if="loading" class="post_title">{{ post[0].event_name }}</div>
        <div v-if="loading" class="post_inf">
          <div class="post_rate">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                  d="M8.31392 0.419595C8.61517 -0.139865 9.38483 -0.139865 9.68608 0.419596L12.0727 4.8518C12.1851 5.06052 12.3786 5.20773 12.603 5.25511L17.3675 6.26139C17.9689 6.38841 18.2068 7.15463 17.7915 7.62741L14.502 11.3729C14.3471 11.5493 14.2732 11.7875 14.2995 12.0255L14.8575 17.0796C14.9279 17.7176 14.3053 18.1911 13.7474 17.9239L9.32775 15.8065C9.11963 15.7068 8.88037 15.7068 8.67225 15.8065L4.25261 17.9239C3.69474 18.1911 3.07206 17.7176 3.1425 17.0796L3.70053 12.0255C3.72681 11.7875 3.65287 11.5493 3.49797 11.3729L0.208452 7.62741C-0.206771 7.15463 0.0310692 6.38841 0.632476 6.26139L5.39699 5.25511C5.62136 5.20773 5.81493 5.06052 5.92731 4.8518L8.31392 0.419595Z"
                  fill="#292929"/>
            </svg>
            <div class="number">{{ post[0].rate == null ? '4.94' : post[0].rate }}</div>
            <div class="count">(100)</div>
          </div>
          <div class="post_country">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                  d="M9.99944 1.6073C11.8465 1.6073 13.6179 2.34104 14.9239 3.64709C16.23 4.95315 16.9637 6.72454 16.9637 8.57158C16.9637 11.5144 14.8959 14.7216 10.8137 18.2273C10.5867 18.4223 10.2973 18.5294 9.99811 18.5291C9.69887 18.5289 9.40966 18.4213 9.18301 18.2259L8.91301 17.9916C5.01159 14.5773 3.03516 11.4487 3.03516 8.57158C3.03516 6.72454 3.76889 4.95315 5.07495 3.64709C6.38101 2.34104 8.1524 1.6073 9.99944 1.6073ZM9.99944 5.89301C9.28904 5.89301 8.60774 6.17522 8.10541 6.67755C7.60308 7.17988 7.32087 7.86118 7.32087 8.57158C7.32087 9.28199 7.60308 9.96329 8.10541 10.4656C8.60774 10.9679 9.28904 11.2502 9.99944 11.2502C10.7098 11.2502 11.3911 10.9679 11.8935 10.4656C12.3958 9.96329 12.678 9.28199 12.678 8.57158C12.678 7.86118 12.3958 7.17988 11.8935 6.67755C11.3911 6.17522 10.7098 5.89301 9.99944 5.89301Z"
                  fill="#292929"/>
            </svg>
            <div class="name">{{ post[0].city_name }}, {{ post[0].country_name }}</div>
          </div>
        </div>
        <div v-if="loading" class="follow__wrapper">
          <div class="share__wrapper" @click="copyPageLink">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M15 13.4013C14.3667 13.4013 13.8 13.6513 13.3667 14.043L7.425 10.5846C7.46667 10.393 7.5 10.2013 7.5 10.0013C7.5 9.8013 7.46667 9.60964 7.425 9.41797L13.3 5.99297C13.75 6.40964 14.3417 6.66797 15 6.66797C16.3833 6.66797 17.5 5.5513 17.5 4.16797C17.5 2.78464 16.3833 1.66797 15 1.66797C13.6167 1.66797 12.5 2.78464 12.5 4.16797C12.5 4.36797 12.5333 4.55964 12.575 4.7513L6.7 8.1763C6.25 7.75964 5.65833 7.5013 5 7.5013C3.61667 7.5013 2.5 8.61797 2.5 10.0013C2.5 11.3846 3.61667 12.5013 5 12.5013C5.65833 12.5013 6.25 12.243 6.7 11.8263L12.6333 15.293C12.5917 15.468 12.5667 15.6513 12.5667 15.8346C12.5667 17.1763 13.6583 18.268 15 18.268C16.3417 18.268 17.4333 17.1763 17.4333 15.8346C17.4333 14.493 16.3417 13.4013 15 13.4013ZM15 3.33464C15.4583 3.33464 15.8333 3.70964 15.8333 4.16797C15.8333 4.6263 15.4583 5.0013 15 5.0013C14.5417 5.0013 14.1667 4.6263 14.1667 4.16797C14.1667 3.70964 14.5417 3.33464 15 3.33464ZM5 10.8346C4.54167 10.8346 4.16667 10.4596 4.16667 10.0013C4.16667 9.54297 4.54167 9.16797 5 9.16797C5.45833 9.16797 5.83333 9.54297 5.83333 10.0013C5.83333 10.4596 5.45833 10.8346 5 10.8346ZM15 16.6846C14.5417 16.6846 14.1667 16.3096 14.1667 15.8513C14.1667 15.393 14.5417 15.018 15 15.018C15.4583 15.018 15.8333 15.393 15.8333 15.8513C15.8333 16.3096 15.4583 16.6846 15 16.6846Z" fill="#292929"/>
            </svg>
            Share
          </div>
          <div class="follow">
            <input class="btn__checkbox" type="checkbox" @change="updateWishlist($event,post[0].id)" :checked="post[0].in_wishlist === 1" :id="post[0].id" />
            <label class="btn-love" :for="post[0].id">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <g clip-path="url(#clip0_5358_30287)">
                  <g filter="url(#filter0_bdd_5358_30287)">
                    <path d="M18.2778 6.73933V7.45292C18.2675 7.48781 18.2595 7.52344 18.2539 7.55955C18.1549 8.66977 17.7478 9.65092 17.1865 10.5651C16.3906 11.86 15.3827 12.9507 14.3527 14.0199C13.148 15.2693 11.8471 16.4091 10.6953 17.7219C10.6763 17.7416 10.6563 17.7603 10.6355 17.7778C10.5918 17.7326 10.5521 17.6933 10.5141 17.6523C10.1749 17.2869 9.84624 16.9088 9.49512 16.5577C8.63498 15.6978 7.7536 14.8623 6.90034 13.9943C5.86823 12.9439 4.8756 11.8506 4.08663 10.5638C3.59735 9.76633 3.21931 8.91587 3.07336 7.95878C2.84338 6.44478 3.14454 5.08359 4.11265 3.94736C5.07478 2.81797 6.29591 2.38688 7.6893 2.54358C8.63887 2.65119 9.47598 3.07415 10.1905 3.76628C10.3445 3.91551 10.4767 4.09138 10.6391 4.27994C10.6792 4.21752 10.6962 4.18241 10.7192 4.15575C11.2151 3.55243 11.8407 3.09212 12.5403 2.81602C13.4755 2.43987 14.4296 2.38688 15.3767 2.72531C16.8512 3.25198 17.7801 4.35341 18.1581 5.99257C18.2144 6.23705 18.2386 6.4916 18.2778 6.73933Z" stroke="#292929" stroke-width="2"/>
                  </g>
                </g>
                <defs>
                  <filter id="filter0_bdd_5358_30287" x="-4" y="-4.5" width="29.2773" height="29.6445" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5358_30287"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="effect1_backgroundBlur_5358_30287" result="effect2_dropShadow_5358_30287"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"/>
                    <feBlend mode="normal" in2="effect2_dropShadow_5358_30287" result="effect3_dropShadow_5358_30287"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_5358_30287" result="shape"/>
                  </filter>
                  <clipPath id="clip0_5358_30287">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              Save
            </label>

          </div>
        </div>
      </div>
    </div>
    <div class="message__success" :class="{active: messageSuccess}">
      Link copied
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
export default {
  name: 'bread-crumb',
  props: {
    post: Array,
    exit: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      messageSuccess: false,
      bread__crumb: [],
    }
  },
  methods: {
    async updateWishlist(event,id) {
      if(this.$store.state.user.role != null && this.$store.state.user.role == "customer"){
        try {
          let fData = new FormData();
          fData.append("isChecked", event.target.checked);
          fData.append("event_id", id);
          const response = await axios.post('/post/checked-wishlist', fData);
          console.log(response.data);
        } catch (error) {
          console.error('Error updating wishlist:', error);
        }
      }else if(this.$store.state.user.role == "business" || this.$store.state.user.role == "admin"){
        event.target.checked = false;
      }
      else{
        event.target.checked = false;
        this.$store.state.dialogLoginForm = true;
      }
    },
    copyPageLink() {
      const textarea = document.createElement('textarea');
      textarea.value = window.location.href;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.messageSuccess = true;

      setTimeout(() => {
        this.messageSuccess = false;
      }, 2000);
    },
    getBreadCrumbs(){
      const currentPath = window.location.pathname;
      const paths = currentPath.split('/').filter(path => path.trim() !== '');
      let cumulativePath = '';
      this.bread__crumb = paths.map(path => {
        cumulativePath += `/${path}`;
        return {
          name: path,
          path: cumulativePath
        };
      });
    },
  },
  computed: {
    ...mapState(['currency','languages'])
  },
  mounted () {
    this.getBreadCrumbs();
  },
}
</script>

<style scoped>
.message__success{
  position: fixed;
  display: flex;
  align-items: center;
  top: 60px;
  right: -100%;
  border-radius: 8px;
  background: var(--Green-0, rgba(0, 211, 72, 0.24));
  height: 50px;
  padding: 10px 15px;
  color: var(--Green-400, #39766A);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.message__success.active{
  right: 60px;
  visibility: visible;
  opacity: 1;
}
.bread__crumb__container{
  padding-bottom: 24px;
}
.bread__crumb__container > div{
  position: relative;
}

.follow__wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0;
  right: 0;
  z-index: 3;
  width: auto;
  gap: 12px;
  height: 36px;
}
.follow__wrapper >div{
  width: 93px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 4px;
}
.share__wrapper{
  color: var(--Black-400, #292929);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
}
.btn__checkbox {
  display: none;
}

.btn-love svg path {
  transition: fill 0.5s ease;
}

.btn__checkbox:checked + .btn-love svg path {
  fill: #292929;
}

.btn-love {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.follow{
  cursor: pointer;

}
.follow label{
  display: flex;
  gap: 4px;
  align-items: center;
  width: max-content;

  color: var(--Black-400, #292929);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
}

nav ol li {
  list-style: none;
  opacity: 0.8;
}

nav ol {
  display: flex;
  align-items: center;
  gap: 15px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--Black-300, #333);
}

nav ol li a {
  color: var(--Black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.9%; /* 22.064px */
  letter-spacing: 0.4px;
  text-transform: capitalize;
  cursor: pointer;
}

nav ol li:last-child {
  pointer-events: none;
  opacity: 1;
}


.unactive {
  min-height: 100px;
  width: 100%;
  border-radius: 16px;
  -webkit-animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
  animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }
  100% {
    background: #D3D3D3;
  }
}

@keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }
  100% {
    background: #D3D3D3;
  }
}

.bread__crumb_content {
  position: relative;
}

.bread__crumb_content button.exit-dialog {
  right: 0;
  top: 0;
}

.bread__crumb_content {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.bread__crumb_content .arrow__back {
  margin-right: 10px;
}

.bread__crumb_content a {
  margin-right: 15px;
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.9%; /* 22.064px */
  letter-spacing: 0.4px;
  opacity: 0.8;
}

.bread__crumb_content a:last-of-type {
  margin-right: 0px;
  opacity: 1;
}

.bread__crumb_content .circle {
  margin-right: 15px;
  width: 6px;
  height: 6px;
  background: #333333;
  opacity: 0.8;
  border-radius: 50%;
}

.bread__crumb_content .circle:last-of-type {
  opacity: 1;
}

.bread__crumb {
  margin-bottom: 24px;
}

.bread__crumb__container {
}

.bread__crumb__container .post_title {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.168px;
  padding-bottom: 16px;
  padding-top: 16px;
}

.bread__crumb__container .post_inf {
  display: flex;
  gap: 16px;
}

.post_inf .post_rate {
  display: flex;
  gap: 4px;
}

.post_inf .number {
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}

.post_inf .count {
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.102px;
}

.post_inf .post_country {
  display: flex;
  gap: 4px;
}

.post_inf .name {
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}
@media screen and (max-width: 768px) {
  .mobile-none {
    display: none;
  }
}
</style>