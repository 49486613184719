<template>
    <div class="dialog__currency" :class="{ active: show }" @click.stop="hideDialog">
      <div class="bg_color"></div>
      <div  class="login_content">
        <div class="content">
            <div class="menu__wrapper" :class="{ active: show }">
                <div>
                <div class="menu" v-if="role">
                    <router-link :to="value.route" v-for="value in roleData[this.role]">
                    <img :src="require(`@/assets/image/menu-icons${value.img}`)" alt="">
                    {{ value.name }}
                    </router-link>
                    <a v-if="role != 'admin'" href="#" @click.prevent="this.$emit('open-dialog', 'Information center'); hideDialog();">
                      <img :src="require(`@/assets/image/menu-icons/info.svg`)" alt="">
                      Information center
                    </a>
                </div>
                <div class="menu" v-else>
                    <a href="#" @click.prevent="this.$emit('open-dialog', index); hideDialog()" v-for="(value,index) in roleData[this.role]">
                    <img :src="require(`@/assets/image/menu-icons${value.img}`)" alt="">
                    {{ value.name }}
                    </a>
                </div>
                <template v-if="shouldDisplay">
                  <div class="line"></div>
                  <div class="modal__btn">
                    <div @click="this.$emit('open-dialog', 'Currency')"><img :src="require(`@/assets/image/menu-icons/finance.svg`)" alt="">Currency</div>
                    <div @click="this.$emit('open-dialog', 'Language')"><img class="flag" :src='this.$store.state.base_directory + "flags/"+this.$store.state.languages.country+".png"' alt="">{{this.$store.state.languages.name }}</div>
                  </div>
                </template>
                <div class="line"></div>
                <div class="logout" @click="logout" v-if="role">
                    <img :src="require(`@/assets/image/menu-icons/logout.svg`)" alt="">
                    Log out
                </div>
                <div v-else class="logout" @click="this.$emit('open-dialog', 'Information center')">
                    <img :src="require(`@/assets/image/menu-icons/info.svg`)" alt="">
                    Information center
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import jsonData from '@/assets/json/UserRouters.json';
  import axios from "axios";
  export default {
    props: {
      role: String,
      show: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        roleData: jsonData,
      }
    },
      computed: {
      shouldDisplay() {
        const routeName = this.$route.name;
        const hasCountryParam = this.$route.params.country !== undefined;

        return routeName === 'home' || hasCountryParam;
      }
    },
    methods: {
      hideDialog() {
        this.$emit('update:show', false)
      },
      handleBlur() {
        setTimeout(() => {
          if (!this.$el.contains(document.activeElement)) {
            this.$emit('update:show', false)
          }
        }, 100);
      },
      logout(){
        axios.post("/logout/auth")
          .then(resp => {
            setTimeout(() => {
            this.$emit('update:show', false);
            window.location.reload();
            }, 200);
          })
          .catch(() => {
          })
          .finally(() => {
          });
      }
    },
  }
  </script>
  <style scoped>
  .modal__btn{
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px 8px;
  }
  .modal__btn > div{
    height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    gap: 7px;
    padding: 8px;
  }
  .modal__btn > div .flag{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 20px;
    max-height: 15px;
    min-width: 20px;
    min-height: 15px;
  }
  button.exit-dialog{
    top: 24px;
    right: 24px;
  }
  .bg_color{
    opacity: 0.32;
    background: #292929;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    transition: opacity 1s cubic-bezier(.16,1,.3,1);
  }
  .dialog__currency{
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  
    position: fixed;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    display: none;
  }
  .dialog__currency.active{
    visibility: visible;
    opacity: 1;
  }
  .menu__wrapper {
  position: absolute;
  left: 0;
  top: 72px;
  width: 100%;
  border-radius: 10px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  z-index: 100;
}
.menu__wrapper.active{
  visibility: visible;
  opacity: 1;
}
.menu {
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.menu a {
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
  gap: 7px;
  padding-bottom: 10px;
}

.menu__wrapper .logout {
    height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    gap: 7px;
    padding: 8px;
    margin: 8px;
    margin-top: 6px;
}

.menu__wrapper .logout:hover {
  background: #F1F1F1;
}

.menu a:hover {
  background: #F1F1F1;
}

.menu__wrapper .line {
  width: 100%;
  height: 1px;
  background: #E5E5EA;
}
.menu__wrapper{
  left: unset;
  right: 0;
  width: max-content;
  min-width: 190px;
}
@media screen and (max-width: 768px) {
  .dialog__currency{
    display: flex;
  }
}

  </style>