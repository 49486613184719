<template>
  <div class="dialog__filters__wrapper" :class="{ active: show }" @click.stop="hideDialog">
    <div class="bg_color"></div>
    <div @click.stop class="content">

      <Button class="exit-dialog" @click="hideDialog">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                fill="#292929"/>
        </svg>
      </Button>
      <div class="content__top">
        <div class="title">Filter</div>
        <div class="content__wrapper">
          <div class="price__filter">
            <div class="title">Price rage</div>
            <PriceFilter v-model:maxPrice="filters.maxPrice" v-model:minPrice="filters.minPrice"></PriceFilter>
          </div>
          <div class="type__activity">
            <div class="title">Type of activity</div>
            <RadioButton v-model="filters.category" :maxPrice="maxPrice" :array="category"></RadioButton>
          </div>
          <div class="event__duration">
            <div class="title">Event duration</div>
            <CheckBox :name="'duration'" v-model="filters.eventDuration" :array="event__duration"></CheckBox>
          </div>
          <div class="choose__location" v-if="location.length > 0">
            <div class="title">Choose a location</div>
            <CheckBox :name="'location'" v-model="filters.chooseLocation" :array="location"></CheckBox>
          </div>
          <div class="time__day">
            <div class="title">Time of day</div>
            <CheckBox :class="'column'" :name="'time'" v-model="filters.timeDay" :array="times"></CheckBox>
          </div>
        </div>
      </div>
      <div class="content__bottom">
        <div class="btn">
          <Button class="white" @click="clearAll">Clear All</Button>
          <Button class="green" @click="onFilter">Show options</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RadioButton from "@/components/MainPages/CityPage/RadioButton.vue";
import CheckBox from "@/components/MainPages/CityPage/CheckBox.vue";
import PriceFilter from "@/components/MainPages/CityPage/PriceFilter.vue";

export default {
  components: {PriceFilter, CheckBox, RadioButton},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    category: Array,
    current_tab: {
      type: String,
      default: false
    }
  },
  data() {
    return {
      activity: [
        {name: 'Tours & Cruises', id: '1'},
        {name: 'Historical', id: '2'},
        {name: 'Romantic', id: '3'},
        {name: 'Extreme', id: '4'},
        {name: 'Food & Drinks', id: '5'},
        {name: 'Entertaiments', id: '6'},
        {name: 'Entertaiments', id: '7'},
      ],
      event__duration: [
        {name: '0 - 2 hours', id: '1'},
        {name: '3 - 5 hours', id: '2'},
        {name: '6 - 9 hours', id: '3'},
        {name: '10 - 12 hours', id: '4'},
        {name: '1 day', id: '5'},
        {name: 'more then 2 days', id: '6'},
      ],
      location: [
        {name: 'Ubud', id: '1'},
        {name: 'Kuta', id: '2'},
        {name: 'Seminyak', id: '3'},
        {name: 'Bali', id: '4'},
        {name: 'Nusa Dua', id: '5'},
        {name: 'Jimbaran', id: '6'},
      ],
      times: [
        {name: 'Morning (before 12 pm)', id: '1'},
        {name: 'Afternoon (after 12 pm)', id: '2'},
        {name: 'Evening (after 5 pm)', id: '3'},
      ],
      maxPrice: '',
      minPrice:'',
      filters: {
        minPrice: '',
        maxPrice: '',
        category: '',
        eventDuration: [],
        chooseLocation: [],
        timeDay: [],
      },
      firstCategoryChange: true
    }
  },
  methods: {
    getFiltersInit(type){
      let fData = new FormData();
      fData.append("country", this.$route.params.city);
      fData.append("type", this.current_tab);
      axios.post("/get/city/get-filters-init", fData)
          .then(resp => {
            this.location = resp.data.location;
            const urlParams = new URLSearchParams(window.location.search);
            if(type == 1 && !urlParams.has('minPrice') && !urlParams.has('maxPrice')){
              this.filters.maxPrice= resp.data.max_price;
              this.filters.minPrice= resp.data.min_price;
              this.maxPrice = resp.data.max_price;
              this.minPrice = resp.data.min_price;
            }
          })
          .catch(() => {
          })
          .finally(() => {
          });
    },
    hideDialog() {
      this.$emit('update:show', false)
    },
    clearAll(){
      this.filters = {minPrice: this.minPrice,maxPrice: this.maxPrice,category: '',eventDuration: [],chooseLocation: [],timeDay: []};
      this.deleteURL();
    },
    onFilter(){
      this.updateURL();
      this.$emit('filters', this.filters);
      this.$emit('update:show', false)
    },
    deleteURL(){
      const url = new URL(window.location.href);
      for (const key in this.filters) {
          url.searchParams.delete(key);
      }
      window.history.pushState({}, "", url.toString());
      this.$emit('filters', this.filters);
    },
    updateURL() {
      const url = new URL(window.location.href);
      for (const key in this.filters) {
        if (
          this.filters.hasOwnProperty(key) &&
          this.filters[key] !== null &&
          this.filters[key] !== undefined &&
          this.filters[key] !== '' &&
          !(Array.isArray(this.filters[key]) && this.filters[key].length === 0)
        ) {
          url.searchParams.set(key, this.filters[key]);
        } else {
          url.searchParams.delete(key);
        }
      }
      window.history.pushState({}, "", url.toString());
    }
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search);
    for (const key in this.filters) {
        if (urlParams.has(key)) {
          const paramValue = urlParams.get(key);
          if (['eventDuration', 'chooseLocation', 'timeDay'].includes(key)) {
            this.filters[key] = paramValue.includes(',')
              ? paramValue.split(',').map(value => value.trim())
              : [paramValue];
          } else {
            this.filters[key] = paramValue;
          }
        }
    }
    this.getFiltersInit(1);
  },
  watch: {
    category(newValue, oldValue) {
      if (this.firstCategoryChange) {
        console.log(1);
      this.firstCategoryChange = false;
      } 
      else if(JSON.stringify(newValue) === JSON.stringify(oldValue)){
        console.log(2);
      }
      else {
        console.log(newValue);
        console.log(oldValue);
        this.filters['category'] = '';
        this.getFiltersInit(2);
      }
    }
  },
}
</script>

<style scoped>
.btn button{
  height: 50px;
}
.content__bottom{
  padding: 24px;
  border-top: 1px solid #CCC;
  background: #FFF;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.content__top > .title {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.content__wrapper .title {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 12px;
}

.content__wrapper {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.exit-dialog {
  top: 24px;
  right: 24px;
}

.bg_color {
  opacity: 0.32;
  background: #292929;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transition: opacity 1s cubic-bezier(.16, 1, .3, 1);
}

.dialog__filters__wrapper {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  position: fixed;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}

.dialog__filters__wrapper.active {
  visibility: visible;
  opacity: 1;
}

.content {
  z-index: 100;
  position: relative;
  width: 100%;
  max-width: 610px;
  border-radius: 16px;
  background: var(--White-100, #FFF);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  height: calc(100vh - 80px);
  overflow: hidden;
}
.content__top{
  padding: 24px;
  padding-bottom: 120px;
  overflow: overlay;
  max-height: 100%;
}
@media screen and (max-width: 768px) {
  .login_content {
    width: calc(100% - 32px);
  }
  .content {
    height: 100%;
    min-width: 100%;
    border-radius: unset;
  }
  .login_content .page_title {
    color: var(--black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.144px;
  }
  .content__top{
    padding: 24px;
    padding-bottom: 120px;
  }

}
</style>