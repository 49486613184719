<template>
    <div class="wrapper__preloader">
        <div class="wrapper__preloader_left"></div>
        <div class="wrapper__preloader_right"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.wrapper__preloader{
  display: flex;
  gap: 24px;
  width: 100%;
}
.wrapper__preloader .wrapper__preloader_left{
  width: calc(20.5% -  12px);
  border-radius: 16px;
  background: #EBEBEB;
    height: 100%;
    min-width: 220px;
}
.wrapper__preloader .wrapper__preloader_right{
  width: calc(79.5% -  12px);
  border-radius: 12px;
  background: #EBEBEB;
    height: 100%;
}
@media screen and (max-width: 1024px) {
    .wrapper__preloader{
        flex-direction: column;
    }
    .wrapper__preloader .wrapper__preloader_left{
        width: 100%;
        max-width: unset;
        height: 207px;
    }
    .wrapper__preloader .wrapper__preloader_right{
        width: 100%;
        max-width: unset;
        height: 700px
    }
}
</style>