<template>
    <div class="post_gallery">
        <div class="all__category" @click="this.$emit('all-photo', true)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.5 2.5V9.16667H9.16667V2.5H2.5ZM7.5 7.5H4.16667V4.16667H7.5V7.5ZM2.5 10.8333V17.5H9.16667V10.8333H2.5ZM7.5 15.8333H4.16667V12.5H7.5V15.8333ZM10.8333 2.5V9.16667H17.5V2.5H10.8333ZM15.8333 7.5H12.5V4.16667H15.8333V7.5ZM10.8333 10.8333V17.5H17.5V10.8333H10.8333ZM15.8333 15.8333H12.5V12.5H15.8333V15.8333Z"
                    fill="#292929" />
            </svg>
            <div class="title">View all photos</div>
        </div>
        <div class="big_img" :class="{ unactive: !loading, 'w100':  !images[1]}">
            <div class="front_gr"></div>
            <img v-if="loading"
                :src='this.$store.state.base_directory + "company/" + company_id + "/events/" + id + "/" + images[0]'
                alt="">
        </div>
        <div class="small_imgs" v-if="images[1]"  :class="{ unactive: !loading }">
            <img v-if="loading && images[1]"
                :src='this.$store.state.base_directory + "company/" + company_id + "/events/" + id + "/" + images[1]'
                alt="">
            <img v-if="loading && images[2]"
                :src='this.$store.state.base_directory + "company/" + company_id + "/events/" + id + "/" + images[2]'
                alt="">
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        all_photo: Boolean,
        company_id: Number,
        id: Number,
        images: Object, Array,
        loading: Boolean
    },
}
</script>

<style scoped>
.post_gallery.preview .all__category{
    display: none;
}
.big_img.w100{
    width: 100%;
}
.post_gallery {
    display: flex;
    gap: 32px;
    position: relative;
}

.post_gallery .all__category {
    height: 36px;
    display: flex;
    align-items: center;
    gap: 4px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    padding: 8px 0;
    padding-left: 15px;
    padding-right: 23px;
    border-radius: 4px;
    background: #FFF;
    cursor: pointer;
}

.post_gallery .all__category .title {
    color: var(--black-400, #292929);
    leading-trim: both;
    text-edge: cap;
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.096px;
}

.big_img {
    position: relative;
    width: 65.89%;
}

.big_img .front_gr {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0;
    background: linear-gradient(0deg, rgba(41, 41, 41, 0.20) 0%, rgba(41, 41, 41, 0.20) 100%), lightgray 50% / cover no-repeat;
}

.big_img img {

    width: 100%;
    height: 100%;
    max-height: 620px;
    border-radius: 16px;
    object-fit: cover;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.small_imgs {
    width: 34.10%;
    display: flex;
    flex-direction: column;
    gap: 34px;
}

.small_imgs img {
    width: 100%;
    height: calc(50% - 17px);
    max-height: 293px;
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(41, 41, 41, 0.16) 0%, rgba(41, 41, 41, 0.16) 100%), lightgray 50% / cover no-repeat;
    object-fit: cover;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.unactive {
    min-height: 300px;
    width: 100%;
    border-radius: 16px;
    -webkit-animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
    animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
}

@-webkit-keyframes color-change-2x {
    0% {
        background: #E5E5EA;
    }

    100% {
        background: #D3D3D3;
    }
}

@keyframes color-change-2x {
    0% {
        background: #E5E5EA;
    }

    100% {
        background: #D3D3D3;
    }
}
</style>