<template>
    <div class="page-404">
        <div class="content">
            <div class="title">404</div>
            <div class="text">Page not found</div>
            <div class="description">Sorry, we couldn't find the page you're looking for</div>
            <a href="/">Back to homepage</a>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.page-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    margin: 0 16px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #323232;
    color: white; /* Цвет текста внутри */
    font-family: "SF Pro Text";
    font-size: 200px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
}

.text {
    color: #323232;
    text-align: center;
    font-family: "SF Pro Text";
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-bottom: 24px;
}

.description {
    color: #323232;
    font-family: "SF Pro Text";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.216px;
}

a {
    margin-top: 60px;
    display: flex;
    width: 296px;
    height: 44px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}
@media screen and (max-width: 768px) {
a{
    width: 100%;
}
.title {
    font-size: 150px;
}
.text {
    font-size: 40px;
}
}
</style>