<template>
    <div class="wrapper">
        <div class="mobile__wrapper">
            <div class="calendar__button mobile__visible">
                <button @click="this.$emit('step', false);">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_4857_55074)">
                            <path
                                d="M9.70696 12.707C9.31643 12.3165 9.31643 11.6833 9.70696 11.2928L13.2927 7.70701C13.9227 7.07704 14.9998 7.52321 14.9998 8.41411L14.9998 15.5857C14.9998 16.4766 13.9227 16.9228 13.2927 16.2928L9.70696 12.707Z"
                                fill="#292929" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4857_55074">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
                <div class="date">{{ getMonth() }}</div>
                <button @click="this.$emit('step', true);">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_4857_55114)">
                            <path
                                d="M14.7071 10.8809C15.0976 11.2714 15.0976 11.9046 14.7071 12.2951L11.1213 15.8809C10.4914 16.5108 9.41421 16.0647 9.41421 15.1738L9.41421 8.0022C9.41421 7.1113 10.4914 6.66513 11.1213 7.2951L14.7071 10.8809Z"
                                fill="#292929" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4857_55114">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
            <slot name="select_event"></slot>
            <div class="weekdays mobile-none">
                <div>Monday</div>
                <div>Tuesday</div>
                <div>Wednesday</div>
                <div>Thursday</div>
                <div>Friday</div>
                <div class="weekend">Saturday</div>
                <div class="weekend">Sunday</div>
            </div>
            <div class="weekdays mobile">
                <div>Mo</div>
                <div>Tu</div>
                <div>We</div>
                <div>Th</div>
                <div>Fr</div>
                <div class="weekend">Sa</div>
                <div class="weekend">Su</div>
            </div>
            <div class="days">
                <div v-for="n in arrayDates" @click="clickEvent(n.value)"
                    :class="[{ 'active': n.status === 'active' }, { 'inactive': n.status === 'inactive' }, { 'focus': n.status === 'focus' }, { 'half-focus': n.status === 'half-focus' }]">
                    <div class="number">{{ getDays(n.value) }}</div>
                    <div class="counter" v-if="getEventCount(n.value)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <circle cx="6.5" cy="6" r="6" fill="#39766A" />
                        </svg>
                        {{ getEventCount(n.value) > 1 ? getEventCount(n.value) + ' Events' : getEventCount(n.value) +
                            'Event'
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        step: Boolean,
        current_date: String,
        events_data: Object,
    },
    data() {
        return {
            stateShow: this.show,
            arrayDates: [],
            now: '',
            start_date: '',
            current_date: this.current_date,
            current_start_date: '_._.__',
            current_end_date: '_._.__',
            range: false,
            current_select_range: {
                current_start: null,
                current_end: null,
            },
            current_select_query: {
                current_start: null,
                current_end: null,
            }
        }
    },
    methods: {
        init() {
            this.change();
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2); // Форматируем дату в строку
        },
        getEventCount(timestamp) {
            const dateKey = this.formatDate(timestamp); // Получаем строковое представление даты
            return this.events_data[dateKey] || 0; // Возвращаем значение из массива событий или 0, если его нет
        },
        change() {
            this.arrayDates = [];
            this.now = new Date();
            this.now.setUTCHours(0);
            this.now.setUTCMinutes(0);
            this.now.setUTCSeconds(0);
            this.now.setUTCMilliseconds(0);
            let start_date = new Date(this.current_date);
            let month = this.current_date.getMonth();
            let year = this.current_date.getFullYear();
            start_date.setUTCDate(1);
            start_date.setHours(0, 0, 0, 0);
            let day_week;
            if (start_date.getDay() == 0) {
                day_week = 7 - 1;
            } else day_week = start_date.getDay() - 1;

            let last_date = new Date(this.current_date.getFullYear(), this.current_date.getMonth() + 1, 0);
            let day_week_next = 7 - last_date.getDay();
            let last_day_prev_month = new Date(this.current_date.getFullYear(), this.current_date.getMonth(), -day_week + 1);
            let first_day_next_month = new Date(this.current_date.getFullYear(), this.current_date.getMonth() + 1, 1);
            for (let i = 0; i < day_week; i++) {
                let current_time = last_day_prev_month.getTime();
                let object = { value: current_time, status: 'inactive' }
                if (this.current_select_range.current_start === current_time || this.current_select_range.current_end === current_time) object.status = 'focus';
                else if (this.current_select_range.current_start < current_time && this.current_select_range.current_end > current_time) object.status = 'half-focus';
                this.arrayDates.push(object);
                last_day_prev_month.setDate(last_day_prev_month.getDate() + 1);
            }

            while (true) {
                if (start_date.getMonth() != month) break;
                let current_time = start_date.getTime();
                let object = { value: current_time, status: 'active' }
                if (this.current_select_range.current_start === current_time || this.current_select_range.current_end === current_time) object.status = 'focus';
                else if (this.current_select_range.current_start < current_time && this.current_select_range.current_end > current_time) object.status = 'half-focus';
                this.arrayDates.push(object);
                start_date.setDate(start_date.getDate() + 1);
            }

            for (let i = 0; i < day_week_next; i++) {
                if (day_week_next == 7) break;
                let current_time = first_day_next_month.getTime();
                let object = { value: current_time, status: 'inactive' }
                if (this.current_select_range.current_start === current_time || this.current_select_range.current_end === current_time) object.status = 'focus';
                else if (this.current_select_range.current_start < current_time && this.current_select_range.current_end > current_time) object.status = 'half-focus';
                this.arrayDates.push(object);
                first_day_next_month.setDate(first_day_next_month.getDate() + 1);
            }
        },
        prevArrowPicker() {
            this.current_date.setMonth((this.current_date.getMonth() - 1));
            this.$emit('currentDate', this.current_date);
            this.change();
        },
        nextArrowPicker() {
            this.current_date.setMonth(this.current_date.getMonth() + 1);
            this.$emit('currentDate', this.current_date);
            this.change();
        },
        clickEvent(value) {
            let current_start_date = new Date(value);
            this.$emit('currentDate', current_start_date);
            this.$emit('status_change', 0);
        },
        getDays(value) {
            let new_value = new Date(value);
            return new_value.getDate();
        },
        getMonth() {
            const dayOfMonth = this.$store.state.months_names[this.current_date.getMonth()] + ' ' + this.current_date.getFullYear();

            return dayOfMonth;
        },
    },
    mounted() {

        let start_date = new Date(this.current_date);
        this.init();
    },
    watch: {
        step: {
            handler(val, oldVal) {
                if (this.step == true) this.nextArrowPicker();
                else if (this.step == false) this.prevArrowPicker();
                this.$emit('step', null);
            },
            deep: true
        },
    },
}
</script>

<style scoped>
.calendar__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.calendar__button button {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-200, #CCC);
    background: var(--White-100, #FFF);

    /* last */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}

.calendar__button .date {
    color: var(--Black-300, #333);
    text-align: center;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
}

.weekdays {
    display: flex;
    gap: 6.5px;
    width: 100%;
}

.weekdays div {

    color: #000;
    text-align: center;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 100% */

    display: flex;
    width: calc((100% / 7) - 5.5px);
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
}

.days {
    margin-top: 8px;
    display: flex;
    gap: 6.5px;
    row-gap: 8px;
    width: 100%;
    flex-wrap: wrap;
}

.days>div {
    padding: 16px;
    width: calc((100% / 7) - 5.6px);
    height: 148px;
    border-radius: 6px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;

}

.days>div.active {
    cursor: pointer;
}


.days>div.active .number {
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: end;
    cursor: pointer;
}

.days>div.inactive .number {
    color: var(--Gray-200, #CCC);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: end;
    /* 117.647% */
}

.days>div .counter {
    margin-top: 12px;
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.days>div .counter svg {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}
.mobile__visible{
    display: none;
}
.mobile {
    display: none !important;
}

@media screen and (max-width: 1024px) {
    .mobile {
        display: flex !important;
    }

    .mobile-none {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .mobile__visible{
        display: flex;
    }
    .mobile__wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 16px;
    position: relative;
}
    .weekdays {
        margin-top: 12px;
    }

    .weekdays div {
        border: unset;
        border-radius: 6px;
        color: var(--Black-400, #292929);
        text-align: center;
        font-family: Standerd;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 100% */
    }

    .days>div {
        height: 54px;
        padding: 4px;
        border-radius: 6px;
        border: 1.5px solid var(--Gray-stroke, #E5E5EA);
        background: #FFF;

        /* 2 */
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

    }

    .days>div .counter {
        display: none;
    }

    .days>div.active .number {
        color: var(--Gray-300, #888);
        font-family: Standerd;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
    }
    .days>div.inactive{
        opacity: 0;
    }
}</style>