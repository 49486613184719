<template>
    <header-page></header-page>
    <main>
        <section class="companies">
            <div class="companies__container">
                <div class="companies__content">
                    <SideBar></SideBar>
                    <div class="right__main__content">
                        <div class="filter__content">
                            <div class="filter_content_wrapper">
                                <div class="picker-option">
                                    <template v-for="status in arr_status">
                                        <button
                                            :class="[status.status_name, { 'active': real_filters.status_id == status.id }]"
                                            @click="changeStatus(status.id)" type="button">
                                            {{ status.status_name }}
                                        </button>
                                        <div class="arrow__top"></div>
                                    </template>
                                </div>
                                <ActiveFilters @delete="deleteFilter" :arrFilters="arrFilters" :filters="filters">
                                </ActiveFilters>
                            </div>
                            <div class="filter">
                                <router-link :to="{ name: 'BusinesNewEvent' }">
                                    <div class="btn-new-event"><svg xmlns="http://www.w3.org/2000/svg" width="14"
                                            height="14" viewBox="0 0 14 14" fill="none">
                                            <g clip-path="url(#clip0_2982_129386)">
                                                <path
                                                    d="M7.875 0.875C7.875 0.391751 7.48325 0 7 0C6.51675 0 6.125 0.391751 6.125 0.875V6.125H0.875C0.391751 6.125 0 6.51675 0 7C0 7.48325 0.391751 7.875 0.875 7.875H6.125V13.125C6.125 13.6082 6.51675 14 7 14C7.48325 14 7.875 13.6082 7.875 13.125V7.875H13.125C13.6082 7.875 14 7.48325 14 7C14 6.51675 13.6082 6.125 13.125 6.125H7.875V0.875Z"
                                                    fill="#606060" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2982_129386">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Add new event</div>
                                </router-link>
                                <input-filter @value="getSearchData" :placeholder_title="'Search for event'"
                                    :data_name="'event_name'" :sort="arr_event" @search="getSearchEvent"></input-filter>
                            </div>
                        </div>
                        <div>
                            <post-list class="active" :posts="arr_data" :loader="data_info.loading">
                                <table class="list">
                                    <thead>
                                        <tr>
                                            <th>Event name</th>
                                            <th>Category</th>
                                            <th>Price</th>
                                            <th>Orders</th>
                                            <th>Rate</th>
                                            <th>Reviews</th>
                                            <th>Status</th>
                                            <th>Edit</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="loadingСompleted">
                                        <tr v-for="post in this.arr_data" :key="post.id">
                                            <td>
                                                <div class="name-wrapper">
                                                    <img class="comp-none" v-if="post.images"
                                                        :src='this.$store.state.base_directory + "company/" + post.company_id + "/events/" + post.id + "/" + post.images[0]'
                                                        alt="">
                                                    <div class="comp-none image_letter"
                                                        :style="{ background: getBackgroundColor(post.event_name) }"
                                                        v-if="!post.images && post.event_name">{{ post.event_name.substr(0, 1) }}</div>
                                                    <div class="text" v-if="post.event_name">{{ post.event_name }}</div>
                                                </div>
                                                <ToggleButtons v-if="selectedStatus != 2" class="comp-none"
                                                    @update="getData" :status_id="post.status_id"
                                                    :published="post.published" :id="post.id">
                                                </ToggleButtons>
                                            </td>
                                            <td class="mobile__none">
                                                <div class="category_wrapper">
                                                    <template v-for="(value, name, index) in post.category_data">
                                                        <div v-if="index < 2" class="category">
                                                            {{ value }}
                                                        </div>
                                                        <div v-else-if="index === this.countCategory(post.category_data)"
                                                            class="category grey">
                                                            +{{ this.countCategory(post.category_data) - 1 }}
                                                        </div>
                                                    </template>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="table__name">Price:</div>
                                                <div class="text">{{ post.price == null ? 'Free' : '$' + post.price }}</div>
                                            </td>
                                            <td class="mobile__none">
                                                <div class="table__name">Orders:</div>
                                                <div class="text">{{ post.order }}</div>
                                            </td>
                                            <td class="mobile__none">
                                                <div class="company__rate">
                                                    <div>
                                                        <div class="company__rateNumber">{{ post.rate }}</div>
                                                        <svg class="company__rateStar" xmlns="http://www.w3.org/2000/svg"
                                                            width="21" height="20" viewBox="0 0 21 20" fill="none">
                                                            <path
                                                                d="M10.0013 3.11533C10.0263 3.06113 10.0663 3.01522 10.1166 2.98304C10.1669 2.95086 10.2254 2.93376 10.2851 2.93376C10.3448 2.93376 10.4032 2.95086 10.4535 2.98304C10.5038 3.01522 10.5438 3.06113 10.5688 3.11533L12.5044 7.31158C12.5268 7.36009 12.5613 7.40205 12.6045 7.43345C12.6477 7.46486 12.6982 7.48465 12.7513 7.49096L17.3394 8.03471C17.3987 8.04176 17.4548 8.06566 17.5009 8.10359C17.5471 8.14151 17.5814 8.19186 17.5998 8.24868C17.6182 8.3055 17.62 8.36641 17.6048 8.42419C17.5897 8.48197 17.5583 8.53421 17.5144 8.57471L14.1226 11.7122C14.0835 11.7485 14.0543 11.7942 14.0379 11.845C14.0215 11.8958 14.0184 11.9499 14.0288 12.0022L14.9288 16.5341C14.9405 16.5927 14.9352 16.6534 14.9134 16.7091C14.8917 16.7647 14.8544 16.813 14.806 16.8481C14.7577 16.8833 14.7003 16.9038 14.6406 16.9073C14.581 16.9108 14.5215 16.8971 14.4694 16.8678L10.4382 14.611C10.3914 14.5847 10.3387 14.5709 10.2851 14.5709C10.2314 14.5709 10.1787 14.5847 10.1319 14.611L6.10068 16.8672C6.04861 16.8964 5.98927 16.9101 5.92968 16.9066C5.8701 16.9031 5.81275 16.8826 5.76443 16.8476C5.71611 16.8125 5.67883 16.7644 5.65701 16.7088C5.63519 16.6533 5.62974 16.5926 5.6413 16.5341L6.5413 12.0022C6.55184 11.9498 6.54876 11.8955 6.53234 11.8447C6.51593 11.7938 6.48673 11.748 6.44755 11.7116L3.05505 8.57408C3.01134 8.53355 2.98014 8.48136 2.96513 8.42367C2.95011 8.36599 2.95191 8.30521 2.9703 8.24851C2.9887 8.19181 3.02293 8.14155 3.06895 8.10367C3.11497 8.06579 3.17087 8.04186 3.23005 8.03471L7.8188 7.49096C7.87196 7.48476 7.92264 7.46501 7.96598 7.4336C8.00932 7.40219 8.04386 7.36017 8.0663 7.31158L10.0013 3.11533Z"
                                                                fill="#FFCE47" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="mobile__none">
                                                <div class="text reviews">{{ post.reviews }}</div>
                                            </td>
                                            <td>
                                                <div class="table__name">Status:</div>
                                                <div class="company__status" :class="[post.color]">
                                                    <div>{{ post.status_name }}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="company__panelButton">
                                                    <router-link v-if="post.status_id != 3"
                                                        :to="{ name: 'BusinesDraftEvent', params: { id: post.slug } }">
                                                        <button>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                                viewBox="0 0 20 20" fill="none">
                                                                <path
                                                                    d="M2.5 14.3746V17.4996H5.625L14.8417 8.28297L11.7167 5.15798L2.5 14.3746ZM17.2583 5.86631C17.5833 5.54131 17.5833 5.01631 17.2583 4.69131L15.3083 2.74131C14.9833 2.41631 14.4583 2.41631 14.1333 2.74131L12.6083 4.26631L15.7333 7.39131L17.2583 5.86631Z"
                                                                    fill="#292929" />
                                                            </svg>
                                                        </button>
                                                    </router-link>
                                                    <router-link v-if="post.status_id == 3"
                                                        :to="{ name: 'BusinesNewEventPreview', params: { id: post.slug } }">
                                                        <button>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                                viewBox="0 0 20 20" fill="none">
                                                                <path
                                                                    d="M2.5 14.3746V17.4996H5.625L14.8417 8.28297L11.7167 5.15798L2.5 14.3746ZM17.2583 5.86631C17.5833 5.54131 17.5833 5.01631 17.2583 4.69131L15.3083 2.74131C14.9833 2.41631 14.4583 2.41631 14.1333 2.74131L12.6083 4.26631L15.7333 7.39131L17.2583 5.86631Z"
                                                                    fill="#292929" />
                                                            </svg>
                                                        </button>
                                                    </router-link>
                                                    <button class="mobile-none" @click="confirmDeleteItem(post)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20"
                                                            viewBox="0 0 21 20" fill="none">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M13.5 1V2H18.5V4H17.5V17C17.5 18.1 16.6 19 15.5 19H5.5C4.4 19 3.5 18.1 3.5 17V4H2.5V2H7.5V1H13.5ZM5.5 17H15.5V4H5.5V17ZM7.5 6H9.5V15H7.5V6ZM13.5 6H11.5V15H13.5V6Z"
                                                                fill="#292929" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                            <td class="mobile__none">
                                                <ToggleButtons @update="getData" :status_id="post.status_id"
                                                    :published="post.published" :id="post.id"></ToggleButtons>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </post-list>
                            <list-preloader v-if="!loadingСompleted"></list-preloader>
                            <list-flip @update="onPage" v-if="(data_info.loading || arr_data.length > 0)"
                                :class="{ active: loadingСompleted }" :page="data_info.page" :currenNumber="arr_data.length"
                                :allCount="data_info.allCount" :next="data_info.next"></list-flip>
                        </div>
                    </div>
                    <modal-dialog v-model:show="dialogs.DeleteItemDialogeEvent" :modal="true">
                        <div>
                            <div class="icon delete">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M23 11V12H28V14H27V27C27 28.1 26.1 29 25 29H15C13.9 29 13 28.1 13 27V14H12V12H17V11H23ZM15 27H25V14H15V27ZM17 16H19V25H17V16ZM23 16H21V25H23V16Z"
                                        fill="#EA2313" />
                                </svg>
                            </div>
                            <div class="title">Delete event</div>
                            <div class="text">You want to delete the surfing event. You have scheduled events, if you want
                                to cancel them, go to the <a href="">calendar</a></div>

                        </div>
                        <div class="btn">
                            <Button class="white" @click="this.dialogs.DeleteItemDialogeEvent = false">Cancel</Button>
                            <Button class="red" @click="deleteItem">Delete</Button>
                        </div>
                        <button class="exit-dialog" @click="this.dialogs.DeleteItemDialogeEvent = false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                                    fill="#292929" />
                            </svg>
                        </button>
                    </modal-dialog>
                    <modal-dialog v-model:show="dialogs.DeleteItemDialog" :modal="true">
                        <div>
                            <div class="icon delete">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M23 11V12H28V14H27V27C27 28.1 26.1 29 25 29H15C13.9 29 13 28.1 13 27V14H12V12H17V11H23ZM15 27H25V14H15V27ZM17 16H19V25H17V16ZM23 16H21V25H23V16Z"
                                        fill="#EA2313" />
                                </svg>
                            </div>
                            <div class="title">Permanently delete Event</div>
                            <div class="text">Do you really want to delete {{ this.delete_data.event_name }} event?</div>
                        </div>
                        <div class="btn">
                            <Button class="white" @click="this.dialogs.DeleteItemDialog = false">Cancel</Button>
                            <Button class="red" @click="deleteItem">Delete</Button>
                        </div>
                        <button class="exit-dialog" @click="this.dialogs.DeleteItemDialog = false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                                    fill="#292929" />
                            </svg>
                        </button>
                    </modal-dialog>
                </div>
            </div>
        </section>
    </main>                                                                                                                    
</template>

<script>
import SideBar from "@/components/Busines/SideBar.vue";
import ListFlip from "@/components/Admin/ListFlip.vue";
import PostList from "@/components/Admin/AdminPanelList.vue";
import axios from "axios";
import ListPreloader from "@/components/UI/Admin/ListPreloader.vue";
import ToggleButtons from "@/components/Busines/EventsPage/ToggleButtons.vue";
import HeaderBusines from "@/components/Busines/HeaderBusines.vue";
export default {
    components: {
        SideBar, ListPreloader, PostList, ListFlip, ToggleButtons,HeaderBusines
    },
    data() {
        return {
            arr_status: [
                { status_name: 'Events', id: 1 },
                { status_name: 'Drafts', id: 2 }
            ],
            filters: {
                'status_id': 1,
            },
            arrFilters: {
                'id': 'Event',
            },
            real_filters: {},
            data_info: {
                loading: true,
                totalRecords: 0,
                page: 0,
                next: true,
                allCount: null,
                sort: {
                    field: "",
                    order: 1,
                },
                selectedItems: [],
                selectAll: false,
            },
            dialogs: {
                DeleteItemDialog: false,
                DeleteItemDialogeEvent: false,
            },
            delete_data: [],
            selectedStatus: 1,
            arr_data: [],
            arr_event: [],
            loadingСompleted: false,
        }
    },
    methods: {
        getBackgroundColor(name) {
        // Простейшая хэш-функция для генерации цвета на основе имени
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
        },
            onPage(e){
        this.data_info.loading = true;
        this.data_info.page = e;
        this.getData();
    },
        getData() {
            this.loadingСompleted = false;
            this.filters.status_id = this.selectedStatus;
            this.real_filters = this.filters;
            let fData = new FormData();
            if (this.arr_data.length <= 0) {
                this.data_info.page--;
                if (this.data_info.page < 0) this.data_info.page = 0;
            }
            fData.append("page", this.data_info.page);
            fData.append("filters", JSON.stringify(this.real_filters));
            axios.post("/business/events/get", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.arr_data = resp.data.data;
                    this.data_info.allCount = resp.data.allCount;
                    this.data_info.next = resp.data.next;
                    this.data_info.loading = false;
                }
            }).catch(() => { }).finally(() => {
                this.loadingСompleted = true;
            });
        },
        changeStatus(e) {
            this.selectedStatus = e;
            this.data_info.loading = true;
            this.filters.status_id = this.selectedStatus;
            this.getData();
        },
        confirmDeleteItem(data) {
            if (this.selectedStatus == 2) {
                this.delete_data = data;
                this.dialogs.DeleteItemDialog = true;
            } else if (this.selectedStatus == 1) {
                this.delete_data = data;
                this.dialogs.DeleteItemDialogeEvent = true;
            }
        },
        deleteItem(e) {
            let fData = new FormData();
            fData.append("id", this.delete_data.id);
            axios.post("/business/events/delete-item", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.getData();
                }
            }).catch(() => { }).finally(() => {
                this.dialogs.DeleteItemDialog = false;
                this.dialogs.DeleteItemDialogeEvent = false;
            });
        },
        deleteFilter(item) {
            delete this.filters[item];
            this.getData();
        },
        countCategory(data) {
            const propOwn = Object.getOwnPropertyNames(data);
            return propOwn.length - 1;
        },
        getSearchData(e) {
            if (e == '') delete this.filters['id'];
            else this.filters['id'] = e;
            this.getData();
        },
        getSearchEvent(e) {
            let fData = new FormData();
            fData.append("name", e);
            fData.append("status_id", this.selectedStatus);
            axios.post("/business/events/event-search", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.arr_event = resp.data.events;
                }
            }).catch(() => { }).finally(() => { });
        },
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("tab")) {
            const url = new URL(window.location.href);
            this.selectedStatus = urlParams.get("tab");
            this.filters.status_id = this.selectedStatus;
            console.log(this.filters);
            // url.searchParams.delete("tab");
            // window.history.pushState({}, "", url.toString());
        }
        this.real_filters = this.filters;

        this.getSearchEvent('');
        this.getData();
    },
}
</script>

<style scoped>
.comp-none {
    display: none !important;
}
.dialog__content .exit-dialog {
  position: absolute;
}
.dialog__content .text a {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
}

.name-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
}

.name-wrapper img {
    border-radius: 40px;
    background: lightgray -14.363px -29.853px / 170.833% 227.778% no-repeat;
    height: 40px;
    width: 40px;
    object-fit: cover;
}

.filter_content_wrapper {
    width: unset;
}

.company__panelButton {
    justify-content: unset;
}

.btn-new-event {
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    height: 36px;
    padding: 0 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.08px;
    white-space: nowrap;
}

.picker-option {
    max-width: unset;
}

.picker-option button {
    width: 118px;
}
.image_letter {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #FFF;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    text-align: center;
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }

@media screen and (max-width: 1350px) {
    .category_wrapper .category:nth-child(n+2) {
        display: none;
    }
    .list th, .list td {
        padding: 7px 6px;
    }
}
@media screen and (max-width: 1024px) {
    .list thead {
        display: none;
    }

    .comp-none {
        display: flex !important;
    }

    .filter_content_wrapper {
        width: 100%;
    }

    .picker-option {
        max-width: unset;
    }

    .btn-new-event {
        height: 48px;
    }

    .picker-option button {
        width: 100%;
    }

    td {
        gap: 20px;
    }

    .list td .text {
        max-width: unset;
        color: #000;
        font-family: Standerd;
        font-size: 18px;
    font-style: normal;
        font-weight: 600;
        line-height: 150%;
        white-space: unset;
        /* 27px */
        letter-spacing: -0.396px;

    }
}

@media screen and (max-width: 768px) {
    .filter>a {
        width: 100%;

    }

    .btn-new-event {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }

    .filter {
        flex-direction: column;
        gap: 20px;
    }

    .filter>a {
        order: 2;
    }

    .mobile-none {
        display: none;
    }
}
</style>
 