<template>
  <PreloaderSideBar v-show="isLoading"></PreloaderSideBar>
  <div v-show="!isLoading" class="left__sideBar">
<!--    <div class="button__arrow" @click="hideSideBar">-->
<!--      <svg :class="{ hide: this.hide }" xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10"-->
<!--           fill="none">-->
<!--        <path-->
<!--            d="M0.707107 5.70711C0.316582 5.31658 0.316582 4.68342 0.707107 4.29289L4.29289 0.707106C4.92286 0.0771415 6 0.523308 6 1.41421L6 8.58579C6 9.47669 4.92286 9.92286 4.29289 9.29289L0.707107 5.70711Z"-->
<!--            fill="#292929" fill-opacity="0.8" />-->
<!--      </svg>-->
<!--    </div>-->
    <ul class="nav__side__bar" :class="{ hide: this.hide }">
      <li>
        <router-link active-class="active" :to="{ name: 'CustomerMyBooking' }">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_5125_81909)">
              <path d="M19.2 12C19.2 11.0375 20.01 10.25 21 10.25V6.75C21 5.7875 20.19 5 19.2 5H4.8C3.81 5 3.009 5.7875 3.009 6.75V10.25C3.999 10.25 4.8 11.0375 4.8 12C4.8 12.9625 3.999 13.75 3 13.75V17.25C3 18.2125 3.81 19 4.8 19H19.2C20.19 19 21 18.2125 21 17.25V13.75C20.01 13.75 19.2 12.9625 19.2 12ZM15.222 16.2L12 14.1875L8.778 16.2L9.75 12.595L6.789 10.2413L10.605 10.0225L12 6.575L13.386 10.0312L17.202 10.25L14.241 12.6037L15.222 16.2Z" fill="#292929"/>
            </g>
            <defs>
              <clipPath id="clip0_5125_81909">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="text">My Bookings</div>
      </router-link>
      </li>
      <li>
        <router-link active-class="active" :to="{ name: 'CustomerWishlist' }">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M7 18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V5C17 4.44772 16.5523 4 16 4H8C7.44772 4 7 4.44772 7 5V18ZM2 16C2 16.5523 2.44772 17 3 17H5C5.55228 17 6 16.5523 6 16V7C6 6.44772 5.55228 6 5 6H3C2.44772 6 2 6.44772 2 7V16ZM19 6C18.4477 6 18 6.44772 18 7V16C18 16.5523 18.4477 17 19 17H21C21.5523 17 22 16.5523 22 16V7C22 6.44772 21.5523 6 21 6H19Z"
                  fill="#292929" />
            </svg>
          </div>
          <div class="text">Wishlist</div>
        </router-link>
      </li>
      <li>
        <router-link active-class="active" :to="{ name: 'CustomerMessages' }">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15 4V11H5.17L4 12.17V4H15ZM16 2H3C2.45 2 2 2.45 2 3V17L6 13H16C16.55 13 17 12.55 17 12V3C17 2.45 16.55 2 16 2ZM21 6H19V15H6V17C6 17.55 6.45 18 7 18H18L22 22V7C22 6.45 21.55 6 21 6Z" fill="#292929"/>
            </svg>
          </div>
          <div class="text">Messages</div>
          <div class="count" v-if="this.$store.state.user.unread !== 0 && this.$store.state.user.unread">{{ this.$store.state.user.unread }}</div>
        </router-link>
      </li>
      <li>
        <router-link active-class="active" :to="{ name: 'CustomerProfile' }">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="#292929"/>
            </svg>
          </div>
          <div class="text">Profile</div>
        </router-link>
      </li>

      <button class="logout mobile" @click="logOut">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_3096_1434)">
              <path
                  d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z"
                  fill="#292929" />
            </g>
            <defs>
              <clipPath id="clip0_3096_1434">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="text">Logout</div>
      </button>
    </ul>
    <button class="logout" @click="logOut" :class="{ hide: this.hide }">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_3096_1434)">
            <path
                d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z"
                fill="#292929" />
          </g>
          <defs>
            <clipPath id="clip0_3096_1434">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="text">Logout</div>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import PreloaderSideBar from "@/components/Busines/PreloaderSideBar.vue";
export default {
  data() {
    return {
      hide: this.$store.state.sideBarHide,
    }
  },
  components: {
    PreloaderSideBar,
  },
  props: {
    isLoading: Boolean,
  },
  methods: {
    logOut() {
      let fData = new FormData();

      axios.post("/login/account/logout", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.$router.push({ name: "CustomerAuth" });
          window.localStorage.removeItem("auth_token");
        } else {
          // this.showMessage(false,this.page_titles.error.fatal);
        }
      }).catch(() => {
        // this.showMessage(false,this.page_titles.error.fatal);
      });
    },
    hideSideBar() {
      this.hide = !this.hide;
      store.commit('updateHide', this.hide);
    }
  },
}
</script>

<style scoped>
.nav__side__bar.hide .text {
  display: none;
}

.logout.hide .text {
  display: none;
}

.button__arrow {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #D1D1D6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__arrow svg {
  transition: 0.3s;
}

.button__arrow .hide {
  rotate: 180deg;
}

.mobile {
  display: none;
}

.left__sideBar.hide {
  width: 160px;
}

.left__sideBar {
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 8px 0px, rgba(0, 0, 0, 0.04) 0px 0px 4px 0px;
  padding: 16px;
  max-width: 232px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: 0.3s;
  width: 100%;
  height: calc(100vh - 145px);
  position: sticky;
  top: 120px;
}
.left__sideBar .nav__side__bar a .count{
  position: absolute;
    right: -40px;
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    border-radius: 50px;
    background: var(--Red-100, #F72314);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left__sideBar .nav__side__bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left__sideBar .nav__side__bar a {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  position: relative;
  width: max-content;
}

.left__sideBar .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.left__sideBar .active .icon {
  border-radius: 6px;
  background: rgb(239, 239, 244);
}

.left__sideBar .text {
  color: rgb(41, 41, 41);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.084px;
}

.left__sideBar .logout {
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;
}

.logout.mobile {
  display: none;
}

.logout .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.logout .text {
  color: rgb(41, 41, 41);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.084px;
}

@media screen and (max-width: 1024px) {
  .left__sideBar .nav__side__bar a .count{
    right: -10px;
  }
  .button__arrow {
    display: none;
  }

  .nav__side__bar.hide .text {
    display: unset;
  }

  .left__sideBar.hide {
    width: unset;
  }

  .left__sideBar {
    order: 2;
    width: 100%;
    max-width: 100%;
    height: max-content;
    position: fixed;
    left: 0;
    bottom: 0;
    border: 0px;
    border-top: 1.5px solid #E5E5EA;
    background: #FFF;
    height: 86px;
    flex-direction: row;
    z-index: 100;
    border-radius: 0;
    gap: 10px;
    justify-content: unset;
    overflow-x: overlay;
    min-height: unset;
    top: unset;
  }

  .left__sideBar .nav__side__bar a {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
    width: unset;
  }

  .left__sideBar .logout {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 0;
    min-width: 68px;
    width: 68px;
  }

  .logout.mobile {
    display: flex;
  }

  .left__sideBar .text {
    color: #292929;
    text-align: center;
    font-family: Standerd;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.072px;
  }

  .left__sideBar .icon svg {
    max-width: 32px;
    max-height: 32px;
    width: 100%;
    height: 100%;
    fill: #888888;
  }

  .left__sideBar .icon svg path {
    fill: #888888;
  }

  .left__sideBar .active .icon svg path {
    fill: #292929;
  }

  .left__sideBar .icon {
    max-width: 32px;
    max-height: 32px;
    width: 100%;
    height: 100%;
  }

  .left__sideBar .active .icon {
    background: unset;
  }

  .left__sideBar {
    flex-direction: row;
    overflow-x: overlay;
  }

  .left__sideBar .nav__side__bar {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .left__sideBar .nav__side__bar li {
    min-width: 68px;
  }

  .left__sideBar .logout.mobile {
    display: flex;
  }

  .left__sideBar .logout {
    display: none;
  }
}
</style>