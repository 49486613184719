<template>
  <div class="dialog" :class="{ active: show }" @click.stop="hideDialog">
    <div class="bg_color"></div>
    <div @click.stop class="filter__settings">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dialog-filter',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:show', false)
    }
  },
}
</script>

<style>
.dialog {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s !important;
}
.dialog.active{
  visibility: visible;
  opacity: 1;
}
.filter__settings {
  position: absolute;
  max-width: 542px;
  width: 100%;

  gap: 24px;
  top: 20px;
  padding: 24px;
  z-index: 30;
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 8px 0px, rgba(0, 0, 0, 0.04) 0px 0px 4px 0px;
  height: calc(100vh - 40px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  right: -100%;
  transition: transform 0.3s ease-in-out, right 0.3s ease-in-out;
}
.dialog.active .filter__settings{
  transform: translateX(0);
  right: 20px;
  overflow-y: overlay;
  max-height: 100%;
}

.filter__settings .checkbox_input{
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.bg_color{
  opacity: 0.32;
  background: #292929;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transition: opacity 1s cubic-bezier(.16,1,.3,1);
}
.title__filter {
  color: #333;
  font-family: Standerd;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.144px;
  padding-bottom: 30px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters .title {
  color: #292929;
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 8px;
}
.filters .rate .title {
  padding-bottom: 0px;
}
.filters select {
  height: 50px;
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  width: 100%;

  color: #888;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  padding-left: 12px
}

.rate {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.rate .checkbox {
  display: flex;
  gap: 12px;
  align-items: center;
}

.rate .count_stars {
  display: flex;
  align-items: center;
}
.rate .count_post_star{
  color: #333;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}
.category_filter .title_category{
  color: #323232;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
  padding-bottom: 16px;
}
.category_filter .category__wrapper{
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.category_filter .custom-control label{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px ;
  border-radius: 4px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.category_filter .custom-control-input{
  display: none;
}
.category_filter .custom-control-input:checked~.custom-control-label{
  border-radius: 4px;
  border: 1.5px solid var(--green-400, #39766A);
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.category_filter .custom-control label{
  color: #323232;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}
@media screen and (max-width: 768px) {
  .filter__settings{
    max-width: 100%;
    right: 0;
    top: 0;
    border-radius: 0;
    height: 100%;
  }
  .dialog.active .filter__settings {
    right: 0;
    overflow-y: overlay;
    max-height: 100%;
  }
}
</style>