<template>
	<div class="wrapper">
		<input type="checkbox" v-model="isChecked" @change="handlerChecked" :id="'switch-' + id"
			:disabled="status_id == 2 || status_id == 3 || status_id == 10 || status_id == 12" />
		<label :for="'switch-' + id" :class="{ disabled: status_id == 2 || status_id == 3 || status_id == 10 || status_id == 12 }"></label>
	</div>
</template>

<script>
import axios from "axios";
export default {
	props: {
		id: Number,
		published: String,
		status_id: Number,
	},
	data() {
		return {
			isChecked: this.status_id == 1,
		}
	},
	methods: {
		handlerChecked() {
			console.log('Checkbox checked:', this.isChecked);
			let fData = new FormData();
            fData.append("type", this.isChecked);
			fData.append("id", this.id);
            axios.post("/business/events/change-published", fData).then(resp => {
				this.$emit('update');
            }).catch(() => { }).finally(() => {
            });
		}
	},
}
</script>

<style scoped>
.wrapper {
	display: flex;
	align-items: center;
}

input[type=checkbox] {
	height: 0;
	width: 0;
	visibility: hidden;
	display: none;
}

label {
	cursor: pointer;
	text-indent: -9999px;
	width: 44px;
	height: 24px;
	background: #EA2313;
	display: block;
	border-radius: 100px;
	position: relative;
}

label.disabled {
	background: #CCCCCC;
}

label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked+label {
	background: #00D348;
}

input:checked+label:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

label:active:after {
	width: 20px;
}</style>