<template>
  <div class="price__range__wrapper">
    <label for="min__price">
      <div class="text">Min price</div>
      <div class="input-wrapper">
        <span class="currency-symbol">$</span>
        <input id="min__price" v-model="minPriceValue" :placeholder="'From'" type="text">
      </div>
    </label>
    <label for="max__price">
      <div class="text">Max price</div>
      <div class="input-wrapper">
        <span class="currency-symbol">$</span>
        <input id="max__price" v-model="maxPriceValue" :placeholder="'To'" type="text">
      </div>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      minPriceValue: '',
      maxPriceValue: ''
    }
  },
  props: {
    maxPrice: [Number,String],
    minPrice: [Number,String],
  },
  watch: {
    minPriceValue(newValue) {
      this.$emit('update:minPrice', newValue);
    },
    maxPriceValue(newValue) {
      this.$emit('update:maxPrice', newValue);
    },
    minPrice(newValue) {
      if(newValue == 0) this.minPriceValue = this.minPrice;
      else if(newValue != this.minPriceValue) this.minPriceValue = this.minPrice;
    },
    maxPrice(newValue) {
      if(newValue != this.maxPriceValue) this.maxPriceValue = this.maxPrice;
    }
  }
}
</script>

<style scoped>
.input-wrapper {
  display: flex;
  align-items: center;
}
.price__range__wrapper{
  display: flex;
  gap: 64px;
}
.price__range__wrapper label{
  width: calc((100% / 2) - 32px);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: #FFF;
  padding: 16px;
}
.price__range__wrapper label .text{
  color: var(--Gray-400, #606060);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 6px;
}
.price__range__wrapper label input::placeholder{
  color: var(--Black-400, #888);
}
.currency-symbol {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
}
.price__range__wrapper label input{
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
  width: 100%;
}
</style>