<template>
    <div class="left__sideBar">

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.left__sideBar {
    border-radius: 16px;
    border: 1.5px solid var(--gray-stroke, #E5E5EA);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 8px 0px, rgba(0, 0, 0, 0.04) 0px 0px 4px 0px;
    padding: 16px;
    padding-right: 100px;
    max-width: 232px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: 0.3s;
    height: calc(100vh - 145px);
    position: sticky;
    width: 100% !important;
    top: 120px;
    background: linear-gradient(to right, #EBEBEB, #EBEBEB) !important;
}
</style>