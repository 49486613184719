<template>
  <div class="finance__block">
    <slot></slot>
    <div>
    <div class="text">{{ title }}</div>
    <div v-if="dollar" class="count">${{ number }}</div>
    <div v-else class="count">{{ number }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stats-block',
  props: {
    title: String,
    number: String,
    dollar: Boolean,
  },
}
</script>

<style scoped>
.finance__block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  width: 100%;
  /* 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.finance__block svg{
  margin-bottom: 8px;
}
.finance__block .text {
  color: rgba(41, 41, 41, 0.80);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
  padding-bottom: 8px;
  white-space: nowrap
}
.finance__block .count {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.144px;
  white-space: nowrap;
}
@media screen and (max-width: 938px) {
  .finance__block{
    padding: 10px;
  }
}
@media screen and (max-width: 938px) {
  .finance__block{
    padding: 16px;
  }
}
</style>