<template>
    <div class="block__values">
        <div class="title">{{title}}</div>
        <div v-for="item in arrayList" class="block_value">
        <div class="text_value">{{ item.value }}</div>
        <div class="value">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_2686_134099)">
                <path d="M7.4987 13.4997L3.9987 9.99972L2.83203 11.1664L7.4987 15.833L17.4987 5.83305L16.332 4.66638L7.4987 13.4997Z" fill="#39766A" stroke="#39766A" stroke-width="2"/>
            </g>
            <defs>
                <clipPath id="clip0_2686_134099">
                <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
            </svg>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            arrayList: Object,Array,
            title: String,
        },
    }
</script>

<style scoped>
.block__values .title {
    color: #000;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 16px;
}

.block__values .block_value {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #E5E5EA;
    ;
}

.block__values .block_value:last-of-type {
    padding-bottom: 0;
}

.block__values .text_value {
    color: var(--black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.108px;
}

.block__values .value {
    color: var(--black-400, #292929);
    text-align: right;
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.108px;
    display: flex;
    align-items: center;
}
</style>