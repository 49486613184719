import FilterButton from "@/components/UI/Admin/FilterButton.vue";
import Header from "@/components/Header.vue";
import SideBar from "@/components/Admin/SideBar.vue";
import InputFilter from "@/components/UI/Admin/InputFilter.vue";
import Dialog from "@/components/UI/Admin/Dialog.vue";
import FilterDialog from "@/components/UI/Admin/FilterDialog.vue";
import Button from "@/components/UI/Admin/Button.vue";
import Select from "@/components/UI/Admin/Select.vue";
import DetailsDialog from "@/components/UI/Admin/DetailsDialog.vue";
import PickerDialog from "@/components/UI/Admin/PickerDialog.vue";
import InputPickerFilter from "@/components/UI/Admin/InputPickerFilter.vue";
import ActiveFilters from "@/components/UI/Admin/ActiveFilters.vue";
import DialogInvoice from "@/components/UI/Admin/DialogInvoice.vue";
import AnalyticsBlockStats from "@/components/UI/Admin/AnalyticsBlockStats.vue";
import CircleDiagram from "@/components/UI/Admin/CircleDiagram.vue";
import AnalyticsSessionCountry from "@/components/UI/Admin/AnalyticsSessionCountry.vue";
import AnalyticsLinesDiagrams from "@/components/UI/Admin/AnalyticsLinesDiagrams.vue";
import AnalyticsComparisonDiagrams from "@/components/UI/Admin/AnalyticsComparisonDiagrams.vue";
import AnalyticsProfitableCompany from "@/components/UI/Admin/AnalyticsProfitableCompany.vue";
import ChatList from "@/components/UI/Admin/ChatList.vue";
import MessagesChat from "@/components/UI/Admin/MessagesChat.vue";
import MessageSend from "@/components/UI/Admin/MessageSend.vue";
export default [
    FilterButton,
    Header,
    SideBar,
    InputFilter,
    Dialog,
    FilterDialog,
    Button,
    Select,
    DetailsDialog,
    PickerDialog,
    InputPickerFilter,
    ActiveFilters,
    DialogInvoice,
    AnalyticsBlockStats,
    CircleDiagram,
    AnalyticsSessionCountry,
    AnalyticsLinesDiagrams,
    AnalyticsComparisonDiagrams,
    AnalyticsProfitableCompany,
    ChatList,
    MessagesChat,
    MessageSend
]