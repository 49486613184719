<template>
  <section class="post">
    <div class="post__container">
      <div class="post__content">
        <div class="post_gallery">
          <div class="all__category" v-if="loading">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 2.5V9.16667H9.16667V2.5H2.5ZM7.5 7.5H4.16667V4.16667H7.5V7.5ZM2.5 10.8333V17.5H9.16667V10.8333H2.5ZM7.5 15.8333H4.16667V12.5H7.5V15.8333ZM10.8333 2.5V9.16667H17.5V2.5H10.8333ZM15.8333 7.5H12.5V4.16667H15.8333V7.5ZM10.8333 10.8333V17.5H17.5V10.8333H10.8333ZM15.8333 15.8333H12.5V12.5H15.8333V15.8333Z" fill="#292929"/>
            </svg>
            <div class="title">View all photos</div>
          </div>
          <div class="big_img" :class="{ unactive: !loading}">
            <div class="front_gr"></div>
            <img v-if="loading" :src='this.$store.state.base_directory + "company/"+ post[0].company_id + "/events/"+ post[0].id +"/"+ post[0].arr_images[0]' alt="">
          </div>
          <div class="small_imgs" :class="{ unactive: !loading}">
            <img v-if="loading" :src='this.$store.state.base_directory + "company/"+ post[0].company_id + "/events/"+ post[0].id +"/"+ post[0].arr_images[1]' alt="">
            <img v-if="loading" :src='this.$store.state.base_directory + "company/"+ post[0].company_id + "/events/"+ post[0].id +"/"+ post[0].arr_images[2]' alt="">
          </div>
        </div>
        <div class="post__content__info">
          <div class="info_left">
            <div class="info_block">
              <div :class="{ unactive: !loading}" class="left">
                <div v-if="loading" class="block__values">
                  <div class="title">General information</div>
                  <div class="block_value">
                    <div class="text_value">Estimated duration</div>
                    <div class="value">{{ post[0].estimated_duration }}</div>
                  </div>
                  <div class="block_value">
                    <div class="text_value">Pickup</div>
                    <div class="value">{{ post[0].pickup }}</div>
                  </div>
                  <div class="block_value">
                    <div class="text_value">Free cancellation</div>
                    <div class="value">{{ post[0].free_cancellation }}</div>
                  </div>
                  <div class="block_value">
                    <div class="text_value">Live tour guide</div>
                    <div class="value">{{ post[0].live_tour_guide }}</div>
                  </div>
                  <div class="block_value">
                    <div class="text_value">Small Group</div>
                    <div class="value">{{ post[0].small_group }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading}" class="left">
                <div v-if="loading" class="block__description">
                  <div class="title">Overview</div>
                  <div class="text">{{ post[0].overview }}</div>
                  <div class="read_more">Read more</div>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading}" class="left">
                <div v-if="loading"  class="block__description">
                  <div class="title">Payment method</div>
                  <div class="text">{{ post[0].payment_method }}</div>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading}" class="left">
                <div v-if="loading" class="block__values">
                  <div class="title">Additional Information</div>
                  <div v-for="item in post[0].arr_additional_information" class="block_value">
                    <div class="text_value">{{ item }}</div>
                    <div class="value">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_2686_134099)">
                          <path d="M7.4987 13.4997L3.9987 9.99972L2.83203 11.1664L7.4987 15.833L17.4987 5.83305L16.332 4.66638L7.4987 13.4997Z" fill="#39766A" stroke="#39766A" stroke-width="2"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_2686_134099">
                            <rect width="20" height="20" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading}" class="left">
                <div v-if="loading" class="block__description">
                  <div class="title">Meeting point</div>
                  <div class="text">{{ post[0].meeting_point }}</div>
                  <div class="read_more">Read more</div>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading}" class="left">
                <div v-if="loading" class="block__values">
                  <div class="title">Cancelation policy</div>
                  <div v-for="item in post[0].arr_cancelation_policy" class="block_value">
                    <div class="text_value">{{ item }}</div>
                    <div class="value">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_2686_134099)">
                          <path d="M7.4987 13.4997L3.9987 9.99972L2.83203 11.1664L7.4987 15.833L17.4987 5.83305L16.332 4.66638L7.4987 13.4997Z" fill="#39766A" stroke="#39766A" stroke-width="2"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_2686_134099">
                            <rect width="20" height="20" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info_right" >
            <div :class="{ unactive: !loading}">
            <div class="booking_details" v-if="loading">
              <div class="price_person"><span>${{ post[0].price }}</span>per person</div>
              <form action="">
                <div class="title">Booking details</div>
                <div class="input__wrapper">
                  <input class="input date" type="date">
                  <input class="input number" type="number">
                  <input class="input time" type="time">
                </div>
                <button class="button submit" type="submit">Book Now</button>
              </form>
              <div class="totals">
                <div class="sum">
                  <div class="title">$200 x 2 Adult</div>
                  <div class="result">$400</div>
                </div>
                <div class="total">
                  <div class="title">In total</div>
                  <div class="result">$400</div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'view-event-content',
  props: {
    post: {
      type: Array,
      required: true,
    },
    loading: Boolean,
  },
}
</script>

<style scoped>
.unactive{
  min-height: 300px;
  width: 100%;
  border-radius: 16px;
  -webkit-animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
  animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }
  100% {
    background: #D3D3D3;
  }
}
@keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }
  100% {
    background: #D3D3D3;
  }
}
.post__content{
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.post_gallery {
  display: flex;
  gap: 32px;
  position: relative;
}
.post_gallery .all__category{
  height: 36px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 8px 0;
  padding-left: 15px;
  padding-right: 23px;
  border-radius: 4px;
  background: #FFF;
}
.post_gallery .all__category .title{
  color: var(--black-400, #292929);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
}
.big_img {
  position: relative;
  width: 65.89%;
}
.big_img .front_gr{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  background: linear-gradient(0deg, rgba(41, 41, 41, 0.20) 0%, rgba(41, 41, 41, 0.20) 100%), lightgray 50% / cover no-repeat;
}
.big_img img{

  width: 100%;
  height: 100%;
  max-height: 620px;
  border-radius: 16px;

  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.small_imgs {
  width: 34.10%;
  display: flex;
  flex-direction: column;
  gap: 34px;
}
.small_imgs img{
  width: 100%;
  height: calc(50% - 17px);
  max-height: 293px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(41, 41, 41, 0.16) 0%, rgba(41, 41, 41, 0.16) 100%), lightgray 50% / cover no-repeat;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}


.post__content__info {
  display: flex;
  gap: 32px;
}
.info_left {
  width: 65.89%;
  display: flex;
  gap: 32px;
  flex-direction: column;
}
.info_block {
  display: flex;
  gap: 32px;
}
.info_block .left{
  width: 100%;
  padding: 24px;
  padding-bottom: 32px;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.info_block .right{
  width: 34.10%;
}
.block__values  .title {
  color: #000;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 16px;
}
.block__values .block_value {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid #E5E5EA;;
}
.block__values .block_value:last-of-type{
  padding-bottom: 0;
}
.block__values .text_value {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.108px;
}
.block__values .value {
  color: var(--black-400, #292929);
  text-align: right;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.108px;
  display: flex;
  align-items: center;
}
.info_block .block__description {

}
.info_block .confirmation_content{
  /* position: absolute;
  top: 0;
  right: -124px; */
}
.confirmation_content .input__wrapper{
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}
.info_block .confirmation_content input,
.info_block .confirmation_content .inactive{
  display: none;
}
.info_block .confirmation_content .inp_done:checked~.done svg path{
  fill: #39766A;
}
.info_block .confirmation_content .inp_close:checked~.close svg path{
  fill: #EA2313;
}
.info_block .confirmation_content label{
  display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);

  /* last */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.info_block .confirmation_content textarea{
  display: none;
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
  height: 128px;
  padding: 16px;
  width: 100%;
  resize: none;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;

  /* 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.block__description .title {
  color: #292929;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 16px;
}
.block__description .text {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.8px */
  letter-spacing: 0.102px;
}
.block__description .read_more {
  padding-top: 24px;
  color: #000;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.108px;
  text-decoration-line: underline;
}
.post .confirmation__block{
  margin-top: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  width: 100%;
  height: 100px;
}
.info_right {
  width: 34.10%;
}
.info_right .booking_details {
  top: 120px;
  position: sticky;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 26px 24px;
}
.booking_details .price_person {
  color: #000;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.108px;
  padding-bottom: 16px;
}
.booking_details .price_person span{
  color: #000;
  font-family: Standerd;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.168px;
  padding-right: 12px;
}
.booking_details form{
  padding: 16px 0;
  border-top: 1px solid #E5E5EA;
  border-bottom: 1px solid #E5E5EA;
}
.booking_details form .input__wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.booking_details form .title {
  color: #000;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 16px;
}

.booking_details form .input {
  position: relative;
  height: 40px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
}
.booking_details form .number {
}
.booking_details form .time {
}
.booking_details form .button{
  margin-top: 24px;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: var(--green-0, rgba(0, 211, 72, 0.24));
  color: var(--green-400, #39766A);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}
.booking_details .totals .sum ,
.booking_details .totals .total{
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #E5E5EA;
}
.booking_details .totals .total{
  border-bottom: 0px solid #E5E5EA;
  padding-bottom: 0;
}
.booking_details .totals .sum .title {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.108px;
}
.booking_details .totals .sum .result {
  color: var(--black-400, #292929);
  text-align: right;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.108px;
}
.booking_details .totals .total .title {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: 0.12px;
}
.booking_details .totals .total .result {
  color: var(--black-400, #292929);
  text-align: right;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.12px;
}
</style>