<template>
  <div class="wishlist__wrapper">
    <div class="content">
      <div v-for="value in posts" class="block">
        <div class="amuse__top">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
                d="M10.6364 4.72727L11.375 3.10227L13 2.36364L11.375 1.625L10.6364 0L9.89773 1.625L8.27273 2.36364L9.89773 3.10227L10.6364 4.72727ZM6.20455 5.02273L4.72727 1.77273L3.25 5.02273L0 6.5L3.25 7.97727L4.72727 11.2273L6.20455 7.97727L9.45455 6.5L6.20455 5.02273ZM10.6364 8.27273L9.89773 9.89773L8.27273 10.6364L9.89773 11.375L10.6364 13L11.375 11.375L13 10.6364L11.375 9.89773L10.6364 8.27273Z"
                fill="#292929"/>
          </svg>
          amuse top
        </div>
        <div class="follow">
          <input class="btn__checkbox" type="checkbox" :id="value.id" @change="updateWishlist($event,value.id)"
                 :checked="true"/>
          <label class="btn-love" :for="value.id">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_5125_37485)">
                <g filter="url(#filter0_bdd_5125_37485)">
                  <path
                      d="M21.3333 8.08719V8.9435C21.321 8.98538 21.3114 9.02813 21.3046 9.07146C21.1858 10.4037 20.6974 11.5811 20.0237 12.6781C19.0687 14.232 17.8593 15.5408 16.6232 16.8239C15.1776 18.3232 13.6165 19.6909 12.2344 21.2662C12.2115 21.2899 12.1876 21.3123 12.1626 21.3333C12.1102 21.2791 12.0625 21.2319 12.0169 21.1827C11.6099 20.7443 11.2155 20.2905 10.7941 19.8692C9.76197 18.8373 8.70432 17.8347 7.68041 16.7931C6.44187 15.5326 5.25071 14.2207 4.30396 12.6766C3.71681 11.7196 3.26318 10.699 3.08804 9.55053C2.81205 7.73374 3.17345 6.10031 4.33518 4.73684C5.48974 3.38156 6.95509 2.86426 8.62716 3.0523C9.76664 3.18143 10.7712 3.68897 11.6286 4.51954C11.8134 4.69861 11.972 4.90966 12.1669 5.13593C12.215 5.06103 12.2354 5.01889 12.2631 4.9869C12.8581 4.26291 13.6089 3.71054 14.4484 3.37922C15.5706 2.92785 16.7155 2.86426 17.8521 3.27038C19.6214 3.90237 20.7361 5.2241 21.1898 7.19108C21.2572 7.48445 21.2863 7.78992 21.3333 8.08719Z"
                      stroke="white" stroke-width="2"/>
                </g>
              </g>
              <defs>
                <filter id="filter0_bdd_5125_37485" x="-3.99976" y="-4" width="32.333" height="32.6992"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5125_37485"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset dy="2"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                  <feBlend mode="normal" in2="effect1_backgroundBlur_5125_37485"
                           result="effect2_dropShadow_5125_37485"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="3"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"/>
                  <feBlend mode="normal" in2="effect2_dropShadow_5125_37485" result="effect3_dropShadow_5125_37485"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_5125_37485" result="shape"/>
                </filter>
                <clipPath id="clip0_5125_37485">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </label>
        </div>
        <TopExpiriencesSectionSlider :value="value" :id="value.id" :company_id="value.company_id"
                                     :images="value.images"></TopExpiriencesSectionSlider>
        <div class="event__name">{{ value.event_name }}</div>
        <div class="event__country">{{ value.country_name }}, {{ value.city_name }}</div>
        <div class="event__score__wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
                d="M8.31392 0.419595C8.61517 -0.139865 9.38483 -0.139865 9.68608 0.419596L12.0727 4.8518C12.1851 5.06052 12.3786 5.20773 12.603 5.25511L17.3675 6.26139C17.9689 6.38841 18.2068 7.15463 17.7915 7.62741L14.502 11.3729C14.3471 11.5493 14.2732 11.7875 14.2995 12.0255L14.8575 17.0796C14.9279 17.7176 14.3053 18.1911 13.7474 17.9239L9.32775 15.8065C9.11963 15.7068 8.88037 15.7068 8.67225 15.8065L4.25261 17.9239C3.69474 18.1911 3.07206 17.7176 3.1425 17.0796L3.70053 12.0255C3.72681 11.7875 3.65287 11.5493 3.49797 11.3729L0.208452 7.62741C-0.206771 7.15463 0.0310692 6.38841 0.632476 6.26139L5.39699 5.25511C5.62136 5.20773 5.81493 5.06052 5.92731 4.8518L8.31392 0.419595Z"
                fill="#292929"/>
          </svg>
          {{ value.rate }}
        </div>
        <div class="event__price__wrapper">
          <span>${{ value.price }}</span>
          {{ value.conditions == 1 ? "per person" : "" }}
        </div>
        <a :href="value.country_name.toLowerCase() + '/' + value.city_name.toLowerCase() + '/' + value.slug"></a>

      </div>
    </div>
  </div>
</template>

<script>
import TopExpiriencesSectionSlider from "@/components/MainPages/HomePage/TopExpiriencesSectionSlider.vue";
import axios from "axios";

export default {
  components: {TopExpiriencesSectionSlider},
  props: {
    posts: Array,
  },
  methods: {
    async updateWishlist(event, id) {
      const data = { event, id };
      this.$emit('updateWish', data);
    }
  },
}
</script>

<style scoped>
.wishlist__wrapper{
  width: 100%;
}
.wishlist__wrapper .content {
  display: flex;
  gap: 32px;
  width: 100%;
  flex-wrap: wrap;
}

.wishlist__wrapper .block {
  width: calc((100% / 4) - 24px);
  position: relative;
  z-index: 1;
}

.wishlist__wrapper .block a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.wishlist__wrapper .block img {
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(41, 41, 41, 0.20) 0%, rgba(41, 41, 41, 0.20) 100%), lightgray 50% / cover no-repeat;
  width: 100%;
  aspect-ratio: 1 / 1;
  margin-bottom: 16px;
  object-fit: cover;
}

.wishlist__wrapper .block .event__name {
  color: #292929;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.108px;
  margin-bottom: 8px;
}

.wishlist__wrapper .block .event__country {
  color: rgba(41, 41, 41, 0.70);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
  margin-bottom: 12px;
}

.wishlist__wrapper .block .event__score__wrapper {
  display: flex;
  gap: 10px;
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
  margin-bottom: 12px;
  align-items: center;
}

.wishlist__wrapper .block .event__price__wrapper {
  display: flex;
  gap: 6px;
  color: rgba(41, 41, 41, 0.70);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
  align-items: center;
}

.wishlist__wrapper .block .event__price__wrapper span {
  color: #292929;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}

.wishlist__wrapper .block .follow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  fill: rgba(255, 255, 255, 0.16);
  border-radius: 100%;
  border: 1.5px solid #E5E5EA;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
  backdrop-filter: blur(9.5px);

  top: 16px;
  right: 16px;
  z-index: 3;
}

.btn__checkbox {
  display: none;
}

.btn-love svg path {
  transition: fill 0.5s ease;
}

.btn__checkbox:checked + .btn-love svg path {
  fill: #FFFFFF;
}

.btn-love {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.wishlist__wrapper .block .amuse__top {
  position: absolute;
  top: 16px;
  left: 16px;
  border-radius: 3px;
  background: #FEE8A2;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

  display: flex;
  align-items: center;
  padding: 0 6px;
  gap: 6px;
  height: 27px;

  z-index: 10;

  color: #292929;
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.078px;
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .wishlist__wrapper .block {
    width: calc((100% / 2) - 16px);
  }
}
@media screen and (max-width: 768px) {
  .wishlist__wrapper .block {
    width: 100%;
  }
  .mobile{
    display: flex !important;
  }
}
</style>