<template>
  <div class="input__filter__wrapper box" :class="[additionalClass,{ open: open }]">
    <label class="button_filter box" :class="class">
      <svg class="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_3096_68038)">
          <path
              d="M12.9168 11.6664H12.2585L12.0251 11.4414C13.0251 10.2747 13.5418 8.68303 13.2585 6.99136C12.8668 4.6747 10.9335 2.8247 8.60012 2.54136C5.07512 2.10803 2.10846 5.0747 2.54179 8.5997C2.82512 10.933 4.67513 12.8664 6.99179 13.258C8.68346 13.5414 10.2751 13.0247 11.4418 12.0247L11.6668 12.258V12.9164L15.2085 16.458C15.5501 16.7997 16.1085 16.7997 16.4501 16.458C16.7918 16.1164 16.7918 15.558 16.4501 15.2164L12.9168 11.6664ZM7.91679 11.6664C5.84179 11.6664 4.16679 9.99136 4.16679 7.91636C4.16679 5.84136 5.84179 4.16636 7.91679 4.16636C9.99179 4.16636 11.6668 5.84136 11.6668 7.91636C11.6668 9.99136 9.99179 11.6664 7.91679 11.6664Z"
              fill="#333333" />
        </g>
        <defs>
          <clipPath id="clip0_3096_68038">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <input :class="{ open: open }" @focus="open = true" @blur="handleBlur" class="search box"
             @input="selected = $event.target.value; searchEventOrCity($event.target.value)" :placeholder="placeholder_title"
             :value="selected" type="text">
    </label>
    <div class="items box" v-if="sort_city.length > 0" :class="{ open: open }">
      <template v-for="option in sort_city" :key="option.id">
        <div class="item__wrapper" @click="open = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M11.9997 2.42969C14.2162 2.42969 16.3418 3.31017 17.9091 4.87744C19.4764 6.44471 20.3569 8.57038 20.3569 10.7868C20.3569 14.3183 17.8754 18.1668 12.9769 22.3737C12.7045 22.6077 12.3572 22.7362 11.9981 22.7359C11.639 22.7356 11.292 22.6064 11.02 22.372L10.696 22.0908C6.01429 17.9937 3.64258 14.2394 3.64258 10.7868C3.64258 8.57038 4.52306 6.44471 6.09033 4.87744C7.6576 3.31017 9.78327 2.42969 11.9997 2.42969ZM11.9997 7.57254C11.1472 7.57254 10.3297 7.91119 9.72688 8.51399C9.12408 9.11678 8.78544 9.93435 8.78544 10.7868C8.78544 11.6393 9.12408 12.4569 9.72688 13.0597C10.3297 13.6625 11.1472 14.0011 11.9997 14.0011C12.8522 14.0011 13.6698 13.6625 14.2726 13.0597C14.8754 12.4569 15.214 11.6393 15.214 10.7868C15.214 9.93435 14.8754 9.11678 14.2726 8.51399C13.6698 7.91119 12.8522 7.57254 11.9997 7.57254Z" fill="#292929"/>
          </svg>    
          <div>
            <div class="title"> {{ option.city }}</div>
            <div class="country_name"> {{ option.country }}</div>
          </div>
          <router-link :to="{
              name: 'CityPage',
              params: {
              country: option.country.toLowerCase(),
              city: option.city.toLowerCase()
              },
              query: {
                  currency: currency.currency_code,
                  type: 1,
                  lang: languages.code
              }
            }">
          </router-link>
        </div>
      </template>
      <template v-if="sort_category.length > 0"  v-for="option in sort_category" :key="option.id">
        <div class="item__wrapper" @click="open = false">
          <img :src="require('@/assets/image/categories/' + option.name + '.svg')" alt="">
          <div>
            <div class="title"> {{ sort_city[0].city }}  <span>{{ option.name}} </span></div>
            <div class="country_name"> {{ sort_city[0].country }}</div>
          </div>
          <router-link :to="{
            name: 'CityPage',
            params: {
            country: sort_city[0].country.toLowerCase(),
            city: sort_city[0].city.toLowerCase()
            },
            query:{
              category: option.id,
              type: option.type,
              currency: currency.currency_code,
              lang: languages.code
            }
          }">
          </router-link>
        </div>
      </template>
    </div>
    <div class="clear" v-if="selected" @click="selected = ''; searchEventOrCity(''); sort_city = []; sort_category = []">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
      </svg>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
export default {
  props: {
    additionalClass: String,
    data_name: String,
    placeholder_title: String,
  },
  data() {
    return {
      selected: '',
      sort_city: [],
      sort_category: [],
      open: false,
    }
  },
  methods: {
    handleBlur() {
      setTimeout(() => {
        if (!this.$el.contains(document.activeElement)) {
          this.open = false;
        }
      }, 100);
    },
    searchEventOrCity(value){
      console.log(111);
      let fData = new FormData();
      fData.append("query", value);
      axios.post("/get/banner/search", fData)
          .then(resp => {
            if(resp.data.categories){
              this.sort_city = resp.data.cities;
              this.sort_category = resp.data.categories;
            }else{
              this.sort_city = resp.data.cities;
            }
            
          })
          .catch(() => {
          })
          .finally(() => {
          });
    },
  },
  mounted () {
    this.searchEventOrCity('');
  },
  computed: {
    ...mapState(['currency','languages'])
  },
}
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}

.input__filter__wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  left: 30px;
  bottom: 30px;
  max-width: 479px;
  width: 100%;
  z-index: 20;
  transition: all 0.3s;
}
.input__filter__wrapper.open{
  max-width: 479px;
}
.items {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.input__filter__wrapper .items img {
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.input__filter__wrapper .items .item__wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 50px;
  padding: 10px;
  border-radius: 4px;
  position: relative;
}
.items .item__wrapper > a{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.items .item__wrapper > div{
display: flex;
flex-direction: column;
justify-content: space-between;
}
.items{
  padding: 12px 8px;
}
.items .item__wrapper > div .title{
  color: var(--Black-400, #292929);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.102px;
}
.items .item__wrapper > div .title span{
  color: var(--Black-400, #292929);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.102px;
}
.items .item__wrapper > div .country_name{
  color: var(--Black-400, #292929);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.078px;
}
.input__filter__wrapper .items .image_letter {
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #FFF;
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}

.input__filter__wrapper.open {
  width: 100%;
}

.input__filter__wrapper .clear {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 16px;
  top: 12px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input__filter__wrapper .items {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.input__filter__wrapper .items.open {
  opacity: 1;
  visibility: visible;
}

.input__filter__wrapper .items p {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 22.5px */
  letter-spacing: -0.33px;
  padding: 10px 8px;
}

.button_filter {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  height: 48px;
  border-radius: 8px;
  border: 1.5px solid rgb(229, 229, 234);
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 8px 0px, rgba(0, 0, 0, 0.04) 0px 0px 4px 0px;
  width: 100%;
}

.button_filter input {
  height: 100%;
  border: 0px;
  width: 100%;
  transition: all 0.3s;
  color: rgba(41, 41, 41, 0.72);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.102px;
}

.button_filter input:focus {
  outline: none;
}

.input__filter__wrapper.open .button_filter {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .button_filter {
    width: 100%;
    height: 48px;
    z-index: 2;
  }
  .splide:hover .splide__arrow {
    display: none;
  }
  .custom-pagination{
    display: flex;
  }
  .input__filter__wrapper.open .bg_wrapper {
    opacity: 0.32;
    background: var(--gray-400, #606060);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
  }


  .button_filter i {
    width: 100%;
  }

  .input__filter__wrapper .clear {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
@media screen and (max-width: 768px) {
  .input__filter__wrapper {
    width: 100%;
    transition: 0.4s;
    left: auto;
    bottom: auto;
    width: calc(100% - 32px);
    max-width: unset;
  }
  .input__filter__wrapper.open{
    width: calc(100% - 32px);
    max-width: unset;
  }
}
</style>