<template>
  <div class="category__filter__wrapper">
    <div class="button__prev" @click="scrollBackward">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_5293_79415)">
          <path
              d="M9.70696 12.709C9.31643 12.3184 9.31643 11.6853 9.70696 11.2947L13.2927 7.70896C13.9227 7.079 14.9998 7.52516 14.9998 8.41607L14.9998 15.5876C14.9998 16.4785 13.9227 16.9247 13.2927 16.2947L9.70696 12.709Z"
              fill="#606060"/>
        </g>
        <defs>
          <clipPath id="clip0_5293_79415">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="bg__wrapper">
      <div class="category__content" @scroll="handleScroll" ref="scrollContainer">
        <div class="category__block" v-for="status in category"
             :class="[status.name,{'active': current_category == status.id}]"
             @click="changeStatus(status.id)">
             <img :src="require('@/assets/image/categories/' + status.name + '.svg')" alt="">
          {{ status.name }}
        </div>
      </div>
      <div class="bg__left" :class="{ active: showTopGradient }" ref="leftBackground"></div>
      <div class="bg__right" :class="{ active: showBottomGradient }" ref="rightBackground"></div>
    </div>
    <div class="button__next" @click="scrollForward">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_5293_79415)">
          <path
              d="M9.70696 12.709C9.31643 12.3184 9.31643 11.6853 9.70696 11.2947L13.2927 7.70896C13.9227 7.079 14.9998 7.52516 14.9998 8.41607L14.9998 15.5876C14.9998 16.4785 13.9227 16.9247 13.2927 16.2947L9.70696 12.709Z"
              fill="#606060"/>
        </g>
        <defs>
          <clipPath id="clip0_5293_79415">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    category: Array,
    current_category: Number,
  },
  data() {
    return {

      showTopGradient: false,
      showBottomGradient: true
    }
  },
  methods: {
    changeStatus(id) {
      this.$emit('change_category', id);
      this.updateURL(id); // Вызываем функцию для обновления URL при изменении вкладки
    },
    updateURL(id) {
      const url = new URL(window.location.href);
      url.searchParams.set("category", id); // Устанавливаем значение параметра 'type' равным текущей вкладке
      window.history.pushState({}, "", url); // Обновляем URL без перезагрузки страницы
    },
    scrollForward() {
      const container = this.$refs.scrollContainer;
      const currentScroll = container.scrollLeft;
      const maxScroll = container.scrollWidth - container.clientWidth;
      const targetScroll = Math.min(currentScroll + 200, maxScroll);
      this.scrollTo(container, targetScroll);
    },

    scrollBackward() {
      const container = this.$refs.scrollContainer;
      const currentScroll = container.scrollLeft;
      const targetScroll = Math.max(currentScroll - 200, 0);
      this.scrollTo(container, targetScroll);
    },

    handleScroll(event) {
      const scrollTop = event.target.scrollLeft;
      const scrollHeight = event.target.scrollWidth;
      const clientWidth = event.target.clientWidth;
      if (scrollTop === 0) this.showTopGradient = false;
      else if (scrollTop >= 30) this.showTopGradient = true;

      if (scrollTop + clientWidth > scrollHeight - 5) this.showBottomGradient = false;
      else if (scrollTop + clientWidth <= scrollHeight - 30) this.showBottomGradient = true;
    },

    scrollTo(element, targetScroll) {
      const startScroll = element.scrollLeft;
      const distance = targetScroll - startScroll;
      const duration = 300;
      const startTime = performance.now();

      const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const scrollProgress = Math.min(elapsedTime / duration, 1);
        const easedProgress = scrollProgress * (2 - scrollProgress);

        element.scrollLeft = startScroll + distance * easedProgress;

        if (scrollProgress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  },
}
</script>

<style scoped>
.bg__right,.bg__left{
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.bg__right.active,.bg__left.active{
  opacity: 1;
  visibility: visible;
}
.bg__right{
  background: linear-gradient(270deg, #FFF 50%, rgba(255, 255, 255, 0.00) 95.02%, rgba(255, 255, 255, 0.00) 106.5%);
  position: absolute;
  right: 0;
  height: 100%;
  width: 52px;
  z-index: 1;
  top: 0;
}
.bg__left{
  background: linear-gradient(90deg, #FFF 50%, rgba(255, 255, 255, 0.00) 95.02%, rgba(255, 255, 255, 0.00) 106.5%);
  position: absolute;
  height: 100%;
  width: 52px;
  z-index: 1;
  left: 0;
  top: 0;
}
.bg__wrapper{
  overflow-x: auto;
  position: relative;
}
.category__filter__wrapper {
  display: flex;
  align-items: center;
  flex: 1; /* Занимает 100% оставшегося пространства */
  flex-shrink: 1; /* Может уменьшаться от размера экрана */
  gap: 10px;
  overflow: auto;
}

.category__content {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  max-width: 922px;
}

.category__content::-webkit-scrollbar {
  width: 0;
  /* Для WebKit (Chrome, Safari) */
  display: none;
  /* Для WebKit (Chrome, Safari) */
}

.category__content .category__block {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 17px */
  letter-spacing: -0.374px;
  height: 36px;
  cursor: pointer;
  text-wrap: nowrap;
  white-space: nowrap;
}
.category__block img{
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
}
.category__content .category__block.active {

  border-bottom: 1.5px solid var(--Black-400, #292929);
}

.button__prev, .button__next {
  display: flex;
  width: 48px;
  height: 48px;
  min-width: 48px;
  max-height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);

  /* last */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.button__next svg {
  transform: rotate(180deg);
}
@media screen and (max-width: 768px) {
  .button__prev,.button__next{
    display: none;
  }
  .bg__left,.bg__right{
    display: none;
  }
}
</style>