import { createRouter, createWebHistory } from 'vue-router'
import AdminDashboard from "@/views/Admin/AdminDashboard.vue";
import AdminCompanies from "@/views/Admin/AdminCompanies.vue";
import AdminContent from "@/views/Admin/AdminContent.vue";
import AdminEvents from "@/views/Admin/AdminEvents.vue";
import AdminApproveEvent from "@/views/Admin/AdminApproveEvent.vue";
import AdminViewEvent from "@/views/Admin/AdminViewEvent.vue";
import AdminOrders from "@/views/Admin/AdminOrders.vue";
import AdminFinance from "@/views/Admin/AdminFinance.vue";
import LoginView from "@/views/Admin/AuthView.vue";
import EmailVerification from "@/views/Admin/EmailVerification.vue";
import axios from "axios";
import ResetPassword from "@/views/ResetPassword.vue";
import AdminProfile from "@/views/Admin/AdminProfile.vue";
import store from "@/store";
import AdminAnalytics from "@/views/Admin/AdminAnalytics.vue";
import NotFound from "@/views/NotFound.vue";
import AdminMessage from "@/views/Admin/AdminMessage.vue";
import RegistrationBusines from "@/views/Busines/Registration.vue";
import BusinesEmailVerification from "@/views/Busines/EmailVerification.vue";
import AuthBusines from "@/views/Busines/Auth.vue";
import BusinesDashboard from "@/views/Busines/BusinesDashboard.vue";
import BusinesEvents from "@/views/Busines/BusinessEvents.vue";
import BusinessOrders from "@/views/Busines/BusinesOrders.vue";
import BusinessCalendar from "@/views/Busines/BusinessCalendar.vue";
import BusinessFinance from "@/views/Busines/BusinessFinance.vue";
import BusinessMessages from "@/views/Busines/BusinessMessages.vue";
import BusinessProfile from "@/views/Busines/BusinessProfile.vue";
import BusinesNewEvent from "@/views/Busines/CreateNewEvent.vue";
import BusinesDraftEvent from "@/views/Busines/DraftEventEdit.vue";
import BusinesNewEventPreview from "@/views/Busines/CreateNewEventPreview.vue";
import CustomerAuth from "@/views/Customer/Auth.vue";
import CustomerRegistration from "@/views/Customer/Registration.vue";
import CustomerEmailVerification from "@/views/Customer/EmailVerification.vue";
import CustomerMyBooking from "@/views/Customer/CustomerMyBooking.vue";
import CustomerProfile from "@/views/Customer/CustomerProfile.vue";
import CustomerWishlist from "@/views/Customer/CustomerWishlist.vue";
import CustomerMessages from "@/views/Customer/CustomerMessages.vue";
import HomePage from "@/views/MainPages/HomePage.vue";
import CountryPage from "@/views/MainPages/CountryPage.vue";
import CityPage from "@/views/MainPages/CityPage.vue";
import EventPage from "@/views/MainPages/EventPage.vue";
import AuthCallback from "@/views/AuthCallback.vue";
import ReservationPage from "@/views/MainPages/ReservationPage.vue";
const routes = [
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '/auth/callback',
    name: 'AuthCallback',
    component: AuthCallback
  },
  {
    path: '/',
    name: 'home',
    component: HomePage,
    props: true,
    meta:{breadcrumb: 'Home'}
  },
  {
    path: '/:country',
    name: 'CountryPage',
    component: CountryPage,
    props: true,
  },
  {
    path: '/:country/:city',
    name: 'CityPage',
    component: CityPage,
    props: true,
  },
  {
    path: '/:country/:city/:id/reservation',
    name: 'ReservationPage',
    component: ReservationPage,
    props: true,
  },
  {
    path: '/:country/:city/:id',
    name: 'EventPage',
    component: EventPage,
    props: true,
  },
  {
    path: '/panel/admin/dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/companies',
    name: 'AdminCompanies',
    component: AdminCompanies,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/content',
    name: 'AdminContent',
    component: AdminContent,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/events',
    name: 'AdminEvents',
    component: AdminEvents,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/orders',
    name: 'AdminOrders',
    component: AdminOrders,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/finance',
    name: 'AdminFinance',
    component: AdminFinance,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/events/approve-event/:id',
    name: 'AdminApproveEvent',
    component: AdminApproveEvent,
    props: true,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/events/:id',
    name: 'AdminViewEvent',
    component: AdminViewEvent,
    props: true,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/profile',
    name: 'AdminProfile',
    component: AdminProfile,
    props: true,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/analytics',
    name: 'AdminAnalytics',
    component: AdminAnalytics,
    props: true,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/panel/admin/messages',
    name: 'AdminMessage',
    component: AdminMessage,
    props: true,
    meta:{auth:true,role:"admins"}
  },
  {
    path: '/admin/login',
    name: 'LoginView',
    component: LoginView,
  },
  {
    path: '/admin/email-verification',
    name: 'EmailVerification',
    component: EmailVerification,
  },
  {
    path: '/reset-password/',
    name: 'ResetPassword',
    props: true,
    component: ResetPassword,
  },
  {
    path: '/business/registration',
    name: 'RegistrationBusines',
    component: RegistrationBusines,
  },
  {
    path: '/business/auth',
    name: 'AuthBusines',
    component: AuthBusines,
  },
  {
    path: '/business/email-verification',
    name: 'BusinesEmailVerification',
    component: BusinesEmailVerification,
  },
  {
    path: '/panel/business/dashboard',
    name: 'BusinesDashboard',
    component: BusinesDashboard,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/events',
    name: 'BusinesEvents',
    component: BusinesEvents,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/event/new',
    name: 'BusinesNewEvent',
    component: BusinesNewEvent,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/event/draft/:id',
    name: 'BusinesDraftEvent',
    component: BusinesDraftEvent,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/event/preview/:id',
    name: 'BusinesNewEventPreview',
    component: BusinesNewEventPreview,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/orders',
    name: 'BusinessOrders',
    component: BusinessOrders,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/calendar',
    name: 'BusinessCalendar',
    component: BusinessCalendar,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/finance',
    name: 'BusinessFinance',
    component: BusinessFinance,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/messages',
    name: 'BusinessMessages',
    component: BusinessMessages,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/panel/business/profile',
    name: 'BusinessProfile',
    component: BusinessProfile,
    props: true,
    meta:{auth:true,role:"business"}
  },
  {
    path: '/auth',
    name: 'CustomerAuth',
    component: CustomerAuth,
  },
  {
    path: '/registration',
    name: 'CustomerRegistration',
    component: CustomerRegistration,
  },
  {
    path: '/email-verification',
    name: 'CustomerEmailVerification',
    component: CustomerEmailVerification,
  },
  {
    path: '/my-booking',
    name: 'CustomerMyBooking',
    component: CustomerMyBooking,
    props: true,
    meta:{auth:true,role:"customer"}
  },
  {
    path: '/profile',
    name: 'CustomerProfile',
    component: CustomerProfile,
    props: true,
    meta:{auth:true,role:"customer"}
  },
  {
    path: '/wishlist',
    name: 'CustomerWishlist',
    component: CustomerWishlist,
    props: true,
    meta:{auth:true,role:"customer"}
  },
  {
    path: '/messages',
    name: 'CustomerMessages',
    component: CustomerMessages,
    props: true,
    meta:{auth:true,role:"customer"}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Если сохраненная позиция существует (например, при нажатии кнопки "назад"), восстановить её
    if (savedPosition) {
      return savedPosition;
    } else {
      // Прокрутить наверх при переходе на новый маршрут
      return { left: 0, top: 0 };
    }
  },
})
router.beforeEach((to,from,next)=>{
  axios.get('/record-visitor')
  .then(response => {
  })
  .catch(error => {
  });
  if (to.name === '404') {
    next();
    return;
  }
  if(to.params.country){
  const country = to.params.country;
  let fData = new FormData();
  if(to.params.id && to.params.country && to.params.city){
    fData.append("city", to.params.city); 
    fData.append("event_slug", to.params.id); 
  }
  else if(to.params.country && to.params.city){
    fData.append("city", to.params.city); 
  }
  fData.append("country", country);
  axios.post(`/check-404`,fData)
    .then(response => {
      if (response.data.status == 'found') {
        next();
      } else {
        window.location.href = '/404';
      }
    })
    .catch(error => {
      window.location.href = '/404';
    });
  }
  if(window.localStorage.getItem("auth_token")){
    axios.defaults.headers.common['Authorization'] = 'Bearer '+ window.localStorage.getItem("auth_token");
  }
  if(to.meta.auth){
    axios.post('/account/check/'+to.meta.role)
        .then((res) => {
          if(res.data.result==true){
            const userData = res.data.data;
            store.dispatch('setUser', userData);
            next();
          }
          else{
            window.localStorage.removeItem("auth_token");
            router.push({name:'home'});
            return;
          }
        })
        .catch((res) => {
          if (res.response.status == 401) {
            window.localStorage.removeItem("auth_token");
            router.push({name:'home'});
            return;
          }
        });
  }
  else if(window.localStorage.getItem("auth_token")){
    axios.post('/check/auth')
        .then((res) => {
          if(res.data.result==true){
            const userData = res.data.data;
            store.dispatch('setUser', userData);
            next();
          }
          else{
            next();
          }
        })
        .catch((res) => {
          if (res.response.status == 401) {
            window.localStorage.removeItem("auth_token");
            router.push({name:'home'});
            return;
          }
        });
  }
  else {
    console.log('Пользователь не авторизован.');
    next();
  }
});
export default router
