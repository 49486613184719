<template>
  <div class="dialog" :class="{ active: show }" @click.stop="hideDialog">
    <div class="bg_color"></div>
    <div @click.stop class="main_content">
      <Button class="exit-dialog" @click="hideDialog">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                fill="#292929"/>
        </svg>
      </Button>
      <div class="content">
        <div class="top__content">
          <div class="title_modal">Information center</div>
        </div>
        <div class="bottom__content" v-if="!showArticle">
          <div class="title_modal">Suggested articles</div>
          <div class="articles">
            <div class="article" v-for="(item,index) in articles" :key="index" @click="showBlock(index)">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="bottom__content" v-if="showArticle">
          <div class="article__block" v-if="currentIndex == 0 && showArticle">
            <div class="btn__back" @click="hideBlock">Back</div>
            <div class="title_modal">Choosing your activities</div>
            <div class="description_modal">
              If you cancel a booking, we'll confirm your cancellation via email. Please check your spam folder if you
              don't receive a confirmation. You can also check your booking status on the Bookings page in your amuse
              account.
            </div>
            <div class="description_modal">
              If you canceled your booking within the cancellation policy, your refund will be automatically processed
              via the same payment method you used to book the activity. You should see it in your account within 3-5
              business days.
            </div>
          </div>
          <div class="article__block" v-if="currentIndex == 1 && showArticle">
            <div class="btn__back" @click="hideBlock">Back</div>
            <div class="title_modal">Cancelation policy</div>
            <div class="description_modal">
              If you cancel a booking, we'll confirm your cancellation via email. Please check your spam folder if you
              don't receive a confirmation. You can also check your booking status on the Bookings page in your amuse
              account.
            </div>
            <div class="description_modal">
              If you canceled your booking within the cancellation policy, your refund will be automatically processed
              via the same payment method you used to book the activity. You should see it in your account within 3-5
              business days.
            </div>
          </div>
          <div class="article__block" v-if="currentIndex == 2 && showArticle">
            <div class="btn__back" @click="hideBlock">Back</div>
            <div class="title_modal">How it works?</div>
            <div class="description_modal">
              If you cancel a booking, we'll confirm your cancellation via email. Please check your spam folder if you
              don't receive a confirmation. You can also check your booking status on the Bookings page in your amuse
              account.
            </div>
            <div class="description_modal">
              If you canceled your booking within the cancellation policy, your refund will be automatically processed
              via the same payment method you used to book the activity. You should see it in your account within 3-5
              business days.
            </div>
          </div>
          <div class="article__block" v-if="currentIndex == 3 && showArticle">
            <div class="btn__back" @click="hideBlock">Back</div>
            <div class="title_modal">Contact us</div>
            <form @submit.prevent="submitForm" action="">
              <textarea v-model="form.message" placeholder="Enter booking number"></textarea>
              <div class="input__wrapper">
                <div class="title">Email</div>
                <input type="text" v-model="form.email" placeholder="Email">
              </div>
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentIndex: null,
      showArticle: false,
      form: {
        message: '',
        email: '',
      },
      articles: [
        {title: "Choosing your activities"},
        {title: "Cancelation policy"},
        {title: "How it works?"},
        {title: "Contact us"}
      ]
    }
  },
  methods: {
    submitForm() {
      let fData = new FormData();
      fData.append("message", this.form.message);
      fData.append("email", this.form.email);
      axios.post("/business/contact-us", fData)
          .then(resp => {

          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    showBlock(index) {
      this.currentIndex = index;
      this.showArticle = true;
    },
    hideBlock() {
      this.currentIndex = null;
      this.showArticle = false;
    },
    hideDialog() {
      this.currentIndex = null;
      this.showArticle = false;
      this.$emit('update:show', false)
    }
  },
}
</script>

<style scoped>
.article__block form textarea {
  padding: 16px;
  width: 100%;
  resize: none;
  height: 214px;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
}

.article__block form button {
  border-radius: 8px;
  background: var(--Green-0, rgba(0, 211, 72, 0.24));
  height: 50px;
  width: 100%;

  color: var(--Green-400, #39766A);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
  margin-top: 32px;
}

.article__block .input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.article__block .input__wrapper .title {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
}

.article__block .input__wrapper input {
  width: 100%;
  height: 46px;
  padding-left: 12px;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.dialog .main_content{
  padding: 0;
}
.exit-dialog {
  top: 24px;
  right: 24px;
}

.main_content {
  padding: 0;
}

.top__content {
  padding: 24px;
  border-bottom: 1.5px solid var(--Gray-stroke, #E5E5EA);
  align-items: center;
  display: flex;
}

.content .top__content .title_modal {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.bottom__content .title_modal {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 0;
  padding-bottom: 16px;
}

.bottom__content {
  padding: 24px;
}

.articles {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.articles .article {
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 16px;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

  color: var(--Black-400, #292929);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.article__block .btn__back {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
  text-decoration-line: underline;
  padding-bottom: 24px;
  cursor: pointer;
}

.article__block .description_modal {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.102px;
  padding-bottom: 16px;
}
@media screen and (max-width: 768px) {
    .dialog.active .main_content {
        padding: 0;
    }
}
</style>