<template>
<div class="top__experiences__wrapper">
    <div class="title"></div>
    <div class="content">
      <div v-for="value in 8" class="block">
        <div class="image__block"></div>
        <div class="event__name"></div>
        <div class="event__country"></div>
        <div class="event__score__wrapper"></div>
        <div class="event__price__wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.title{
  background: #EBEBEB;
    width: 224px;
    height: 37px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.content {
  display: flex;
  gap: 32px;
  width: 100%;
  flex-wrap: wrap;
}
.image__block{
    aspect-ratio: 1/1;
    border-radius: 16px;
    background: #EBEBEB;
    margin-bottom: 15px;
}
.block {
  width: calc((100% / 4) - 24px);
}
.event__name{
  background: #EBEBEB;
    border-radius: 8px;
    max-width: 284px;
    height: 42px;
    margin-bottom: 8px;
}
.event__country{
  background: #EBEBEB;
    border-radius: 8px;
    width: 172px;
height: 20px;
margin-bottom: 12px;
}
.event__score__wrapper{
  background: #EBEBEB;
    border-radius: 8px;
    width: 54px;
    height: 20px;
    margin-bottom: 12px;
}
.event__price__wrapper{
  background: #EBEBEB;
    border-radius: 8px;
    width: 112px;
height: 23px;
}
@media screen and (max-width: 1024px) {
  .block {
    width: calc((100% / 2) - 16px);
  }
}
@media screen and (max-width: 768px) {
  .block {
    width: 100%;
  }
}
</style>