<template>
    <div class="category">
        <div class="category__wrapper">
            <div class="big__block"></div>
            <div class="block">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="big__block"></div>
        </div>
        <div class="right">
            <div class="full"></div>
            <div class="low"></div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.category{
    display: flex;
    gap: 12px;
    justify-content: space-between;
}
.category__wrapper{
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    flex-shrink: 1;
    gap: 12px;
}
.category__wrapper .block{
    display: flex;
    gap: 18px;
    width: 100%; 
}
.category__wrapper .block > div{
    background: #EBEBEB;
    height: 36px;
    width: 100%;
    border-radius: 8px;
}
.big__block{
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background: #EBEBEB;
    min-width: 48px;
    max-height: 48px;
}
.right{
    display: flex;
    gap: 12px;
    width: 24.7%;

}
.right .full{
    height: 48px;
    border-radius: 8px;
    background: #EBEBEB;
    width: 248px;
}
.right .low{
    height: 48px;
    border-radius: 8px;
    background: #EBEBEB;
    width: 100px;
}
</style>