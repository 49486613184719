
<template>
  <PreloaderTopDestination :class="{ hidden: !isLoading, visible: isLoading }"></PreloaderTopDestination>
  <div :class="{ hidden: isLoading, visible: !isLoading }" class="top__destinations__wrapper">
    <div class="title">{{ title }}</div>
    <div class="content">
        <div class="block" v-for="post in posts">
          <img data-load v-if="post['images'].length < 2"  :src="this.$store.state.base_directory + post['images'][0]"  alt="">
          <TopDestinationSlider v-else :languages="languages.code" :currency="currency.currency_code" :value="post" :images="post.images"></TopDestinationSlider>
          <div class="event__name">{{ post.name }}</div>
          <router-link :to="{
              name: 'CityPage',
              params: {
              country: post.country_name.toLowerCase(),
              city: post.name.toLowerCase()
              },
              query: {
                  currency: currency.currency_code,
                  type: 1,
                  lang: languages.code
              }
            }">
          </router-link>
        </div>
    </div>
  </div>
</template>
<script>
import TopDestinationSlider from "@/components/MainPages/HomePage/TopDestinationSlider.vue";
import PreloaderTopDestination from "@/components/MainPages/HomePage/PreloaderTopDestination.vue";
import { mapState } from 'vuex';
export default {
  components: {
    TopDestinationSlider,PreloaderTopDestination
  },
  props: {
    title: String,
    posts: Array,
    isLoading: Boolean,
  },
  computed: {
    ...mapState(['currency','languages'])
  },
}
</script>
<style scoped>
.hidden {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}
.visible {
  visibility: visible;
}
.title{
  color: #292929;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.192px;
  padding-bottom: 20px;
}
.mobile{
  display: none !important;
}
.view__more {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;

  color: var(--Gray-400, #606060);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;

  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  margin-top: 8px;
}
.top__destinations__wrapper .content{
  display: flex;
  gap: 32px;
  width: 100%;
  flex-wrap: wrap;
}
.top__destinations__wrapper .block{
  width: calc((100% / 4) - 24px);
  position: relative;
}
.top__destinations__wrapper a{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.top__destinations__wrapper .block img{
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(41, 41, 41, 0.20) 0%, rgba(41, 41, 41, 0.20) 100%), lightgray 50% / cover no-repeat;
  width: 100%;
  aspect-ratio: 1 / 0.66;
  margin-bottom: 16px;
  object-fit: cover;
}
.top__destinations__wrapper .block .event__name{
  color: #292929;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.108px;
  margin-bottom: 8px;
}
.top__destinations__wrapper .block .event__country{
  color: rgba(41, 41, 41, 0.70);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
  margin-bottom: 12px;
}
.top__destinations__wrapper .block .event__score__wrapper{
  display: flex;
  gap: 10px;
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
  margin-bottom: 12px;
  align-items: center;
}
.top__destinations__wrapper .block .event__price__wrapper{
  display: flex;
  gap: 6px;
  color: rgba(41, 41, 41, 0.70);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
  align-items: center;
}
.top__destinations__wrapper .block .event__price__wrapper span{
  color: #292929;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
@media screen and (max-width: 1024px) {
  .top__destinations__wrapper .block {
    width: calc((100% / 2) - 16px);
  }
}
@media screen and (max-width: 768px) {
  .top__destinations__wrapper .block {
    width: 100%;
  }
  .mobile{
    display: flex !important;
  }
  .title{
  color: #292929;
  font-family: Standerd;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.144px;
  }
}
</style>