<template>
  <header-page></header-page>
  <main>
    <section class="companies">
    <div class="companies__container">
        <div class="companies__content">
          <SideBar></SideBar>
          <MyBookingsContent @update="changeStatus" :data="data"></MyBookingsContent>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SideBar from "@/components/Customer/SideBar.vue";
import axios from "axios";
import MyBookingsContent from "@/components/Customer/MyBookingsContent.vue";
export default {
  components: {SideBar,MyBookingsContent},
  data() {
    return {
      data: [],
    }
  },
  methods: {
    changeStatus(id) {
            if(id == 1){
              this.getMyBookings();
            }else{
              this.getMyBookingsArchive();
            }
      },
    async getMyBookings() {
      try {
        let fData = new FormData();
        const response = await axios.post('/customer/get/my-booking', fData);
        this.data = response.data.data;
      } catch (error) {
        console.error('Error updating wishlist:', error);
      }
    },
    async getMyBookingsArchive() {
      try {
        let fData = new FormData();
        const response = await axios.post('/customer/get/my-booking-archive', fData);
        this.data = response.data.data;
      } catch (error) {
        console.error('Error updating wishlist:', error);
      }
    },

  },
  mounted () {
    this.getMyBookings();
  },
}
</script>

<style scoped>
.companies{
    padding-bottom: 19px;
}
</style>