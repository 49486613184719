<template>
  <header-page></header-page>
  <main>
    <BreadCrumbs  :exit="false" :loading="loadingСompleted" :post="arr_data"></BreadCrumbs>
    <event-view-content :loading="loadingСompleted" :post="arr_data"></event-view-content>
  </main>
</template>

<script>
import BreadCrumbs from "@/components/MainPages/EventPage/BreadCrumb.vue";
import EventViewContent from "@/components/Admin/AdminViewEventContent.vue";
import axios from "axios";

export default {
  components: {EventViewContent, BreadCrumbs},
  data() {
    return {
      loadingСompleted: false,
      id_page: this.$route.params.id,
      arr_data: [],
    }
  },
  methods: {
    getData() {
      this.loadingСompleted = false;
      let fData = new FormData();
      fData.append("id", this.id_page);
      axios.post("/admin/events/approve-page", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_data = resp.data.data;
        }
      }).catch(() => {
      }).finally(() => {
        this.loadingСompleted = true;
      });
    },
  },
  mounted() {
    this.getData();
  },
}
</script>

<style scoped>
</style>