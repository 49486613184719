<template>
  <div class="map_sessions">
    <slot></slot>
    <div class="sessions__country">
      <template v-for="item in values">
        <div class="country">
          <div class="country__name">
            <!-- <img  :src='this.$store.state.base_directory + "country/"+ item.name + "/flag.png"' alt=""> -->
            <img class="flag" :src='this.$store.state.base_directory + "flags/"+item.name+".png"' alt="">
            <div class="title">{{ item.name }}</div>
          </div>
          <div class="count">{{ item.count}} Users</div>
        </div>
        <div class="line"></div>
      </template>
    </div>
    <div class="session__total">
      <div class="title">Total users</div>
      <div class="count">{{ sumSessions }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'analytics-session',
  props: {
    values: Object,
  },
  computed: {
    sumSessions() {
      return this.values.reduce((acc, value) => acc + value.count, 0);
    }
  },
}
</script>

<style scoped>
.map_sessions{
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: 16px;
  padding-bottom: 20px;
  height: 100%;
}
.line{
  height: 1.5px;
  width: 100%;
  background: #E5E5EA;
}
.sessions__country{
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 263px;
  padding-right: 16px;
  overflow-y: auto;
  scrollbar-color: #d4aa70 #e4e4e4;
  scrollbar-width: thin;
  margin-bottom: 24px;
}
.sessions__country .line:last-child{
  display: none;
}
.sessions__country::-webkit-scrollbar {
  width: 4px;
}
.sessions__country::-webkit-scrollbar-track {
  border-radius: 200px;
  background: #E5E5EA;
}

.sessions__country::-webkit-scrollbar-thumb {
  border-radius: 200px;
  background: #292929;
}
.country{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.country__name{
  display: flex;
  gap: 8px;
  align-items: center;
}
.country__name img{
  width: 24px;
  height: 16px;
}
.country__name .title{
  color: #292929;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
}
.sessions__country .count{
  color: rgba(41, 41, 41, 0.80);
  text-align: right;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
}
.session__total .title{
  color: rgba(41, 41, 41, 0.80);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
  padding-bottom: 8px;
}
.session__total .count{
  color: #292929;
  font-family: Standerd;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.144px;
}
@media screen and (max-width: 768px) {
  .session__total{
    display: none;

  }
  .sessions__country{
    max-height: 263px;
    padding-right: 0;
    scrollbar-color: unset;
    scrollbar-width: unset;
    margin-bottom: 0;
  }
  .sessions__country::-webkit-scrollbar {
    width: 0;
    height: unset;
  }
  .sessions__country::-webkit-scrollbar-track {
    border-radius: unset;
    background: unset;
  }

  .sessions__country::-webkit-scrollbar-thumb {
    border-radius: unset;
    background: unset;
  }
}
</style>