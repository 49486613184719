<template>
  <div class="dialog" :class="{ active: show }" @click.stop="hideDialog">
    <div class="bg_color"></div>
    <div @click.stop class="main_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dialog-details',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:show', false)
    }
  },
}
</script>

<style>
.dialog {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s !important;
}
.dialog.active{
  visibility: visible;
  opacity: 1;
}
.dialog .main_content{
    position: absolute;
    max-width: 542px;
    width: 100%;
    top: 20px;
    padding: 24px;
    padding-top: 30px;
    z-index: 30;
    height: calc(100vh - 40px);
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 1.5px solid #E5E5EA;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  right: -100%;
  transition: transform 0.3s ease-in-out, right 0.3s ease-in-out;
}
.dialog.active .main_content{
  transform: translateX(0);
  right: 20px;
}
.dialog .content .title_modal{
  color: #292929;
  font-family: Standerd;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.144px;
  padding-bottom: 26px;
}
.dialog .content{
  height: calc(100% - 224px);
  max-height: calc(100% - 224px);
}

.dialog .content .top_nav_bnt{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
}
.dialog .content .company_detail{
  display: flex;
  gap: 12px;
  align-items: center;
}
.dialog .company_detail img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog .company_detail .image_letter{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.dialog .company_detail .company__name{
  color: #232323;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
}
.content .all_details_info{
  padding: 16px;
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  overflow-y: auto;
  max-height: 100%
}
.dialog .all_details_info .item{
    display: flex;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #E5E5EA;
}
.dialog .all_details_info .item.column{
  align-items: unset;
}
.dialog .all_details_info .item:last-of-type{
  border-bottom: 0px solid #E5E5EA;
  padding-bottom: 0;
}
.dialog .all_details_info .item:first-of-type{
  padding-top: 0;
}
.dialog .item .left{
  color: #292929;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 25.5px */
  letter-spacing: -0.374px;
}
.dialog .item .right{
  display: flex;
  align-items: center;
}
.dialog .item .right.column{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.dialog .item .right .text{
  color: #606060;
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 117.647% */
}
.dialog .item .right .text.underlining{
  text-decoration-line: underline;
}
.dialog .item .right .company__panelButton{
  padding-left: 16px;
}
.bg_color{
  opacity: 0.32;
  background: #292929;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transition: opacity 1s cubic-bezier(.16,1,.3,1);
}
.company__panelButton {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.company__panelButton button{
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
@media screen and (max-width: 768px) {
  .dialog .content {
    height: unset;
  }
  .content .all_details_info{
    overflow-y: unset;
    height: unset;
  }
  .dialog.active .main_content{
    right: 0;
  }
  .dialog  .main_content{
    max-width: 100%;
    border-radius: 0;
    height: 100%;
    border: 0;
    padding: 16px;
    padding-top: 22px;
    right: 0;
    top: 0;
    gap: 16px;
    overflow-y: overlay;
  }
  .dialog .content .top_nav_bnt{
    flex-direction: column;
    gap: 24px;
  }
  .dialog .top_nav_bnt .company__panelButton{
    width: 100%;
    justify-content: unset;
  }
  .dialog .top_nav_bnt .company__panelButton button{
    width: 100%;
    height: 40px;
  }
}
</style>