<template>
    <div class="wrapper__cropper">
        <div class="bg"></div>
        <div class="content__wrapper">
            <div class="img__cropper">
                <vue-cropper 
                        ref="cropper"
                        :src="img"
                        :dragMode="'none'"
                        :background="false"
                        :highlight="true"
                        :scalable="false"
                        :zoomable="false"
                        :cropBoxResizable="false"
                        :autoCrop="true"
                        :autoCropArea="0.1"
                        :viewMode="3"
                        :minCropBoxWidth="99999"
                        :minCropBoxHeight="300"
                        :guides="false"
                        />
            </div>
            <div class="button__wrapper">
                <div class="button button__cancel" @click="this.$emit('cancel');">Cancel</div>
                <div class="button button__save" @click="getCroppedImage">Save</div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
    components: {VueCropper},
    props: {
        img: [String, File],
    },
    methods: {
        getCroppedImage() {
            console.log(this.$refs.cropper);
            const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
            // Convert canvas to Blob (JPEG format)
            croppedCanvas.toBlob((blob) => {
            // Create a URL for the Blob object
            const croppedImageURL = URL.createObjectURL(blob);
            const croppedImageFile = new File([blob], 12414, { type: blob.type });
            // Emit the URL for the cropped image
            this.$emit('save', {url:croppedImageURL,file:croppedImageFile});
            }, 'image/jpeg');
        },
    },
}
</script>

<style scoped>
.cropper-line{
    background-color: unset
}
.cropper-center{
    display: none;
}
.cropper-drag-box.cropper-modal{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%), lightgray -0.153px -405px / 100.04% 485.714% no-repeat;
}
.bg{
    background: rgba(50, 50, 50, 0.60);
    width: 100%;
    height: 100%;
}
.wrapper__cropper{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.content__wrapper{
    position: absolute;
    z-index: 1;
    padding: 37px;
    padding-bottom: 24px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    max-width: 838px;

}
.button__wrapper{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 24px;
}
.button{
    height: 44px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button__cancel{
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}
.button__save{
    border-radius: 8px;
    background: #323232;
    color: #FFF;
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}
</style>