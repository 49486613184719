<template>
    <div class="tab-bar">
        <div class="percent-wrapper">
            <div class="percent">{{percent[step]}}% Complete</div>
            <progress class="progress" :value="percent[step]" max="100"></progress>
        </div>
        <div class="tabs">
            <div class="tab" v-for="(value,index) in tabList" 
            :class="{ 'active': step === index || step > index }" 
            :key="index" 
            >{{value}}
            </div>
            <!-- @click="step != index &&  step > index ? this.$emit('tab',index) : false;" -->
        </div>
    </div>
</template>

<script>
    export default {
        name: "tab-bar",
        props: {
            step: Number,
        },
        data() {
            return {
                tabList: [
                "General information",
                "Experience description",
                "Photos & Price",
                "Date",
                "Time",
                "Additional Information",
                ],
                percent: [
                    20,
                    40,
                    60,
                    80,
                    80,
                    100,
                ],

            }
        },
    }
</script>

<style scoped>
.tab-bar{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    max-width: 326px;
    height: 100%;
    min-height: 809px;
    width: 39%;
    min-width: 240px;
}
.percent-wrapper .percent{
    color: var(--Black-300, #333);
font-family: Standerd;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
padding-bottom: 8px;
}
.percent-wrapper progress{
  width: 100%;
  height: 8px;
  border-radius: 500px;
  overflow: hidden;
}

.percent-wrapper progress[value]::-webkit-progress-bar {
    background: var(--Gray-stroke, #E5E5EA);
}

.percent-wrapper progress[value]::-webkit-progress-value {
    background: var(--Green-400, #39766A);
    border-radius: 500px;
}
.tabs{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.tabs .tab{
    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;

    cursor: pointer;
}
.tabs .tab.active{
    border: 1.5px solid var(--Green-400, #39766A);
}
@media screen and (max-width: 1024px) {
    .tab-bar{
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .tab-bar{
        display: none;
    }
}
</style>