<template>
  <div :class="['toast', types, visible ? 'show' : 'hide']">
    {{ messages }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: '',
      types: '',
      duration: '',
      visible: false
    };
  },
  methods: {
    show(type,title,duration) {
      this.messages = title;
      this.types = type;
      this.visible = true;
      setTimeout(() => {
        this.hide();
      }, duration);
    },
    hide() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 10px;
  width: auto;
  padding: 16px;
  right: -100%;
  border-radius: 5px;
  color: #fff;
  transition: all .3s;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.toast.hide {
  right: -100%;
}
.success {
  right: 20px;
  background-color: #28a745; /* Зеленый цвет */
}

.error {
  right: 20px;
  background-color: #dc3545; /* Красный цвет */
}
</style>