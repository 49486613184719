<template>
  <header-page></header-page>
  <main>
    <section class="companies">
      <div class="companies__container">
        <div class="companies__content">
          <side-bar></side-bar>
          <div class="right__main__content">
            <LanguageList @exit="dialogs.detailsLanguage = false; getContent(); " 
            @edit="getEdit" 
            v-if="dialogs.detailsLanguage" 
            :id="detailsValue" 
            :status="selectedStatus"
            @delete="deleteLanguage"/>
            <EditPage @exit="dialogs.editLanguage = false; selectedStatus < 2 ? (dialogs.detailsLanguage = true) : getContent();" 
            :arr="editOptions"
            :status="selectedStatus"
            v-else-if="dialogs.editLanguage"
            />
            <div v-else>
              <div class="filter__content">
                <div class="filter_content_wrapper">
                <div class="picker-option">
                  <template v-for="status in arr_status">
                    <button :class="[status.status_name,{'active': selectedStatus == status.id}]"  @click="changeStatus(status.id)" type="button">
                      {{ status.status_name }}
                    </button>
                    <div class="arrow__top"></div>
                  </template>
                </div>
                <ActiveFilters @delete="deleteFilter" :arrFilters="arrFilters" :filters="filters"></ActiveFilters>
                </div>
                <div class="filter">
                  <div @click="getChooseInit('');" class="btn-new-event"><svg xmlns="http://www.w3.org/2000/svg" width="14"
                                                height="14" viewBox="0 0 14 14" fill="none">
                                                <g clip-path="url(#clip0_2982_129386)">
                                                    <path
                                                        d="M7.875 0.875C7.875 0.391751 7.48325 0 7 0C6.51675 0 6.125 0.391751 6.125 0.875V6.125H0.875C0.391751 6.125 0 6.51675 0 7C0 7.48325 0.391751 7.875 0.875 7.875H6.125V13.125C6.125 13.6082 6.51675 14 7 14C7.48325 14 7.875 13.6082 7.875 13.125V7.875H13.125C13.6082 7.875 14 7.48325 14 7C14 6.51675 13.6082 6.125 13.125 6.125H7.875V0.875Z"
                                                        fill="#606060" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_2982_129386">
                                                        <rect width="14" height="14" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            {{btnAddText[selectedStatus]}}</div>
                  <input-filter v-if="selectedStatus != 2" @value="getSearchData" :placeholder_title="inputPlacholder[selectedStatus]" :data_name="'name'" :sort="searchData" @search="getSearch"></input-filter>
                </div>
              </div>
              <div>
              <post-list v-if="selectedStatus == 0" class="active" :posts="arr_data" :loader="data_info.loading">
                <table class="list">
                  <thead>
                  <tr>
                    <th>Country</th>
                    <th>Multi-language</th>
                    <th>Last update</th>
                    <th>Status</th>
                    <th>Open</th>
                  </tr>
                  </thead>

                  <tbody v-if="loadingСompleted">
                  <tr v-for="post in this.arr_data"
                      :key="post.id">
                    <td >
                      <div class="table__name">Country name:</div>
                      <div class="text">{{ post.country_name }}</div>
                    </td>
                    <td>
                      <div class="table__name">Multi-language</div>
                      <div class="text">{{ post.language_count }}</div>
                    </td>
                    <td>
                      <div class="table__name">Last update:</div>
                      <div class="text">{{ post.updated_at }}</div>
                    </td>
                    <td>
                      <div class="table__name">Status:</div>
                      <ToggleButton :status="selectedStatus" :status_id="post.status"
                                                      :published="post.published" :id="post.id"></ToggleButton>
                    </td>
                    <td>
                      <div class="company__panelButton">
                          <button @click="detailsValue = post.id; dialogs.detailsLanguage = true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                              <g clip-path="url(#clip0_3118_41499)">
                                <path d="M16.3333 15.8333H4.66667V4.16667H10.5V2.5H4.66667C3.74167 2.5 3 3.25 3 4.16667V15.8333C3 16.75 3.74167 17.5 4.66667 17.5H16.3333C17.25 17.5 18 16.75 18 15.8333V10H16.3333V15.8333ZM12.1667 2.5V4.16667H15.1583L6.96667 12.3583L8.14167 13.5333L16.3333 5.34167V8.33333H18V2.5H12.1667Z" fill="#292929"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_3118_41499">
                                  <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </post-list>
              <post-list v-if="selectedStatus == 1" class="active" :posts="arr_data" :loader="data_info.loading">
                <table class="list">
                  <thead>
                  <tr>
                    <th>City</th>
                    <th>Country</th>
                    <th>Multi-language</th>
                    <th>Last update</th>
                    <th>Status</th>
                    <th>Open</th>
                  </tr>
                  </thead>
                  <tbody v-if="loadingСompleted">
                  <tr v-for="post in this.arr_data"
                      :key="post.id">
                      <td >
                      <div class="table__name">City: </div>
                      <div class="text">{{ post.city_name }}</div>
                    </td>
                    <td >
                      <div class="table__name">Country name:</div>
                      <div class="text">{{ post.country_name }}</div>
                    </td>
                    <td>
                      <div class="table__name">Multi-language</div>
                      <div class="text">{{ post.language_count }}</div>
                    </td>
                    <td>
                      <div class="table__name">Last update:</div>
                      <div class="text">{{ post.updated_at }}</div>
                    </td>
                    <td>
                      <div class="table__name">Status:</div>
                      <ToggleButton :status="selectedStatus" :status_id="post.status"
                                                      :published="post.published" :id="post.id"></ToggleButton>
                    </td>
                    <td>
                      <div class="company__panelButton">
                          <button @click="detailsValue = post.id; dialogs.detailsLanguage = true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                              <g clip-path="url(#clip0_3118_41499)">
                                <path d="M16.3333 15.8333H4.66667V4.16667H10.5V2.5H4.66667C3.74167 2.5 3 3.25 3 4.16667V15.8333C3 16.75 3.74167 17.5 4.66667 17.5H16.3333C17.25 17.5 18 16.75 18 15.8333V10H16.3333V15.8333ZM12.1667 2.5V4.16667H15.1583L6.96667 12.3583L8.14167 13.5333L16.3333 5.34167V8.33333H18V2.5H12.1667Z" fill="#292929"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_3118_41499">
                                  <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </post-list>
              <post-list v-if="selectedStatus == 2" class="active" :posts="arr_data" :loader="data_info.loading">
                <table class="list">
                  <thead>
                  <tr>
                    <th>Language</th>
                    <th>Baners</th>
                    <th>Last update</th>
                    <th>Edit</th>
                  </tr>
                  </thead>

                  <tbody v-if="loadingСompleted">
                  <tr v-for="post in this.arr_data"
                      :key="post.id">
                      <td >
                      <div class="table__name">Language: </div>
                      <div class="text">{{ post.language_name == "English" ? post.language_name + ' (main version)': post.language_name}}</div>
                    </td>
                    <td >
                      <div class="table__name">Baners:</div>
                      <div class="text">{{ !post.content ? 0 : post.content.length}}</div>
                    </td>

                    <td>
                      <div class="table__name">Last update:</div>
                      <div class="text">{{ post.updated_at }}</div>
                    </td>
  
                    <td>
                      <div class="company__panelButton">
                            <button @click="getEdit(post)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M4.16667 15.8337H5.33334L12.5208 8.64616L11.3542 7.47949L4.16667 14.667V15.8337ZM16.0833 7.43783L12.5417 3.93783L13.7083 2.77116C14.0278 2.45171 14.4203 2.29199 14.8858 2.29199C15.3514 2.29199 15.7436 2.45171 16.0625 2.77116L17.2292 3.93783C17.5486 4.25727 17.7153 4.64283 17.7292 5.09449C17.7431 5.54616 17.5903 5.93144 17.2708 6.25033L16.0833 7.43783ZM3.33334 17.5003C3.09723 17.5003 2.89917 17.4203 2.73917 17.2603C2.57917 17.1003 2.49945 16.9025 2.5 16.667V14.3128C2.5 14.2017 2.52084 14.0939 2.5625 13.9895C2.60417 13.885 2.66667 13.7914 2.75 13.7087L11.3333 5.12533L14.875 8.66699L6.29167 17.2503C6.20834 17.3337 6.11472 17.3962 6.01084 17.4378C5.90695 17.4795 5.79917 17.5003 5.6875 17.5003H3.33334ZM11.9375 8.06283L11.3542 7.47949L12.5208 8.64616L11.9375 8.06283Z" fill="black"/>
                                </svg>
                            </button>
                            <button @click="deleteLanguage(post.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 1V2H18V4H17V17C17 18.1 16.1 19 15 19H5C3.9 19 3 18.1 3 17V4H2V2H7V1H13ZM5 17H15V4H5V17ZM7 6H9V15H7V6ZM13 6H11V15H13V6Z" fill="#292929"/>
                                </svg>
                            </button>
                        </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </post-list>
              <list-preloader v-if="!loadingСompleted"></list-preloader>
              <list-flip @update="onPage" v-if="data_info.loading || arr_data.length > 0" :class="{ active: loadingСompleted }" :page="data_info.page" :currenNumber="arr_data.length" :allCount="data_info.allCount" :next="data_info.next"></list-flip>
              </div>
            </div>

          </div>
          <modal-dialog v-model:show="dialogs.chooseDialog" :modal="true">
            <div>
              <div class="title">{{ dialogTitle[selectedStatus]}}</div>
            </div>
            <SelectDate @update:modelValue="handleUpdate" :show="dialogs.chooseDialog" @search="getChooseInit" :data_name="'name'" :sort="dataChoose"></SelectDate>
            <div class="btn">
              <Button class="white" @click="this.dialogs.chooseDialog = false;selectChoose = []">Cancel</Button>
              <Button class="green" @click="addContent">Confirm</Button>
            </div>
          </modal-dialog>
        </div>
    </div>
    </section>
  </main>
</template>

<script>
import ListFlip from "@/components/Admin/ListFlip.vue";
import PostList from "@/components/Admin/AdminPanelList.vue";
import axios from "axios";
import ListPreloader from "@/components/UI/Admin/ListPreloader.vue";
import ToggleButton from "@/components/Admin/ContentPage/ToggleButton.vue";
import SelectDate from "@/components/Admin/ContentPage/SelectDate.vue";
import LanguageList from "@/components/Admin/ContentPage/LanguageList.vue";
import EditPage from "@/components/Admin/ContentPage/EditPage.vue";
    export default {
      components: {ListPreloader, PostList, ListFlip,ToggleButton,SelectDate,LanguageList,EditPage},
        data() {
          return {
            dataChoose:[],
            arr_data: [],
            editOptions: [],
            searchValue: null,
            selectChoose: null,
            searchData: [],
            arr_status: [
              {"id":0,"status_name":"Country"},
              {"id":1,"status_name":"City"},
              {"id":2,"status_name":"Main page"},
            ],
            inputPlacholder: [
              "Search for country",
              "Search for city",
            ],
            btnAddText: [
              "Add country",
              "Add city",
              "Add language"
            ],
            dialogTitle:[
            "Choose Country",
              "Choose City",
              "Choose language"
            ],
            detailsValue: null,
            selectedStatus:0,
            dialogs: {
              chooseDialog: false,
              detailsLanguage: false,
              editLanguage: false,
            },
            data_info:{
              loading: true,
              totalRecords: 0,
              page:0,
              next: true,
              allCount: null,
            },
            loadingСompleted: false,
          }
        },
        methods: {
          deleteLanguage(id){
            let fData = new FormData();
            fData.append("id", id);
            fData.append("status",this.selectedStatus);
            axios.post("/admin/get/content/delete-lang",fData)
            .then(resp => {
                this.getContent();
            })
            .catch(()=>{}).finally(()=>{});
          },
          getEdit(e){
            this.editOptions = e;
            this.dialogs.detailsLanguage = false;
            this.dialogs.editLanguage = true;
          },
          addContent(){
            let fData = new FormData();
            fData.append("id", this.selectChoose);
            fData.append("status",this.selectedStatus);
            axios.post("/admin/add/content/post",fData)
            .then(resp => {
                this.getContent();
                this.dialogs.chooseDialog = false;
            })
            .catch(()=>{}).finally(()=>{});
          },
          getContent(){
            this.loadingСompleted = false;
            let fData = new FormData();
            if(this.arr_data.length<=0){
              this.data_info.page--;
              if(this.data_info.page<0) this.data_info.page = 0;
            }
            if(this.searchValue){
              fData.append("id",this.searchValue);
            }
            fData.append("page",this.data_info.page);
            fData.append("status",this.selectedStatus);
            axios.post("/admin/get/content/country",fData).then(resp => {
              if (resp.data['result'] == true) {
                this.arr_data = resp.data.data;
                this.data_info.allCount = resp.data.allCount;
                this.data_info.next = resp.data.next;
                this.data_info.loading = false;
              }
            }).catch(()=>{}).finally(()=>{
              this.loadingСompleted = true;
            });
          },
          getSearch(text){
            let fData = new FormData();
            fData.append("name", text);
            fData.append("status",this.selectedStatus);
            axios.post("/admin/get/content/search",fData).then(resp => {
              if (resp.data['result'] == true) {
                this.searchData = resp.data.data;
              }
            })
            .catch(()=>{}).finally(()=>{});
          },
          getChooseInit(text){
            let fData = new FormData();
            fData.append("name", text);
            fData.append("status",this.selectedStatus);
            axios.post("/admin/get/content/choose-data-init",fData).then(resp => {
              if (resp.data['result'] == true) {
                this.dataChoose = resp.data.data;
                this.dialogs.chooseDialog = true;
              }
            }).catch(()=>{}).finally(()=>{
            });
          },
          changeStatus(e){
            this.selectedStatus = e;
            this.data_info.loading = true;
            this.getContent();
            this.getSearch('');
          },
          handleUpdate(option) {
            this.selectChoose = option.id;
          },
          getSearchData(e){
            this.searchValue = e;
            this.getContent();
          }
        },
        mounted () {
          this.getContent();
          this.getSearch('');
        },
    }
</script>

<style scoped>
.list td .text{
    max-width: unset;
}
.btn-new-event {
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    height: 36px;
    padding: 0 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.08px;
    white-space: nowrap;
}
.company__panelButton{
  justify-content: unset;
}
@media screen and (max-width: 1024px) {
    .picker-option {
      max-width: unset;
    }
    .input__filter__wrapper{
    order: 1;
    }
    .filter {
      flex-direction: column;
    }
    .btn-new-event {
    height: 48px;
    margin-bottom: 8px;
    order: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>