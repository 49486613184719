<template>
    <div class="dialog__policy_wrapper" :class="{ active: show }" @click.stop="hideDialog">
      <div class="bg_color"></div>
  
      <div  @click.stop class="policy__content">
        <Button class="exit-dialog" @click="hideDialog">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
          </svg>
        </Button>
        <div class="title">Cancelation policy</div>
        <div class="block__values">
        <div class="title">{{title}}</div>
        <div v-for="item in arrayList" class="block_value">
        <div class="text_value">{{ item.value }}</div>
        <div class="value">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_2686_134099)">
                <path d="M7.4987 13.4997L3.9987 9.99972L2.83203 11.1664L7.4987 15.833L17.4987 5.83305L16.332 4.66638L7.4987 13.4997Z" fill="#39766A" stroke="#39766A" stroke-width="2"/>
            </g>
            <defs>
                <clipPath id="clip0_2686_134099">
                <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
            </svg>
        </div>
        </div>
    </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        arrayList: Object,Array,
      show: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      hideDialog() {
        this.$emit('update:show', false)
      },
    },
  }
  </script>
  
  <style scoped>
  .bg_color{
    opacity: 0.32;
    background: #292929;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    transition: opacity 1s cubic-bezier(.16,1,.3,1);
  }
  .dialog.bg-0 .bg_color{
    display: none;
  }
  .dialog__policy_wrapper{
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  
    position: fixed;
    display: flex;
    z-index: 100;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }
  .dialog__policy_wrapper.active{
    visibility: visible;
    opacity: 1;
  }
  
  .policy__content {
    width: 912px;
    max-width: 912px;
    z-index: 999;
    position: relative;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 24px;
    padding-bottom: 32px;
  }
  .policy__content .title{
    color: #000;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 16px;
  }
  .block__values .title {
    color: #000;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 16px;
}

.block__values .block_value {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #E5E5EA;
    ;
}

.block__values .block_value:last-of-type {
    padding-bottom: 0;
}

.block__values .text_value {
    color: var(--black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.108px;
}

.block__values .value {
    color: var(--black-400, #292929);
    text-align: right;
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.108px;
    display: flex;
    align-items: center;
}
  @media screen and (max-width: 768px) {
    .policy__content {
      width: calc(100% - 32px);
    }
  
    .policy__content .page_title {
      color: var(--black-400, #292929);
      text-align: center;
      font-family: Standerd;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.144px;
    }
  }
  </style>