<template>
  <div class="messages__send">
    <div class="file__add">
      <input type="file" id="input__file" @change="updateFile"/>
      <label for="input__file">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.4154 19.1667C7.88203 19.1667 5.83203 16.9117 5.83203 14.125L5.83203 4.49999C5.83203 2.47416 7.3237 0.833322 9.16536 0.833322C11.007 0.833322 12.4987 2.47416 12.4987 4.49999L12.4987 12.2917C12.4987 13.5567 11.5654 14.5833 10.4154 14.5833C9.26536 14.5833 8.33203 13.5567 8.33203 12.2917L8.33203 5.41666L9.9987 5.41666L9.9987 12.3742C9.9987 12.8783 10.832 12.8783 10.832 12.3742L10.832 4.49999C10.832 3.49166 10.082 2.66666 9.16536 2.66666C8.2487 2.66666 7.4987 3.49166 7.4987 4.49999L7.4987 14.125C7.4987 15.8942 8.80703 17.3333 10.4154 17.3333C12.0237 17.3333 13.332 15.8942 13.332 14.125L13.332 5.41666L14.9987 5.41666L14.9987 14.125C14.9987 16.9117 12.9487 19.1667 10.4154 19.1667Z" fill="#333333"/>
        </svg>
      </label>
    </div>
    <input class="inp" type="text" placeholder="Write your message" v-model="textOrFileName" @keydown.enter="textOrFileName != '' ? this.$emit('sendText',textOrFileName) : false; textOrFileName = '';">
    <button class="send" @click="textOrFileName != '' ? this.$emit('sendText',textOrFileName) : false; textOrFileName = '';">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_4052_16192)">
        <path d="M1.6763 17.5L19.168 10L1.6763 2.5L1.66797 8.33333L14.168 10L1.66797 11.6667L1.6763 17.5Z" fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_4052_16192">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'message-send',
  data() {
    return {
      textOrFileName: '', // Информация или название файла
    }
  },
  methods: {
    updateFile(event) {
      const fileInput = event.target;
      console.log(fileInput);
      if (fileInput.files.length > 0) {
        this.$emit('send',fileInput.files[0])
      }
    },
  },
}
</script>

<style scoped>
.messages__send{
  border-top: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  padding: 10px;
  display: flex;
  gap: 10px;
}
.file__add input{
  display: none;
}
.file__add label{
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.send{
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);

  /* last */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.inp{
  width: 100%;
  color: var(--gray-300, #888);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.308px;
}
.inp:focus{
  outline: none;
}
</style>