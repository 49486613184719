<template>
  <div class="circle__diagram__block">
    <div class="title">{{ title }}</div>
    <template v-if="title == 'Paid orders'">
      <div class="diagram">
          <div class="diagram__wrapper">
            <div class="chart" :style="{background: `conic-gradient( #C2F5D3 0% ${percentValue/2}%, #14AE5C 0% 100% )`}"></div>
          </div>
      </div>
      <div class="circle__diagram__wrapper">
        <div class="space-between">
          <div class="flex">
            <div class="circle" :style="{background: '#C2F5D3'}"></div>
            <div class="title">Unpaid</div>
          </div>
          <div class="count">{{ value.countOne }}</div>
        </div>
        <div class="line"></div>
        <div class="space-between">
          <div class="flex">
            <div class="circle" :style="{background: '#14AE5C'}"></div>
            <div class="title">Paid</div>
          </div>
          <div class="count">{{ value.countTwo }}</div>
        </div>
      </div>
    </template>
    <template v-else-if="title == 'New users'">
      <div class="diagram">
        <div class="diagram__wrapper">
        <div class="chart" :style="{background: `conic-gradient( #F8ECAF 0% ${percentValueUsers/2}%, #FFCE47 0% 100% )`}"></div>
        </div>
      </div>
      <div class="circle__diagram__wrapper">
        <div class="space-between">
          <div class="flex">
            <div class="circle" :style="{background: `#F8ECAF`}"></div>
            <div class="title">Registered users </div>
          </div>
          <div class="count" v-if="this.value.countOne !== 0">100</div>
          <div class="count" v-if="this.value.countOne == 0">0</div>
        </div>
        <div class="line"></div>
        <div class="space-between">
          <div class="flex">
            <div class="circle" :style="{background: `#FFCE47`}"></div>
            <div class="title">Not registered users </div>
          </div>
          <div class="count" v-if="this.value.countOne !== 0">30</div>
          <div class="count" v-if="this.value.countOne == 0">0</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'circle-diagram',
  props: {
    title: String,
    value: Object,
    type: String,
  },
  data() {
    return {
    }
  },
  computed: {
    percentValue() {
      if(this.value.countOne == 0) return 0;
      else return (this.value.countOne / (this.value.countOne + this.value.countTwo)) * 100;
    },
    percentValueUsers() {
      if(this.value.countOne == 0) return 0;
      else return (100 / (100 + 30)) * 100;
    },
  },
}
</script>

<style scoped>
.diagram{
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}
.circle__diagram__block{
  padding: 16px;
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  width: 100%;
}
.circle__diagram__block > .title{
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 20px;
  font-styleComp: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 32px;
}
.diagram__wrapper{
  width: 100%;
  padding-top: 100%;
  position: relative;
  margin-bottom: -50%;
}
.chart {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  rotate: -90deg;
}
.chart::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.circle__diagram__wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.circle__diagram__wrapper .flex{
  display: flex;
  gap: 8px;
  align-items: center;
}
.circle__diagram__wrapper .space-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.circle__diagram__wrapper .title{
  color: var(--gray-400, #606060);
  font-family: Standerd;
  font-size: 15px;
  font-styleComp: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
}
.circle__diagram__wrapper .count{
  color: #292929;
  font-family: Standerd;
  font-size: 15px;
  font-styleComp: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
}
.circle__diagram__wrapper .line{
  height: 1.5px;
  width: 100%;
  background: #E5E5EA;
}

@media screen and (max-width: 768px){
  .diagram{
    margin-bottom: 16px;
  }
}
</style>