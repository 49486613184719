<template>
    <div class="wrapper__content">
        <div @click="decrement" class="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_5562_34228)">
                <path d="M9.70696 12.709C9.31643 12.3184 9.31643 11.6853 9.70696 11.2947L13.2927 7.70896C13.9227 7.079 14.9998 7.52516 14.9998 8.41607L14.9998 15.5876C14.9998 16.4785 13.9227 16.9247 13.2927 16.2947L9.70696 12.709Z" fill="#292929"/>
            </g>
            <defs>
                <clipPath id="clip0_5562_34228">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
            </svg>
        </div>
        <input type="text" v-model="selectedNumber" @input="this.$emit('update:value', $event.target.value);"/>
        <div @click="increment" class="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_5562_34235)">
                <path d="M14.7071 10.8809C15.0976 11.2714 15.0976 11.9046 14.7071 12.2951L11.1213 15.8809C10.4914 16.5108 9.41421 16.0647 9.41421 15.1738L9.41421 8.0022C9.41421 7.1113 10.4914 6.66513 11.1213 7.2951L14.7071 10.8809Z" fill="#292929"/>
            </g>
            <defs>
                <clipPath id="clip0_5562_34235">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
    props: {
        value: Number,
    },
    data() {
    return {
        selectedNumber: this.value,
    };
    },
    methods: {
        increment() {
            this.selectedNumber++;
            this.$emit('update:value', this.selectedNumber);
        },
        decrement() {
            if (this.selectedNumber > 0) {
                this.selectedNumber--;
                this.$emit('update:value', this.selectedNumber);
            }
        }
    }
    }
</script>

<style scoped>
.wrapper__content{
    display: flex;
    gap: 12px;
    align-items: center;
}
input{
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    width: 80px;
    height: 40px;
    color: var(--Gray-400, #606060);
    text-align: center;
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
}
.button{
    display: flex;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    /* last */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);   
}
</style>