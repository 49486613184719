<template>
  <div class="dialog__login__form" :class="{ active: show }" @click.stop="hideDialog">
    <div class="bg_color"></div>

    <div  @click.stop v-if="tabIndex == 1" class="login_content">
      <Button class="exit-dialog" @click="hideDialog">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
        </svg>
      </Button>
      <form action="">
        <div class="title">Login</div>
        <div class="inputs__wrapper">
          <div class="input__wrapper">
            <div class="text">Email</div>
            <Input-email :placeholder="'Email'" :email="auth_data.email"
                         @update:email="auth_data.email = $event"></Input-email>
          </div>
          <div class="input__wrapper">
            <div class="text__wrapper">
              <div class="text">Password</div>
              <router-link class="link" :to="{name: 'CustomerEmailVerification'}">Forgot password?</router-link>
            </div>
            <Input-password :placeholder="'Password'" :password="auth_data.password"
                            @update:password="auth_data.password = $event"></Input-password>
            <div class="checkbox">
              <input id="remember_me" class="checkbox_input" type="checkbox" v-model="auth_data.remember_me"/>
              <label for="remember_me"></label>
              <div class="text">remember me</div>
            </div>
          </div>
        </div>
        <button class="btn_login" type="submit" @click="SubmitAuth">Login</button>
        <div class="social__login">
          <div @click="loginWithGoogle('google')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#FFC107"/>
              <path
                  d="M3.15234 7.3455L6.43784 9.755C7.32684 7.554 9.47984 6 11.9993 6C13.5288 6 14.9203 6.577 15.9798 7.5195L18.8083 4.691C17.0223 3.0265 14.6333 2 11.9993 2C8.15834 2 4.82734 4.1685 3.15234 7.3455Z"
                  fill="#FF3D00"/>
              <path
                  d="M12.0002 22.0003C14.5832 22.0003 16.9302 21.0118 18.7047 19.4043L15.6097 16.7853C14.5719 17.5745 13.3039 18.0014 12.0002 18.0003C9.39916 18.0003 7.19066 16.3418 6.35866 14.0273L3.09766 16.5398C4.75266 19.7783 8.11366 22.0003 12.0002 22.0003Z"
                  fill="#4CAF50"/>
              <path
                  d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#1976D2"/>
            </svg>
          </div>
          <!-- <div @click="loginWithGoogle('apple')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M17.3987 12.8335C17.3889 11.1532 18.1502 9.88677 19.6876 8.95297C18.8278 7.72105 17.5268 7.04349 15.812 6.91291C14.1883 6.78479 12.4119 7.85903 11.7614 7.85903C11.074 7.85903 9.50209 6.95726 8.26524 6.95726C5.7127 6.99668 3 8.9924 3 13.0528C3 14.2527 3.21928 15.492 3.65785 16.7683C4.24424 18.4486 6.35822 22.5657 8.56337 22.4992C9.71645 22.4721 10.532 21.6812 12.0325 21.6812C13.4886 21.6812 14.2425 22.4992 15.5287 22.4992C17.7535 22.4672 19.6655 18.7246 20.2223 17.0393C17.2386 15.6325 17.3987 12.9198 17.3987 12.8335ZM14.8092 5.3188C16.0584 3.83557 15.9451 2.48538 15.9081 2C14.8043 2.06406 13.528 2.75147 12.8012 3.59657C12.0004 4.50327 11.5298 5.62432 11.6309 6.88827C12.8234 6.97943 13.9124 6.36594 14.8092 5.3188Z"
                  fill="#292929"/>
            </svg>
          </div>
          <div @click="loginWithGoogle('facebook')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M22 12.0611C22 6.50455 17.5228 2 12 2C6.47719 2 2 6.50447 2 12.0611C2 17.0829 5.65687 21.2453 10.4375 22V14.9694H7.89844V12.0611H10.4375V9.84452C10.4375 7.32296 11.9305 5.93012 14.2146 5.93012C15.3088 5.93012 16.4531 6.12663 16.4531 6.12663V8.60261H15.1922C13.9498 8.60261 13.5625 9.37818 13.5625 10.1739V12.0611H16.3359L15.8926 14.9694H13.5625V22C18.3431 21.2453 22 17.0829 22 12.0611Z"
                  fill="#1877F2"/>
            </svg>
          </div> -->
        </div>

        <div class="sign__up">
          Don’t have an account?
          <div @click="tabIndex = 2">Sign up</div>
        </div>


      </form>
    </div>
    <div @click.stop v-else class="login_content">
      <Button class="exit-dialog" @click="hideDialog">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
        </svg>
      </Button>
      <form action="">
        <div class="title">Sign up</div>
        <div class="inputs__wrapper">
          <div class="input__wrapper">
            <div class="text">Email</div>
            <Input-email :placeholder="'Email'" :email="auth_data.email"
                         @update:email="auth_data.email = $event"></Input-email>
          </div>
          <div class="input__wrapper">
            <div class="text__wrapper">
              <div class="text">Password</div>
              <router-link class="link" :to="{name: 'CustomerEmailVerification'}">Forgot password?</router-link>
            </div>
            <Input-password :placeholder="'Password'" :password="auth_data.password"
                            @update:password="auth_data.password = $event"></Input-password>
            <div class="checkbox">
              <input id="remember_me" class="checkbox_input" type="checkbox" v-model="auth_data.remember_me"/>
              <label for="remember_me"></label>
              <div class="text">remember me</div>
            </div>
          </div>
        </div>
        <button class="btn_login" type="submit" @click="SubmitAuth">Sign up</button>
        <div class="social__login">
          <div @click="loginWithGoogle('google')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#FFC107"/>
              <path
                  d="M3.15234 7.3455L6.43784 9.755C7.32684 7.554 9.47984 6 11.9993 6C13.5288 6 14.9203 6.577 15.9798 7.5195L18.8083 4.691C17.0223 3.0265 14.6333 2 11.9993 2C8.15834 2 4.82734 4.1685 3.15234 7.3455Z"
                  fill="#FF3D00"/>
              <path
                  d="M12.0002 22.0003C14.5832 22.0003 16.9302 21.0118 18.7047 19.4043L15.6097 16.7853C14.5719 17.5745 13.3039 18.0014 12.0002 18.0003C9.39916 18.0003 7.19066 16.3418 6.35866 14.0273L3.09766 16.5398C4.75266 19.7783 8.11366 22.0003 12.0002 22.0003Z"
                  fill="#4CAF50"/>
              <path
                  d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#1976D2"/>
            </svg>
          </div>
          <!-- <div @click="loginWithGoogle('apple')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M17.3987 12.8335C17.3889 11.1532 18.1502 9.88677 19.6876 8.95297C18.8278 7.72105 17.5268 7.04349 15.812 6.91291C14.1883 6.78479 12.4119 7.85903 11.7614 7.85903C11.074 7.85903 9.50209 6.95726 8.26524 6.95726C5.7127 6.99668 3 8.9924 3 13.0528C3 14.2527 3.21928 15.492 3.65785 16.7683C4.24424 18.4486 6.35822 22.5657 8.56337 22.4992C9.71645 22.4721 10.532 21.6812 12.0325 21.6812C13.4886 21.6812 14.2425 22.4992 15.5287 22.4992C17.7535 22.4672 19.6655 18.7246 20.2223 17.0393C17.2386 15.6325 17.3987 12.9198 17.3987 12.8335ZM14.8092 5.3188C16.0584 3.83557 15.9451 2.48538 15.9081 2C14.8043 2.06406 13.528 2.75147 12.8012 3.59657C12.0004 4.50327 11.5298 5.62432 11.6309 6.88827C12.8234 6.97943 13.9124 6.36594 14.8092 5.3188Z"
                  fill="#292929"/>
            </svg>
          </div>
          <div @click="loginWithGoogle('facebook')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M22 12.0611C22 6.50455 17.5228 2 12 2C6.47719 2 2 6.50447 2 12.0611C2 17.0829 5.65687 21.2453 10.4375 22V14.9694H7.89844V12.0611H10.4375V9.84452C10.4375 7.32296 11.9305 5.93012 14.2146 5.93012C15.3088 5.93012 16.4531 6.12663 16.4531 6.12663V8.60261H15.1922C13.9498 8.60261 13.5625 9.37818 13.5625 10.1739V12.0611H16.3359L15.8926 14.9694H13.5625V22C18.3431 21.2453 22 17.0829 22 12.0611Z"
                  fill="#1877F2"/>
            </svg>
          </div> -->
        </div>

        <div class="sign__up">
          Already have an account?
          <div @click="tabIndex = 1">Log in</div>
        </div>


      </form>
    </div>
  </div>
  <StatusRequest ref="toast"></StatusRequest>
</template>

<script>
import InputPassword from "@/components/UI/InputPassword.vue";
import InputEmail from "@/components/UI/InputEmail.vue";
import StatusRequest from "@/components/UI/StatusRequest.vue";
import axios from "axios";
import store from "@/store";
export default {
  components: {StatusRequest, InputEmail, InputPassword},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    tab_index: Number,
  },
  data() {
    return {
      tabIndex: 1,
      auth_data: {
        email: null,
        password: null,
        remember_me: false,
      },
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:show', false)
    },
    loginWithGoogle(provider) {
      const domain = window.location.origin;
      window.location.href = domain+'/auth/'+provider+'/redirect';
    },
    SubmitAuth(e) {
      e.preventDefault();
      let current_arr = JSON.parse(JSON.stringify(this.auth_data));
      let fData = new FormData();

      fData.append("login", current_arr.email);
      fData.append("password", current_arr.password);
      axios.post("/customer/login/account/auth", fData).then(resp => {
        if (resp.data.result == true) {
          window.localStorage.setItem("auth_token", resp.data.token);
          axios.defaults.headers.common['Authorization'] = 'Bearer '+ window.localStorage.getItem("auth_token");
          const userData = resp.data.user;
          store.dispatch('setUser', userData);
          setTimeout(() => {
            this.$emit('update:show', false);
            // window.location.reload();
          }, 200);
        } else if (resp.data.code && resp.data.code == "error_data")
          this.showMessage(false, "Incorrect login details");
      }).catch(() => {
        this.showMessage(false, "There was an unknown error, try again later");
      });

    },

    showMessage(type, title) {
      if(type) type='success';
      else type="error";
      const toast = this.$refs.toast;
      toast.show(type,title,5000);
    }
  },
  watch: {
    tab_index: {
      handler(val, oldVal) {
          this.tabIndex = this.tab_index;
      },
      deep: true
    },
  },
}
</script>

<style scoped>
.bg_color{
  opacity: 0.32;
  background: #292929;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transition: opacity 1s cubic-bezier(.16,1,.3,1);
}
.dialog.bg-0 .bg_color{
  display: none;
}
.dialog__login__form{
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  position: fixed;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.dialog__login__form.active{
  visibility: visible;
  opacity: 1;
}
.social__login {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.social__login div {
  cursor: pointer;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #E5E5EA;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
}

.sign__up {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
}

.sign__up div {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  margin-left: 5px;
}

.checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
}

.login {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_content {
  width: 562px;
  max-width: 562px;
  z-index: 999;
  position: relative;
}

.login_content .page_title {
  color: var(--black-400, #292929);
  text-align: center;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.192px;
  padding-bottom: 40px;
}

.login_content form {
  padding: 16px;
  padding-top: 25px;
  width: 100%;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.inputs__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

.login_content .title {
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  padding-bottom: 16px;
}

.login_content .input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text__wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.text__wrapper .link {
  color: var(--black-400, #292929);
  text-align: right;
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  text-decoration-line: underline;
}

.input__wrapper .text {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
}

.checkbox .text {
  color: var(--gray-300, #888);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
}

.input__wrapper input {
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  height: 40px;
  padding-left: 12px;
}

.login_content .btn_login {
  cursor: pointer;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  height: 44px;
  width: 100%;
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

@media screen and (max-width: 768px) {
  .login_content {
    width: calc(100% - 32px);
  }

  .login_content .page_title {
    color: var(--black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.144px;
  }
}
</style>