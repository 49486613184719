<template>
    <div class="star-rating">
      <svg v-for="star in stars"
        :key="star"
        class="star"
        :class="{ filled: star <= currentRating || star <= hoverRating }"
        @mouseover="setHoverRating(star)"
        @mouseleave="resetHoverRating"
        @click="setRating(star)"
      xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M12.1586 3.73742C12.1886 3.67237 12.2367 3.61728 12.297 3.57867C12.3574 3.54005 12.4275 3.51953 12.4991 3.51953C12.5708 3.51953 12.6409 3.54005 12.7013 3.57867C12.7616 3.61728 12.8096 3.67237 12.8396 3.73742L15.1624 8.77292C15.1892 8.83113 15.2306 8.88148 15.2824 8.91916C15.3343 8.95685 15.395 8.9806 15.4586 8.98817L20.9644 9.64067C21.0356 9.64913 21.1028 9.67781 21.1582 9.72332C21.2136 9.76883 21.2547 9.82925 21.2768 9.89744C21.2989 9.96562 21.301 10.0387 21.2829 10.108C21.2647 10.1774 21.2271 10.2401 21.1744 10.2887L17.1041 14.0537C17.0572 14.0972 17.0223 14.1521 17.0026 14.213C16.9829 14.2739 16.9791 14.3389 16.9916 14.4017L18.0716 19.8399C18.0857 19.9102 18.0793 19.9831 18.0532 20.0499C18.0271 20.1167 17.9823 20.1746 17.9243 20.2168C17.8663 20.2589 17.7974 20.2836 17.7258 20.2878C17.6542 20.2919 17.5829 20.2755 17.5204 20.2404L12.6829 17.5322C12.6268 17.5006 12.5635 17.4841 12.4991 17.4841C12.4348 17.4841 12.3715 17.5006 12.3154 17.5322L7.47788 20.2397C7.4154 20.2747 7.34419 20.2911 7.27269 20.2869C7.20118 20.2827 7.13237 20.2582 7.07438 20.2161C7.0164 20.1741 6.97167 20.1163 6.94548 20.0496C6.9193 19.983 6.91276 19.9102 6.92663 19.8399L8.00663 14.4017C8.01928 14.3388 8.01558 14.2737 7.99588 14.2126C7.97618 14.1516 7.94115 14.0966 7.89413 14.0529L3.82313 10.2879C3.77068 10.2393 3.73324 10.1767 3.71522 10.1074C3.6972 10.0382 3.69935 9.96527 3.72143 9.89723C3.74351 9.82919 3.78458 9.76888 3.83981 9.72342C3.89504 9.67796 3.96212 9.64925 4.03313 9.64067L9.53963 8.98817C9.60342 8.98073 9.66424 8.95702 9.71624 8.91933C9.76825 8.88164 9.8097 8.83123 9.83663 8.77292L12.1586 3.73742Z" stroke="#292929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StarRating',
    props: {
      rating: {
        type: Number,
        required: true,
        default: 0
      },
      maxStars: {
        type: Number,
        required: true,
        default: 5
      }
    },
    data() {
      return {
        currentRating: this.rating,
        hoverRating: 0
      };
    },
    computed: {
      stars() {
        return Array.from({ length: this.maxStars }, (_, i) => i + 1);
      }
    },
    methods: {
      setRating(rating) {
        this.currentRating = rating;
        this.$emit('update:rating', rating);
      },
      setHoverRating(rating) {
        this.hoverRating = rating;
      },
      resetHoverRating() {
        this.hoverRating = 0;
      }
    },
    watch: {
      rating(newValue, oldValue) {
        this.currentRating = newValue;
      }
    },
  };
  </script>
  
  <style scoped>
  .star-rating {
    display: flex;
    gap: 12px;
    justify-content: center;
  }
  .star {
    font-size: 2rem;
    color: #d3d3d3;
    cursor: pointer;
    transition: color 0.3s;
  }
  .star.filled path {
    fill: #292929;
  }
  </style>
  