<template>
  <section class="post">
    <div class="post__container">
      <div class="post__content">
        <ViewImages class="mobile-none" v-if="loading" :all_photo="showAllPhoto" @all-photo="viewAllPhotos"
                  :images="post[0].images" :loading="loading" :company_id="post[0].company_id"
                  :id="post[0].id"></ViewImages>
        <!-- <ViewPhotosSlider class="mobile" v-if="loading" :id="post[0].id" :company_id="post[0].company_id" :images="post[0].images"></ViewPhotosSlider> -->
        <div class="post__content__info">
          <div class="info_left">
            <div class="info_block">
              <div :class="{ unactive: !loading }" class="left">
                <GeneralInformation v-if="loading" :estimated_duration="post[0].estimated_duration"
                                  :estimated_duration_type="post[0].estimated_duration_type"
                                  :meeting_point_type="post[0].meeting_point_type"
                                  :max_participants="post[0].max_participants"
                                  :languages_name="post[0].languages_name"></GeneralInformation>
              </div>
              <div class="right">
                <div class="confirmation_content">
                  <div class="input__wrapper">
                    <input type="radio" id="general_information_done" name="general_information" value="done"
                      class="inp_done" v-model="arr_confirmation[0].state" />
                    <label for="general_information_done" class="done">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_3461_71982)">
                          <path
                            d="M8.99844 16.1996L4.79844 11.9996L3.39844 13.3996L8.99844 18.9996L20.9984 6.99961L19.5984 5.59961L8.99844 16.1996Z"
                            fill="#888888" />
                        </g>
                        <defs>
                          <clipPath id="clip0_3461_71982">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </label>
                    <input type="radio" id="general_information_close" name="general_information" value="close"
                      class="inp_close" v-model="arr_confirmation[0].state" />
                    <label for="general_information_close" class="close">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.3532 6.70711C18.7437 6.31658 18.7437 5.68342 18.3532 5.29289C17.9627 4.90237 17.3295 4.90237 16.939 5.29289L11.8224 10.4095L6.70711 5.29426C6.31658 4.90373 5.68342 4.90373 5.2929 5.29426C4.90237 5.68478 4.90237 6.31795 5.29289 6.70847L10.4082 11.8237L5.29289 16.939C4.90237 17.3295 4.90237 17.9627 5.29289 18.3532C5.68342 18.7437 6.31658 18.7437 6.70711 18.3532L11.8224 13.2379L16.939 18.3546C17.3295 18.7451 17.9627 18.7451 18.3532 18.3546C18.7437 17.964 18.7437 17.3309 18.3532 16.9404L13.2366 11.8237L18.3532 6.70711Z"
                          fill="#888888" />
                      </svg>
                    </label>
                    <input type="radio" id="general_information_inactive" name="general_information" value="inactive"
                      checked v-model="arr_confirmation[0].state" />
                    <label for="general_information_inactive" class="inactive"></label>
                  </div>
                  <textarea v-if="arr_confirmation[0].state == 'close'" placeholder="Reporting non-compliance"
                    v-model="arr_confirmation[0].message" name="" id="" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading }" class="left">
                <BlockDescription v-if="loading" :title="'Overview'" :value="post[0].overview">
                </BlockDescription>
              </div>
              <div class="right">
                <div class="confirmation_content">
                  <div class="input__wrapper">
                    <input type="radio" id="overview_done" name="overview" value="done" class="inp_done"
                      v-model="arr_confirmation[1].state" />
                    <label for="overview_done" class="done">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_3461_71982)">
                          <path
                            d="M8.99844 16.1996L4.79844 11.9996L3.39844 13.3996L8.99844 18.9996L20.9984 6.99961L19.5984 5.59961L8.99844 16.1996Z"
                            fill="#888888" />
                        </g>
                        <defs>
                          <clipPath id="clip0_3461_71982">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </label>
                    <input type="radio" id="overview_close" name="overview" value="close" class="inp_close"
                      v-model="arr_confirmation[1].state" />
                    <label for="overview_close" class="close">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.3532 6.70711C18.7437 6.31658 18.7437 5.68342 18.3532 5.29289C17.9627 4.90237 17.3295 4.90237 16.939 5.29289L11.8224 10.4095L6.70711 5.29426C6.31658 4.90373 5.68342 4.90373 5.2929 5.29426C4.90237 5.68478 4.90237 6.31795 5.29289 6.70847L10.4082 11.8237L5.29289 16.939C4.90237 17.3295 4.90237 17.9627 5.29289 18.3532C5.68342 18.7437 6.31658 18.7437 6.70711 18.3532L11.8224 13.2379L16.939 18.3546C17.3295 18.7451 17.9627 18.7451 18.3532 18.3546C18.7437 17.964 18.7437 17.3309 18.3532 16.9404L13.2366 11.8237L18.3532 6.70711Z"
                          fill="#888888" />
                      </svg>
                    </label>
                    <input type="radio" id="overview_inactive" name="overview" value="inactive" checked
                      v-model="arr_confirmation[1].state" />
                    <label for="overview_inactive" class="inactive"></label>
                  </div>
                  <textarea v-if="arr_confirmation[1].state == 'close'" placeholder="Reporting non-compliance"
                    v-model="arr_confirmation[1].message" name="" id="" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading }" class="left">
                <div v-if="loading" class="block__description">
                  <div class="title">Payment method</div>
                  <div class="text">{{ post[0].payment_method }}</div>
                </div>
              </div>
              <div class="right">
                <div class="confirmation_content">
                  <div class="input__wrapper">
                    <input type="radio" id="payment_method_done" name="payment_method" value="done" class="inp_done"
                      v-model="arr_confirmation[2].state" />
                    <label for="payment_method_done" class="done">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_3461_71982)">
                          <path
                            d="M8.99844 16.1996L4.79844 11.9996L3.39844 13.3996L8.99844 18.9996L20.9984 6.99961L19.5984 5.59961L8.99844 16.1996Z"
                            fill="#888888" />
                        </g>
                        <defs>
                          <clipPath id="clip0_3461_71982">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </label>
                    <input type="radio" id="payment_method_close" name="payment_method" value="close" class="inp_close"
                      v-model="arr_confirmation[2].state" />
                    <label for="payment_method_close" class="close">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.3532 6.70711C18.7437 6.31658 18.7437 5.68342 18.3532 5.29289C17.9627 4.90237 17.3295 4.90237 16.939 5.29289L11.8224 10.4095L6.70711 5.29426C6.31658 4.90373 5.68342 4.90373 5.2929 5.29426C4.90237 5.68478 4.90237 6.31795 5.29289 6.70847L10.4082 11.8237L5.29289 16.939C4.90237 17.3295 4.90237 17.9627 5.29289 18.3532C5.68342 18.7437 6.31658 18.7437 6.70711 18.3532L11.8224 13.2379L16.939 18.3546C17.3295 18.7451 17.9627 18.7451 18.3532 18.3546C18.7437 17.964 18.7437 17.3309 18.3532 16.9404L13.2366 11.8237L18.3532 6.70711Z"
                          fill="#888888" />
                      </svg>
                    </label>
                    <input type="radio" id="payment_method_inactive" name="payment_method" value="inactive"
                      v-model="arr_confirmation[2].state" checked />
                    <label for="payment_method_inactive" class="inactive"></label>
                  </div>
                  <textarea v-if="arr_confirmation[2].state == 'close'" placeholder="Reporting non-compliance"
                    v-model="arr_confirmation[2].message" name="" id="" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading }" class="left">
                <BlockList v-if="loading" :title="'Additional Information'"
                :arrayList="post[0].additional_information"></BlockList>
              </div>
              <div class="right">
                <div class="confirmation_content">
                  <div class="input__wrapper">
                    <input type="radio" id="additional_information_done" name="additional_information" value="done"
                      class="inp_done" v-model="arr_confirmation[3].state" />
                    <label for="additional_information_done" class="done">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_3461_71982)">
                          <path
                            d="M8.99844 16.1996L4.79844 11.9996L3.39844 13.3996L8.99844 18.9996L20.9984 6.99961L19.5984 5.59961L8.99844 16.1996Z"
                            fill="#888888" />
                        </g>
                        <defs>
                          <clipPath id="clip0_3461_71982">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </label>
                    <input type="radio" id="additional_information_close" name="additional_information" value="close"
                      class="inp_close" v-model="arr_confirmation[3].state" />
                    <label for="additional_information_close" class="close">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.3532 6.70711C18.7437 6.31658 18.7437 5.68342 18.3532 5.29289C17.9627 4.90237 17.3295 4.90237 16.939 5.29289L11.8224 10.4095L6.70711 5.29426C6.31658 4.90373 5.68342 4.90373 5.2929 5.29426C4.90237 5.68478 4.90237 6.31795 5.29289 6.70847L10.4082 11.8237L5.29289 16.939C4.90237 17.3295 4.90237 17.9627 5.29289 18.3532C5.68342 18.7437 6.31658 18.7437 6.70711 18.3532L11.8224 13.2379L16.939 18.3546C17.3295 18.7451 17.9627 18.7451 18.3532 18.3546C18.7437 17.964 18.7437 17.3309 18.3532 16.9404L13.2366 11.8237L18.3532 6.70711Z"
                          fill="#888888" />
                      </svg>
                    </label>
                    <input type="radio" id="additional_information_inactive" name="additional_information"
                      value="inactive" v-model="arr_confirmation[3].state" checked />
                    <label for="additional_information_inactive" class="inactive"></label>
                  </div>
                  <textarea v-if="arr_confirmation[3].state == 'close'" placeholder="Reporting non-compliance"
                    v-model="arr_confirmation[3].message" name="" id="" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div :class="{ unactive: !loading }" class="left">
                <BlockDescription v-if="loading" :title="'Meeting point'" :value="post[0].meeting_point">
                </BlockDescription>
              </div>
              <div class="right">
                <div class="confirmation_content">
                  <div class="input__wrapper">
                    <input type="radio" id="meeting_point_done" name="meeting_point" value="done" class="inp_done"
                      v-model="arr_confirmation[4].state" />
                    <label for="meeting_point_done" class="done">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_3461_71982)">
                          <path
                            d="M8.99844 16.1996L4.79844 11.9996L3.39844 13.3996L8.99844 18.9996L20.9984 6.99961L19.5984 5.59961L8.99844 16.1996Z"
                            fill="#888888" />
                        </g>
                        <defs>
                          <clipPath id="clip0_3461_71982">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </label>
                    <input type="radio" id="meeting_point_close" name="meeting_point" value="close" class="inp_close"
                      v-model="arr_confirmation[4].state" />
                    <label for="meeting_point_close" class="close">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.3532 6.70711C18.7437 6.31658 18.7437 5.68342 18.3532 5.29289C17.9627 4.90237 17.3295 4.90237 16.939 5.29289L11.8224 10.4095L6.70711 5.29426C6.31658 4.90373 5.68342 4.90373 5.2929 5.29426C4.90237 5.68478 4.90237 6.31795 5.29289 6.70847L10.4082 11.8237L5.29289 16.939C4.90237 17.3295 4.90237 17.9627 5.29289 18.3532C5.68342 18.7437 6.31658 18.7437 6.70711 18.3532L11.8224 13.2379L16.939 18.3546C17.3295 18.7451 17.9627 18.7451 18.3532 18.3546C18.7437 17.964 18.7437 17.3309 18.3532 16.9404L13.2366 11.8237L18.3532 6.70711Z"
                          fill="#888888" />
                      </svg>
                    </label>
                    <input type="radio" id="meeting_point_inactive" name="meeting_point" value="inactive"
                      v-model="arr_confirmation[4].state" checked />
                    <label for="meeting_point_inactive" class="inactive"></label>
                  </div>
                  <textarea v-if="arr_confirmation[4].state == 'close'" placeholder="Reporting non-compliance"
                    v-model="arr_confirmation[4].message" name="" id="" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
            <div class="info_block">
              <div class="left" :class="{ unactive: !loading }">
                <BlockList v-if="loading" :title="'Cancelation policy'"
                :arrayList="post[0].cancelation_policy"></BlockList>
              </div>
              <div class="right">
                <div class="confirmation_content">
                  <div class="input__wrapper">
                    <input type="radio" id="cancelation_policy_done" name="cancelation_policy" value="done"
                      class="inp_done" v-model="arr_confirmation[5].state" />
                    <label for="cancelation_policy_done" class="done">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_3461_71982)">
                          <path
                            d="M8.99844 16.1996L4.79844 11.9996L3.39844 13.3996L8.99844 18.9996L20.9984 6.99961L19.5984 5.59961L8.99844 16.1996Z"
                            fill="#888888" />
                        </g>
                        <defs>
                          <clipPath id="clip0_3461_71982">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </label>
                    <input type="radio" id="cancelation_policy_close" name="cancelation_policy" value="close"
                      class="inp_close" v-model="arr_confirmation[5].state" />
                    <label for="cancelation_policy_close" class="close">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.3532 6.70711C18.7437 6.31658 18.7437 5.68342 18.3532 5.29289C17.9627 4.90237 17.3295 4.90237 16.939 5.29289L11.8224 10.4095L6.70711 5.29426C6.31658 4.90373 5.68342 4.90373 5.2929 5.29426C4.90237 5.68478 4.90237 6.31795 5.29289 6.70847L10.4082 11.8237L5.29289 16.939C4.90237 17.3295 4.90237 17.9627 5.29289 18.3532C5.68342 18.7437 6.31658 18.7437 6.70711 18.3532L11.8224 13.2379L16.939 18.3546C17.3295 18.7451 17.9627 18.7451 18.3532 18.3546C18.7437 17.964 18.7437 17.3309 18.3532 16.9404L13.2366 11.8237L18.3532 6.70711Z"
                          fill="#888888" />
                      </svg>
                    </label>
                    <input type="radio" id="cancelation_policy_inactive" name="cancelation_policy" value="inactive"
                      v-model="arr_confirmation[5].state" checked />
                    <label for="cancelation_policy_inactive" class="inactive"></label>
                  </div>
                  <textarea v-if="arr_confirmation[5].state == 'close'" placeholder="Reporting non-compliance"
                    v-model="arr_confirmation[5].message" name="" id="" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmation__block">
      <div class="btn">
        <Button class="white" @click="rejectEvent">Reject</Button>
        <Button class="green" @click="approveEvent">Approve</Button>
      </div>
    </div>
  </section>
</template>

<script>
import ViewPhotosSlider from "@/components/EventPage/ViewPhotosSlider.vue";
import ViewImages from "@/components/EventPage/ViewPhotos.vue";
import ViewAllPhotos from "@/components/EventPage/ViewAllPhotos.vue";
import GeneralInformation from "@/components/MainPages/EventPage/GeneralInformation.vue";
import BlockList from "@/components/MainPages/EventPage/BlockList.vue";
import BlockDescription from "@/components/MainPages/EventPage/BlockDescription.vue";
export default {
  name: 'approve-event-content',
  components: {
    ViewAllPhotos,ViewImages,ViewPhotosSlider,GeneralInformation,BlockList,BlockDescription
  },
  props: {
    post: {
      type: Array,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      check: null,
      height: 200,
      next: false,
      arr_confirmation: [
        {
          step: '0',
          state: ''
        },
        {
          step: '1',
          state: ''
        },
        {
          step: '1',
          state: ''
        },
        {
          step: '1',
          state: ''
        },
        {
          step: '1',
          state: ''
        },
        {
          step: '1',
          state: ''
        },
      ],
    }
  },
  methods: {
    viewAllPhotos(status){
      this.$emit('all-photos', status);
    },
    approveEvent() {
      this.check = this.arr_confirmation.every(function (elem) {
        if (elem.state == 'done') {
          return true;
        } else {
          return false;
        }
      });
      if (this.check) this.$emit('approve', this.arr_confirmation);
    },
    rejectEvent() {
      this.check = this.arr_confirmation.some(item => item.state === '');
      if (!this.check) {
        this.$emit('reject', this.arr_confirmation);
      }
    },
  }
}
</script>

<style scoped>
.unactive {
  min-height: 300px;
  width: 100%;
  border-radius: 16px;
  -webkit-animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
  animation: color-change-2x 0.5s ease-in-out infinite alternate backwards;
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }

  100% {
    background: #D3D3D3;
  }
}

@keyframes color-change-2x {
  0% {
    background: #E5E5EA;
  }

  100% {
    background: #D3D3D3;
  }
}

.post__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.post_gallery {
  display: flex;
  gap: 32px;
  position: relative;
}

.post_gallery .all__category {
  height: 36px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 8px 0;
  padding-left: 15px;
  padding-right: 23px;
  border-radius: 4px;
  background: #FFF;
}

.post_gallery .all__category .title {
  color: var(--black-400, #292929);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
}

.big_img {
  position: relative;
  width: 65.89%;
  border-radius: 16px;
}

.big_img .front_gr {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  background: linear-gradient(0deg, rgba(41, 41, 41, 0.20) 0%, rgba(41, 41, 41, 0.20) 100%), lightgray 50% / cover no-repeat;
}

.big_img img {

  width: 100%;
  height: 100%;
  max-height: 620px;
  border-radius: 16px;

  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.small_imgs {
  width: 34.10%;
  display: flex;
  flex-direction: column;
  gap: 34px;
  border-radius: 16px;
}

.small_imgs img {
  width: 100%;
  height: calc(50% - 17px);
  max-height: 293px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(41, 41, 41, 0.16) 0%, rgba(41, 41, 41, 0.16) 100%), lightgray 50% / cover no-repeat;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}


.post__content__info {
  display: flex;
  gap: 32px;
}

.info_left {
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: column;
}

.info_block {
  display: flex;
  gap: 32px;
}

.info_block .left {
  width: 65.89%;
  position: relative;
  padding: 24px;
  padding-bottom: 32px;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.info_block .right {
  width: 34.10%;
}

.block__values .title {
  color: #000;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 16px;
}

.block__values .block_value {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid #E5E5EA;
  ;
}

.block__values .block_value:last-of-type {
  padding-bottom: 0;
}

.block__values .text_value {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.108px;
}

.block__values .value {
  color: var(--black-400, #292929);
  text-align: right;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.108px;
  display: flex;
  align-items: center;
}

.info_block .block__description {}

.info_block .confirmation_content {
  /* position: absolute;
  top: 0;
  right: -124px; */
}

.confirmation_content .input__wrapper {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.info_block .confirmation_content input,
.info_block .confirmation_content .inactive {
  display: none;
}

.info_block .confirmation_content .inp_done:checked~.done svg path {
  fill: #39766A;
}

.info_block .confirmation_content .inp_close:checked~.close svg path {
  fill: #EA2313;
}

.info_block .confirmation_content label {
  display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);

  /* last */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}

.info_block .confirmation_content textarea {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
  height: 128px;
  padding: 16px;
  width: 100%;
  resize: none;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;

  /* 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.block__description .title {
  color: #292929;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 16px;
}

.block__description .text {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 23.8px */
  letter-spacing: 0.102px;
}

.block__description .read_more {
  padding-top: 24px;
  color: #000;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.108px;
  text-decoration-line: underline;
}

.post .confirmation__block {
  margin-top: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
}

.confirmation__block button {
  max-width: 239px;
  width: 100%;
}

.confirmation__block .btn {
  justify-content: center;
}
</style>