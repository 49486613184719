<template>
  <PreloaderTopDestination v-show="isLoading"></PreloaderTopDestination>
  <div v-show="!isLoading" class="popular__cities__wrapper">
    <div class="title">{{ title }}</div>
    <div class="content__wrapper">
      <a :href="createUrl(post.name)"  class="block" v-for="post in posts">
        <img :src="this.$store.state.base_directory + post.images[0]"  alt="">
        <div class="content">
          <div class="city__name">{{ post.name}}</div>
          <div class="city__articles">{{ post.event_count }} Activites</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import PreloaderTopDestination from "@/components/MainPages/HomePage/PreloaderTopDestination.vue";
export default {
  components: {
    PreloaderTopDestination,
  },
  props: {
    title: String,
    posts: Array,
    isLoading: Boolean
  },
  methods: {
    createUrl(city) {
      city = city.toLowerCase();
      const currentURL = window.location.href;
      const urlWithoutParams = currentURL.split('?')[0]; 
      return urlWithoutParams + '/' + city;
    },
  },
};
</script>

<style scoped>

.title{
  color: #292929;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.192px;
  padding-bottom: 20px;
}
.content__wrapper{
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
.content__wrapper .block{
  width: calc((100% /  5) - 25.6px);
  position: relative;
  cursor: pointer;
}
.content__wrapper .block img{
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1.24;
  border-radius: 16px;
  max-height: 309px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray 50% / cover no-repeat;
}
.content__wrapper .block .content{
  position: absolute;
  left: 16px;
  bottom: 16px;
}
.content__wrapper .block .content .city__name{
  color: #FFF;
  font-family: Standerd;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.168px;
  padding-bottom: 4px;
}
.content__wrapper .block .content .city__articles{
  color: #FFF;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.096px;
}
@media screen and (max-width: 768px) {

  .content__wrapper {
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: scroll; 
    gap: 20px;
  }

  .content__wrapper .block {
    width: 251px;
  }

  img {
    width: 251px;
    height: 309px;
  }
}
</style>