<template>
  <div class="info_block">
    <div :class="{ unactive: !loading }" class="left">
      <slot name="left"></slot>
    </div>
    <div class="right mobile-none" v-if="$slots.right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean
  },
}
</script>

<style scoped>
.unactive {
  min-height: 300px;
  width: 100%;
  border-radius: 16px;
  background: #EBEBEB;
}

.info_block {
  display: flex;
  gap: 32px;
}

.info_block .left {
  width: calc(65.89% - 16px);
  padding: 24px;
  padding-bottom: 32px;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}

.info_block .right {
  width: calc(34.10% - 16px);
}
@media screen and (max-width: 1024px) {
    .info_block .left{
      width: 100%;
  }
  .info_block .right.mobile-none {
    display: none;
  }
  .mobile{
  display: block;
}
}
</style>