<template>
    <div class="wrapper">
        <input multiple @event.prevent type="file" ref="fileInput" accept="image/png, image/jpeg"
            @change="handleFileChange($event)">
        <draggable class="items-image" @event.prevent @dragover.prevent @dragenter.prevent
            @drop.prevent="handleFileChange($event)" v-model="loadFiles" item-key="id">
            <div class="item" v-for="item, index in loadFiles" :key="index">
                <img class="images" v-if="item.url" :src="item.url" alt="">
                <button v-if="item.url" class="remove-button" @click="removeImage(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g filter="url(#filter0_b_6409_71795)">
    <rect width="24" height="24" rx="12" fill="#292929"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3532 6.70711C18.7437 6.31658 18.7437 5.68342 18.3532 5.29289C17.9627 4.90237 17.3295 4.90237 16.939 5.29289L11.8224 10.4095L6.70711 5.29426C6.31658 4.90373 5.68342 4.90373 5.2929 5.29426C4.90237 5.68478 4.90237 6.31795 5.29289 6.70847L10.4082 11.8237L5.29289 16.939C4.90237 17.3295 4.90237 17.9627 5.29289 18.3532C5.68342 18.7437 6.31658 18.7437 6.70711 18.3532L11.8224 13.2379L16.939 18.3546C17.3295 18.7451 17.9627 18.7451 18.3532 18.3546C18.7437 17.964 18.7437 17.3309 18.3532 16.9404L13.2366 11.8237L18.3532 6.70711Z" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_b_6409_71795" x="-40" y="-40" width="104" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feGaussianBlur in="BackgroundImageFix" stdDeviation="20"/>
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6409_71795"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6409_71795" result="shape"/>
    </filter>
  </defs>
</svg>
                </button>
                <div class="content" @click="this.$refs.fileInput.click();">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <g clip-path="url(#clip0_4462_54624)">
                            <path
                                d="M3.5 4V1H5.5V4H8.5V6H5.5V9H3.5V6H0.5V4H3.5ZM6.5 10V7H9.5V4H16.5L18.33 6H21.5C22.6 6 23.5 6.9 23.5 8V20C23.5 21.1 22.6 22 21.5 22H5.5C4.4 22 3.5 21.1 3.5 20V10H6.5ZM13.5 19C16.26 19 18.5 16.76 18.5 14C18.5 11.24 16.26 9 13.5 9C10.74 9 8.5 11.24 8.5 14C8.5 16.76 10.74 19 13.5 19ZM10.3 14C10.3 15.77 11.73 17.2 13.5 17.2C15.27 17.2 16.7 15.77 16.7 14C16.7 12.23 15.27 10.8 13.5 10.8C11.73 10.8 10.3 12.23 10.3 14Z"
                                fill="#292929" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4462_54624">
                                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div>Add Photo</div>
                </div>
            </div>
        </draggable>
    </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
export default {
    name: 'images-load',
    components: {
        draggable: VueDraggableNext,
    },
    data() {
        return {
            loadFiles: [
                { url: '' },
                { url: '' },
                { url: '' },
                { url: '' },
                { url: '' },
                { url: '' },
                { url: '' },
                { url: '' },
            ],
            files: [],
        }
    },
    methods: {
        handleFileChange(event) {
            if (this.loadFiles.length < 9) {
                let newFiles = [];
                if (event.target.files) { newFiles = Array.from(event.target.files); }
                else if (event.dataTransfer.files) { newFiles = Array.from(event.dataTransfer.files); }

                for (let i = 0; i < newFiles.length; i++) {
                    for (let k = 0; k < this.loadFiles.length; k++) {
                        if (this.loadFiles[k].url == '') {
                            this.loadFiles[k].file = newFiles[i];
                            this.files.push(newFiles[i]);
                            this.loadFiles[k].url = URL.createObjectURL(newFiles[i]);
                            break;
                        }
                    }
                }
                this.files = [];
                for (let k = 0; k < this.loadFiles.length; k++) {
                    if (this.loadFiles[k].url != '') {
                        this.files.push(this.loadFiles[k]);
                    }
                }
                this.$emit('files', this.files);
            }
            // this.uploadFile();
        },
        removeImage(index) {
            this.loadFiles[index].url = '';
            this.files = [];
            for (let k = 0; k < this.loadFiles.length; k++) {
                if (this.loadFiles[k].url != '') {
                    this.files.push(this.loadFiles[k]);
                }
            }
            this.$emit('files', this.files);
        },
        uploadFile() {
            this.loadFiles.forEach((fileObj, index) => {

                if (fileObj.progress === 0) {
                    const formData = new FormData();
                    formData.append('file', fileObj.file);

                    axios.post('/busines/register/upload/file', formData, {
                        onUploadProgress: progressEvent => {
                            const progress = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                            this.loadFiles[index] = { ...fileObj, progress };
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }).then(response => {
                        this.loadFiles[index] = { ...fileObj, path: response.data.path, progress: 100 };
                        this.uploadId.push(response.data.id);
                    }).catch(error => {
                    });
                }
            });
        },
    },
    watch: {
        loadFiles: {
            handler(newData) {
                this.files = [];
                for (let k = 0; k < this.loadFiles.length; k++) {
                    if (this.loadFiles[k].url != '') {
                        this.files.push(this.loadFiles[k]);
                    }
                }
                this.$emit('files', this.files);
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
.items-image {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

.wrapper input {
    display: none;
}

.items-image .item {
    position: relative;
    width: 179px;
    height: 150px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    overflow: hidden;
}

.item .images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    width: 100%;
    height: 100%;
}

.item .content div {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.102px;
}

.remove-button {
    position: absolute;
    top: 16px;
    right: 16px;
}

@media screen and (max-width: 768px) {
    .items-image .item {
        width: calc((100% /2) - 8px);
    }
}</style>