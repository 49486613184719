<template>
    <div class="wrapper">
        <div class="control__buttons">
            <div class="back" @click="this.$emit('exit');">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_5434_54779)">
                    <path d="M9.70696 12.707C9.31643 12.3165 9.31643 11.6833 9.70696 11.2928L13.2927 7.70701C13.9227 7.07704 14.9998 7.52321 14.9998 8.41411L14.9998 15.5857C14.9998 16.4766 13.9227 16.9228 13.2927 16.2928L9.70696 12.707Z" fill="#292929"/>
                </g>
                <defs>
                    <clipPath id="clip0_5434_54779">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
                </svg>
                Back
            </div>
            <div class="title">{{ arr.name }} ({{ arr.language_name}})</div>
            <div class="save" @click="saveData">Save</div>
        </div>
        <div class="content">
            <div class="block" v-for="(block,index) in content">
                <span @click="removeRow(index)" v-if="index > 0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15.4127 5.70711C15.8032 5.31658 15.8032 4.68342 15.4127 4.29289C15.0222 3.90237 14.389 3.90237 13.9985 4.29289L9.85246 8.43889L5.7076 4.29403C5.31707 3.90351 4.68391 3.90351 4.29338 4.29403C3.90286 4.68455 3.90286 5.31772 4.29338 5.70824L8.43825 9.85311L4.29338 13.998C3.90286 14.3885 3.90286 15.0217 4.29338 15.4122C4.68391 15.8027 5.31707 15.8027 5.70759 15.4122L9.85246 11.2673L13.9985 15.4133C14.389 15.8039 15.0222 15.8039 15.4127 15.4133C15.8032 15.0228 15.8032 14.3896 15.4127 13.9991L11.2667 9.85311L15.4127 5.70711Z"
                            fill="#606060" />
                    </svg>
                </span>
                <div class="banner__count">Baner {{ index+1 }}</div>
                <div class="content__wrapper">
                    <div class="input__wrapper">
                        <div class="title">Title</div>
                        <input v-model="block.title" placeholder="Event name" type="text">
                    </div>
                    <div class="input__wrapper">
                        <div class="title">Description</div>
                        <textarea v-model="block.description" name="" id="" cols="30" rows="10" placeholder="Description"></textarea>
                    </div>
                    <div class="input__wrapper">
                        
                        <div class="title">Add photo</div>
                        <div class="specification">Recomended size  </div>
                        <label class="btn" :for="index">
                            <img :src="this.$store.state.base_directory + block.image" v-if="block.image && block.file === null" alt="">
                            <img :src="block.image" v-else-if="block.image" alt="">
                            <div class="remove" v-if="block.image" @click="removeImage(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3532 6.70711C18.7437 6.31658 18.7437 5.68342 18.3532 5.29289C17.9627 4.90237 17.3295 4.90237 16.939 5.29289L11.8224 10.4095L6.70711 5.29426C6.31658 4.90373 5.68342 4.90373 5.2929 5.29426C4.90237 5.68478 4.90237 6.31795 5.29289 6.70847L10.4082 11.8237L5.29289 16.939C4.90237 17.3295 4.90237 17.9627 5.29289 18.3532C5.68342 18.7437 6.31658 18.7437 6.70711 18.3532L11.8224 13.2379L16.939 18.3546C17.3295 18.7451 17.9627 18.7451 18.3532 18.3546C18.7437 17.964 18.7437 17.3309 18.3532 16.9404L13.2366 11.8237L18.3532 6.70711Z" fill="#E5E5EA"/>
                                </svg>
                            </div>
                            <div class="add__photo">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <g clip-path="url(#clip0_5454_56438)">
                                        <path d="M3.5 4V1H5.5V4H8.5V6H5.5V9H3.5V6H0.5V4H3.5ZM6.5 10V7H9.5V4H16.5L18.33 6H21.5C22.6 6 23.5 6.9 23.5 8V20C23.5 21.1 22.6 22 21.5 22H5.5C4.4 22 3.5 21.1 3.5 20V10H6.5ZM13.5 19C16.26 19 18.5 16.76 18.5 14C18.5 11.24 16.26 9 13.5 9C10.74 9 8.5 11.24 8.5 14C8.5 16.76 10.74 19 13.5 19ZM10.3 14C10.3 15.77 11.73 17.2 13.5 17.2C15.27 17.2 16.7 15.77 16.7 14C16.7 12.23 15.27 10.8 13.5 10.8C11.73 10.8 10.3 12.23 10.3 14Z" fill="#292929"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5454_56438">
                                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Add Photo
                            </div>
                        </label>
                        <input type="file" :id="index" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/jpg"
                        @change="uploadImg($event,index)">
                    </div>
                </div>
            </div>
            <div class="add" @click="addRow">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5Z" fill="#292929"/>
                </svg>
            </div>
        </div>
    </div>
    <CropperImage v-if="cropperShow" @cancel="this.cropperShow = false" @save="save" :img="cropperFile"></CropperImage>
</template>

<script>
import axios from "axios";
import CropperImage from "@/components/Admin/ContentPage/CropperImage.vue";
import imageCompression from 'browser-image-compression';
    export default {
        components: {
            CropperImage,
        },
        props: {
            arr: Array,
            status: Number,
        },
        data() {
            return {
                content: this.arr.content ? [...this.arr.content] : [{title: '',description:'',image: '',file:''}],
                cropperFile: null,
                cropperShow: false,
                cropperIndex: null,
                files: [],
            }
        },
        methods: {
            removeRow(index) {
                if (this.content.length > 0) {
                    this.content.splice(index, 1)
                }
            },
            removeImage(index) {
            this.content[index].image = '';
            this.content[index].file = '';
            },
            addRow() {
                this.content.push({ title: "", description: "", image: "",file:""});
            },
            save(event){
                this.content[this.cropperIndex].image = event.url;
                this.content[this.cropperIndex].file = event.file;
                this.cropperShow = false;
                console.log(event);
            },
            uploadImg(e,index) {
                var file = e.target.files[0];
                var reader = new FileReader();
                reader.onload = (e) => {
                let data;
                if (typeof e.target.result === 'object') {
                    data = window.URL.createObjectURL(new Blob([e.target.result]));
                } else {
                    data = e.target.result;
                }

                this.cropperFile = data;
                this.cropperIndex = index;
                this.cropperShow = true;
                };
                reader.readAsArrayBuffer(file);
            },
            async saveData() {
                this.files = [];
                this.loadingСompleted = false;
                let fData = new FormData();

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920, // максимальная ширина или высота
                    useWebWorker: true,
                    fileType: 'image/webp', // устанавливаем тип файла на webp
                    initialQuality: 0.7
                };

                // Используем for...of для корректного использования await
                for (const fileObj of this.content) {
                    if (fileObj.file == null) {
                        this.files.push(0);
                        fData.append('file[]', fileObj.file);
                    } else {
                        this.files.push(1);

                        try {
                            // Конвертируем изображение в WebP формат
                            const compressedFile = await imageCompression(fileObj.file, options);
                            console.log('compressedFile instanceof Blob', compressedFile); // true
                            fData.append('file[]', compressedFile);
                        } catch (error) {
                            console.log('Ошибка при сжатии изображения:', error);
                        }
                    }
                }

                fData.append("index", JSON.stringify(this.files));
                fData.append("id", this.arr.id);
                fData.append("status", this.status);
                fData.append("content", JSON.stringify(this.content));

                axios.post("/admin/get/content/save-language-content", fData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(resp => {
                    if (resp.data['result'] == true) {
                        this.$emit('exit');
                    }
                }).catch(() => {}).finally(() => {

                });
            }
        },
    }
</script>

<style scoped>
.add__photo{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.102px;
}
.remove{
    position: absolute;
    top: 24px;
    right: 24px;
}
.input__wrapper label{
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    /* 2 */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    height: 300px;
}
.wrapper{
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 24px;
}
.wrapper .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
}
.wrapper .content .add{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.wrapper .content .block{
    position: relative;
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.wrapper .content .block span{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;
    right: 24px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;

    /* last */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.control__buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.control__buttons .back{
    height: 36px;
    padding: 0 8px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #E5E5EA;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);

    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;
}
.control__buttons .title{
    color: var(--Black-300, #333);
    text-align: center;
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.144px;
}
.control__buttons .save{
    padding: 0 16px;
    height: 36px;
    display: flex;
    align-items: center;
    color: var(--White-100, #FFF);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;

    border-radius: 8px;
    border: 1px solid #E5E5EA;
    background: var(--Black-300, #333);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
}
.content .content__wrapper{
    max-width: 682px;
}
.content .banner__count{
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.144px;
    padding-bottom: 36px;
}
.content .input__wrapper{
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
}
.input__wrapper label img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.content .input__wrapper .title{
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.144px;
}
.content .input__wrapper .specification{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.102px;
    padding-bottom: 24px;
}
.content .input__wrapper input[type="text"]{
    padding-left: 12px;
    height: 50px;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 40px;
}
.content .input__wrapper input[type="text"]::placeholder{
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}
.content .input__wrapper textarea{
    padding: 16px 0;
    padding-left: 12px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    height: 111px;
    resize: none;
    margin-bottom: 24px;

box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
</style>