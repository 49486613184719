<template>
  <PreloaderSideBar v-show="isLoading"></PreloaderSideBar>
  <div v-show="!isLoading" class="left__sideBar">
      <div class="button__arrow" @click="hideSideBar">
        <svg :class="{ hide: this.hide }" xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
          <path d="M0.707107 5.70711C0.316582 5.31658 0.316582 4.68342 0.707107 4.29289L4.29289 0.707106C4.92286 0.0771415 6 0.523308 6 1.41421L6 8.58579C6 9.47669 4.92286 9.92286 4.29289 9.29289L0.707107 5.70711Z" fill="#292929" fill-opacity="0.8"/>
        </svg>
      </div>
      <ul class="nav__side__bar" :class="{ hide: this.hide }">
        <li><router-link active-class="active" :to="{name: 'AdminDashboard'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_3096_1377)">
                <path d="M3 12C3 12.5523 3.44772 13 4 13H10C10.5523 13 11 12.5523 11 12V4C11 3.44772 10.5523 3 10 3H4C3.44772 3 3 3.44772 3 4V12ZM3 20C3 20.5523 3.44772 21 4 21H10C10.5523 21 11 20.5523 11 20V16C11 15.4477 10.5523 15 10 15H4C3.44772 15 3 15.4477 3 16V20ZM13 20C13 20.5523 13.4477 21 14 21H20C20.5523 21 21 20.5523 21 20V12C21 11.4477 20.5523 11 20 11H14C13.4477 11 13 11.4477 13 12V20ZM14 3C13.4477 3 13 3.44772 13 4V8C13 8.55228 13.4477 9 14 9H20C20.5523 9 21 8.55228 21 8V4C21 3.44772 20.5523 3 20 3H14Z" fill="#292929"/>
              </g>
              <defs>
                <clipPath id="clip0_3096_1377">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="text">Dashboard</div>
        </router-link></li>

        <li>
          <router-link active-class="active" :to="{name: 'AdminCompanies'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
              <path d="M8 13V12H1.01L1 16C1 17.11 1.89 18 3 18H17C18.11 18 19 17.11 19 16V12H12V13H8ZM18 4H13.99V2L11.99 0H7.99L5.99 2V4H2C0.9 4 0 4.9 0 6V9C0 10.11 0.89 11 2 11H8V9H12V11H18C19.1 11 20 10.1 20 9V6C20 4.9 19.1 4 18 4ZM12 4H8V2H12V4Z" fill="#292929"/>
            </svg>
          </div>
          <div class="text">Companies</div>
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{name: 'AdminEvents'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M7 18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V5C17 4.44772 16.5523 4 16 4H8C7.44772 4 7 4.44772 7 5V18ZM2 16C2 16.5523 2.44772 17 3 17H5C5.55228 17 6 16.5523 6 16V7C6 6.44772 5.55228 6 5 6H3C2.44772 6 2 6.44772 2 7V16ZM19 6C18.4477 6 18 6.44772 18 7V16C18 16.5523 18.4477 17 19 17H21C21.5523 17 22 16.5523 22 16V7C22 6.44772 21.5523 6 21 6H19Z" fill="#292929"/>
            </svg></div>
          <div class="text">Events</div>
         </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{name: 'AdminOrders'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z" fill="#292929"/>
            </svg></div>
          <div class="text">Orders</div>
          </router-link>
        </li>
        <li><router-link active-class="active" :to="{name: 'AdminFinance'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.9551 19.2713C9.4948 19.1561 8.31805 18.6879 7.42486 17.8667C6.53166 17.0456 6.05671 15.922 6 14.4958H8.82845C8.88516 15.1729 9.09783 15.7131 9.46645 16.1164C9.83507 16.5198 10.3313 16.7719 10.9551 16.8727V13.0048C10.0052 12.7167 9.21125 12.4142 8.57325 12.0972C7.93526 11.7659 7.41068 11.3265 6.99953 10.7791C6.58837 10.2317 6.3828 9.53301 6.3828 8.68307C6.3828 7.47299 6.80813 6.5078 7.65879 5.78752C8.50945 5.06723 9.60822 4.66387 10.9551 4.57743V3H12.3374V4.59904C13.6985 4.7431 14.7689 5.21128 15.5487 6.0036C16.3426 6.79592 16.7892 7.79712 16.8885 9.0072H14.1451C14.0884 8.37335 13.9112 7.88355 13.6134 7.53781C13.3157 7.19208 12.8904 6.96879 12.3374 6.86795V10.563C13.5284 10.9376 14.4641 11.3049 15.1446 11.6651C15.8393 12.0252 16.3639 12.4646 16.7183 12.9832C17.0728 13.4874 17.25 14.1285 17.25 14.9064C17.25 16.1597 16.7963 17.1753 15.8889 17.9532C14.9957 18.7311 13.8119 19.1705 12.3374 19.2713V21H10.9551V19.2713ZM9.14745 8.35894C9.14745 8.7623 9.28922 9.08643 9.57278 9.33133C9.87051 9.57623 10.3313 9.82113 10.9551 10.066V6.84634C10.388 6.91837 9.9414 7.08403 9.61531 7.34334C9.3034 7.60264 9.14745 7.94118 9.14745 8.35894ZM12.3374 16.9376C12.9612 16.88 13.4646 16.7071 13.8474 16.419C14.2302 16.1164 14.4215 15.7275 14.4215 15.2521C14.4215 14.8487 14.2514 14.5174 13.9112 14.2581C13.5851 13.9844 13.0605 13.7179 12.3374 13.4586V16.9376Z" fill="#292929"/>
            </svg></div>
          <div class="text">Finance</div>
        </router-link>
        </li>
        <li><router-link active-class="active" :to="{name: 'AdminAnalytics'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
              <path d="M5.5 18H0V6H5.5V18ZM12.75 0H7.25V18H12.75V0ZM20 8H14.5V18H20V8Z" fill="#292929"/>
            </svg></div>
          <div class="text">Analytics</div>
        </router-link></li>
        <li><router-link active-class="active" :to="{name: 'AdminMessage'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15 4V11H5.17L4 12.17V4H15ZM16 2H3C2.45 2 2 2.45 2 3V17L6 13H16C16.55 13 17 12.55 17 12V3C17 2.45 16.55 2 16 2ZM21 6H19V15H6V17C6 17.55 6.45 18 7 18H18L22 22V7C22 6.45 21.55 6 21 6Z" fill="#292929"/>
            </svg></div>
          <div class="text">Messages</div>
          <div class="count" v-if="this.$store.state.user.unread !== 0 && this.$store.state.user.unread">{{ this.$store.state.user.unread }}</div>
        </router-link></li>
        <li><router-link active-class="active" :to="{name: 'AdminContent'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M15 3H6.75C5.75544 3 4.80161 3.39509 4.09835 4.09835C3.39509 4.80161 3 5.75544 3 6.75V17.25C3 18.2446 3.39509 19.1984 4.09835 19.9016C4.80161 20.6049 5.75544 21 6.75 21H15V3ZM16.5 21H17.25C18.2446 21 19.1984 20.6049 19.9016 19.9016C20.6049 19.1984 21 18.2446 21 17.25V16.5H16.5V21ZM21 15V9H16.5V15H21ZM21 7.5V6.75C21 5.75544 20.6049 4.80161 19.9016 4.09835C19.1984 3.39509 18.2446 3 17.25 3H16.5V7.5H21ZM6 6.75C6 6.55109 6.07902 6.36032 6.21967 6.21967C6.36032 6.07902 6.55109 6 6.75 6H11.25C11.4489 6 11.6397 6.07902 11.7803 6.21967C11.921 6.36032 12 6.55109 12 6.75V11.25C12 11.4489 11.921 11.6397 11.7803 11.7803C11.6397 11.921 11.4489 12 11.25 12H6.75C6.55109 12 6.36032 11.921 6.21967 11.7803C6.07902 11.6397 6 11.4489 6 11.25V6.75ZM7.5 7.5V10.5H10.5V7.5H7.5ZM6.75 13.5H11.25C11.4489 13.5 11.6397 13.579 11.7803 13.7197C11.921 13.8603 12 14.0511 12 14.25C12 14.4489 11.921 14.6397 11.7803 14.7803C11.6397 14.921 11.4489 15 11.25 15H6.75C6.55109 15 6.36032 14.921 6.21967 14.7803C6.07902 14.6397 6 14.4489 6 14.25C6 14.0511 6.07902 13.8603 6.21967 13.7197C6.36032 13.579 6.55109 13.5 6.75 13.5ZM6.75 16.5H11.25C11.4489 16.5 11.6397 16.579 11.7803 16.7197C11.921 16.8603 12 17.0511 12 17.25C12 17.4489 11.921 17.6397 11.7803 17.7803C11.6397 17.921 11.4489 18 11.25 18H6.75C6.55109 18 6.36032 17.921 6.21967 17.7803C6.07902 17.6397 6 17.4489 6 17.25C6 17.0511 6.07902 16.8603 6.21967 16.7197C6.36032 16.579 6.55109 16.5 6.75 16.5Z" fill="#292929"/>
</svg>
          </div>
          <div class="text">Content</div>
        </router-link>
      </li>
        <li>
          <router-link active-class="active" :to="{name: 'AdminProfile'}">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="#292929"/>
            </svg></div>
          <div class="text">Profile</div>
          </router-link>
        </li>
        <button class="logout mobile" @click="logOut">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_3096_1434)">
                <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill="#292929"/>
              </g>
              <defs>
                <clipPath id="clip0_3096_1434">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="text">Logout</div>
        </button>
      </ul>
    <button class="logout" @click="logOut" :class="{ hide: this.hide }">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_3096_1434)">
            <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill="#292929"/>
          </g>
          <defs>
            <clipPath id="clip0_3096_1434">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="text">Logout</div>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import PreloaderSideBar from "@/components/Busines/PreloaderSideBar.vue";
export default {
  name: 'side-bar',
  components: {
    PreloaderSideBar,
  },
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      hide: this.$store.state.sideBarHide,
    }
  },
  methods: {
    logOut(){
      let fData = new FormData();

      axios.post("/login/account/logout",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.$router.push({name:"LoginView"});
          window.localStorage.removeItem("auth_token");
        } else {
          // this.showMessage(false,this.page_titles.error.fatal);
        }
      }).catch(()=>{
        // this.showMessage(false,this.page_titles.error.fatal);
      });
    },
    hideSideBar(){
      this.hide = !this.hide;
      store.commit('updateHide', this.hide);
    }
  },
}
</script>

<style scoped>
.left__sideBar .nav__side__bar a .count{
  position: absolute;
    right: -40px;
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    border-radius: 50px;
    background: var(--Red-100, #F72314);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav__side__bar.hide .text{
  display: none;
}
.logout.hide .text{
  display: none;
}
.button__arrow{
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #D1D1D6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button__arrow svg{
  transition: 0.3s;
}
.button__arrow .hide{
  rotate: 180deg;
}
.mobile{
  display: none;
}
.left__sideBar.hide{
  width: 160px;
}
.left__sideBar {
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 8px 0px, rgba(0, 0, 0, 0.04) 0px 0px 4px 0px;
  padding: 16px;
  padding-right: 100px;
  max-width: 232px;
  min-height: calc((100vh - 55px) - 120px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: 0.3s;
  width: max-content;
  height: max-content;
  height: calc(100vh - 145px);
  position: sticky;
  top: 120px;
}

.left__sideBar .nav__side__bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left__sideBar .nav__side__bar a {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  position: relative;
}

.left__sideBar .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.left__sideBar .active .icon {
  border-radius: 6px;
  background: rgb(239, 239, 244);
}

.left__sideBar .text {
  color: rgb(41, 41, 41);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.084px;
}

.left__sideBar .logout {
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;
}
.logout.mobile{
  display: none;
}

.logout .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.logout .text {
  color: rgb(41, 41, 41);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.084px;
}
@media screen and (max-width: 1024px) {
  .left__sideBar .nav__side__bar a .count{
    right: -10px;
  }
  .button__arrow{
    display: none;
  }
  .nav__side__bar.hide .text{
    display: unset;
  }
  .left__sideBar.hide{
    width: unset;
  }
  .left__sideBar{
    order: 2;
    width: 100%;
    max-width: 100%;
    height: max-content;
    position: fixed;
    left: 0;
    bottom: 0;
    border: 0px;
    border-top: 1.5px solid #E5E5EA;
    background: #FFF;
    height: 86px;
    flex-direction: row;
    z-index: 100;
    border-radius: 0;
    gap: 10px;
    justify-content: unset;
    overflow-x: overlay;
    min-height: unset;
    top: unset;
  }
  .left__sideBar .nav__side__bar a{
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
  }
  .left__sideBar .logout{
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 0;
    min-width: 68px;
    width: 68px;
  }
  .logout.mobile{
    display: flex;
  }
  .left__sideBar .text{
    color: #292929;
    text-align: center;
    font-family: Standerd;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.072px;
  }
  .left__sideBar .icon svg{
    max-width: 32px;
    max-height: 32px;
    width: 100%;
    height: 100%;
    fill: #888888;
  }
  .left__sideBar .icon svg path{
    fill: #888888;
  }
  .left__sideBar .active .icon svg path{
    fill: #292929;
  }
  .left__sideBar .icon{
    max-width: 32px;
    max-height: 32px;
    width: 100%;
    height: 100%;
  }
  .left__sideBar .active .icon{
    background: unset;
  }
  .left__sideBar{
    flex-direction: row;
    overflow-x: overlay;
  }
  .left__sideBar .nav__side__bar{
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .left__sideBar .nav__side__bar li {
    min-width: 68px;
  }
  .left__sideBar .logout.mobile{
    display: flex;
  }
  .left__sideBar .logout{
    display: none;
  }
}
</style>