<template>
  <div class="tabs__wrapper">
    <div v-for="status in tabs" :class="[status.title,{'active': current_tab2 == status.id}]"  @click="changeStatus(status.id)" class="tab">{{ status.title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    current_tab2: Number,
  },
  data() {
    return {
      tabs: [
        { "id": 1,"title": "Experience" },
        { "id": 2,"title": "Events" },
      ],
      current_tab_id: '',
    }
  },

  methods: {
    changeStatus(id) {
      this.$emit('change_type', id);
      this.updateURL(id);
    },
    updateURL(id) {
      const url = new URL(window.location.href);
      url.searchParams.delete("category");
      url.searchParams.set("type", id);
      window.history.pushState({}, "", url.toString());
    },
  },
  mounted() {

  },
}
</script>

<style scoped>
.tabs__wrapper{
  display: flex;
  padding: 4px;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: #FFF;

  /* 2 */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.tabs__wrapper .tab{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 23px;
  border-radius: 4px;
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
  min-width: 120px;
  cursor: pointer;
  max-width: 120px;
}
.tabs__wrapper .tab.active{
  background: var(--Black-400, #292929);
  color: var(--White-100, #FFF);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: -0.352px;
}
@media screen and (max-width: 1024px) {
  .tabs__wrapper{
    width: 100%;
    height: 48px;
    padding: 0 4px;
    align-items: center;
  }
  .tabs__wrapper .tab{
    width: 100%;
    border-radius: 10px;
    padding: 0;
    max-width: unset;
  }
}

</style>