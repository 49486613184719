<template>
  
  <div class="bread__crumb__container">
    <PreloaderBreadCrumbs v-show="isLoading"></PreloaderBreadCrumbs>
      <nav v-show="!isLoading" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
            <path d="M4.70711 13.7071C4.31658 13.3166 4.31658 12.6834 4.70711 12.2929L8.29289 8.70711C8.92286 8.07714 10 8.52331 10 9.41421L10 16.5858C10 17.4767 8.92286 17.9229 8.29289 17.2929L4.70711 13.7071Z" fill="#333333"/>
          </svg>
          <li class="breadcrumb-item">
            <router-link :to="{
                      path:'/', 
                      query: { 
                        currency: $route.query.currency,
                        lang: languages.code
                      } 
                      }">Home</router-link>
          </li>
          <template v-for="(crumb, index) in bread__crumb" :key="index" >
            <div class="circle"></div>
            <li class="breadcrumb-item">
              <router-link :to="{ 
                path: crumb.path, 
                query: { 
                  currency: currency.currency_code,
                  lang: languages.code
                } 
              }">{{ crumb.name }}</router-link>

            </li>
          </template>
        </ol>
      </nav>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import PreloaderBreadCrumbs from "@/components/MainPages/HomePage/PreloaderBreadCrumbs.vue";
export default {
  components: {
    PreloaderBreadCrumbs,
  },
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      bread__crumb: []
    }
  },
  methods: {
    getBreadCrumbs(){
      const currentPath = window.location.pathname;
      const paths = currentPath.split('/').filter(path => path.trim() !== '');
      let cumulativePath = '';
      this.bread__crumb = paths.map(path => {
        cumulativePath += `/${path}`;
        return {
          name: path,
          path: cumulativePath
        };
      });
    },
  },
  mounted () {
    this.getBreadCrumbs();
  },
  computed: {
    ...mapState(['currency','languages'])
  },
};
</script>

<style scoped>
.bread__crumb__container{
  padding-bottom: 17px;
}
nav ol li {
  list-style: none;
  opacity: 0.8;
}

nav ol {
  display: flex;
  align-items: center;
  gap: 15px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--Black-300, #333);
}
nav ol li a{
  color: var(--Black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.9%; /* 22.064px */
  letter-spacing: 0.4px;
  text-transform: capitalize;
  cursor: pointer;

}
nav ol li:last-child {
  pointer-events: none;
  opacity: 1;
}

</style>