<template>
  <div class="lists" v-if="loader || posts.length > 0">
      <slot></slot>
  </div>
  <div class="lists" v-else>
    <slot></slot>
    <div class="company-none">The list is empty</div>
  </div>
</template>
<script>
export default {
  props: {
    posts: {
      type: Array,
      required: true
    },
    loader: Boolean,
  },
}
</script>

<style>
.lists{
  transition: 0.3s;
}
.company-none{
  transition: 0.5s;
  color: var(--Black-300, #333);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
letter-spacing: 0.108px;
  padding-top: 14px;
}
.lists table{
  margin-bottom: 200px;
  position: relative;
}
.lists.active table{
  margin-bottom: 0px;
}
.lists table{
  width: 100%;
  border-spacing: 0px 8px;
  /* border-collapse: collapse; */
}
.list tr{
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  border-collapse: collapse;
  position: relative;
}
.list tr::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;

  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  width: 100%;
  height: 100%;
  z-index: -1;
}
.list th{
  text-align: start;
  height: 50px;
  color: rgba(35, 35, 35, 0.60);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}
.list th, .list td {
  padding: 7px 16px;
  height: 50px;
}
@media screen and (max-width: 1270px) {
  .list th, .list td {
    padding: 7px 8px;
  }
}
@media screen and (max-width: 1024px) {
  .list th, .list td {
    padding: 7px 8px;
    height: unset;
  }
}
@media screen and (max-width: 1024px) {
  .list table{
    display: flex;
    flex-direction: column;
  }
  .list thead{
    display: none;
  }
  .list th{
    display: none;
  }
}
</style>
