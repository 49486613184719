<template>
  <header-page></header-page>
  <section class="registration">
    <div class="registration-content">
      <div class="page-title">Join us as a supply partner</div>
      <div class="page-desc">Tell us about your company, become part of our community of travelers</div>
      <div class="form-wrapper">
        <div class="form-title" v-if="step === 0">Contact info</div>
        <div class="form-title" v-if="step === 1">General info</div>
        <div class="form-title" v-if="step === 2 || step === 3">Join us as a supply partner</div>
        <div class="registration-progress">
          <div class="tab" :class="{ 'active': step === 0 || step > 0 }" id="tab_1">
            <div class="tab-number">01</div>
            <div class="tab-title">Contact info</div>
          </div>
          <div class="tab" :class="{ 'active': step === 1 || step > 1 }" id="tab_2">
            <div class="tab-number">02</div>
            <div class="tab-title">General info</div>
          </div>
          <div class="tab" :class="{ 'active': step === 2 || step > 2 }" id="tab_3">
            <div class="tab-number">03</div>
            <div class="tab-title">Company details</div>
          </div>
        </div>
        <form action="">
          <div class="registration-content-wrapper" :class="{show:step === 0}">
            <div class="input-flex">
              <div class="input-wrapper">
                <div class="input-title">Name</div>
                <input class="input-text" :class="{ error: v$.valueField.name.$invalid && submitted }"
                  v-model="v$.valueField.name.$model" type="text" placeholder="Name">
                <span v-if="v$.valueField.name.$invalid && submitted">{{ errorField['name'] }}</span>
              </div>
              <div class="input-wrapper">
                <div class="input-title">Last name</div>
                <input class="input-text" :class="{ error: v$.valueField.lastName.$invalid && submitted }"
                  v-model="v$.valueField.lastName.$model" type="text" placeholder="Last name">
                <span v-if="v$.valueField.lastName.$invalid && submitted">{{ errorField['lastName'] }}</span>
              </div>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Legal Company Name / Individual Trader Name</div>
              <input class="input-text" :class="{ error: v$.valueField.companyName.$invalid && submitted }"
                v-model="v$.valueField.companyName.$model" type="text" placeholder="Company name">
              <span v-if="v$.valueField.companyName.$invalid && submitted">{{ errorField['companyName'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Mobile phone number</div>
              <VueCountryIntl :class="{ error: v$.valueField.mobilePhone.$invalid && submitted }"
                v-model="v$.valueField.mobilePhone.$model" :countries="countries"></VueCountryIntl>
              <span v-if="v$.valueField.mobilePhone.$invalid && submitted">{{ errorField['mobilePhone'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Email</div>
              <input class="input-email" type="email" :class="{ error: v$.valueField.email.$invalid && submitted }"
                v-model="v$.valueField.email.$model" placeholder="Email">
              <span v-if="v$.valueField.email.$invalid && submitted">{{ errorField['mobilePhone'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Password</div>
              <Input-password class="input-text" :password="v$.valueField.password.$model"
                :class="{ error: v$.valueField.password.$invalid && submitted }"
                @update:password="v$.valueField.password.$model = $event" :placeholder="'Password'">
              </Input-password>
              <span v-if="v$.valueField.password.$invalid && submitted">{{ errorField['mobilePhone'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Confirm Password</div>
              <Input-password class="input-text" :password="v$.valueField.confirmPassword.$model"
                :class="{ error: v$.valueField.confirmPassword.$invalid && submitted }"
                @update:password="v$.valueField.confirmPassword.$model = $event"
                :placeholder="'Confirm Password'"></Input-password>
              <span v-if="v$.valueField.confirmPassword.$invalid && submitted">{{ errorField['mobilePhone'] }}</span>
            </div>
            <div class="user-agreement">By registering you agree to the Amuse <a href="">Terms of Use</a></div>
          </div>
          <div class="registration-content-wrapper" :class="{show:step === 1}">
            <div class="input-wrapper">
              <div class="input-title">What type of activities do you offer?</div>
              <div class="category-wrapper">
                <div class="custom-control" v-for="event in events" :key="event.id">
                  <input type="checkbox" :id="event.id" class="custom-control-input"
                    v-model="v$.valueField.selectedEvents.$model" :value="event.id" />
                  <label class="custom-control-label" :for="event.id">{{ event.name }}</label>
                </div>
              </div>
              <span v-if="v$.valueField.selectedEvents.$invalid && submitted">{{ errorField['selectedEvents'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Where do your activities take place?</div>
              <div class="activity-wrapper">
                <label :class="{ error: v$.valueField.activities.$invalid && submitted }" class="activities-take h-40"
                  for="activities-take">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path
                      d="M16 14H15.21L14.93 13.73C15.91 12.59 16.5 11.11 16.5 9.5C16.5 5.91 13.59 3 10 3C6.41 3 3.5 5.91 3.5 9.5C3.5 13.09 6.41 16 10 16C11.61 16 13.09 15.41 14.23 14.43L14.5 14.71V15.5L19.5 20.49L20.99 19L16 14ZM10 14C7.51 14 5.5 11.99 5.5 9.5C5.5 7.01 7.51 5 10 5C12.49 5 14.5 7.01 14.5 9.5C14.5 11.99 12.49 14 10 14Z"
                      fill="#292929" />
                  </svg>
                  <input class="input-style-none" @focus="showDropDown = true" @blur="handleBlur"
                    @input="getSearchActivities" v-model="selectedActiviti" type="text" id="activities-take"
                    placeholder="Where do your activities take place?">

                </label>
                <div class="drop-down" v-if="showDropDown">
                  <div v-for="activity in activitiesData" :key="activity.id"
                    @click="v$.valueField.activities.$model = activity.id; showDropDown = false; selectedActiviti = activity.name;">
                    {{ activity.name }}, {{ activity.country.name }}</div>
                </div>
              </div>
              <span v-if="v$.valueField.activities.$invalid && submitted">{{ errorField['activities'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Add your website</div>
              <input class="input-text h-40" :class="{ error: v$.valueField.webSiteUrl.$invalid && submitted }"
                v-model="v$.valueField.webSiteUrl.$model" type="text" placeholder="company.com">
              <span v-if="v$.valueField.webSiteUrl.$invalid && submitted">{{ errorField['webSiteUrl'] }}</span>

            </div>
          </div>
          <div class="registration-content-wrapper" :class="{show:step === 2}">
            <div class="input-wrapper">
              <div class="input-title">Country of registration</div>
              <Select class="h-40 p-reletive" :class="{ error: v$.valueField.countryRegion.$invalid && submitted }"
                v-model="v$.valueField.countryRegion.$model" :placeholder_title="'Country'" :data_name="'name'"
                :sort="countryData" @search="getSearchCountry"></Select>
              <span v-if="v$.valueField.countryRegion.$invalid && submitted">{{ errorField['countryRegion'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Industry</div>
              <Select class="h-40 p-reletive" :class="{ error: v$.valueField.industry.$invalid && submitted }"
                v-model="v$.valueField.industry.$model" :placeholder_title="'Travel agency'" :data_name="'name'"
                :sort="industryData" @search="getSearchIndustry"></Select>
              <span v-if="v$.valueField.industry.$invalid && submitted">{{ errorField['industry'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Company registration number</div>
              <input class="input-email" :class="{ error: v$.valueField.companyRegistrationNumber.$invalid && submitted }"
                v-model="v$.valueField.companyRegistrationNumber.$model" type="text"
                placeholder="Company registration number">
              <span v-if="v$.valueField.companyRegistrationNumber.$invalid && submitted">{{
                errorField['companyRegistrationNumber'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Legal address</div>
              <input class="input-email" :class="{ error: v$.valueField.legalAddress.$invalid && submitted }"
                v-model="v$.valueField.legalAddress.$model" type="text" placeholder="Address">
              <span v-if="v$.valueField.legalAddress.$invalid && submitted">{{ errorField['legalAddress'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Postcode</div>
              <input class="input-email" :class="{ error: v$.valueField.postCode.$invalid && submitted }"
                v-model="v$.valueField.postCode.$model" type="text" placeholder="Postcode">
              <span v-if="v$.valueField.postCode.$invalid && submitted">{{ errorField['postCode'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Employee name</div>
              <input class="input-email" :class="{ error: v$.valueField.employeeName.$invalid && submitted }"
                v-model="v$.valueField.employeeName.$model" type="text" placeholder="Employee name">
              <span v-if="v$.valueField.employeeName.$invalid && submitted">{{ errorField['employeeName'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Job title</div>
              <Select class="h-40 p-reletive" :class="{ error: v$.valueField.jobTitle.$invalid && submitted }"
                v-model="v$.valueField.jobTitle.$model" :placeholder_title="'CEO/Owner'" :data_name="'name'"
                :sort="jobsData" @search="getSearchJobs"></Select>
              <span v-if="v$.valueField.jobTitle.$invalid && submitted">{{ errorField['jobTitle'] }}</span>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Mobile phone number</div>
              <VueCountryIntl :class="{ error: v$.valueField.companyPhoneNumber.$invalid && submitted }"
                v-model="v$.valueField.companyPhoneNumber.$model" :countries="countries"></VueCountryIntl>
              <span v-if="v$.valueField.companyPhoneNumber.$invalid && submitted">{{ errorField['companyPhoneNumber']}}</span>
            </div>
          </div>
          <div class="registration-content-wrapper" :class="{show:step === 3}">
            <div class="input-wrapper">
              <div class="input-title">Please upload the company registration certificate and 1st Passport page</div>
              <div class="files-load" @event.prevent @dragover.prevent @dragenter.prevent
                @drop.prevent="handleFileChange($event)">
                <div class="drop-field">
                  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
                    <path d="M32.5 32L24.5 24L16.5 32" stroke="#888888" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M24.5 24V42" stroke="#888888" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M41.2799 36.7809C43.2306 35.7175 44.7716 34.0347 45.6597 31.9982C46.5477 29.9617 46.7323 27.6874 46.1843 25.5343C45.6363 23.3812 44.3869 21.472 42.6333 20.1078C40.8796 18.7437 38.7216 18.0024 36.4999 18.0009H33.9799C33.3745 15.6594 32.2462 13.4856 30.6798 11.6429C29.1134 9.80025 27.1496 8.33665 24.9361 7.36216C22.7226 6.38767 20.317 5.92766 17.9002 6.01671C15.4833 6.10576 13.1181 6.74154 10.9823 7.87628C8.84649 9.01101 6.99574 10.6152 5.56916 12.5681C4.14259 14.5211 3.1773 16.772 2.74588 19.1517C2.31446 21.5315 2.42813 23.978 3.07835 26.3075C3.72856 28.6369 4.8984 30.7887 6.49992 32.6009"
                      stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M32.5 32L24.5 24L16.5 32" stroke="#888888" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <div class="info">
                    <div class="title">Select a file or drag and drop here</div>
                    <div class="desc">JPG, PNG or PDF, file size no more than 10MB</div>
                  </div>
                </div>
                <input multiple @event.prevent type="file" ref="fileInput" @change="handleFileChange($event)"
                  style="display: none" />
                <div class="open-file-input" @click="this.$refs.fileInput.click();">Select file</div>
              </div>
              <span v-if="v$.valueField.loadFiles.$invalid && submitted">{{ errorField['loadFiles'] }}</span>
            </div>
            <div class="wrapper" v-if="valueField.loadFiles.length > 0">
              <div class="load-wrapper" v-for="(file, index) in valueField.loadFiles" :key="index">
                <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
                    <path
                      d="M7.25391 10.5C7.54938 10.5 7.84196 10.4418 8.11494 10.3287C8.38793 10.2157 8.63597 10.0499 8.8449 9.84099C9.05383 9.63206 9.21956 9.38402 9.33264 9.11104C9.44571 8.83806 9.50391 8.54547 9.50391 8.25C9.50391 7.95453 9.44571 7.66194 9.33264 7.38896C9.21956 7.11598 9.05383 6.86794 8.8449 6.65901C8.63597 6.45008 8.38793 6.28434 8.11494 6.17127C7.84196 6.0582 7.54938 6 7.25391 6C6.65717 6 6.08487 6.23705 5.66292 6.65901C5.24096 7.08097 5.00391 7.65326 5.00391 8.25C5.00391 8.84674 5.24096 9.41903 5.66292 9.84099C6.08487 10.2629 6.65717 10.5 7.25391 10.5Z"
                      fill="#888888" />
                    <path
                      d="M18.5 21C18.5 21.7956 18.1839 22.5587 17.6213 23.1213C17.0587 23.6839 16.2956 24 15.5 24H3.5C2.70435 24 1.94129 23.6839 1.37868 23.1213C0.81607 22.5587 0.5 21.7956 0.5 21V3C0.5 2.20435 0.81607 1.44129 1.37868 0.87868C1.94129 0.316071 2.70435 0 3.5 0L11.75 0L18.5 6.75V21ZM3.5 1.5C3.10218 1.5 2.72064 1.65804 2.43934 1.93934C2.15804 2.22064 2 2.60218 2 3V18L5.336 14.664C5.45422 14.5461 5.60843 14.4709 5.77417 14.4506C5.93992 14.4302 6.10773 14.4657 6.251 14.5515L9.5 16.5L12.7355 11.97C12.7988 11.8815 12.8806 11.8078 12.9753 11.754C13.0699 11.7003 13.1751 11.6678 13.2836 11.6588C13.3921 11.6498 13.5012 11.6645 13.6034 11.702C13.7056 11.7394 13.7985 11.7986 13.8755 11.8755L17 15V6.75H14C13.4033 6.75 12.831 6.51295 12.409 6.09099C11.9871 5.66903 11.75 5.09674 11.75 4.5V1.5H3.5Z"
                      fill="#888888" />
                  </svg>
                </div>
                <div class="load-files">
                  <div class="wrapper">
                    <div class="left">
                      {{ file.file.name }}
                      <svg xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
                        <circle cx="1.5" cy="2" r="1.5" fill="#767676" />
                      </svg>
                      <a v-if="file.progress === 100" :href='this.$store.state.base_directory + file.path'
                        target="_blank">Preview</a>
                    </div>
                    <div class="right">
                      {{ formatSize(file.file.size) }}
                      <svg v-if="file.progress === 100" @click="removeFile(index)" xmlns="http://www.w3.org/2000/svg"
                        width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M15.9124 5.70711C16.303 5.31658 16.303 4.68342 15.9124 4.29289C15.5219 3.90237 14.8887 3.90237 14.4982 4.29289L10.3522 8.43889L6.20735 4.29403C5.81683 3.90351 5.18366 3.90351 4.79314 4.29403C4.40262 4.68455 4.40262 5.31772 4.79314 5.70824L8.938 9.85311L4.79314 13.998C4.40261 14.3885 4.40261 15.0217 4.79314 15.4122C5.18366 15.8027 5.81683 15.8027 6.20735 15.4122L10.3522 11.2673L14.4982 15.4133C14.8887 15.8039 15.5219 15.8039 15.9124 15.4133C16.303 15.0228 16.303 14.3896 15.9124 13.9991L11.7664 9.85311L15.9124 5.70711Z"
                          fill="#292929" />
                      </svg>

                    </div>
                  </div>
                  <progress v-if="file.progress != 100" class="progress" :value="file.progress" max="100"></progress>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="steps">
          <div class="step gray" v-if="step != 0" @click="previousStep">{{ stepButtonName[step] }}</div>
          <div class="step white" @click="nextStep">{{ nextButtonName[step] }}</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import InputPassword from "@/components/UI/InputPassword.vue";
import VueCountryIntl from "@/components/UI/Busines/VueCountryIntl.vue";
import axios from "axios";
import userData from '@/assets/json/BusinesRegistrationError.json';
import countryCodeData from '@/assets/json/CountryPhoneMask.json';
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, helpers, numeric, url } from '@vuelidate/validators'
export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    InputPassword,
    VueCountryIntl
  },
  data() {
    return {
      events: [],
      selectedCountry: '',
      selectedActiviti: '',
      showDropDown: false,
      countries: countryCodeData,
      uploadId: [],
      countryData: [],
      industryData: [],
      jobsData: [],
      activitiesData: [],
      errorField: {},
      valueField: {
        selectedEvents: [],
        loadFiles: [],
      },
      step: 0,
      stepButtonName: [
        '0',
        'Back to Step 1',
        'Back to Step 2',
        'Back to Step 3',
      ],
      nextButtonName: [
        'Next',
        'Next',
        'Upload documents',
        'Finish',
      ],
      isDropdownOpen: false,
      submitted: false,
    };
  },
  validations() {
    return {
      valueField: {
        name: { required },
        lastName: { required },
        companyName: { required },
        mobilePhone: { required },
        email: { required, email },
        password: { required, minLength: minLength(6) },
        confirmPassword: { required, sameAsPassword: this.sameAsPassword },
        activities: { required },
        webSiteUrl: { required, url },
        countryRegion: { required },
        industry: { required },
        companyRegistrationNumber: { required },
        legalAddress: { required },
        postCode: { required },
        employeeName: { required },
        jobTitle: { required },
        companyPhoneNumber: { required },
        selectedEvents: { required },
        loadFiles: { required },
      }
    }
  },
  methods: {
    handleBlur() {
      // Introduce a small delay before hiding the drop-down
      setTimeout(() => {
        if (!this.$el.contains(document.activeElement)) {
          this.showDropDown = false;
        }
      }, 100);
    },
    sameAsPassword() {
      return this.valueField.confirmPassword === this.valueField.password;
    },
    validateFields() {
      switch (this.step) {
        case 0:
          return !this.v$.valueField.name.$invalid
            && !this.v$.valueField.lastName.$invalid
            && !this.v$.valueField.companyName.$invalid
            && !this.v$.valueField.mobilePhone.$invalid
            && !this.v$.valueField.email.$invalid
            && !this.v$.valueField.password.$invalid
            && !this.v$.valueField.confirmPassword.$invalid;
        case 1:
          return !this.v$.valueField.selectedEvents.$invalid
            && !this.v$.valueField.activities.$invalid
            && !this.v$.valueField.webSiteUrl.$invalid;
        case 2:
          return !this.v$.valueField.countryRegion.$invalid
            && !this.v$.valueField.industry.$invalid
            && !this.v$.valueField.companyRegistrationNumber.$invalid
            && !this.v$.valueField.legalAddress.$invalid
            && !this.v$.valueField.postCode.$invalid
            && !this.v$.valueField.employeeName.$invalid
            && !this.v$.valueField.jobTitle.$invalid
            && !this.v$.valueField.companyPhoneNumber.$invalid;
        case 3:
          return !this.v$.valueField.loadFiles.$invalid
        default:
          return false;
      }
    },
    nextStep() {
      if (this.validateFields()) {
        this.submitted = false;
        this.step < 3 ? (this.step += 1) : this.submitForm();
      } else {
        this.submitted = true;
        console.log("Validation error");
      }
    },
    previousStep() {
      this.step = Math.max(0, this.step - 1);
    },
    formatSize(size) {
      // Функция для форматирования размера файла
      const kb = 1024;
      const mb = kb * 1024;
      const gb = mb * 1024;

      if (size < kb) {
        return size + ' B';
      } else if (size < mb) {
        return (size / kb).toFixed(2) + ' KB';
      } else if (size < gb) {
        return (size / mb).toFixed(2) + ' MB';
      } else {
        return (size / gb).toFixed(2) + ' GB';
      }
    },
    handleFileChange(event) {
      let newFiles = [];
      if (event.target.files) { newFiles = Array.from(event.target.files); }
      else if (event.dataTransfer.files) { newFiles = Array.from(event.dataTransfer.files); }
      this.valueField.loadFiles = this.valueField.loadFiles.concat(newFiles.map(file => ({
        file,
        progress: 0,
        path: '',
      })));

      this.uploadFile();
    },
    uploadFile() {
      this.valueField.loadFiles.forEach((fileObj, index) => {

        if (fileObj.progress === 0) {
          const formData = new FormData();
          formData.append('file', fileObj.file);

          axios.post('/busines/register/upload/file', formData, {
            onUploadProgress: progressEvent => {
              const progress = Math.round(progressEvent.loaded * 100 / progressEvent.total);
              this.valueField.loadFiles[index] = { ...fileObj, progress };
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }).then(response => {
            this.valueField.loadFiles[index] = { ...fileObj, path: response.data.path, progress: 100 };
            this.uploadId.push(response.data.id);
          }).catch(error => {
          });
        }
      });
    },
    removeFile(index) {
      let fData = new FormData();
      fData.append("path", this.valueField.loadFiles[index].path);
      axios.post("/busines/register/remove/file", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.jobsData = resp.data.jobs;
        }
      }).catch(() => { }).finally(() => {
        this.valueField.loadFiles.splice(index, 1);
      });
    },
    submitForm() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.valueField.$invalid) {
        alert('Form Invalid');
      } else {
        let fData = new FormData();
        for (let [key, value] of Object.entries(this.valueField)) {
          fData.append(key, value);
        }
        fData.append('uploadId', JSON.stringify(this.uploadId));
        axios.post("/busines/register/form", fData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(resp => {
          }).catch(() => { }).finally(() => {
          });
        alert('Form Valid');
      }
    },
    loadErrors(language) {
      this.errorField = userData[language].error;
    },
    getSearchCountry(e) {
      let fData = new FormData();
      fData.append("name", e);
      axios.post("/busines/register/get-country", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.countryData = resp.data.country;
        }
      }).catch(() => { }).finally(() => { });
    },
    getSearchIndustry(e) {
      let fData = new FormData();
      fData.append("name", e);
      axios.post("/busines/register/get-industry", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.industryData = resp.data.industry;
        }
      }).catch(() => { }).finally(() => { });
    },
    getSearchJobs(e) {
      let fData = new FormData();
      fData.append("name", e);
      axios.post("/busines/register/get-jobs", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.jobsData = resp.data.jobs;
        }
      }).catch(() => { }).finally(() => { });
    },
    getSearchActivities(e) {
      console.log(e);
      let fData = new FormData();
      let name = e;
      if (name) fData.append("name", e.target.value);
      else fData.append("name", e);
      axios.post("/busines/register/get-activities", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.activitiesData = resp.data.cities;
        }
      }).catch(() => { }).finally(() => { });
    },
    getInit() {
      axios.get("/busines/register/get-init").then(resp => {
        if (resp.data['result'] == true) {
          this.events = resp.data.category;
        }
      }).catch(() => { }).finally(() => { });
    }
  },
  mounted() {
    // Загружаем JSON-данные после монтирования компонента
    this.loadErrors('EN');
    this.getSearchCountry('');
    this.getSearchIndustry('');
    this.getSearchJobs('');
    this.getSearchActivities('');
    this.getInit();
  },
}
</script>
<style>
.registration {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.registration-content {
  width: 100%;
  max-width: 587px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registration-content .page-title {
  color: var(--Black-400, #292929);
  text-align: center;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.192px;
  margin-bottom: 16px;
}

.registration-content .page-desc {
  color: var(--Black-300, #333);
  text-align: center;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
  margin-bottom: 40px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);
  padding: 16px;

  max-width: 562px;
  width: 100%;
}

.form-wrapper .form-title {
  color: var(--Black-300, #333);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 120% */
}

.form-wrapper .registration-progress {
  display: flex;
  gap: 19px;
}

.registration-progress .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: var(--Gray-stroke, #E5E5EA);
  gap: 4px;

  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
}

.registration-progress .tab.active {
  border-radius: 8px;
  background: var(--Green-400, #39766A);
  color: #FFF;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
}

form .registration-content-wrapper {
  display: none;
  flex-direction: column;
  gap: 16px;
}
form .registration-content-wrapper.show {
  display: flex;
}

form .input-flex {
  display: flex;
  gap: 18px;
  width: 100%;
}

form .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

form .input-wrapper span {
  color: var(--Red-100, #F72314);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

form .input-wrapper .input-title {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 123.077% */
}

form .input-wrapper input {
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  padding-left: 16px;
  width: 100%;
  height: 36px;
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  outline: none;
}

form .input-wrapper input:focus {
  border-radius: 8px;
  border: 1.5px solid var(--Gray-300, #888);
  color: #292929;
}

form .input-wrapper input.error {
  border-radius: 8px;
  border: 1.5px solid var(--Red-200, #EA2313);
}

form .input-wrapper .h-40 {
  height: 40px !important;
}

form .input-wrapper input::placeholder {
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

form .input-wrapper .activities-take {
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  padding-left: 16px;
  width: 100%;
  height: 36px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 12px;
}

form .input-wrapper .activity-wrapper {
  position: relative;
}

form .input-wrapper .activity-wrapper .drop-down {
  position: absolute;
  top: 113%;
  left: 0;
  width: 100%;
  overflow-y: auto;
  max-height: 200px;
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 8px;
}

form .input-wrapper .activity-wrapper .drop-down div {
  padding: 0 8px;
  height: 40px;
  display: flex;
  align-items: center;

  color: #232323;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 133.333% */
}

form .input-wrapper .activities-take.error {
  border-radius: 8px;
  border: 1.5px solid var(--Red-200, #EA2313);
}

form .input-wrapper .input-style-none {
  border-radius: unset;
  border: unset;
  padding-left: unset;
  height: unset;
}

form .input-wrapper .input-style-none:focus {
  border: unset;
}

form .input-wrapper .input-text {
  height: 36px;
}

form .input-wrapper .input-phone {
  height: 40px;
}

form .user-agreement {
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 18px */
  letter-spacing: -0.264px;
}

form .user-agreement a {
  color: var(--Black-300, #333);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.264px;
  text-decoration-line: underline;
}

form .country-input-wrapper {
  display: flex;
  gap: 12px;
}

.form-wrapper .steps {
  display: flex;
  gap: 62px;
}

.form-wrapper .steps .step {
  border-radius: 8px;

  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.form-wrapper .steps .step.white {
  background: var(--White-100, #FFF);
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  color: var(--Black-300, #333);
}

.form-wrapper .steps .step.gray {
  background: var(--Gray-stroke, #E5E5EA);
  color: var(--Black-400, #292929);
}

.form-wrapper .custom-control label {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.form-wrapper .custom-control-input {
  display: none;
}

.form-wrapper .custom-control-input:checked~.custom-control-label {
  border-radius: 4px;
  border: 1.5px solid var(--Black-400, #292929);
  background: var(--White-100, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.form-wrapper .custom-control label {
  color: #323232;
  color: var(--Black-300, #333);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
}

.form-wrapper .category-wrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.input-wrapper .files-load {
  position: relative;
  border-radius: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  padding: 16px 32px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.input-wrapper .files-load .drop-field {
  display: flex;
  gap: 24px;
}

.input-wrapper .files-load .drop-field .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-wrapper .files-load .drop-field .info .title {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input-wrapper .files-load .drop-field .info .desc {
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input-wrapper .files-load .open-file-input {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.39px;
  border-radius: 8px;
  border: 2px solid var(--Gray-stroke, #E5E5EA);
  height: 40px;
  padding: 7px 10px;
  width: 100px;
}

.load-wrapper {
  display: flex;
  gap: 12px;
}

.registration-content-wrapper>.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.load-files {
  width: 100%;
}

.load-wrapper .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  align-items: center;
}

.load-wrapper .progress {
  width: 100%;
  height: 3px;
  border-radius: 2px;
}

.load-wrapper progress[value]::-webkit-progress-bar {
  background: rgba(0, 0, 0, 0.10);
}

.load-wrapper progress[value]::-webkit-progress-value {
  background: #14AE5C;
}

.load-files .left {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.load-files .left a {
  color: var(--Green-400, #39766A);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.load-files .right {
  display: flex;
  gap: 12px;
  align-items: center;
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .form-wrapper .registration-progress {
    order: 1;
    gap: 4px;
  }

  .form-wrapper .form-title {
    order: 2;
    padding: 0;
  }

  .form-wrapper form {
    order: 3;
  }

  .form-wrapper .steps {
    order: 4;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    gap: 0;
  }

  .registration-progress .tab div {
    display: none;
  }

  .registration-progress .tab {
    border-radius: 5px;
    height: 11px;
  }

  form .input-flex {
    flex-direction: column;
    gap: 16px;
  }

  .registration-content {
    width: calc(100% - 32px);
  }

  .registration {
    padding-top: 96px;
    padding-bottom: 72px;
  }

  .registration-content .page-title {
    font-size: 28px;
  }

  .registration-content .page-desc {
    padding: 0 16px;
  }

  .form-wrapper .steps .step {
    border-radius: 0;
  }

  .input-wrapper .files-load {
    flex-direction: column;
    gap: 24px;
    padding: 13px 41px;
  }

  .input-wrapper .files-load .drop-field {
    flex-direction: column;
    align-items: center;
  }

  .input-wrapper .files-load .drop-field .info {
    align-items: center;
    gap: 12px;
  }

  .input-wrapper .files-load .open-file-input {
    color: var(--Gray-300, #888);
  }

  .input-wrapper .files-load .drop-field .info .desc {
    font-size: 13px;
  }

  form .registration-content-wrapper {
    gap: 30px;
  }

  .load-files .left {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}</style>