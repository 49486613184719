<template>
  <tr>
    <td>
      <div class="company__name">
        <img src="" alt="">
        <div>{{ post.company_name }}</div>
      </div>
    </td>
    <td>
      <div class="table__name">Country:</div>
      <div class="company__country">{{ post.country_name }}</div></td>
    <td>
      <div class="table__name">Contact person:</div>
      <div class="company__person">
        <div class="company__personName">{{ post.person_name }}</div>
        <div class="company__personmail">{{ post.person_email }}</div>
      </div>
    </td>
    <td>
      <div class="table__name">Status:</div>
      <div class="company__status" :class="[ post.color ]">
        <div>{{ post.status_name }}</div>
      </div>
    </td>
    <td  class="mobile__none">
      <div class="company__registrationDate">{{ post.registration_date }}</div>
    </td>
    <td  class="mobile__none">
      <div class="company__rate">
        <div>
          <div class="company__rateNumber">{{ post.rate }}</div>
          <svg class="company__rateStar" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path d="M10.0013 3.11533C10.0263 3.06113 10.0663 3.01522 10.1166 2.98304C10.1669 2.95086 10.2254 2.93376 10.2851 2.93376C10.3448 2.93376 10.4032 2.95086 10.4535 2.98304C10.5038 3.01522 10.5438 3.06113 10.5688 3.11533L12.5044 7.31158C12.5268 7.36009 12.5613 7.40205 12.6045 7.43345C12.6477 7.46486 12.6982 7.48465 12.7513 7.49096L17.3394 8.03471C17.3987 8.04176 17.4548 8.06566 17.5009 8.10359C17.5471 8.14151 17.5814 8.19186 17.5998 8.24868C17.6182 8.3055 17.62 8.36641 17.6048 8.42419C17.5897 8.48197 17.5583 8.53421 17.5144 8.57471L14.1226 11.7122C14.0835 11.7485 14.0543 11.7942 14.0379 11.845C14.0215 11.8958 14.0184 11.9499 14.0288 12.0022L14.9288 16.5341C14.9405 16.5927 14.9352 16.6534 14.9134 16.7091C14.8917 16.7647 14.8544 16.813 14.806 16.8481C14.7577 16.8833 14.7003 16.9038 14.6406 16.9073C14.581 16.9108 14.5215 16.8971 14.4694 16.8678L10.4382 14.611C10.3914 14.5847 10.3387 14.5709 10.2851 14.5709C10.2314 14.5709 10.1787 14.5847 10.1319 14.611L6.10068 16.8672C6.04861 16.8964 5.98927 16.9101 5.92968 16.9066C5.8701 16.9031 5.81275 16.8826 5.76443 16.8476C5.71611 16.8125 5.67883 16.7644 5.65701 16.7088C5.63519 16.6533 5.62974 16.5926 5.6413 16.5341L6.5413 12.0022C6.55184 11.9498 6.54876 11.8955 6.53234 11.8447C6.51593 11.7938 6.48673 11.748 6.44755 11.7116L3.05505 8.57408C3.01134 8.53355 2.98014 8.48136 2.96513 8.42367C2.95011 8.36599 2.95191 8.30521 2.9703 8.24851C2.9887 8.19181 3.02293 8.14155 3.06895 8.10367C3.11497 8.06579 3.17087 8.04186 3.23005 8.03471L7.8188 7.49096C7.87196 7.48476 7.92264 7.46501 7.96598 7.4336C8.00932 7.40219 8.04386 7.36017 8.0663 7.31158L10.0013 3.11533Z" fill="#FFCE47"/>
          </svg>
        </div>
      </div>
    </td>
    <td class="mobile__none">
      <div class="company__terms">{{ post.terms }}%</div>
    </td>
    <td>
      <div class="company__panelButton">
        <button @click="$emit('details', post)">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" @click="$emit('details', post)">
          <g clip-path="url(#clip0_3118_41499)">
            <path d="M16.3333 15.8333H4.66667V4.16667H10.5V2.5H4.66667C3.74167 2.5 3 3.25 3 4.16667V15.8333C3 16.75 3.74167 17.5 4.66667 17.5H16.3333C17.25 17.5 18 16.75 18 15.8333V10H16.3333V15.8333ZM12.1667 2.5V4.16667H15.1583L6.96667 12.3583L8.14167 13.5333L16.3333 5.34167V8.33333H18V2.5H12.1667Z" fill="#292929"/>
          </g>
          <defs>
            <clipPath id="clip0_3118_41499">
              <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg>
        </button>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path d="M13 3.33334V9.16667H4.80835L3.83335 10.1417V3.33334H13ZM13.8334 1.66667H3.00002C2.54169 1.66667 2.16669 2.04167 2.16669 2.50001V14.1667L5.50002 10.8333H13.8334C14.2917 10.8333 14.6667 10.4583 14.6667 10V2.50001C14.6667 2.04167 14.2917 1.66667 13.8334 1.66667ZM18 5.00001H16.3334V12.5H5.50002V14.1667C5.50002 14.625 5.87502 15 6.33335 15H15.5L18.8334 18.3333V5.83334C18.8334 5.37501 18.4584 5.00001 18 5.00001Z" fill="#292929"/>
          </svg>
        </button>
        <button @click="$emit('remove', post)">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 1V2H18.5V4H17.5V17C17.5 18.1 16.6 19 15.5 19H5.5C4.4 19 3.5 18.1 3.5 17V4H2.5V2H7.5V1H13.5ZM5.5 17H15.5V4H5.5V17ZM7.5 6H9.5V15H7.5V6ZM13.5 6H11.5V15H13.5V6Z" fill="#292929"/>
          </svg>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    post: {
      type: Array,
      required: true,
    }
  }
}
</script>

<style>
</style>