<template>
  <header-page></header-page>
  <main>
    <section class="companies">
      <div class="companies__container">
        <div class="companies__content">
          <SideBar></SideBar>
          <WishListBlock @updateWish="updateWishlist" :posts="wishListData"></WishListBlock>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SideBar from "@/components/Customer/SideBar.vue";
import WishListBlock from "@/components/Customer/WishListBlock.vue";
import axios from "axios";
export default {
  components: {SideBar,WishListBlock},
  data() {
    return {
      wishListData: [],
    }
  },
  methods: {
    async getWishlist() {
      try {
        let fData = new FormData();
        const response = await axios.post('/customer/get/wishlist', fData);
        this.wishListData = response.data.data;
      } catch (error) {
        console.error('Error updating wishlist:', error);
      }
    },
    async updateWishlist(event) {
      console.log(event);
      try {
        let fData = new FormData();
        fData.append("isChecked", event.event.target.checked);
        fData.append("event_id", event.id);
        const response = await axios.post('/post/checked-wishlist', fData);
        this.getWishlist();
      } catch (error) {
        console.error('Error updating wishlist:', error);
      }
    }
  },
  mounted() {
  this.getWishlist();
  },
}
</script>

<style scoped>
.companies{
    padding-bottom: 19px;
}
</style>