<template>
  <div class="picker-option">
    <button :class="{'active': filters.status_id == status.id}" v-for="status in arr_status" @click="changeStatus(status.id)" type="button">
      {{ status.status_name }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clicked: '1',
    }
  },
  props: {
    modelValue: Number
  },
  methods: {
    updateInput(number) {
      this.$emit('filter', number);
    }
  }
}
</script>

<style scoped>
.picker-option {
  display: flex;
  height: 32px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  align-items: center;
}

.picker-option button {
  width: 85px;
  height: 100%;
  color: var(--gray-300, #888);
  text-align: center;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.08px;
  cursor: pointer;
}

.picker-option button.active {
  border-radius: 7px;
  background: var(--black-300, #333);
  color: var(--label-color-dark-primary, #FFF);
  text-align: center;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.08px;
}

.picker-option button::before {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  background: rgb(136, 136, 136);
  height: 16px;
  width: 1px;
}

.picker-option button:first-of-type::before {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  background: rgb(136, 136, 136);
  height: 16px;
  width: 0px;
}
</style>