<template>
    <header-page></header-page>
    <main class="new-event">
        <section>
            <div class="new__event__container">
                <div class="button" v-if="step > 0" @click="saveDraft">Save</div>
                <div class="title">Add experience to Amuse</div>
                <div class="desc">Tell us about your services, contribute to our travel community</div>
                <div class="new__event__content">
                    <tab-bar @tab="handleStepChange" :values="error_data" :step="step"></tab-bar>
                    <tab-content @step="handleStepChange" :error_data="error_data" :data="data" @arrData="newEvent = $event"
                        :step="step"></tab-content>
                </div>
            </div>
        </section>
    </main>
</template>
  
<script>
import axios from "axios";
import TabBar from "@/components/Busines/EventDraft/TabBar.vue";
import TabContent from "@/components/Busines/EventDraft/TabContent.vue";
import HeaderBusines from "@/components/Busines/HeaderBusines.vue";
export default {
    components: {
        TabBar,
        TabContent,
        HeaderBusines
    },
    data() {
        return {
            step: 0,
            newEvent: [],
            id_page: this.$route.params.id,
            data: {},
            error_data: [],
        }
    },
    methods: {
        getData() {
            let fData = new FormData();
            fData.append('id', this.id_page);
            axios.post("/business/events/draft/get", fData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(resp => {
                    this.data = resp.data.data[0];
                    this.error_data =  [...new Set(this.data.verification.filter(item => item.state === 'close').map(item => parseInt(item.step, 10)))];
                    if(this.error_data[0]) this.step = this.error_data[0];
                }).catch(() => { }).finally(() => { });
        },
        handleStepChange(value) {
            this.step = value;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        saveDraft() {
            let fData = new FormData();
            for (let [key, value] of Object.entries(this.newEvent)) {
                if (key == "images") {
                    value.forEach((fileObj, index) => {
                        fData.append('images[]', fileObj.file);
                    });
                }
                else if ((Array.isArray(value) || typeof value === "object") && value != "") fData.append(key, JSON.stringify(value));
                else if (value != "") fData.append(key, value);
            }
            axios.post("/business/new-event/save-draft", fData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(resp => {
                    setTimeout(() => {
                        this.$router.push({ name: "BusinesDashboard" });
                    }, 200);
                }).catch(() => { }).finally(() => { });
        },
    },
    mounted() {
        this.getData();
    },
}
</script>
  
<style scoped>
.new-event {
    margin-top: 136px;
}

.new__event__content {
    display: flex;
    gap: 32px;
    padding-bottom: 92px;
}

.new__event__container>.title {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.192px;
    padding-bottom: 16px;
}

.new__event__container>.desc {
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 40px;
}

.new__event__container>.button {
    height: 50px;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0 14px;
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    width: max-content;
    margin-bottom: 31px;
}

@media screen and (max-width: 1024px) {
    .new__event__content {
        padding-bottom: 0px;
    }

    .new-event {
        margin-bottom: 57px;
    }

    .new__event__container>.button {
        display: flex;
    }

    .new-event {
        margin-bottom: 117px;
        margin-top: 113px;
    }
}

@media screen and (max-width: 768px) {


    .new__event__container>.title {
        font-size: 31px;
        text-align: center;
    }

    .new__event__container>.desc {
        text-align: center;
    }

    .new-event {
        margin-top: 96px;
    }
}
</style>