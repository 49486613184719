<template>
    <div class="bread">
        <div class="circle"></div>  
        <div class="title"></div>
        <div class="circle"></div>  
        <div class="title"></div>
        <div class="circle"></div>  
        <div class="title"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.bread{
    display: flex;
    gap: 8px;
}
.bread .circle{
    border-radius: 8px;
    width: 14px;
    height: 21px;
    background: #EBEBEB;
}
.bread .title{
    border-radius: 8px;
    width: 78px;
    height: 21px;
    background: #EBEBEB;
}

</style>