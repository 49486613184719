<template>
  <div class="filters__wrapper" v-show="!isLoading">
    <Category :current_category="current_category" :category="categories" @change_category="handleCategoryChange"></Category>
    <div class="right">
      <Tabs :current_tab2="current_tab" @change_type="handleTabChange"></Tabs>
      <div class="filter__wrapper" @click="this.$emit('update:dialog', true);">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
          <path
              d="M9.5 15.75C9.5 15.5511 9.57902 15.3603 9.71967 15.2197C9.86032 15.079 10.0511 15 10.25 15H14.75C14.9489 15 15.1397 15.079 15.2803 15.2197C15.421 15.3603 15.5 15.5511 15.5 15.75C15.5 15.9489 15.421 16.1397 15.2803 16.2803C15.1397 16.421 14.9489 16.5 14.75 16.5H10.25C10.0511 16.5 9.86032 16.421 9.71967 16.2803C9.57902 16.1397 9.5 15.9489 9.5 15.75ZM6.5 11.25C6.5 11.0511 6.57902 10.8603 6.71967 10.7197C6.86032 10.579 7.05109 10.5 7.25 10.5H17.75C17.9489 10.5 18.1397 10.579 18.2803 10.7197C18.421 10.8603 18.5 11.0511 18.5 11.25C18.5 11.4489 18.421 11.6397 18.2803 11.7803C18.1397 11.921 17.9489 12 17.75 12H7.25C7.05109 12 6.86032 11.921 6.71967 11.7803C6.57902 11.6397 6.5 11.4489 6.5 11.25ZM3.5 6.75C3.5 6.55109 3.57902 6.36032 3.71967 6.21967C3.86032 6.07902 4.05109 6 4.25 6H20.75C20.9489 6 21.1397 6.07902 21.2803 6.21967C21.421 6.36032 21.5 6.55109 21.5 6.75C21.5 6.94891 21.421 7.13968 21.2803 7.28033C21.1397 7.42098 20.9489 7.5 20.75 7.5H4.25C4.05109 7.5 3.86032 7.42098 3.71967 7.28033C3.57902 7.13968 3.5 6.94891 3.5 6.75Z"
              fill="#292929"/>
        </svg>
        <div>Filter</div>
      </div>
    </div>
  </div>
  <PreloaderCategory v-show="isLoading"></PreloaderCategory>
  <slot></slot>
</template>

<script>
import Category from "@/components/MainPages/CityPage/Category.vue";
import Tabs from "@/components/MainPages/CityPage/Tabs.vue";
import DialogFilters from "@/components/MainPages/CityPage/DialogFilters.vue";
import PreloaderCategory from "@/components/MainPages/CityPage/PreloaderCategory.vue";

export default {
  components: {
    Category, Tabs, DialogFilters,PreloaderCategory
  },
  props: {
    categories: Array,
    current_tab: String,
    current_category: String,
    isLoading: Boolean,
    dialog: Boolean,
  },
  methods: {
    handleTabChange(tabData) {
      // Вызываем событие 'change_type' и передаем параметр tabData
      this.$emit('change_type', tabData);
    },
    handleCategoryChange(tabData) {
      // Вызываем событие 'change_type' и передаем параметр tabData
      this.$emit('change_category', tabData);
    }
  },
}
</script>

<style scoped>
.right{
  display: flex;
  gap: 12px;
}
.filters__wrapper {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  cursor: pointer;
}
.filter__wrapper {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: -0.352px;

  display: flex;
  height: 48px;
  padding: 0 18.5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
@media screen and (max-width: 1024px) {
  .filters__wrapper{
    flex-direction: column;
    gap: 24px;
  }
  .right{
    order: 1;
  }
  .category__filter__wrapper{
    order: 2;
  }

  .filter__wrapper{
    width: 48px;
    height: 48px;
    padding: 0;
  }
  .filter__wrapper svg{
    min-height: 30px;
    min-width: 30px;
  }
  .filter__wrapper > div{
    display: none;

  }
}

@media screen and (max-width: 768px) {


}
</style>