<template>
  <button>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
}
</script>

<style>
.btn {
  display: flex;
  gap: 16px;
  width: 100%;
}

.btn button {
  height: 44px;
  width: 100%;
  position: relative;
}

.btn button a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.btn button.max-content {
  width: max-content;
  padding: 0 24px;
}

button.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 8px;
}

button.white {
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;

  color: #606060;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

button.red {
  border-radius: 8px;
  background: #FFEBEB;

  color: #EA2313;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

button.green {
  border-radius: 8px;
  background: rgba(0, 211, 72, 0.24);

  color: #39766A;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
}

button.exit-dialog {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  button {
    height: 50px;
  }
}</style>