import { createStore } from 'vuex'
import Vue from 'vue';
import Vuex from 'vuex';

const store = new Vuex.Store({
  state: {
    sideBarHide: false,
    base_directory: '',
    VUE_APP_WEBSOCKETS_KEY:'local',
    VUE_APP_WEBSOCKETS_SERVER: '127.0.0.1',
    baseURL: '',
    currency: '',
    languages: '',
    months_names : [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    short_months_names:[
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    dialogLoginForm: false,
    timeData: [
      { title: '12 am', id: '24:00:00' },
      { title: '1 am', id: '01:00:00' },
      { title: '2 am', id: '02:00:00' },
      { title: '3 am', id: '03:00:00' },
      { title: '4 am', id: '04:00:00' },
      { title: '5 am', id: '05:00:00' },
      { title: '6 am', id: '06:00:00' },
      { title: '7 am', id: '07:00:00' },
      { title: '8 am', id: '08:00:00' },
      { title: '9 am', id: '09:00:00' },
      { title: '10 am', id: '10:00:00' },
      { title: '11 am', id: '11:00:00' },
      { title: '12 pm', id: '12:00:00' },
      { title: '1 pm', id: '13:00:00' },
      { title: '2 pm', id: '14:00:00' },
      { title: '3 pm', id: '15:00:00' },
      { title: '4 pm', id: '16:00:00' },
      { title: '5 pm', id: '17:00:00' },
      { title: '6 pm', id: '18:00:00' },
      { title: '7 pm', id: '19:00:00' },
      { title: '8 pm', id: '20:00:00' },
      { title: '9 pm', id: '21:00:00' },
      { title: '10 pm', id: '22:00:00' },
      { title: '11 pm', id: '23:00:00' },
  ],
  user:
    { "id": null,
      "image":null,
      "name":null,
      "last_name": null,
      "phone": null,
      "position": null,
      "email": null,
      "created_at": null,
      "updated_at": null,
      "role": null
    }
  },
  getters: {
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
    },
    updateHide(state, newValue) {
      state.sideBarHide = newValue;
    },
    updateCurrency(state, currency) {
      state.currency = currency;
    },
    updateLang(state, lang) {
      state.languages = lang;
    }
  },
  actions: {
    setUser({ commit }, userData) {
      commit('setUser', userData);
    },
    updateHide({ commit }, newValue) {
      commit('updateHide', newValue);
    },
    updateCurrency({ commit }, newCurrency) {
      commit('updateCurrency', newCurrency);
    },
    updateLang({ commit }, newLang) {
      commit('updateLang', newLang);
    }
  },
  modules: {
  }
})
export default store;