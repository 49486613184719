<template>
  <div class="dialog" :class="{ active: show }" @click.stop="hideDialog">
    <div class="bg_color"></div>
    <div @click.stop class="dialog__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:show', false)
    }
  },
}
</script>

<style>

.dialog {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  position: fixed;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  gap: 24px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.dialog.active{
  visibility: visible;
  opacity: 1;
}
.bg_color{
  opacity: 0.32;
  background: #292929;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transition: opacity 1s cubic-bezier(.16,1,.3,1);
}
.dialog.bg-0 .bg_color{
 display: none;
}
.dialog__content {
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 16px;
  padding-top: 24px;
  width: 450px;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 999;
  position: relative;
  min-height: 178px;
}
.dialog__content .btn button{
  height: 44px;
  width: 100%;
}
.dialog__content textarea {
  background: #FFFFFF;
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  height: 96px;
  width: 100%;
  padding: 10px;
  resize: none;


  color: var(--gray-300, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.dialog__content .exit-dialog{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}
.dialog__content .title{
  color: #333;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 12px;
}
.dialog__content .icon.delete{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background: #FFEBEB;
  margin-bottom: 12px;
}
.dialog__content .text{
  color: #606060;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}
.dialog__content .input{
  margin-top: 24px;
}
@media screen and (max-width: 768px) {
  .dialog__content{
    width: calc(100% - 32px);
  }
  .dialog__content .title {
    color: #333;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
  }
  .dialog__content .text{
    color: #606060;
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

</style>