<template>
    <div class="control__buttons">
        <div class="back" @click="this.$emit('exit');">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_5434_54779)">
                <path d="M9.70696 12.707C9.31643 12.3165 9.31643 11.6833 9.70696 11.2928L13.2927 7.70701C13.9227 7.07704 14.9998 7.52321 14.9998 8.41411L14.9998 15.5857C14.9998 16.4766 13.9227 16.9228 13.2927 16.2928L9.70696 12.707Z" fill="#292929"/>
            </g>
            <defs>
                <clipPath id="clip0_5434_54779">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
            </svg>
            Back
        </div>
        <div class="btn-new-event" @click="dialogs.chooseDialog = true">
            <svg xmlns="http://www.w3.org/2000/svg" width="14"
                height="14" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_2982_129386)">
                    <path
                        d="M7.875 0.875C7.875 0.391751 7.48325 0 7 0C6.51675 0 6.125 0.391751 6.125 0.875V6.125H0.875C0.391751 6.125 0 6.51675 0 7C0 7.48325 0.391751 7.875 0.875 7.875H6.125V13.125C6.125 13.6082 6.51675 14 7 14C7.48325 14 7.875 13.6082 7.875 13.125V7.875H13.125C13.6082 7.875 14 7.48325 14 7C14 6.51675 13.6082 6.125 13.125 6.125H7.875V0.875Z"
                        fill="#606060" />
                </g>
                <defs>
                    <clipPath id="clip0_2982_129386">
                        <rect width="14" height="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            Add language
        </div>
    </div>
    <post-list class="active" :posts="arr_data" :loader="data_info.loading">
              <table class="list">
                <thead>
                <tr>
                  <th>Country</th>
                  <th>Language</th>
                  <th>Baners</th>
                  <th>Last update</th>
                  <th>Edit</th>
                </tr>
                </thead>

                <tbody v-if="loadingСompleted">
                    <tr v-for="post in arr_data"
                        :key="post.id">
                        <td >
                        <div class="table__name">{{title[status]}}: </div>
                        <div class="text">{{ post.name }}</div>
                    </td>
                    <td >
                        <div class="table__name">Language:</div>
                        <div class="text">{{ post.language_name == "English" ? post.language_name + ' (main version)': post.language_name}}</div>
                    </td>
                    <td >
                        <div class="table__name">Baners:</div>
                        <div class="text">{{ !post.content ? 0 : post.content.length}}</div>
                    </td>
                    <td>
                        <div class="table__name">Last update:</div>
                        <div class="text">{{ post.updated_at }}</div>
                    </td>
                    <td>
                        <div class="company__panelButton">
                            <button @click="this.$emit('edit', post)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M4.16667 15.8337H5.33334L12.5208 8.64616L11.3542 7.47949L4.16667 14.667V15.8337ZM16.0833 7.43783L12.5417 3.93783L13.7083 2.77116C14.0278 2.45171 14.4203 2.29199 14.8858 2.29199C15.3514 2.29199 15.7436 2.45171 16.0625 2.77116L17.2292 3.93783C17.5486 4.25727 17.7153 4.64283 17.7292 5.09449C17.7431 5.54616 17.5903 5.93144 17.2708 6.25033L16.0833 7.43783ZM3.33334 17.5003C3.09723 17.5003 2.89917 17.4203 2.73917 17.2603C2.57917 17.1003 2.49945 16.9025 2.5 16.667V14.3128C2.5 14.2017 2.52084 14.0939 2.5625 13.9895C2.60417 13.885 2.66667 13.7914 2.75 13.7087L11.3333 5.12533L14.875 8.66699L6.29167 17.2503C6.20834 17.3337 6.11472 17.3962 6.01084 17.4378C5.90695 17.4795 5.79917 17.5003 5.6875 17.5003H3.33334ZM11.9375 8.06283L11.3542 7.47949L12.5208 8.64616L11.9375 8.06283Z" fill="black"/>
                                </svg>
                            </button>
                            <button @click="deleteLanguage(post.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 1V2H18V4H17V17C17 18.1 16.1 19 15 19H5C3.9 19 3 18.1 3 17V4H2V2H7V1H13ZM5 17H15V4H5V17ZM7 6H9V15H7V6ZM13 6H11V15H13V6Z" fill="#292929"/>
                                </svg>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
              </table>
            </post-list>
            <list-preloader v-if="!loadingСompleted"></list-preloader>
            <list-flip @update="onPage" v-if="data_info.loading || arr_data.length > 0" :class="{ active: loadingСompleted }" :page="data_info.page" :currenNumber="arr_data.length" :allCount="data_info.allCount" :next="data_info.next"></list-flip>
        <modal-dialog v-model:show="dialogs.chooseDialog" :modal="true">
        <div>
            <div class="title">Choose language</div>
        </div>
        <SelectDate @update:modelValue="handleUpdate" :show="dialogs.chooseDialog" @search="getChooseInit" :data_name="'name'" :sort="langData"></SelectDate>
        <div class="btn">
            <Button class="white" @click="this.dialogs.chooseDialog = false; selectChoose = []">Cancel</Button>
            <Button class="green" @click="addContent">Confirm</Button>
        </div>
        </modal-dialog>
</template>

<script>
import ListFlip from "@/components/Admin/ListFlip.vue";
import PostList from "@/components/Admin/AdminPanelList.vue";
import axios from "axios";
import ListPreloader from "@/components/UI/Admin/ListPreloader.vue";
import SelectDate from "@/components/Admin/ContentPage/SelectDate.vue";
export default {
    components: {ListPreloader, PostList, ListFlip,SelectDate},
    props: {
        id: Number,
        status: Number,
    },
    data() {
        return {
            title: [
                "Country",
                "City"
            ],
            selectChoose: null,
            dialogs: {
                chooseDialog: false,
            },
            langData: [],
            arr_data: [],
            data_info:{
                loading: true,
                totalRecords: 0,
                page:0,
                next: true,
                allCount: null,
            },
            loadingСompleted: false,
        }
    },
    methods: {
        handleUpdate(option) {
            this.selectChoose = option.id;
        },
        addContent(){
            let fData = new FormData();
            fData.append("id", this.id);
            fData.append("lang", this.selectChoose);
            fData.append("status",this.status);
            axios.post("/admin/get/content/add-lang",fData)
            .then(resp => {
                this.getContent();
                this.dialogs.chooseDialog = false;
            })
            .catch(()=>{}).finally(()=>{});
          },
        getContent() {
            this.loadingСompleted = false;
            let fData = new FormData();
            if(this.arr_data.length<=0){
            this.data_info.page--;
            if(this.data_info.page<0) this.data_info.page = 0;
            }
            fData.append("id", this.id);
            fData.append("page",this.data_info.page);
            fData.append("status",this.status);
            axios.post("/admin/get/content/language",fData).then(resp => {
            if (resp.data['result'] == true) {
                this.arr_data = resp.data.data;
                this.langData = resp.data.language;
                this.data_info.allCount = resp.data.allCount;
                this.data_info.next = resp.data.next;
                this.data_info.loading = false;
            }
            }).catch(()=>{}).finally(()=>{
            this.loadingСompleted = true;
            });
        },
        deleteLanguage(id){
            let fData = new FormData();
            fData.append("id", id);
            fData.append("status",this.status);
            axios.post("/admin/get/content/delete-lang",fData)
            .then(resp => {
                this.getContent();
            })
            .catch(()=>{}).finally(()=>{});
          },
    },
    mounted () {
        this.getContent();
    },
}
</script>

<style scoped>
.list td .text{
    max-width: unset;
}
.company__panelButton{
  justify-content: unset;
}
.control__buttons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.control__buttons .back{
    height: 36px;
    padding: 0 8px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #E5E5EA;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);

    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;
}
.btn-new-event {
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    height: 36px;
    padding: 0 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.08px;
    white-space: nowrap;
}
</style>