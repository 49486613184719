<template>
       <div class="input__filter__wrapper box" :class="[ { open: open }, this.class ]">

            <label class="button_filter box">
                <input :class="{ open: open }" @focus="open = true" @blur="handleBlur" class="search box"
                    @input="selected = $event.target.value; this.$emit('search', selected);"
                    :placeholder="placeholder_title" :value="selected" type="text">
                <svg v-if="!selected" class="arrow-list" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none">
                    <path
                        d="M13.2372 14.1958C12.8408 14.6282 12.1592 14.6282 11.7628 14.1958L8.53608 10.6757C7.94813 10.0343 8.40313 9 9.27323 9L15.7268 9C16.5969 9 17.0519 10.0343 16.4639 10.6757L13.2372 14.1958Z"
                        fill="#5A5E62" />
                </svg>
            </label>
            <div class="items box" v-if="sort.length > 0" :class="{ open: open }">
                <template v-for="option in sort" :key="option.id">
                    <div class="item__wrapper"
                        @click="selected = option[data_name]; open = false; this.$emit('value', option.id);">
                        <div>
                            {{ option[data_name] }}
                        </div>
                    </div>
                </template>
            </div>
            <div class="clear" v-if="selected" @click="selected = ''; this.$emit('value', ''); this.$emit('search', '');">
                <svg class="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15.4124 5.70711C15.803 5.31658 15.803 4.68342 15.4124 4.29289C15.0219 3.90237 14.3887 3.90237 13.9982 4.29289L9.85222 8.43889L5.70735 4.29403C5.31683 3.90351 4.68366 3.90351 4.29314 4.29403C3.90262 4.68455 3.90262 5.31772 4.29314 5.70824L8.438 9.85311L4.29314 13.998C3.90261 14.3885 3.90261 15.0217 4.29314 15.4122C4.68366 15.8027 5.31683 15.8027 5.70735 15.4122L9.85222 11.2673L13.9982 15.4133C14.3887 15.8039 15.0219 15.8039 15.4124 15.4133C15.803 15.0228 15.803 14.3896 15.4124 13.9991L11.2664 9.85311L15.4124 5.70711Z"
                        fill="#292929" />
                </svg>
            </div>
        </div>
</template>
  
<script>
export default {
    props: {
        class: String,
        value: {
            type: String
        },
        sort: {
            type: Array,
            default: () => []
        },
        data_name: String,
        placeholder_title: String,
        selected: [String, Number],
    },
    data() {
        return {
            arr_sort: [],
            open: false,
        }
    },
    methods: {
        handleBlur() {
            setTimeout(() => {
                if (!this.$el.contains(document.activeElement)) {
                    this.open = false;
                }
            }, 100);
        },

    },
}
</script>
  
<style scoped>
.w-100 {
    width: 100% !important;
}

.input__filter__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 350px;
}

.items {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    border-radius: 8px;
    border: 1.5px solid var(--gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.input__filter__wrapper .items img {
    max-width: 24px;
    max-height: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.input__filter__wrapper .items .item__wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}

.input__filter__wrapper .items .image_letter {
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 24px;
    max-height: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #FFF;
    font-family: Standerd;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
}

.input__filter__wrapper.open {
    width: 100%;
}

.input__filter__wrapper .clear {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.input__filter__wrapper .items {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
}

.input__filter__wrapper .items.open {
    opacity: 1;
    visibility: visible;
}

.input__filter__wrapper .items p {
    color: var(--black-400, #292929);
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 22.5px */
    letter-spacing: -0.33px;
    padding: 10px 8px;
}

.button_filter {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    height: 36px;
    border-radius: 8px;
    border: 1.5px solid rgb(229, 229, 234);
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 8px 0px, rgba(0, 0, 0, 0.04) 0px 0px 4px 0px;
    width: 100%;
}

.button_filter input {
    height: 100%;
    border: 0px;
    width: 100%;
    transition: all 0.3s;
}

.button_filter input:focus {
    outline: none;
}

.input__filter__wrapper.open .button_filter {
    width: 100%;
}

.mobile {
    display: none;
}
@media screen and (max-width: 1024px) {
    .mobile__none {
        display: flex;
    }
}
@media screen and (max-width: 768px) {
    .button_filter {
        width: 100%;
        height: 40px;
        z-index: 2;
    }

    .mobile {
        display: flex;
    }

    .input__filter__wrapper {
        width: 100%;
        transition: 0.4s;
        max-width: unset;
    }
    .mg-16{
        margin: 0 16px;
        width: calc(100% -  32px);
    }
    .input__filter__wrapper.open .bg_wrapper {
        opacity: 0.32;
        background: var(--gray-400, #606060);
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 2;
        top: 0;
        left: 0;
    }

    .input__filter__wrapper.open {
        position: relative;
    }

    .button_filter i {
        width: 100%;
    }

    .input__filter__wrapper .clear {
        height: 24px;
        width: 24px;
        position: absolute;
        right: 28px;
        top: 8px;
    }

    .mobile__none {
        display: none !important;
    }
}</style>