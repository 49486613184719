<template>
  <div class="checkbox__wrapper" :class="class">
      <div v-for="item in visibleItems" :key="item.id" class="checkbox" >
        <input :id="name+item.id" :value="item.id" @change="changeRadio" v-model="selectedItems" class="checkbox_input" type="checkbox"/>
        <label :for="name+item.id"></label>
        {{ item.name }}
      </div>
    </div>
  <div class="more" v-if="!showAll && array.length >6" @click="showAllItems">Show more</div>
</template>

<script>
export default {
  props: {
    array: Array,
    name: String,
    modelValue: Array,
    class: String
  },
  data() {
    return {
      selectedItems: this.modelValue,
      showAll: false,
      limit: 6 
    }
  },
  methods: {
    changeRadio() {
      this.$emit('update:modelValue', this.selectedItems);
    },
    showAllItems() {
      this.showAll = true;
    }
  },
  computed: {
    visibleItems() {
      if (this.showAll) {
        return this.array;
      } else {
        return this.array.slice(0, this.limit);
      }
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
        if(newValue != this.selectedItems)
        this.selectedItems = this.modelValue;
    }
  },
}
</script>

<style scoped>
.more{
  color: var(--Black-300, #333);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
  text-decoration-line: underline;
  padding-top: 12px;
}
.checkbox__wrapper{
  display: flex;
  flex-wrap: wrap;
  max-width: 413px;
  gap: 16px;
}
.checkbox__wrapper.column{
  flex-direction: column;
}
.checkbox__wrapper.column .checkbox{
  width: 100%;
}
.checkbox{
  width: calc((100% / 2) - 8px);
  display: flex;
  align-items: center;
  gap: 12px;

  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}
.checkbox .column{
  flex-direction: column;
}
.checkbox_input label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;

}

.checkbox_input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox_input + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox_input + label:hover::before {
  border-radius: 4px;
  border: 2px solid #606060;
}

.checkbox_input + label::before {
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  border: 2px solid #E5E5EA;
  width: 20px;
  height: 20px;
  transition: 0.3s;
}

.checkbox_input:checked + label::before {
  border-color: #292929;
  background-color: #292929;
}
</style>