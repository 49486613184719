<template>
  <div class="profitable__companies custom-styling">
    <slot></slot>
    <div class="wrapper__gradient">
      <div class="companies__wrapper" @scroll="handleScroll" :style="{'max-height': `${maxHeight}px`}" v-show="values.length > 0">
        <div class="company" v-for="(item, index) in values" :key="index">
            <Button class="exit-dialog" @click="this.$emit('details_company',item)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_3984_14678)">
                  <path d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z" fill="#292929"/>
                </g>
                <defs>
                  <clipPath id="clip0_3984_14678">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </Button>
            <div class="company__info">
              <img v-if="item.image" :src='this.$store.state.base_directory + "company/"+ item.id + "/"+ item.image' alt="">
              <div class="image_letter" :style="{ background: getBackgroundColor(item.company_name)}" v-else>{{ item.company_name.substr(0, 1) }}</div>
                <div class="company__name__info">
                  <div class="title">Company name</div>
                  <div class="name">{{ item.company_name }}</div>
                </div>
            </div>
            <div class="company_stats">
                <div class="block total__income">
                  <div class="title">Total Income</div>
                  <div class="count">+${{ item.total_income }} USD</div>
                </div>
                <div class="block total__orders">
                  <div class="title">Total Orders</div>
                  <div class="count">{{ item.total_orders }}+</div>
                </div>
                <div class="block rates">
                  <div class="title">Rate</div>
                  <div class="count">{{ item.rate }}</div>
                </div>
            </div>
        </div>
      </div>
      <div :class="{active: showTopGradient}" class="gradient-top"></div>
      <div v-if="values.length > 2" :class="{active: showBottomGradient}" class="gradient-bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'profitable-company',
  props: {
    values: Object,
    maxHeight: Number,
  },
  data() {
    return {
      showTopGradient: false,
      showBottomGradient: true
    }
  },
  methods: {
    getBackgroundColor(name) {
      // Простейшая хэш-функция для генерации цвета на основе имени
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },
    handleScroll(event){
        const scrollTop = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;

        const clientHeight = event.target.clientHeight;
        console.log(scrollTop + clientHeight);
        console.log(scrollHeight-5);
        if (scrollTop === 0) this.showTopGradient = false;
        else if (scrollTop >= 30) this.showTopGradient = true;

        if (scrollTop + clientHeight > scrollHeight-5) this.showBottomGradient = false;
        else if (scrollTop + clientHeight <= scrollHeight-30) this.showBottomGradient = true;
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.profitable__companies{
  padding: 16px;
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;

  /* 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  height: 100%;
  overflow: hidden;
  max-height: 644px;
  padding-bottom: 0;
}
.companies__wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 372px;
  overflow-y: auto;
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none;
  padding-bottom: 16px;
}
.wrapper__gradient{
  position: relative;
}
.gradient-top,.gradient-bottom{
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  width: 100%;
  height: 60px;
  transition: 0.5s;
}
.gradient-top.active,.gradient-bottom.active{
   opacity: 1;
   visibility: visible;
 }
.gradient-top{
  top: 0;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.00) -8.04%, #FFF 100%);
}
.gradient-bottom{
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -8.04%, #FFF 100%);
}
.companies__wrapper::-webkit-scrollbar {
  width: 0; /* Для WebKit (Chrome, Safari) */
  display: none; /* Для WebKit (Chrome, Safari) */
}
.company{
  padding: 16px;
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.company .company__info{
  display: flex;
  gap: 20px;
}
.company .company__info > img{
  border-radius: 6px;
  width: 45px;
  height: 45px;
  max-width: 45px;
  max-height: 45px;
}
.company .company__info > .image_letter{
  border-radius: 6px;
  background: var(--blue-200, #005CBF);
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Standerd;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.company__info .company__name__info{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: unset;
}
.company__info .company__name__info .title{
  color: rgba(41, 41, 41, 0.80);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
}
.company__info .company__name__info .name{
  color: #292929;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.108px;
}
.company_stats{
  display: grid; /* Используем Grid для размещения блоков */
  grid-template-columns: 2.13fr 1.52fr 0.95fr;
  gap: 16px;
  width: 100%;
  max-width: 492px;
}
.company_stats .block{
  padding: 12px;
  border-radius: 6px;
  background: rgba(233, 233, 235, 0.50);
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.block .title{
  color: rgba(41, 41, 41, 0.80);
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
  text-wrap: nowrap;
}
.block .count{
  color: #292929;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.108px;
  text-wrap: nowrap;
}
.block .total__income{
  flex: 2;
}
.block .total__orders{
  flex: 1;
}
.block .rates{
  flex: 0.5;
}
.my-component {
  background-color: blue; /* Измененный цвет фона */
  font-size: 18px; /* Новый размер шрифта */
}
@media screen and (max-width: 768px){
  .company_stats{
    grid-template-columns: 1fr 1fr; /* Распределение ширины: 1:1:1 */
    grid-gap: 16px;
  }
  .block:first-child {
    grid-column: span 2; /* Занимает 100% ширины */
  }

  /* Для второго и третьего блоков */
  .block:nth-child(2), .block:nth-child(3) {
    grid-column: span 1; /* Занимают по 50% ширины каждый */
  }
  .company_stats .block{
    width: 100%;
  }
  .company .company__info{
    gap: 12px;
  }
  button.exit-dialog{
    width: 36px;
    height: 36px;
  }
  .companies__wrapper{
    max-height: 508px;
  }
  .company__info .company__name__info .name{
    max-width: 140px;
  }
}
</style>