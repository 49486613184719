<template>
    <div class="preloader">
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.preloader{
  border-radius: 16px;
  width: 100%;
  max-height: 448px;
  background: #EBEBEB;
  aspect-ratio: 1384 / 545;
}

@media screen and (max-width: 768px){
  .preloader{
    position: relative;
    width: 100%;
    z-index: 8;
    border-radius: 0px 0px 32px 32px;
    max-height: 390px;
    aspect-ratio: 390 / 390;
  }
}
</style>