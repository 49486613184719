<template>
    <div class="top__experiences__wrapper">
        <div class="title"></div>
        <div class="content">
          <div v-for="value in 8" class="block">
            <div class="image__block"></div>
            <div class="event__name"></div>
            <div class="event__country"></div>
            <div class="event__score__wrapper"></div>
            <div class="event__price__wrapper"></div>
          </div>
        </div>
      </div>
    </template>
    
    <script>
        export default {
            
        }
    </script>
    
    <style scoped>
    .title{
      background: #EBEBEB;
        width: 147px;
        height: 37px;
        border-radius: 8px;
        margin-bottom: 20px;
    }
    .content {
      display: flex;
      gap: 32px;
      width: 100%;
      flex-wrap: wrap;
    }
    .image__block{
        width: 100%;
        border-radius: 16px;
        background: #EBEBEB;
        margin-bottom: 15px;
        max-height: 212px;
        aspect-ratio: 322 / 212;
    }
    .block {
      width: calc((100% / 4) - 24px);
    }
    .event__name{
      background: #EBEBEB;
        border-radius: 8px;
        width: 112px;
        height: 20px;
        margin-bottom: 9px;
    }
    .event__country{
      background: #EBEBEB;
        border-radius: 8px;
        width: 169px;
        height: 20px;
    margin-bottom: 12px;
    }
    .event__score__wrapper{
      background: #EBEBEB;
        border-radius: 8px;
        width: 70px;
        height: 20px;
        margin-bottom: 12px;
    }
    .event__price__wrapper{
      background: #EBEBEB;
        border-radius: 8px;
        width: 112px;
        height: 20px;
    }
    @media screen and (max-width: 1024px) {
      .block {
        width: calc((100% / 2) - 16px);
      }
      .image__block{
        max-height: 322px;;
        }
    }
    @media screen and (max-width: 768px) {
      .block {
        width: 100%;
      }
      .image__block{
        max-height: 412px;;
        }
    }
    </style>