<template>
    <div>Redirecting...</div>
  </template>
  
  <script>
  export default {
    mounted () {
        this.handleAuthCallback();
    },
    methods: {
        handleAuthCallback() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            window.localStorage.setItem('auth_token', token);
            setTimeout(() => {
                    this.$router.push({ name: 'CustomerMyBooking' });
            }, 200);

        }
    },
    },
  };
  </script>