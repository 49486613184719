<template>
  <div class="list__flip">
    <div class="count__post__view" v-if="next">Showing {{ currenNumber*(page+1) }} of {{ allCount }}  Results</div>
    <div class="count__post__view" v-else-if="!next" >Showing {{ allCount }} of {{ allCount }}  Results</div>
    <div class="button__flip">
      <button class="button__prev" @click="page > 0 ? minusCount() :{}"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clip-path="url(#clip0_3118_15314)">
          <path d="M6.70696 9.70701C6.31643 9.31648 6.31643 8.68332 6.70696 8.29279L10.2927 4.70701C10.9227 4.07704 11.9998 4.52321 11.9998 5.41411L11.9998 12.5857C11.9998 13.4766 10.9227 13.9228 10.2927 13.2928L6.70696 9.70701Z" fill="#292929"/>
        </g>
        <defs>
          <clipPath id="clip0_3118_15314">
            <rect width="18" height="18" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      </button>
      <button class="button__next" @click="next ? plusCount() : {}">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <g clip-path="url(#clip0_3118_15318)">
            <path d="M11.707 7.8807C12.0975 8.27122 12.0975 8.90439 11.707 9.29491L8.1212 12.8807C7.49123 13.5107 6.41409 13.0645 6.41409 12.1736L6.41409 5.00202C6.41409 4.11112 7.49123 3.66495 8.1212 4.29491L11.707 7.8807Z" fill="#292929"/>
          </g>
          <defs>
            <clipPath id="clip0_3118_15318">
              <rect width="18" height="18" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: this.page,
    }
  },
  props: {
    page: Number,
    currenNumber: Number,
    allCount: Number,
    next: Boolean,
  },
  methods: {
    minusCount() {
      this.$emit('update', this.count -=1);
    },
    plusCount() {
      this.$emit('update', this.count +=1);
    }
  },
}
</script>

<style scoped>
.list__flip {
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 16px;
  margin-top: 8px;
}
.list__flip .count__post__view {
  color: #888;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.list__flip .button__flip {
  display: flex;
  gap: 8px;
}
.button__flip .button__prev, .button__flip .button__next{
  border-radius: 8px;
  border: 1.5px solid #CCC;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
}
</style>