<template>
    <div class="dialog__login__form" :class="{ active: show }" @click.stop="hideDialog">
        <div class="bg_color"></div>
        <div @click.stop class="login_content">
            <Button class="exit-dialog" @click="hideDialog">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
            </svg>
            </Button>
            <div class="reviews__wrapper">
                <div class="reviews__title__wrapper">
                    <div class="title">We value your feedback</div>
                    <div class="description">It helps us improve</div>
                </div>
                <div class="form__reviews">
                    <div class="input__wrapper">
                        <div class="title">How would you rate Amuse.me service?</div>
                        <StarRating v-model:rating="serviceRating"></StarRating>
                    </div>
                    <div class="input__wrapper">
                        <div class="title">How would you rate the quality of the service/event provided?</div>
                        <StarRating v-model:rating="qualityRating"></StarRating>
                    </div>
                    <div class="input__wrapper">
                        <div class="title">Would you recommend our service to your friends?</div>
                        <StarRating v-model:rating="recommendRating"></StarRating>
                    </div>
                    <div class="input__wrapper" >
                        <div class="title">Would you like to leave a public review or video review ?</div>
                        <textarea v-model="publicReview" id="public-review" rows="4"></textarea>
                        <div class="upload__wrapper" v-if="type">
                          <div class="upload image__upload">
                            <label for="image">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g clip-path="url(#clip0_5760_84772)">
                                <path d="M3 4V1H5V4H8V6H5V9H3V6H0V4H3ZM6 10V7H9V4H16L17.83 6H21C22.1 6 23 6.9 23 8V20C23 21.1 22.1 22 21 22H5C3.9 22 3 21.1 3 20V10H6ZM13 19C15.76 19 18 16.76 18 14C18 11.24 15.76 9 13 9C10.24 9 8 11.24 8 14C8 16.76 10.24 19 13 19ZM9.8 14C9.8 15.77 11.23 17.2 13 17.2C14.77 17.2 16.2 15.77 16.2 14C16.2 12.23 14.77 10.8 13 10.8C11.23 10.8 9.8 12.23 9.8 14Z" fill="#292929"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_5760_84772">
                                  <rect width="24" height="24" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                            </label>
                            <input id="image" type="file" multiple="multiple" accept="image/*" @change="handleImageUpload" ref="imageInput" />
                          </div>
                          <div class="upload video__upload">
                            <label for="video">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21.5984 11.9984C21.5984 9.45236 20.587 7.01056 18.7867 5.21021C16.9863 3.40986 14.5445 2.39844 11.9984 2.39844C9.45236 2.39844 7.01056 3.40986 5.21021 5.21021C3.40986 7.01056 2.39844 9.45236 2.39844 11.9984L2.40684 12.4136L2.43804 12.872C2.54649 14.0796 2.88412 15.2553 3.43284 16.3364L3.50844 16.4804L2.41644 20.8532L2.39964 20.9528V21.0512C2.40726 21.1377 2.43355 21.2215 2.4767 21.2968C2.51984 21.3721 2.57882 21.4372 2.64955 21.4875C2.72028 21.5378 2.80108 21.5722 2.88639 21.5882C2.97169 21.6043 3.05946 21.6016 3.14364 21.5804L7.51764 20.4884L7.66164 20.5652C9.12538 21.3064 10.7547 21.6601 12.394 21.5925C14.0333 21.5249 15.6279 21.0383 17.0256 20.1791C18.4234 19.3199 19.5776 18.1168 20.3782 16.6847C21.1787 15.2526 21.5988 13.6391 21.5984 11.9984ZM7.19844 9.59844C7.19844 9.28018 7.32487 8.97495 7.54991 8.74991C7.77495 8.52487 8.08018 8.39844 8.39844 8.39844H11.9984C12.3167 8.39844 12.6219 8.52487 12.847 8.74991C13.072 8.97495 13.1984 9.28018 13.1984 9.59844V14.3984C13.1984 14.7167 13.072 15.0219 12.847 15.247C12.6219 15.472 12.3167 15.5984 11.9984 15.5984H8.39844C8.08018 15.5984 7.77495 15.472 7.54991 15.247C7.32487 15.0219 7.19844 14.7167 7.19844 14.3984V9.59844ZM15.7736 9.42324C15.8575 9.33918 15.9644 9.28189 16.0808 9.25861C16.1972 9.23533 16.3179 9.24712 16.4276 9.29247C16.5374 9.33782 16.6312 9.4147 16.6972 9.51339C16.7632 9.61207 16.7984 9.72812 16.7984 9.84684V14.15C16.7984 14.2688 16.7632 14.3848 16.6972 14.4835C16.6312 14.5822 16.5374 14.6591 16.4276 14.7044C16.3179 14.7498 16.1972 14.7615 16.0808 14.7383C15.9644 14.715 15.8575 14.6577 15.7736 14.5736L14.3984 13.1984V10.7984L15.7736 9.42324Z" fill="#292929"/>
                              </svg>
                              Video review
                            </label>
                            <input id="video" type="file" accept="video/*" @change="handleVideoUpload" ref="videoInput"/>
                          </div>
                        </div>
                    </div>
                    <button @click="submitFeedback" class="submit-button">Leave feedback</button>
                </div>
            </div>
        </div>
    </div>
  </template>
  
<script>
  import axios from "axios";
  import StarRating from "@/components/StarRating.vue";
  export default {
    components: {
        StarRating,
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      type: {
        type: Boolean,
        default: false
      },
      event_id: Number,
    },
    data() {
      return {
        serviceRating: 0,
        qualityRating: 0,
        recommendRating: 0,
        publicReview: '',
        image: [],
        video: null,
      }
    },
    methods: {
      handleImageUpload(event) {
          let newFiles = [];
          if (event.target.files) { newFiles = Array.from(event.target.files); }
          else if (event.dataTransfer.files) { newFiles = Array.from(event.dataTransfer.files); }

          for (let i = 0; i < newFiles.length; i++) {
                  this.image.push(newFiles[i]);
          }
      },
      handleVideoUpload(event) {
        this.video = event.target.files[0];
      },
      hideDialog() {
        this.$emit('update:show', false);
        this.resetForm();
      },
      submitFeedback(){
          let fData = new FormData();
          let rate = (this.serviceRating+this.qualityRating+this.recommendRating)/3;
          fData.append("rate", rate);
          fData.append("event_id", this.event_id);
          if (this.publicReview != '') {
            fData.append('text', this.publicReview);
          }
          if (this.image) {
            this.image.forEach((fileObj, index) => {
                fData.append('image[]', fileObj);
            });
          }
          if (this.video) {
            fData.append('video', this.video);
          }

          axios.post("/customer/leave-feedback", 
           fData, {
              headers: {'Content-Type': 'multipart/form-data',}
            },
          ).then(resp => {
            this.hideDialog();
          }).catch(() => {
          }).finally(() => {
              
          });
      },
      resetForm() {
      this.serviceRating = 0;
      this.qualityRating = 0;
      this.recommendRating = 0;
      this.publicReview = '';
      this.image = null;
      this.video = null;
      if(this.type){
        this.$refs.imageInput.value = '';
        this.$refs.videoInput.value = '';
      }
    },
    }
  }
</script>
  
  <style scoped>
  .upload__wrapper{
    position: absolute;
    right: 24px;
    bottom: 24px;
    display: flex;
    gap: 12px;
  }
  .upload__wrapper .upload input{
    display: none;
  }
  .upload__wrapper .upload label{
    display: flex;
    min-width: 40px;
    min-height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

    color: #000;
    text-align: center;
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.08px;
  }
  textarea {
  width: 100%;
  padding: 10px;
  height: 196px;
  border-radius: 12px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  resize: vertical;
  resize: none;
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}
.submit-button {
  display: block;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: var(--Green-0, rgba(0, 211, 72, 0.24));
  cursor: pointer;
  text-align: center;
  color: var(--Green-400, #39766A);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.submit-button:hover {
  background-color: #45a049;
}
  .bg_color{
    opacity: 0.32;
    background: #292929;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    transition: opacity 1s cubic-bezier(.16,1,.3,1);
  }
  .dialog.bg-0 .bg_color{
    display: none;
  }
  .dialog__login__form{
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  
    position: fixed;
    display: flex;
    z-index: 100;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }
  .dialog__login__form.active{
    visibility: visible;
    opacity: 1;
  }
  
  .login_content {
    width: 100%;
    max-width: 896px;
    z-index: 999;
    position: relative;

    padding: 24px;
    padding-top: 88px;
    background: #FFF;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .reviews__wrapper{
    max-width: 613px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    width: 100%;
  }
  .form__reviews{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
  }
  .form__reviews .input__wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    position: relative;
  }
  .form__reviews .input__wrapper .title{
    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: -0.08px;
  }
  .reviews__title__wrapper .title{
    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 83.333% */
    letter-spacing: -0.08px;
    padding-bottom: 8px;
  }
  .reviews__title__wrapper .description{
    color: var(--Gray-400, #606060);
    text-align: center;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: -0.08px;
  }
  
  @media screen and (max-width: 768px) {
    .upload__wrapper{
      right: 16px;
      bottom: 16px;
    }
    .login_content {
      width: calc(100%);
      height: 100%;
      overflow-y: auto;
      align-items: unset;
      padding: 24px;
      display: block;
    }
    .reviews__wrapper{
      gap: 40px;
    }
    .login_content .page_title {
      color: var(--black-400, #292929);
      text-align: center;
      font-family: Standerd;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.144px;
    }
    .reviews__title__wrapper .title{
      color: var(--Black-400, #292929);
      text-align: center;
      font-family: Standerd;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 100% */
      letter-spacing: -0.08px;
    }
    .reviews__title__wrapper .description{
      color: var(--Gray-400, #606060);
      text-align: center;
      font-family: Standerd;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.08px;
    }
  }
  </style>