<template>
  <div class="post__content__info">
    <div class="info_left">
        <slot name="left"></slot>
    </div>
    <div class="info_right" v-if="$slots.right">
        <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.post__content__info {
    display: flex;
    gap: 32px;
}

.info_left {
  width: 65.89%;
    display: flex;
    gap: 32px;
    flex-direction: column;
}
.info_right {
  width: 34.10%;
}
@media screen and (max-width: 1024px) {
  .post__content__info {
    flex-direction: column;
    gap: 60px;
    position: relative;
    z-index: 9;
}
.info_left {
  order: 2;
  width: 100%;
    gap: 24px;
}
.info_right {
  width: 100%;
  order: 1;
}
}

</style>