<template>
  <header-page></header-page>
  <main>
    <BreadCrumbs  :exit="true" :loading="loadingСompleted" :post="arr_data"></BreadCrumbs>
    <event-content :loading="loadingСompleted" :post="arr_data" @approve="postApprove"
      @reject="postReject"
      @all-photos="showAllPhoto = $event"></event-content>
      <ViewAllPhotos 
    v-if="loadingСompleted" 
    :reviews="reviewsData" 
    :class="{ active: showAllPhoto }" 
    @exit="showAllPhoto = $event"
    :loading="loadingСompleted" 
    :company_id="arr_data[0].company_id"
    :id="arr_data[0].id"
    :steps="this.steps"
    :viewCurrentImage="arr_data[0].images"
    >
    </ViewAllPhotos>
  </main>
</template>
<script>
import axios from "axios";
import BreadCrumbs from "@/components/BreadCrumb.vue";
import EventContent from "@/components/Admin/AdminApproveEventContent.vue";
import ViewAllPhotos from "@/components/EventPage/ViewAllPhotos.vue";
import router from "@/router";
export default {
  components: { BreadCrumbs, EventContent,ViewAllPhotos },
  data() {
    return {
      showAllPhoto: false,
      loadingСompleted: false,
      id_page: this.$route.params.id,
      blocks: [],
      arr_data: [],
      steps: 1,
    }
  },
  methods: {
    getData() {
      this.loadingСompleted = false;
      let fData = new FormData();
      fData.append("id", this.id_page);
      axios.post("/admin/events/approve-page", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_data = resp.data.data;
        }
      }).catch(() => { }).finally(() => {
        this.loadingСompleted = true;
      });
    },
    loadData() {
      axios.get(`/events/show/${this.id_page}`)
        .then(response => {
          this.blocks = response.data.verification_data;
        })
        .catch(error => {
          console.error('Ошибка при загрузке данных', error);
        });
    },
    postApprove() {
      console.log();
      let fData = new FormData();
      fData.append("id", this.id_page);
      axios.post("/admin/events/post-approve", fData).then(resp => {
        console.log(resp);
        if (resp.data['result'] == true) {
        }
      }).catch(() => { }).finally(() => {
        router.push({ name: 'AdminEvents' });
      });
    },
    postReject(arr) {
      let fData = new FormData();
      fData.append("id", this.id_page);
      fData.append("verification", JSON.stringify(arr));
      axios.post("/admin/events/post-reject", fData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(resp => {
        console.log(resp);
        if (resp.data['result'] == true) {
          router.push({ name: 'AdminEvents' });
        }
      }).catch(() => { }).finally(() => {

      });
    },
  },
  mounted() {
    this.loadData();
    this.getData();
  },
}
</script>

<style scoped>
main{
  margin-bottom: 168px;
}
</style>