<template>
  <section class="login">
    <div class="login_content">
      <div class="page_title">Email verification</div>
      <form action="" >
        <div class="title">Forgot password?</div>
        <div class="inputs__wrapper">
          <div class="input__wrapper">
            <div class="text">Email</div>
            <Input-email :class="{ error: status__error }" :placeholder="'Email'" :email="reset_data.email" @update:email="reset_data.email = $event"></Input-email>
          </div>
          <div v-if="status__error" class="status_error">
            This mailing address is not registered in the system.
            <router-link class="link" :to="{name: 'RegistrationBusines'}">
              <div class="text">Sign up</div>
            </router-link>
          </div>
        </div>
        <button class="btn_login" type="submit" @click="resetPassword">Recover password</button>
      </form>
    </div>
    <modal-dialog v-model:show="dialogs.SuccessfullyItemDialog" :modal="true">
      <div>
        <div class="title__successfully">The letter has been sent!</div>
        <div class="text__successfully">To reset your password, open the email which we just sent to e-mail mail@mail.com and click on the"Reset password" button in it.</div>
      </div>
      <div class="btn">
        <Button class="white" >Go to email
          <a :href="'mailto:' + reset_data.email"></a>
        </Button>
      </div>
      <button class="exit-dialog" @click="this.dialogs.SuccessfullyItemDialog = false">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
        </svg>
      </button>
    </modal-dialog>
  </section>
</template>

<script>
import InputPassword from "@/components/UI/InputPassword.vue";
import InputEmail from "@/components/UI/InputEmail.vue";
import axios from "axios";
export default {
  components: {
    InputPassword,
    InputEmail,
  },
  data() {
    return {
      status__error: false,
      reset_data:{
        email: null,
        type: 3,
      },
      dialogs:{
        SuccessfullyItemDialog: false,
      }
    }
  },
  methods: {
    resetPassword(e){
      e.preventDefault();
      let current_arr = JSON.parse(JSON.stringify(this.reset_data));
      let fData = new FormData();
      fData.append("email",current_arr.email);
      fData.append("type",current_arr.type);
      axios.post("/forget-password",fData)
          .then(resp => {
            this.dialogs.SuccessfullyItemDialog = true;
            this.status__error = false;
          })
          .catch(error => this.status__error = true)
          .finally();

    },
  },
}
</script>

<style scoped>
.status_error{
  color: var(--red-100, #F72314);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  display: flex;
  gap: 3px;
}
.status_error .link{
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
}
.title__successfully{
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  padding-bottom: 32px;
}
.text__successfully{
  color: var(--gray-400, #606060);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}
.checkbox{
  display: flex;
  gap: 8px;
  align-items: center;
}
.login {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_content{
  max-width: 562px;
  width: 562px;
}
.login_content .page_title{
  color: var(--black-400, #292929);
  text-align: center;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.192px;
  padding-bottom: 40px;
}
.login_content form{
  padding: 16px;
  padding-top: 35px;
  width: 100%;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.inputs__wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}
.login_content .title {
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  padding-bottom: 16px;
}
.login_content .input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.input__wrapper .text {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
}
.input__wrapper input{
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  height: 40px;
  padding-left: 12px;
}

.login_content .btn_login{
  cursor: pointer;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  height: 44px;
  width: 100%;
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}
@media screen and (max-width: 768px) {
  .login_content {
    width: calc(100% - 32px);
  }
  .login_content .page_title{
    color: var(--black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.144px;
  }
}
</style>