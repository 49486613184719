<template>
  <section class="login">
    <div class="login_content">
      <div class="page_title">Create your Amuse account</div>
      <form action="">
        <div class="title">Sign up</div>
        <div class="inputs__wrapper">
          <div class="input__wrapper">
            <div class="text">Email</div>
            <Input-email :placeholder="'Email'" :email="auth_data.email"
                         @update:email="auth_data.email = $event"></Input-email>
          </div>
          <div class="input__wrapper">
            <div class="text__wrapper">
              <div class="text">Password</div>
              <router-link class="link" :to="{name: 'CustomerEmailVerification'}">Forgot password?</router-link>
            </div>
            <Input-password :placeholder="'Password'" :password="auth_data.password"
                            @update:password="auth_data.password = $event"></Input-password>
            <div class="checkbox">
              <input id="remember_me" class="checkbox_input" type="checkbox" v-model="auth_data.remember_me"/>
              <label for="remember_me"></label>
              <div class="text">remember me</div>
            </div>
          </div>
        </div>
        <button class="btn_login" type="submit" @click="SubmitAuth">Sign up</button>
        <div class="social__login">
          <div @click="loginWithGoogle('google')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#FFC107"/>
              <path
                  d="M3.15234 7.3455L6.43784 9.755C7.32684 7.554 9.47984 6 11.9993 6C13.5288 6 14.9203 6.577 15.9798 7.5195L18.8083 4.691C17.0223 3.0265 14.6333 2 11.9993 2C8.15834 2 4.82734 4.1685 3.15234 7.3455Z"
                  fill="#FF3D00"/>
              <path
                  d="M12.0002 22.0003C14.5832 22.0003 16.9302 21.0118 18.7047 19.4043L15.6097 16.7853C14.5719 17.5745 13.3039 18.0014 12.0002 18.0003C9.39916 18.0003 7.19066 16.3418 6.35866 14.0273L3.09766 16.5398C4.75266 19.7783 8.11366 22.0003 12.0002 22.0003Z"
                  fill="#4CAF50"/>
              <path
                  d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#1976D2"/>
            </svg>
          </div>
        </div>

        <div class="sign__up">
          Already have an account?
          <router-link :to="{ name: 'CustomerAuth' }">Log in</router-link>
        </div>


      </form>
    </div>
  </section>
  <StatusRequest ref="toast"></StatusRequest>
</template>

<script>
import InputPassword from "@/components/UI/InputPassword.vue";
import InputEmail from "@/components/UI/InputEmail.vue";
import axios from "axios";
import StatusRequest from "@/components/UI/StatusRequest.vue";
import messages from '@/assets/json/messages.json';
export default {
  components: {
    InputPassword,
    InputEmail,
    StatusRequest
  },
  data() {
    return {
      auth_data: {
        email: null,
        password: null,
        remember_me: false,
      },
      messages: messages,
      request: false,
    }
  },
  methods: {
    loginWithGoogle(provider) {
      const domain = window.location.origin;
      window.location.href = domain+'/auth/'+provider+'/redirect';
    },
    async SubmitAuth(e) {
      e.preventDefault();
      if(this.request){
        return false;
      }
      this.request = true;
      let current_arr = JSON.parse(JSON.stringify(this.auth_data));
      let fData = new FormData();

      fData.append("login", current_arr.email);
      fData.append("password", current_arr.password);
      try {
        const response = await axios.post("/registration/account", fData);
        this.showMessage(true, this.getSuccessMessage('registration_success'));
        setTimeout(() => {
          this.$router.push({name: "CustomerAuth"});
        }, 700);
      } catch(error) {
        if (error.response) {
            switch (error.response.status) {
                case 409:
                    this.showMessage(false, this.getErrorMessage('email_exists'));
                    break;
                case 422:
                    this.showMessage(false, this.getErrorMessage('invalid_credentials'));
                    break;
                case 500:
                    this.showMessage(false, this.getErrorMessage('unknown_error'));
                    break;
                default:
                    this.showMessage(false, this.getErrorMessage('unknown_error'));
            }
        } else {
            console.error("Network error");
        }
      }
      finally{
        this.request = false;
      }
    },
    getSuccessMessage(key) {
      return this.messages.success['en'].registration[key];
    },
    getErrorMessage(key) {
      return this.messages.errors['en'].registration[key];
    },
    showMessage(type, title) {
      if (type) type = 'success';
      else type = "error";
      const toast = this.$refs.toast;
      toast.show(type, title, 5000);
    }
  },
}
</script>

<style scoped>
.social__login {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.social__login div {
  cursor: pointer;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #E5E5EA;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
}

.sign__up {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
}

.sign__up a {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  margin-left: 5px;
}

.checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
}

.login {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_content {
  width: 562px;
  max-width: 562px;
}

.login_content .page_title {
  color: var(--black-400, #292929);
  text-align: center;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.192px;
  padding-bottom: 40px;
}

.login_content form {
  padding: 16px;
  padding-top: 25px;
  width: 100%;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.inputs__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

.login_content .title {
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  padding-bottom: 16px;
}

.login_content .input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text__wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.text__wrapper .link {
  color: var(--black-400, #292929);
  text-align: right;
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  text-decoration-line: underline;
}

.input__wrapper .text {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
}

.checkbox .text {
  color: var(--gray-300, #888);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
}

.input__wrapper input {
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  height: 40px;
  padding-left: 12px;
}

.login_content .btn_login {
  cursor: pointer;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  height: 44px;
  width: 100%;
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

@media screen and (max-width: 768px) {
  .login_content {
    width: calc(100% - 32px);
  }

  .login_content .page_title {
    color: var(--black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.144px;
  }
}
</style>