<template>
  <template v-for="(filter,index1) in filters">
    <template v-for="(arr_filter,index2) in arrFilters">
      <div class="active-filters" v-if="index1 == index2">
    <div class="text">
      {{ arr_filter }}
    </div>
    <svg @click="this.$emit('delete', index1);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4712 4.70711C12.8617 4.31658 12.8617 3.68342 12.4712 3.29289C12.0806 2.90237 11.4475 2.90237 11.057 3.29289L7.88158 6.46827L4.70711 3.2938C4.31658 2.90328 3.68342 2.90328 3.29289 3.2938C2.90237 3.68433 2.90237 4.31749 3.29289 4.70802L6.46736 7.88249L3.29289 11.057C2.90237 11.4475 2.90237 12.0806 3.29289 12.4712C3.68342 12.8617 4.31658 12.8617 4.70711 12.4712L7.88158 9.2967L11.057 12.4721C11.4475 12.8626 12.0806 12.8626 12.4712 12.4721C12.8617 12.0816 12.8617 11.4484 12.4712 11.0579L9.29579 7.88249L12.4712 4.70711Z" fill="#606060"/>
    </svg>
      </div>
    </template>
  </template>
</template>

<script>
export default {
    name: 'ActiveFilters',
    data() {
      return {
      }
    },
    props: {
      arrFilters: Object,
      filters: Object
    },
}
</script>

<style scoped>
.active-filters{
  display: flex;
  align-items: center;
  gap: 8px;
  height: 38px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  /* 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.active-filters .text{
  color: var(--gray-400, #606060);
  text-align: center;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.08px;
}
@media screen and (max-width: 1024px) {
  .active-filters{
    display: none;
  }
}
</style>
<script>
</script>