<template>
  <div class="menu__wrapper" :class="{ active: show }">
    <div>
      <div class="menu" v-if="role">
        <!-- <router-link :to="value.route" v-for="value in roleData[this.role]">
          <img :src="require(`@/assets/image/menu-icons${value.img}`)" alt="">
          {{ value.name }}
        </router-link> -->
        <router-link :to="value.route" v-for="value in roleData[this.role]" :key="index" >
          <img :src="require(`@/assets/image/menu-icons${value.img}`)" alt="">
          {{ value.name }}
        </router-link>
        <a href="#" @click.prevent="this.$emit('open-dialog', 'Information center'); hideDialog();">
                      <img :src="require(`@/assets/image/menu-icons/info.svg`)" alt="">
                      Information center
        </a>
      </div>
      <div class="menu" v-else>
          <a href="#" @click.prevent="this.$emit('open-dialog', index); hideDialog()" v-for="(value,index) in roleData[this.role]">
          <img :src="require(`@/assets/image/menu-icons${value.img}`)" alt="">
          {{ value.name }}
          </a>
      </div>
      <div class="line"></div>
      <div class="logout" @click="logout" v-if="role">
          <img :src="require(`@/assets/image/menu-icons/logout.svg`)" alt="">
          Log out
      </div>
    </div>
  </div>
</template>

<script>
import jsonData from '@/assets/json/UserRouters.json';
import axios from "axios";
export default {
  props: {
    role: String,
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      roleData: jsonData,
    }
  }, 
  methods: {
    hideDialog() {
      this.$emit('update:show', false)
    },
    handleBlur() {
      setTimeout(() => {
        if (!this.$el.contains(document.activeElement)) {
          this.$emit('update:show', false)
        }
      }, 100);
    },
    logout(){
      console.log(this.role);
      axios.post("/logout/auth")
        .then(resp => {
          setTimeout(() => {
          this.$emit('update:show', false);
          switch(this.role){
            case 'admin':
              this.$router.push({name:"LoginView"});
              break;
            case 'business':
              this.$router.push({name:"AuthBusines"});
              break;
            case 'customer':
              window.location.reload();
              break;
          }
          }, 200);
        })
        .catch(() => {
        })
        .finally(() => {
        });
    }
  },
}
</script>

<style scoped>
.menu__wrapper {
  position: absolute;
  left: 0;
  top: 137%;
  width: 100%;
  border-radius: 10px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: var(--White-100, #FFF);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  z-index: 100;
}
.menu__wrapper.active{
  visibility: visible;
  opacity: 1;
}
.menu {
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.menu a {
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
  gap: 7px;
  padding-bottom: 10px;
}

.menu__wrapper .logout {
    height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    gap: 7px;
    padding: 8px;
    margin: 8px;
    margin-top: 6px;
}

.menu__wrapper .logout:hover {
  background: #F1F1F1;
}

.menu a:hover {
  background: #F1F1F1;
}

.menu__wrapper .line {
  width: 100%;
  height: 1px;
  background: #E5E5EA;
}
.menu__wrapper{
  left: unset;
  right: 0;
  width: max-content;
  min-width: 190px;
}
@media screen and (max-width: 768px) {
.menu__wrapper{
  display: none;
}
}
</style>