<template>
  <Header></Header>
  <main>
    <BreadCrumbs class="mobile-none" :isLoading="isLoading"></BreadCrumbs>
    <section v-if="bannerData.length > 1 && windowWidth >= 768" class="slider__container mobile__none">
      <SliderSection :array="bannerData" :isLoading="isLoading"></SliderSection>
    </section>
    <section v-else-if="bannerData.length <= 1" class="first__banner__container">
      <FirstBannerPage :array="bannerData" :isLoading="isLoading"></FirstBannerPage>
    </section>
    <section v-if="bannerData.length > 1 && windowWidth <= 768" class="banner__mobile mobile-768">
      <FirstBannerMobile :array="bannerData" :isLoading="isLoading"></FirstBannerMobile>
    </section>
    <section class="top__experiences__container">
      <EventsListBigBlock 
      :posts="topExperienceData" 
      :title="'Top experiences in  '+getCategoryName()" 
      :isLoading="isLoading" 
      :load="filters.stepExperience" 
      :type_load="true"
      @load="loadPost(getURLParams(),1)">
      </EventsListBigBlock>
    </section>
    <section class="top__event__container">
      <EventsListLowBlock 
      :posts="topEventsData" 
      :title="'Top events in '+getCategoryName()" 
      :isLoading="isLoading" 
      :load="filters.stepEvents" 
      :type_load="true"
      @load="loadPost(getURLParams(),2)"
      ></EventsListLowBlock>
    </section>
    <section class="popular__cities__container">
      <PopularCitiesSection :posts="popularCityData" :title="'Popular cities in '+getCategoryName()" :isLoading="isLoading"></PopularCitiesSection>
    </section>
  </main>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/MainPages/Header.vue";
import Footer from "@/components/MainPages/Footer.vue";
import SliderSection from "@/components/MainPages/HomePage/SliderSection.vue";
import BreadCrumbs from "@/components/MainPages/BreadCrumbs.vue";
import FirstBannerPage from "@/components/MainPages/FirstBannerPage.vue";
import EventsListBigBlock from "@/components/MainPages/CountryPage/EventsListBigBlock.vue";
import EventsListLowBlock from "@/components/MainPages/CountryPage/EventsListLowBlock.vue";
import FirstBannerMobile from "@/components/MainPages/HomePage/FirstBannerMobile.vue";
import PopularCitiesSection from "@/components/MainPages/CountryPage/PopularCitiesSection.vue";
import axios from "axios";
import { mapState } from 'vuex';
export default {
  components: {EventsListLowBlock, EventsListBigBlock, Footer, Header, BreadCrumbs, FirstBannerPage, PopularCitiesSection,SliderSection,FirstBannerMobile},
  data() {
    return {
        topExperienceData: [],
        topEventsData: [],
        popularCityData: [],
        bannerData:[],
        isLoading: true,
        loadPage: false,
        filters:{
          topEventsStep: 1,
          topExperienceStep: 1,
          stepEvents: true,
          stepExperience: true,
        },
        windowWidth: window.innerWidth,
    }
  },
  methods: {
    async loadPost(object,type){
      console.log(object);
      console.log(type);
      let currency = object.currency;
      let language = object.language;
      let fData = new FormData();
      fData.append("currency", currency || 'USD');
      fData.append("language", language || 'en');
      fData.append("country", this.$route.params.country);
      fData.append("type", type);
      if(type ==  1){
        fData.append("page", this.filters.topExperienceStep);
      }
      else{
        fData.append("page", this.filters.topEventsStep);
      }
      try {
        const resp = await axios.post("/get/country/load-data", fData);
        if(type ==  1){
          this.topExperienceData.push(...resp.data.events);
          this.filters.stepExperience = resp.data.has_more;
          this.filters.topExperienceStep++;
          console.log(resp.data.has_more);
          console.log(resp.data.events);
        }
        else{
          this.topEventsData.push(...resp.data.events);
          this.filters.stepEvents = resp.data.has_more;
          this.filters.topEventsStep++;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getCategoryName() {
        const country = this.$route.params.country;
        if (country) {
            return country.charAt(0).toUpperCase() + country.slice(1).toLowerCase();
        }
        return '';
    },

    async getTopExperiences(currency, language) {
      const urlWithoutParams = window.location.origin + window.location.pathname;
      let fData = new FormData();
      fData.append("currency", currency || 'USD');
      fData.append("language", language || 'en');
      fData.append("country", this.$route.params.country);
      try {
        const resp = await axios.post("/get/country/events", fData);
        this.topExperienceData = resp.data.top_experiences;
        this.topEventsData = resp.data.top_events;
        this.popularCityData = resp.data.top_destination;
        this.bannerData = resp.data.banner_data;
        this.filters.stepEvents = resp.data.top_events_has_more;
        this.filters.stepExperience = resp.data.top_experiences_has_more;
      } catch (error) {
        console.error(error);
      }
    },
    async loadData({ currency, lang }) {
      this.isLoading = true;
      await Promise.all([
        this.getTopExperiences(currency, lang),
      ]).then(results => {
      this.$nextTick(() => {
        this.waitForImagesToLoad();
      });
      }, error => {
        this.isLoading = false;
        this.loadPage = true;
        console.error("Error loading data:", error);
      });
    },
    waitForImagesToLoad() {
      const images = document.querySelectorAll('img[data-load]');
      const promises = [];

      images.forEach((img, index) => {
        if (!img.complete) {
          promises.push(new Promise((resolve) => {
            img.onload = () => {
              resolve();
            };
            img.onerror = () => {
              resolve(); // Считаем изображение загруженным даже при ошибке
            };
          }));
        } else {
        }
      });

      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          this.isLoading = false;
          this.loadPage = true;
        });
      } else {
        this.isLoading = false;
        this.loadPage = true;
      }
    },
    getURLParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const currency = urlParams.get('currency');
      const lang = urlParams.get('lang');
      return { currency, lang };
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    const urlParams = this.getURLParams();
    this.loadData(urlParams);
    window.addEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState(['currency','user','languages'])
  },
  watch: {
      currency() {
        if (this.loadPage) this.loadData(this.getURLParams());
      },
      languages() {
        if (this.loadPage) this.loadData(this.getURLParams());
      },
      user() {
        if (this.loadPage) this.loadData(this.getURLParams());
      }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.mobile-768 {
  display: none;
}
section {
  padding-bottom: 72px;
}
@media screen and (max-width: 1024px) {
  .mobile__none {
    display: block;
  }
}
@media screen and (max-width: 768px){
  .mobile-768 {
    display: flex;
  }
  .mobile__none {
    display: none;
  }
  .mobile-none{
    display: none;
  }
  .first__banner__container{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 24px;
  }
  main{
    margin-top: 72px;
    margin-bottom: 0;
  }
  section:first-of-type {
    padding-bottom: 24px;
  }
}
</style>