<template>
    <Splide @splide:moved="handleMainSlideMoved"
            @splide:autoplay:play="handleAutoplayPlay"
            @splide:autoplay:pause="handleAutoplayPause"
            :has-track="false" :options="mainOptions" ref="main">
      <SplideTrack @click="generateRoute()">
        <SplideSlide v-for="(slide, index) in images" :key="index" >
          <img data-load :src="this.$store.state.base_directory + slide">
        </SplideSlide>
      </SplideTrack>
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g id="arrow_right">
              <path id="Polygon 1"
                    d="M9.29289 13.1191C8.90237 12.7286 8.90237 12.0954 9.29289 11.7049L12.8787 8.11912C13.5086 7.48915 14.5858 7.93532 14.5858 8.82622L14.5858 15.9978C14.5858 16.8887 13.5086 17.3349 12.8787 16.7049L9.29289 13.1191Z"
                    fill="white" />
            </g>
          </svg>
        </button>
        <button class="splide__arrow splide__arrow--next">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
               fill="none">
            <g clip-path="url(#clip0_4587_53887)">
              <path
                  d="M14.7071 10.8809C15.0976 11.2714 15.0976 11.9046 14.7071 12.2951L11.1213 15.8809C10.4914 16.5108 9.41421 16.0647 9.41421 15.1738L9.41421 8.0022C9.41421 7.1113 10.4914 6.66513 11.1213 7.2951L14.7071 10.8809Z"
                  fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_4587_53887">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <ul class="custom-pagination">
        <li v-for="(slide,index) in images" :key="index" :class="{ active: activeSlide === index }"
            @click="goToSlide(index)">
        </li>
      </ul>
    </Splide>
  </template>
  
  <script>
  import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
  import '@splidejs/vue-splide/css/skyblue';
  import '@splidejs/vue-splide/css/sea-green';
  import '@splidejs/vue-splide/css/core';
  import '@splidejs/vue-splide/css';
  export default {
    components: { Splide, SplideSlide,SplideTrack},
    props: {
      images: Object, Array,
      value: Object,
      currency: String,
      languages: String,
    },
    data() {
      return {
        activeSlide: 0,
        mainOptions: {
          type   : 'loop',
          padding: '0px',
          perMove: 1,
          perPage:1,
          gap: 12,
          rewind: true,
          // height: '100%',
          arrows: true,
          cover: true,
          pagination: false,
          interval: 10000,
        },
      }
    },
    methods: {
      generateRoute(){
        const link = this.$router.push({
            name: 'CityPage',
            params: {
            country: this.value.country_name.toLowerCase(),
            city: this.value.name.toLowerCase()
            },
            query: {
                currency: this.currency,
                type: 1,
                lang: this.languages
            }
        });
      },
      handleMainSlideMoved() {
        this.activeSlide = this.$refs.main.splide.index;
      },
      handleAutoplayPlay() {
        this.activeSlide = this.$refs.main.splide.index;
      },
      handleAutoplayPause() {
        this.activeSlide = this.$refs.main.splide.index;
      },
      goToSlide(index) {
        this.$refs.main.go(index);
      },
    },
  }
  </script>
  
  <style scoped>
  .splide a{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .splide{
    position: relative;
    z-index: 2;
  }
  .splide__slide{
    aspect-ratio: 1 / 0.67;
  }
  .splide__slide img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  .splide__arrow--prev {
    left: 16px;
  }
  
  .splide__arrow--next {
    right: 16px;
  }
  
  .splide__arrow--prev svg {
    transform: rotate(0deg);
  }
  
  .splide__arrow:disabled {
    visibility: hidden;
    opacity: 0;
  }
  .splide:hover .splide__arrow{
    display: flex;
  }
  .splide:hover .custom-pagination{
    display: flex;
  }
  .splide__arrow {
  transition: all 0.1s;
  opacity: 1;
  border-radius: 500px;
  border: 1.5px solid #E5E5EA;
  background-color: rgba(255, 255, 255, 0.16);
  width: 40px;
  height: 40px;
  display: none;
  cursor: pointer;
  z-index: 2;
}
.splide__arrow::before {
  content: '';
  position: absolute;
  top: -1.5px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.16);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
  backdrop-filter: blur(1.2px);
  z-index: -1; /* Помещаем псевдоэлемент позади основного элемента */
}
  
  .splide__arrow svg {
    width: auto;
    height: auto;
  }
  
  .custom-pagination{
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 2;
    bottom: 16px;
    display: none;
    gap: 7px;
    border-radius: 12px;
    border: 1.5px solid #E5E5EA;
    background: rgba(255, 255, 255, 0.16);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(9.5px);
    padding: 0 15px;
    height: 25px;
    align-items: center;
  }
  .custom-pagination li{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.50);
    transition: all 0.1s;
  }
  
  .custom-pagination li.active{
    background: #FFF;
    width: 7px;
    height: 7px;
  }
  .splide{
    border-radius: 16px;
    padding: 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 16px;
  }
  .splide__slide{
    border-radius: 16px;
  }
  .splide__pagination__page{
    height: 10px !important;
    width: 10px !important;
    margin: 0 !important;
  }
  .splide__pagination__page.is-active{
    width: 10px;
    height: 10px;
    transform: scale(1);
  }
  .wrapper .content .left {
    width: calc(67.45%);
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .splide__pagination{
    top: 30px;
    left: 30px;
    bottom: unset;
    right: unset;
    gap: 12px;
    padding: 0;
  }
  .splide__pagination__page{
    margin: 0 6px !important;
  }
  
  .splide .content{
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
  .splide .content .title{
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    font-family: Standerd;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.276px;
    padding-bottom: 10px;
  }
  .splide .content .description{
    color: #FFF;
    text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 24px;
  }
  @media screen and (max-width: 1024px) {
    .splide:hover .splide__arrow {
      display: none;
    }
    .custom-pagination{
      display: flex;
    }
  }
  @media screen and (max-width: 768px){
    .splide__slide{
      aspect-ratio: 1 / 0.56;
    }
    .custom-pagination{
      display: flex;
    }
  }
  </style>