
<template>
    <header-page></header-page>
    <main>
        <section class="companies">
            <div class="companies__container">
                <div class="companies__content">
                    <SideBar></SideBar>
                    <div class="right__main__content">
                        <div class="finance__total_content">
                            <stats-block :title="'Reservations today'" :number="todayData.total_orders">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <g clip-path="url(#clip0_2964_128723)">
                                        <path
                                            d="M19.2 12C19.2 11.0375 20.01 10.25 21 10.25V6.75C21 5.7875 20.19 5 19.2 5H4.8C3.81 5 3.009 5.7875 3.009 6.75V10.25C3.999 10.25 4.8 11.0375 4.8 12C4.8 12.9625 3.999 13.75 3 13.75V17.25C3 18.2125 3.81 19 4.8 19H19.2C20.19 19 21 18.2125 21 17.25V13.75C20.01 13.75 19.2 12.9625 19.2 12ZM15.222 16.2L12 14.1875L8.778 16.2L9.75 12.595L6.789 10.2413L10.605 10.0225L12 6.575L13.386 10.0312L17.202 10.25L14.241 12.6037L15.222 16.2Z"
                                            fill="#323232" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2964_128723">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </stats-block>
                            <stats-block :title="'Income today'" :dollar="true" :number="todayData.total_price">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <g clip-path="url(#clip0_2964_128732)">
                                        <path
                                            d="M4.40454 18.4634C3.10649 18.3609 2.06049 17.9448 1.26654 17.2149C0.47259 16.485 0.0504096 15.4862 0 14.2185H2.51418C2.56459 14.8203 2.75362 15.3005 3.08129 15.6591C3.40895 16.0176 3.85003 16.2417 4.40454 16.3313V12.8932C3.56018 12.6371 2.85444 12.3681 2.28733 12.0864C1.72023 11.7919 1.25394 11.4014 0.888469 10.9148C0.522999 10.4282 0.340265 9.80712 0.340265 9.05162C0.340265 7.97599 0.718337 7.11805 1.47448 6.47779C2.23062 5.83754 3.20731 5.47899 4.40454 5.40216V4H5.63327V5.42137C6.8431 5.54942 7.79458 5.96559 8.48771 6.66987C9.19345 7.37415 9.59042 8.26411 9.67864 9.33974H7.24008C7.18967 8.77631 7.03214 8.34094 6.76749 8.03361C6.50283 7.72629 6.12476 7.52781 5.63327 7.43818V10.7227C6.69187 11.0556 7.52363 11.3822 8.12854 11.7023C8.74606 12.0224 9.21235 12.413 9.52741 12.874C9.84247 13.3221 10 13.892 10 14.5834C10 15.6975 9.59672 16.6002 8.79017 17.2917C7.99622 17.9832 6.94392 18.3738 5.63327 18.4634V20H4.40454V18.4634ZM2.79773 8.76351C2.79773 9.12205 2.92376 9.41016 3.1758 9.62785C3.44045 9.84554 3.85003 10.0632 4.40454 10.2809V7.41897C3.90044 7.48299 3.50347 7.63025 3.21361 7.86074C2.93636 8.09124 2.79773 8.39216 2.79773 8.76351ZM5.63327 16.389C6.18778 16.3377 6.63516 16.1841 6.97542 15.928C7.31569 15.6591 7.48582 15.3133 7.48582 14.8908C7.48582 14.5322 7.33459 14.2377 7.03214 14.0072C6.74228 13.7639 6.27599 13.527 5.63327 13.2965V16.389Z"
                                            fill="#292929" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2964_128732">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </stats-block>
                            <stats-block :title="'Total bookings'" :number="incomeData.total_orders">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <g clip-path="url(#clip0_2964_128723)">
                                        <path
                                            d="M19.2 12C19.2 11.0375 20.01 10.25 21 10.25V6.75C21 5.7875 20.19 5 19.2 5H4.8C3.81 5 3.009 5.7875 3.009 6.75V10.25C3.999 10.25 4.8 11.0375 4.8 12C4.8 12.9625 3.999 13.75 3 13.75V17.25C3 18.2125 3.81 19 4.8 19H19.2C20.19 19 21 18.2125 21 17.25V13.75C20.01 13.75 19.2 12.9625 19.2 12ZM15.222 16.2L12 14.1875L8.778 16.2L9.75 12.595L6.789 10.2413L10.605 10.0225L12 6.575L13.386 10.0312L17.202 10.25L14.241 12.6037L15.222 16.2Z"
                                            fill="#323232" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2964_128723">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </stats-block>
                            <stats-block :title="'Total income'" :dollar="true" :number="incomeData.total_price">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M10.9551 19.2713C9.4948 19.1561 8.31805 18.6879 7.42486 17.8667C6.53166 17.0456 6.05671 15.922 6 14.4958H8.82845C8.88516 15.1729 9.09783 15.7131 9.46645 16.1164C9.83507 16.5198 10.3313 16.7719 10.9551 16.8727V13.0048C10.0052 12.7167 9.21125 12.4142 8.57325 12.0972C7.93526 11.7659 7.41068 11.3265 6.99953 10.7791C6.58837 10.2317 6.3828 9.53301 6.3828 8.68307C6.3828 7.47299 6.80813 6.5078 7.65879 5.78752C8.50945 5.06723 9.60822 4.66387 10.9551 4.57743V3H12.3374V4.59904C13.6985 4.7431 14.7689 5.21128 15.5487 6.0036C16.3426 6.79592 16.7892 7.79712 16.8885 9.0072H14.1451C14.0884 8.37335 13.9112 7.88355 13.6134 7.53781C13.3157 7.19208 12.8904 6.96879 12.3374 6.86795V10.563C13.5284 10.9376 14.4641 11.3049 15.1446 11.6651C15.8393 12.0252 16.3639 12.4646 16.7183 12.9832C17.0728 13.4874 17.25 14.1285 17.25 14.9064C17.25 16.1597 16.7963 17.1753 15.8889 17.9532C14.9957 18.7311 13.8119 19.1705 12.3374 19.2713V21H10.9551V19.2713ZM9.14745 8.35894C9.14745 8.7623 9.28922 9.08643 9.57278 9.33133C9.87051 9.57623 10.3313 9.82113 10.9551 10.066V6.84634C10.388 6.91837 9.9414 7.08403 9.61531 7.34334C9.3034 7.60264 9.14745 7.94118 9.14745 8.35894ZM12.3374 16.9376C12.9612 16.88 13.4646 16.7071 13.8474 16.419C14.2302 16.1164 14.4215 15.7275 14.4215 15.2521C14.4215 14.8487 14.2514 14.5174 13.9112 14.2581C13.5851 13.9844 13.0605 13.7179 12.3374 13.4586V16.9376Z"
                                        fill="#292929" />
                                </svg>
                            </stats-block>
                            <stats-block :title="'Unpaid events'" :dollar="true" :number="unpaidData.total_price">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M21 18V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H19C20.1 3 21 3.9 21 5V6H12C10.89 6 10 6.9 10 8V16C10 17.1 10.89 18 12 18H21ZM12 16H22V8H12V16ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z"
                                        fill="#292929" />
                                </svg>
                            </stats-block>
                        </div>
                        <div class="filter__content">
                            <div class="filter_content_wrapper">
                                <div class="picker-option">
                                    <template v-for="status in arr_status">
                                        <button
                                            :class="[status.status_name, { 'active': real_filters.status_id == status.id }]"
                                            @click="changeStatus(status.id)" type="button">
                                            {{ status.status_name }}
                                        </button>
                                        <div class="arrow__top"></div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div>
    <post-list class="active" :posts="arr_data" :loader="data_info.loading">
        <table class="list">
            <thead>
                <tr>
                    <th v-if="selectedStatus == 2 && isMobile === false">
                        <div class="checkbox">
                            <input :id="'all'" v-model="selectAll" class="checkbox_input" type="checkbox" />
                            <label :for="'all'"></label>
                        </div>
                    </th>
                    <th>Event name</th>
                    <th>Orders</th>
                    <th>Total income</th>
                    <th>Terms</th>
                    <th>Commission</th>
                    <th>Event date</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Payment date</th>
                    <th></th>
                </tr>
            </thead>
            <tbody v-if="loadingСompleted">
                <tr v-for="(post, index) in this.arr_data" :key="post.id">
                    <td class="mobile__none" v-if="selectedStatus == 2 && isMobile === false">
                        <div class="checkbox">
                            <input :id="index" class="checkbox_input" :value="post" type="checkbox" v-model="selectedPosts" />
                            <label :for="index"></label>
                        </div>
                    </td>
                    <td>
                        <div class="company__name">
                            <img v-if="post.images"
                                :src='this.$store.state.base_directory + "company/" + post.company_id + "/events/" + post.event_id + "/" + post.images[0]'
                                alt="">
                            <div>{{ post.event_name }}</div>
                        </div>
                    </td>
                    <td>
                        <div class="table__name">Orders:</div>
                        <div class="text">{{ post.total_orders }}</div>
                    </td>
                    <td>
                        <div class="table__name">Total income:</div>
                        <div class="text">${{ post.total_price }}</div>
                    </td>
                    <td>
                        <div class="table__name">Terms:</div>
                        <div class="text">{{ post.terms }}%</div>
                    </td>
                    <td>
                        <div class="table__name">Income:</div>
                        <div class="text">${{ formatPrice((post.total_price / 100) * post.terms) }}</div>
                    </td>
                    <td class="mobile__none">
                        <div class="table__name">Event date:</div>
                        <div class="text">{{ post.date_event }}</div>
                    </td>
                    <td class="mobile__none">
                        <div class="table__name">Time:</div>
                        <div class="text">{{ post.time }}</div>
                    </td>
                    <td>
                        <div class="table__name">Status:</div>
                        <div class="company__status" :class="[post.color]">
                            <div>{{ post.status_name }}</div>
                        </div>
                    </td>
                    <td class="mobile__none">
                        <div class="table__name">Payment date:</div>
                        <div v-if="post.created_at" class="text">{{ post.created_at }}</div>
                        <div v-else class="text">__/__/__</div>
                    </td>
                    <td class="mobile__none">
                        <div class="company__panelButton">
                            <button @click="getInvoice(post)" :disabled="!isButtonEnabled(post)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                    <path
                                        d="M13.3333 13.8333H1.66667V2.16667H7.5V0.5H1.66667C0.741667 0.5 0 1.25 0 2.16667V13.8333C0 14.75 0.741667 15.5 1.66667 15.5H13.3333C14.25 15.5 15 14.75 15 13.8333V8H13.3333V13.8333ZM9.16667 0.5V2.16667H12.1583L3.96667 10.3583L5.14167 11.5333L13.3333 3.34167V6.33333H15V0.5H9.16667Z"
                                        fill="#292929" />
                                </svg>
                            </button>
                        </div>
                    </td>
                    <td v-if="selectedStatus == 2" class="mobile">
                        <div class="company__panelButton">
                            <button @click="getInvoice(post)" :disabled="!isButtonEnabled(post)">
                                Pay
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </post-list>
    <div class="paid__block" v-if="isAnySelected">
        <button @click="markAsPaid" class="paid-button">Paid</button>
        <div>
            <div class="title">Total</div>
            ${{ totalSelectedIncome }}
        </div>
    </div>
    <list-preloader v-if="!loadingСompleted"></list-preloader>
    <list-flip @update="onPage" v-if="(data_info.loading || arr_data.length > 0) && !isAnySelected" :class="{ active: loadingСompleted }"
        :page="data_info.page" :currenNumber="arr_data.length" :allCount="data_info.allCount" :next="data_info.next"></list-flip>
</div>

                    </div>
                    <invoice-dialog v-model:show="dialogs.detailsItemDialog" :posts="arr_invoice" :modal="true">
                        <div class="space-between">
                            <div class="invoice__event__name" v-if="loadingСompletedInvoice">{{ arr_invoice[0].event_name }}
                            </div>

                            <button class="exit-dialog" @click="this.dialogs.detailsItemDialog = false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                                        fill="#292929" />
                                </svg>
                            </button>
                        </div>
                        <div class="wrapper__pay" v-if="selectedStatus == 2 && loadingСompletedInvoice">
                            <button class="btn__pay">
                                Pay
                            </button>
                            <div class="wrapper__total">
                                <div class="title">Total</div>
                                <div class="count">${{ arr_data.find(item => item.id === invoice_data_info.postId)?.total_price || 'Недоступно' }}</div>
                            </div>
                        </div>
                        <post-list class="active" :posts="arr_invoice" :loader="data_info.loading">

                            <table class="list invoice">
                                <thead>
                                    <tr>
                                        <th>Event name</th>
                                        <th>Customers</th>
                                        <th>Order date</th>
                                        <th>Total income</th>
                                        <th>Terms</th>
                                        <th>Commission</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadingСompletedInvoice">
                                    <tr v-for="post in arr_invoice" :key="post.id">
                                        <td>
                                            <div class="table__name">Event name:</div>
                                            <div class="text">{{ post.event_name }}</div>
                                        </td>
                                        <td>
                                            <div class="table__name">Order:</div>
                                            <div class="text">{{ post.customer_name }}</div>
                                        </td>
                                        <td>
                                            <div class="table__name">Event date:</div>
                                            <div class="text">{{ post.order_date }}</div>
                                        </td>
                                        <td>
                                            <div class="table__name">Total income:</div>
                                            <div class="text">${{ post.total_price }}</div>
                                        </td>
                                        <td>
                                            <div class="table__name">Terms:</div>
                                            <div class="text">{{ post.terms }}%</div>
                                        </td>
                                        <td>
                                            <div class="table__name">Commission:</div>
                                            <div class="text">${{ formatPrice((post.total_price / 100) * post.terms) }}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </post-list>
                        <list-preloader v-if="!loadingСompletedInvoice"></list-preloader>
                        <list-flip @update="onPageInvoice" v-if="data_info.loading || arr_invoice.length > 0"
                            :class="{ active: loadingСompletedInvoice }" :page="invoice_data_info.page"
                            :currenNumber="arr_invoice.length" :allCount="invoice_data_info.allCount"
                            :next="invoice_data_info.next"></list-flip>
                    </invoice-dialog>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import ListFlip from "@/components/Admin/ListFlip.vue";
import PostList from "@/components/Admin/AdminPanelList.vue";
import axios from "axios";
import ListPreloader from "@/components/UI/Admin/ListPreloader.vue";
import SideBar from "@/components/Busines/SideBar.vue";
import HeaderBusines from "@/components/Busines/HeaderBusines.vue";
export default {
    components: { SideBar, ListPreloader, PostList, ListFlip,HeaderBusines },
    data() {
        return {
            selectedPosts: [],
            selectAll: false,
            loadingСompletedInvoice: false,
            loadingСompleted: false,
            arr_data: [],
            arr_status: [
                { status_name: 'Paid', id: 1 },
                { status_name: 'Unpaid', id: 2 },
            ],
            todayData: [],
            arr_invoice: [],
            unpaidData: [],
            incomeData: [],
            arr_company: [],
            current_date: new Date(),
            selectedStatus: 1,
            current_start_date: '_._.__',
            current_end_date: '_._.__',
            filters: {
                'status_id': 1,
            },
            filter_date: {
                'order_date': {
                    'current_start': null,
                    'current_end': null,
                }
            },
            arrFilters: {
                'id': 'Company',
            },
            real_filters: {},
            data_info: {
                page: 0,
                next: true,
                allCount: null,
                loading: true,
            },
            invoice_data_info: {
                page: 0,
                next: true,
                allCount: null,
                postId: 0,
                loading: true,
            },
            dialogs: {
                detailsItemDialog: false,
                calendarDialog: false,
            },
            isMobile: false,
        }
    },
    methods: {
        formatPrice(value) {
        // Округляем число до двух десятичных знаков
        let formattedValue = value.toFixed(1);
        // Проверяем, является ли дробная часть равной 0
        if (formattedValue.endsWith('.0')) {
            // Если да, возвращаем целое число
            return parseInt(formattedValue);
        } else {
            // В противном случае возвращаем число с двумя знаками после запятой
            return formattedValue;
        }
        },
        calculateTotalSelectedIncome() {
        return this.selectedPosts.reduce((total, post) => {
            return total + post.total_price;
        }, 0);
        },
        updateSelectedTotal() {
            this.totalSelectedIncome = this.calculateTotalSelectedIncome();
        },
        isButtonEnabled(post) {
        if (this.selectAll) return false;
        return !this.isAnySelected;
        },
        getDataPaid() {
            this.loadingСompleted = false;
            this.filters.status_id = this.selectedStatus;
            this.real_filters = this.filters;
            let fData = new FormData();
            if (this.arr_data.length <= 0) {
                this.data_info.page--;
                if (this.data_info.page < 0) this.data_info.page = 0;
            }
            fData.append("page", this.data_info.page);
            axios.post("/business/finance/get/paid", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.arr_data = resp.data.data;
                    this.data_info.allCount = resp.data.allCount;
                    this.data_info.next = resp.data.next;
                    this.data_info.loading = false;
                }
            }).catch(() => { }).finally(() => {
                this.loadingСompleted = true;
            });
        },
        getDataUnPaid() {
            this.loadingСompleted = false;
            this.filters.status_id = this.selectedStatus;
            this.real_filters = this.filters;
            let fData = new FormData();
            if (this.arr_data.length <= 0) {
                this.data_info.page--;
                if (this.data_info.page < 0) this.data_info.page = 0;
            }
            fData.append("page", this.data_info.page);
            axios.post("/business/finance/get/unpaid", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.arr_data = resp.data.data;
                    this.data_info.allCount = resp.data.allCount;
                    this.data_info.next = resp.data.next;
                    this.data_info.loading = false;
                }
            }).catch(() => { }).finally(() => {
                this.loadingСompleted = true;
            });
        },
        onPage(e) {
            this.data_info.page = e;
            this.data_info.loading = true;
            if (this.filters.status_id == 7) this.getDataPaid();
            else this.getDataUnPaid();
        },
        onPageInvoice(e) {
            this.invoice_data_info.page = e;
            this.getInvoice();
        },
        getInvoice(e) {
            if (e) this.invoice_data_info.postId = e.id;
            this.loadingСompletedInvoice = false;

            let fData = new FormData();
            fData.append("page", this.invoice_data_info.page);
            fData.append("id", this.invoice_data_info.postId);
            if (this.selectedStatus == 1) {
                fData.append("status_id", this.selectedStatus);
            }
            else {
                fData.append("status_id", this.selectedStatus);
                fData.append("reservation_event", e.reservation_event);
                fData.append("day_execution", e.day_execution);
            }
            axios.post("/business/finance/get/invoice", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.arr_invoice = resp.data.data;
                    this.invoice_data_info.next = resp.data.next;
                    this.invoice_data_info.allCount = resp.data.allCount;
                }
            }).catch(() => { }).finally(() => {
                this.dialogs.detailsItemDialog = true;
                this.loadingСompletedInvoice = true;
            });
        },
        getInit() {
            this.real_filters.status_id = this.selectedStatus;
            let fData = new FormData();
            axios.post("/business/finance/get/init", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.todayData = resp.data.data;
                    this.incomeData = resp.data.total;
                    this.unpaidData = resp.data.unpaid;
                }
            }).catch(() => { }).finally(() => {
            });
        },
        changeStatus(e) {
            this.selectedStatus = e;
            this.data_info.loading = true;
            this.filters.status_id = this.selectedStatus;
            if (e == 1) this.getDataPaid();
            else this.getDataUnPaid();
        },
        checkIfMobile() {
            // Проверяем ширину экрана или другие критерии, которые вы хотите использовать для определения мобильного устройства
            if (window.innerWidth <= 768) { // Пример использования ширины экрана
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        }
    },
    mounted() {
        this.getInit();
        this.getDataPaid();
        this.checkIfMobile();
    },
    computed: {
        isAnySelected() {
        return this.selectedPosts.length > 0;
        }
    },
    watch: {
        selectAll(newValue, oldValue) {
            if (this.selectAll == true) {
                this.arr_data.forEach(post => {
                    this.selectedPosts = [...this.arr_data];
                });
                this.totalSelectedIncome = 0;
                this.totalSelectedIncome = this.calculateTotalSelectedIncome();
            } else {
                this.selectedPosts = [];
            }

        },
        selectedPosts(newValue, oldValue) {
            if (!this.selectedPosts.length) {
                this.selectAll = false;
            }
            this.totalSelectedIncome = 0;
            this.totalSelectedIncome = this.calculateTotalSelectedIncome();
        }
    },
}
</script>
<style scoped>
.mobile{
    display: none !important;
}
.paid__block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 20px 24px;
    border-radius: 12px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;

    /* 2 */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.paid__block button{
    border-radius: 8px;
    background: var(--Green-100, #AFF4C6);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 97px;

    color: var(--Green-400, #39766A);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    }
.paid__block > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
}
.paid__block > div .title{
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}
.company__panelButton button:disabled{
    opacity: 0.5;
}
.company__panelButton button{
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}
.selected .input-date {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1.5px solid #E5E5EA;
    background: #FFF;
    height: 36px;
    width: 266px;
    padding-left: 11px;
}

.selected .input-date svg {
    position: absolute;
    right: 16px;
    z-index: 1;
}

.selected .input-date .input-picker {
    color: var(--black-300, #333);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.companies__content {
    display: flex;
    gap: 24px;
}

.finance__total_content {
    display: flex;
    gap: 24px;
    width: 100%;
    padding-bottom: 24px;
}

.finance__total_content .finance__total__left {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    border-radius: 16px;
    border: 1.5px solid var(--gray-stroke, #E5E5EA);
    background: var(--white-100, #FFF);
    width: calc(57.5% - 8px);
    justify-content: flex-end;
}

.finance__total__left .selected {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.company__name div {
    max-width: 157px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.finance__total__left .selected .input__select input {
    width: 266px;
    height: 36px;
}

.finance__block__wrapper {
    display: flex;
    gap: 16px;
    width: 100%;
}

.finance__total__right {
    padding: 16px;
    width: calc(42.5% - 8px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    border: 1.5px solid var(--gray-stroke, #E5E5EA);
    background: var(--white-100, #FFF);
    min-width: max-content;
}

.finance__total__right .title {
    color: #292929;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
}

.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.invoice__event__name {
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 83.333% */
}

.dialog__content .exit-dialog {
    position: unset;
}

.wrapper__pay {
    display: flex;
    border-radius: 12px;
    justify-content: space-between;
    align-items: center;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 16px;
    padding-right: 89px;
}

.wrapper__pay .btn__pay {
    border-radius: 8px;
    background: var(--Green-100, #AFF4C6);
    height: 36px;
    padding: 8px 36px;

    color: var(--Green-400, #39766A);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.wrapper__pay .wrapper__total .title {
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding-bottom: 0;
    /* 142.857% */
}

.wrapper__pay .wrapper__total .count {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 100% */
}

@media screen and (max-width: 1024px) {
    .list.invoice td:last-of-type {
        border-top: 2px solid #E5E5EA;
    }
    .list.invoice table{
        border-spacing: unset;
    }
    .list.invoice thead{
        display: none;
    }
    .mobile{
    display: flex !important;
    }
    .picker-option {
        max-width: unset;
    }

    .picker-option button {
        width: 100%;
    }

    .finance__total_content {
        overflow-y: scroll;

    }

    .finance__total_content::-webkit-scrollbar {
        height: 0;
        display: none; 
    }

    .finance__block {
        min-width: 207px;
    }
}
@media screen and (max-width: 768px) {}</style>
 
 


 
 

