<template>
  <Header></Header>
  <main>
    <section v-if="bannerData.length > 1 && windowWidth >= 768" class="slider__container mobile__none">
      <SliderSection :array="bannerData" :isLoading="isLoading"></SliderSection>
    </section>
    <section v-else-if="bannerData.length <= 1" class="first__banner__container">
      <FirstBannerPage :array="bannerData" :isLoading="isLoading"></FirstBannerPage>
    </section>
    <section v-if="bannerData.length > 1 && windowWidth <= 768" class="banner__mobile mobile-768">
      <FirstBannerMobile :array="bannerData" :isLoading="isLoading"></FirstBannerMobile>
    </section>
    <section class="recently__viewed__container" v-if="recentlyViewedData.length > 0">
      <TopExpiriencesPage :posts="recentlyViewedData" :title="'Recently viewed'" :isLoading="isLoading"></TopExpiriencesPage>
    </section>
    <section class="top__experiences__container">
      <TopExpiriencesPage :posts="topExperienceData" :title="'Top experiences'" :isLoading="isLoading"></TopExpiriencesPage>
    </section>
    <section class="top__event__container">
      <TopEventSection :posts="topEventsData" :title="'Top events'" :isLoading="isLoading"></TopEventSection>
    </section>
    <section class="top__event__container">
      <TopDestinationsSection :posts="topDestinationsData" :title="'Top destinations'" :isLoading="isLoading"></TopDestinationsSection>
    </section>
  </main>
  <Footer></Footer>
  <!-- <PreloaderBanner v-if="isLoading"/> -->
</template>

<script>
import Header from "@/components/MainPages/Header.vue";
import TopExpiriencesPage from "@/components/MainPages/HomePage/TopExpiriencesSection.vue";
import SliderSection from "@/components/MainPages/HomePage/SliderSection.vue";
import TopEventSection from "@/components/MainPages/HomePage/TopEventSection.vue";
import TopDestinationsSection from "@/components/MainPages/HomePage/TopDestinationsSection.vue";
import DialogLoginForm from "@/components/DialogLoginForm.vue";
import Footer from "@/components/MainPages/Footer.vue";
import FirstBannerMobile from "@/components/MainPages/HomePage/FirstBannerMobile.vue";
import FirstBannerPage from "@/components/MainPages/FirstBannerPage.vue";
import PreloaderBanner from "@/components/MainPages/HomePage/PreloaderBanner.vue";
import axios from "axios";
import { mapState } from 'vuex';
export default {
  components: {
    Header,
    TopExpiriencesPage,
    SliderSection,
    TopEventSection,
    TopDestinationsSection,
    DialogLoginForm,
    Footer,
    FirstBannerMobile,
    FirstBannerPage,
    PreloaderBanner
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      dialogs: {
        DialogLogin: false,
      },
      isLoading: true,
      recentlyViewedData: [],
      topExperienceData: [],
      topEventsData: [],
      topDestinationsData: [],
      bannerData: [],
      countries: [],
      loadPage: false,
    }
  },
  methods: {
    async getEvents(currency, language) {
      let fData = new FormData();
      fData.append("currency", currency || 'USD');
      fData.append("language", language || 'en');
      try {
        const resp = await axios.post("/get/events", fData);
        this.bannerData = resp.data.banner_data;
        this.topExperienceData = resp.data.top_experiences;
        this.topEventsData = resp.data.top_events;
        this.recentlyViewedData = resp.data.recently_viewed;
        this.topDestinationsData = resp.data.top_destination;
      } catch (error) {
        console.error(error);
      }
    },
    loadData({ currency, lang }) {
      this.isLoading = true;
      Promise.all([
        this.getEvents(currency, lang),
      ])
      .then(results => {
      this.$nextTick(() => {
        this.waitForImagesToLoad();
      });
      }, error => {
        this.isLoading = false;
        this.loadPage = true;
        console.error("Error loading data:", error);
      });

    },
    waitForImagesToLoad() {
      const images = document.querySelectorAll('img[data-load]');
      const promises = [];

      images.forEach((img, index) => {
        if (!img.complete) {
          promises.push(new Promise((resolve) => {
            img.onload = () => {
              resolve();
            };
            img.onerror = () => {
              console.log(`Ошибка при загрузке изображения ${index + 1}`);
              resolve();
            };
          }));
        } else {
        }
      });

      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          this.isLoading = false;
          this.loadPage = true;
        });
      } else {
        this.isLoading = false;
        this.loadPage = true;
      }
    },
    getURLParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const currency = urlParams.get('currency');
      const lang = urlParams.get('lang');
      return { currency, lang };
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    const urlParams = this.getURLParams();
    this.loadData(urlParams);
    window.addEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState(['currency','user','languages'])
  },
  watch: {
      currency() {
        if (this.loadPage) this.loadData(this.getURLParams());
      },
      languages() {
        if (this.loadPage) this.loadData(this.getURLParams());
      },
      user() {
        if (this.loadPage) this.loadData(this.getURLParams());
      }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.mobile-768 {
  display: none;
}

section {
  padding-bottom: 72px;
}

section:first-child {
  padding-bottom: 42px;
}

section .title {
  color: #292929;
  font-family: Standerd;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.192px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .mobile__none {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .mobile-768 {
    display: flex;
  }

  .mobile__none {
    display: none;
  }

  section {
    padding-bottom: 60px;
  }

  .banner__mobile {
    padding-bottom: 24px;
  }

  main {
    margin-top: 72px;
    margin-bottom: 0;
  }
  .first__banner__container{
    padding-left: 0;
    padding-right: 0;
  }
  section:first-child {
  padding-bottom: 24px;
}
}
</style>