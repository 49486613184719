<template>
    <div class="wrapper mobile" :class="{ active: show }" @click.stop="hideDialog">

        <div class="bg"></div>
        <div class="dialog__content" @click.stop>
            <Button class="exit-dialog" @click.prevent="hideDialog">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                    fill="#292929"/>
            </svg>
            </Button>
            <div class="review__filters">
                <div class="filters__wrapper">
                    <div class="title">Sort by:</div>
                    <div class="sort__filters">
                        <div class="checkbox" v-for="item in event__duration">
                            <input :id="item.name+'-'+item.value+'1'" name="124" v-model="filters.sortFilter" @change="changeRadio" :value="item.value" class="checkbox_input" type="checkbox"/>
                            <label :for="item.name+'-'+item.value+'1'"></label>
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="filters__wrapper">
                    <div class="title">Star rating: </div>
                    <div class="rate__filters">
                        <template v-for="rate in ratings">
                        <div class="checkbox">
                            <input :id="rate.count_star+'1'" v-model="filters.starsFilter" @change="changeRadio" class="checkbox_input" :value="rate" type="checkbox"/>
                            <label :for="rate.count_star+'1'"></label>
                        <div class="count_stars">
                            <svg v-for="n in rate.count_star" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" fill="#292929" stroke="#292929" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg v-for="n in 5-rate.count_star" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" stroke="#292929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </div>
                            ({{rate.count}}) 
                        </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NumberPicker from "@/components/MainPages/EventPage/NumberPicker.vue";
    export default {
        components: {
            NumberPicker,
            
        },
        props: {
            filters: Object,
            show: {
                type: Boolean,
                default: false
            },
            ratings: Object,
        },
        data() {
            return {
                dataSelect: {},
                event__duration: [
                    {name: 'Video Reviews', value: '1'},
                    {name: 'Text reviews', value: '2'},
                ],
                rates:[
                    {count_stars:5, count:5},
                    {count_stars:4, count:4},
                    {count_stars:3, count:3},
                    {count_stars:2, count:2},
                    {count_stars:1, count:1}
                ],
            }
        },
        methods: {
            hideDialog() {
                this.$emit('update:show', false)
            },
            changeRadio() {
            this.$emit('update:filters', this.filters);
            this.$emit('filters', true);
            },
        },
    }
</script>

<style scoped>
.mobile{
    display: none  !important;;
}
.sort__filters{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.checkbox{
  display: flex;
  align-items: center;
  gap: 12px;

  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.102px;
}
.checkbox_input label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;

}

.checkbox_input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox_input + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox_input + label:hover::before {
  border-radius: 4px;
  border: 2px solid #606060;
}

.checkbox_input + label::before {
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  border: 2px solid #E5E5EA;
  width: 20px;
  height: 20px;
  transition: 0.3s;
}

.checkbox_input:checked + label::before {
  border-color: #292929;
  background-color: #292929;
}
.review__filters{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.review__filters .filters__wrapper .title{
  color: #292929;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.108px;
  padding-bottom: 16px;
}
.wrapper{
    position: absolute;
    left: 0;
    top: 125%;

    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    z-index: 30;
    width: 100%;
    display: flex;
    justify-content: center;
}
.dialog__content{
    width: 100%;
    padding: 24px;
    padding-top: 24px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.wrapper.active{
  visibility: visible;
  opacity: 1;
}
.wrapper .title{
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    padding-bottom: 20px;
}
.form{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
}
.input__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 1024px) {
  .wrapper .bg{
  background: #606060;
  opacity: 0.32;
  width: 100%;
  height: 100%;
  }
  .wrapper.mobile{
        display: flex  !important;;
    }
  .wrapper{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
  }
  .dialog__content{
    position: absolute;
    top: 72px;
    max-width: calc(100% - 32px);
  }
}
</style>