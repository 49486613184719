<template>
  <div class="dialog" :class="{ active: show }" @click.stop="hideDialog">
    <div class="bg_color"></div>
    <div @click.stop class="dialog__content picker">
      <div class="image-input date-picker parent valid">
        <div class="custom-calendar">
          <div class="calendar">
            <div class="title-line">
              <div class="current-month">{{ getCurrentMonth(current_date) }}
              </div>
              <div class="arrows">
                <div @click="prevArrowPicker" class="prev-arrow arrow">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" fill="white" stroke="#D1D1D6"/>
                  <path d="M12.7071 16.7071C12.3166 16.3166 12.3166 15.6834 12.7071 15.2929L16.2929 11.7071C16.9229 11.0771 18 11.5233 18 12.4142L18 19.5858C18 20.4767 16.9229 20.9229 16.2929 20.2929L12.7071 16.7071Z" fill="#292929" fill-opacity="0.8"/>
                  </svg>
                </div>
                <div @click="nextArrowPicker" class="next-arrow arrow">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" fill="white" stroke="#D1D1D6"/>
                  <path d="M12.7071 16.7071C12.3166 16.3166 12.3166 15.6834 12.7071 15.2929L16.2929 11.7071C16.9229 11.0771 18 11.5233 18 12.4142L18 19.5858C18 20.4767 16.9229 20.9229 16.2929 20.2929L12.7071 16.7071Z" fill="#292929" fill-opacity="0.8"/>
                  </svg>
                </div>
              </div>
            </div>
            <div class="input-date">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_1720_35924)">
                  <path
                    d="M14.1667 9.99967H10V14.1663H14.1667V9.99967ZM13.3333 0.833008V2.49967H6.66667V0.833008H5V2.49967H4.16667C3.24167 2.49967 2.50833 3.24967 2.50833 4.16634L2.5 15.833C2.5 16.7497 3.24167 17.4997 4.16667 17.4997H15.8333C16.75 17.4997 17.5 16.7497 17.5 15.833V4.16634C17.5 3.24967 16.75 2.49967 15.8333 2.49967H15V0.833008H13.3333ZM15.8333 15.833H4.16667V6.66634H15.8333V15.833Z"
                    fill="#333333" />
                </g>
                <defs>
                  <clipPath id="clip0_1720_35924">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div class="input-picker" type="text">{{ this.current_start_date }}</div>
            </div>
            <div class="dates-grid">
              <div class="title">
                <div>Sun</div>
                <div>Mon</div>
                <div>Tue</div>
                <div>Wed</div>
                <div>Thu</div>
                <div class="weekend">Fri</div>
                <div class="weekend">Sat</div>
              </div>
              <div class="days">
                <template v-for="n in arrayDates">
                  <div
                    :class="[{ 'active': n.status === 'active' }, { 'inactive': n.status === 'inactive' }, { 'focus': n.status === 'focus' }, { 'half-focus': n.status === 'half-focus' }]">
                    <div @click="n.status === 'active' ? clickEvent(n.value) : false">
                      {{ getDays(n.value) }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="btn">
              <Button class="white" @click="clearDate">Cancel</Button>
              <Button class="green" @click="select_date_query != '' ? nextStep() : false">Confirm</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {

  name: 'picker-dialog',
  props: {
    show: {
      type: Boolean,
      default: false,

    },
    value_start: Object,
    value_end: String,
  },
  data() {
    return {
      stateShow: this.show,
      arrayDates: [],
      now: '',
      start_date: '',
      select_date: '',
      select_date_query: '',
      current_select_date_query: [],
      current_select_date: [],
      current_date: new Date(),
      current_start_date: '_._.__',
      current_end_date: '_._.__',
      range: false,
      current_select_range: {
        current_start: null,
        current_end: null,
      },
      current_select_query: {
        current_start: null,
        current_end: null,
      }
    }
  },
  methods: {
    hideDialog() {
      this.$emit('show', false)
    },
    init() {
      this.updateMonth();
    },
    change() {
      this.arrayDates = [];
      this.now = new Date();
      this.now.setUTCHours(0);
      this.now.setUTCMinutes(0);
      this.now.setUTCSeconds(0);
      this.now.setUTCMilliseconds(0);
      let start_date = new Date(this.current_date);
      let month = this.current_date.getMonth();
      let year = this.current_date.getFullYear();
      start_date.setUTCDate(1);
      start_date.setHours(0, 0, 0, 0);
      let day_week;
      if (start_date.getDay() == 0) {
        day_week = 7 - 1;
      } else day_week = start_date.getDay() - 1;

      let last_date = new Date(this.current_date.getFullYear(), this.current_date.getMonth() + 1, 0);
      let day_week_next = 7 - last_date.getDay();
      let last_day_prev_month = new Date(this.current_date.getFullYear(), this.current_date.getMonth(), -day_week + 1);
      let first_day_next_month = new Date(this.current_date.getFullYear(), this.current_date.getMonth() + 1, 1);
      for (let i = 0; i < day_week; i++) {
        let current_time = last_day_prev_month.getTime();
        let object = { value: current_time, status: 'inactive' }
        this.arrayDates.push(object);
        last_day_prev_month.setDate(last_day_prev_month.getDate() + 1);
      }
      if (this.current_select_range.current_end == null) {
        while (true) {
          if (start_date.getMonth() != month) break;
          let current_time = start_date.getTime();
          let object = { value: current_time, status: 'inactive' }

          for (let i = 0; i < this.current_select_date.length; i++) {
            if (this.current_select_date[i] === current_time) {
              object.status = 'active';
              break;
            }
          }
          if (this.select_date_query === current_time || this.select_date_query === current_time) object.status = 'focus';
          this.arrayDates.push(object);
          start_date.setDate(start_date.getDate() + 1);
        }
      } else if (this.current_select_range.current_end != null) {
        while (true) {
          if (start_date.getMonth() != month) break;
          let current_time = start_date.getTime();
          let object = { value: current_time, status: 'inactive' }
          if (this.current_select_range.current_start === current_time || this.current_select_range.current_end === current_time) object.status = 'active';
          else if (this.current_select_range.current_start < current_time && this.current_select_range.current_end > current_time) object.status = 'active';
          if (this.select_date_query === current_time || this.select_date_query === current_time) object.status = 'focus';
          this.arrayDates.push(object);
          start_date.setDate(start_date.getDate() + 1);
        }
      }


      for (let i = 0; i < day_week_next; i++) {
        if (day_week_next == 7) break;
        let current_time = first_day_next_month.getTime();
        let object = { value: current_time, status: 'inactive' }
        this.arrayDates.push(object);
        first_day_next_month.setDate(first_day_next_month.getDate() + 1);
      }

      // Set status to 'inactive' for past dates
      let today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      for (let i = 0; i < this.arrayDates.length; i++) {
        if (this.arrayDates[i].value < today.getTime()) {
          this.arrayDates[i].status = 'inactive';
        }
      }
    },

    clickEvent(value) {
      this.select_date_query = value;
      let current_start_date = new Date(value);
      this.current_start_date = this.$store.state.short_months_names[current_start_date.getMonth()] + ' ' + current_start_date.getDate() + ', ' + current_start_date.getFullYear();
      this.select_date = current_start_date.getFullYear() + '-' + ('0' + (current_start_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (current_start_date.getDate())).slice(-2);
      this.range = true;
      this.change();
    },
    nextStep() {
      this.hideDialog();
      this.$emit('add', this.select_date);
      this.change();
    },
    clearDate() {
      this.hideDialog();
      this.range = false;
      this.current_select_range.current_start = null;
      this.current_select_range.current_end = null;
      this.current_select_query.current_start = null;
      this.current_select_query.current_end = null;
      this.current_start_date = '_._.__';
      this.current_end_date = '_._.__';
      this.change();
    },
    getDays(value) {
      let new_value = new Date(value);
      return new_value.getDate();
    },
    prevArrowPicker() {
      this.current_date.setMonth((this.current_date.getMonth() - 1));
      this.updateMonth();
    },
    nextArrowPicker() {
      this.current_date.setMonth(this.current_date.getMonth() + 1);
      this.updateMonth();
    },
    getCurrentMonth(value) {
      return this.$store.state.months_names[value.getMonth()] + ' ' + value.getFullYear();
    },
    updateMonth() {
      this.change();
    },
    clearDate() {
      this.hideDialog();
      this.select_date_query = '';
      this.select_date = '';
      this.current_start_date = '_._.__';
      this.change();
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    value_start: {
      handler(val, oldVal) {
        if (this.value_end == null) {
          this.current_select_date_query = [...this.value_start];
          this.current_select_query.current_start = null;
          this.current_select_query.current_end = null;
          this.current_select_range.current_start = null;
          this.current_select_range.current_end = null;
          const dateObjects = this.value_start.map(dateString => {
            const date = new Date(dateString);
            date.setHours(0, 0, 0, 0); // Устанавливаем время на полночь
            return date;
          });
          const timeInMilliseconds = dateObjects.map(dateObject => dateObject.getTime());
          this.current_select_date = [...timeInMilliseconds];
          this.change();
        }
      },
      deep: true
    },
    value_end: {
      handler(val, oldVal) {
        if (this.value_end != null) {
          this.current_select_query.current_start = this.value_start;
          this.current_select_query.current_end = this.value_end;
          this.current_select_date = [];
          this.current_select_date_query = [];
          let dateObjects = new Date(this.value_start);
          dateObjects.setHours(0, 0, 0, 0);
          let timeInMilliseconds = dateObjects.getTime();
          this.current_select_range.current_start = timeInMilliseconds;

          dateObjects = new Date(this.value_end);
          dateObjects.setHours(0, 0, 0, 0); // Устанавливаем время на полночь
          timeInMilliseconds = dateObjects.getTime();
          this.current_select_range.current_end = timeInMilliseconds;
          this.change();
        }
      },
      deep: true
    },
    show: {
      handler(val, oldVal) {
        if (val === false) {
          this.clearDate();
        }
      }
    }
  },
}
</script>
  
<style>
.dialog {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  position: fixed;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  gap: 24px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}

.dialog.active {
  visibility: visible;
  opacity: 1;
}

.bg_color {
  opacity: 0.32;
  background: #292929;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transition: opacity 0.3s cubic-bezier(.16, 1, .3, 1);
}

.dialog__content.picker {
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 16px;
  padding-top: 15px;
  width: 390px;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 999;
  position: relative;
}

.dialog__content .btn button {
  height: 44px;
  width: 100%;
}

.dialog__content textarea {
  background: #FFFFFF;
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  height: 96px;
  width: 100%;
  padding: 10px;
  resize: none;

}

.dialog__content .exit-dialog {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 8px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.dialog__content .title {
  color: #333;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 12px;
}

.dialog__content .icon.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background: #FFEBEB;
  margin-bottom: 12px;
}

.dialog__content .text {
  color: #606060;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.dialog__content .input-date {
  width: 100%;
  height: 36px;
  padding-left: 10px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  color: var(--black-300, #333);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.calendar {
  width: 100%;
}

.calendar .title-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.calendar .title-line .current-month {
  color: #333;
  text-align: center;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 133.333% */
}

.calendar .title-line .arrows {
  display: flex;
  gap: 8px;
}

.calendar .title-line .arrows .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #FFF;
}

.calendar .title-line .arrows .next-arrow {
  transform: rotate(180deg);
}

.calendar .input-date {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 4px;
}

.calendar .input-date svg {
  position: absolute;
  right: 16px;
  z-index: 1;
}

.calendar .input-date .input-picker {
  max-width: 90px;
  border-radius: 0;
  border-radius: 0;
}

.custom-calendar .calendar .dates-grid {
  width: 100%;
  margin-bottom: 24px;
}

.custom-calendar .calendar .dates-grid .title {
  color: #333;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 133.333% */
  justify-content: space-between;
}

.custom-calendar .calendar .dates-grid>div {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
}

.custom-calendar .calendar .dates-grid .title>div {
  width: 36px;
  height: 32px;
}

.custom-calendar .calendar .dates-grid .days>div {
  width: calc(100% / 7);
  color: var(--black-300, #333);
  text-align: center;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 133.333% */
}

.custom-calendar .calendar .dates-grid .days>div.inactive {
  color: #CCC;
  text-align: center;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 133.333% */
}

.custom-calendar .calendar .dates-grid .days>div.active {
  cursor: pointer;
}

.custom-calendar .calendar .dates-grid .days>div.focus div {
  color: var(--white-100, #FFF);
  text-align: center;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 133.333% */
  border-radius: 6px;
  background: var(--black-400, #292929);
}

.custom-calendar .calendar .dates-grid .days>div.half-focus div {
  border-radius: 6px;
  background: var(--gray-stroke, #E5E5EA);
}

.custom-calendar .calendar .dates-grid .days div.active>div:hover {
  color: var(--white-100, #FFF);
  text-align: center;
  font-family: Standerd;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 133.333% */
  border-radius: 6px;
  background: var(--black-400, #292929);
}

.custom-calendar .calendar .dates-grid .days div>div {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .dialog__content {
    width: calc(100% - 32px);
  }

  .dialog__content .title {
    color: #333;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
  }

  .dialog__content .text {
    color: #606060;
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
</style>