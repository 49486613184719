<template>
    <div class="wrapper">
        <div class="top">
            <div class="left"></div>
            <div class="right"></div>
        </div>
        <div class="bottom">
            <div class="left"></div>
            <div class="right"></div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.top{
    display: flex;
    gap: 24px;
    width: 100%;
}
.top .left{
    width: calc(57.5% - 12px);
    height: 197px;
    border-radius: 16px;
    background: #EBEBEB;
}
.top .right{
    width: calc(42.5% - 12px);
    height: 197px;
    border-radius: 16px;
    background: #EBEBEB;
}
.bottom{
    display: flex;
    gap: 24px;
    width: 100%;
    height: 100%;
}
.bottom .left{
    width: calc(41% - 12px);
    border-radius: 12px;
    background: #EBEBEB;
    height: 100%;
}
.bottom .right{
    width: calc(59% - 12px);
    border-radius: 12px;
    background: #EBEBEB;
    height: 100%;
}
@media screen and (max-width: 1024px) {
    .top{
        flex-direction: column;
    }
    .top .left{
        width: 100%;
        height: 400px;
    }
    .top .right{
        width: 100%;
        height: 300px;
    }
    .bottom{
        flex-direction: column;
    }
    .bottom .left{
        width: 100%;
        height: 260px;
    }
    .bottom .right{
        width: 100%;
        height: 600px;
    }
}
</style>