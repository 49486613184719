<template>
    <div class="wrapper">
        <div class="images__container">
            <div class="title-wrapper">
                <div class="title">{{event_name}}</div>
                <div class="tabs">
                    <div :class="{ active: step == 1 }" @click="changeStatus(1)">Provider photos</div>
                    <div :class="{ active: step == 2 }" @click="changeStatus(2)">Travelers photo</div>
                </div>
                <Button class="exit-dialog" @click="this.$emit('exit', false);">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                            fill="#292929" />
                    </svg>
                </Button>
            </div>
            <div class="content" v-show="images.length > 0">
                <div class="left">
                    <Splide @splide:moved="handleMainSlideMoved" :has-track="false" :options="mainOptions" ref="main">
                        <SplideTrack>
                            <SplideSlide v-for="(slide, index) in images" :key="index">
                                <img v-if="step ==  1" :src='this.$store.state.base_directory + "company/" + company_id + "/events/" + id + "/" + slide'>
                                 <img v-if="step ==  2" :src='this.$store.state.base_directory + "reviews/"+ this.id + "/"+ slide' alt="">
                            </SplideSlide>
                        </SplideTrack>
                        <div class="splide__arrows">
                            <button class="splide__arrow splide__arrow--prev">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="arrow_right">
                                        <path id="Polygon 1"
                                            d="M9.29289 13.1191C8.90237 12.7286 8.90237 12.0954 9.29289 11.7049L12.8787 8.11912C13.5086 7.48915 14.5858 7.93532 14.5858 8.82622L14.5858 15.9978C14.5858 16.8887 13.5086 17.3349 12.8787 16.7049L9.29289 13.1191Z"
                                            fill="white" />
                                    </g>
                                </svg>
                            </button>
                            <button class="splide__arrow splide__arrow--next">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <g clip-path="url(#clip0_4587_53887)">
                                        <path
                                            d="M14.7071 10.8809C15.0976 11.2714 15.0976 11.9046 14.7071 12.2951L11.1213 15.8809C10.4914 16.5108 9.41421 16.0647 9.41421 15.1738L9.41421 8.0022C9.41421 7.1113 10.4914 6.66513 11.1213 7.2951L14.7071 10.8809Z"
                                            fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4587_53887">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                        <div class="slide-counter">
                            {{ currentSlide + 1 }}/{{ totalSlides }}
                        </div>
                    </Splide>

                    <Splide @splide:moved="handleThumbsSlideMoved" :options="thumbsOptions" ref="thumbs">
                        <SplideSlide v-for="(slide, index) in images" :key="index">
                            <img v-if="step ==  1" :src='this.$store.state.base_directory + "company/" + company_id + "/events/" + id + "/" + slide'>
                            <img v-if="step ==  2" :src='this.$store.state.base_directory + "reviews/"+ this.id + "/"+ slide' alt="">
                        </SplideSlide>
                    </Splide>
                </div>
                <div class="right" v-if="reviews.length > 0">
                    <div class="content-wrapper" @scroll="handleScroll">
                        <template v-for="(post,postIndex) in reviews">
                            <div class="block" v-if="post.text || post.video">
                            <div class="user-wrapper">
                                <img :src="require('@/assets/image/1.png')" alt="">
                                <div class="info-wrapper">
                                    <div class="name">{{ post.name }}</div>
                                    <div class="date">{{ post.date }}</div>
                                </div>
                            </div>
                            <div class="reviews-star">
                                <svg v-for="stars in post.rate" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M11.6586 3.73742C11.6886 3.67237 11.7367 3.61728 11.797 3.57867C11.8574 3.54005 11.9275 3.51953 11.9991 3.51953C12.0708 3.51953 12.1409 3.54005 12.2013 3.57867C12.2616 3.61728 12.3096 3.67237 12.3396 3.73742L14.6624 8.77292C14.6892 8.83113 14.7306 8.88148 14.7824 8.91916C14.8343 8.95685 14.895 8.9806 14.9586 8.98817L20.4644 9.64067C20.5356 9.64913 20.6028 9.67781 20.6582 9.72332C20.7136 9.76883 20.7547 9.82925 20.7768 9.89744C20.7989 9.96562 20.801 10.0387 20.7829 10.108C20.7647 10.1774 20.7271 10.2401 20.6744 10.2887L16.6041 14.0537C16.5572 14.0972 16.5223 14.1521 16.5026 14.213C16.4829 14.2739 16.4791 14.3389 16.4916 14.4017L17.5716 19.8399C17.5857 19.9102 17.5793 19.9831 17.5532 20.0499C17.5271 20.1167 17.4823 20.1746 17.4243 20.2168C17.3663 20.2589 17.2974 20.2836 17.2258 20.2878C17.1542 20.2919 17.0829 20.2755 17.0204 20.2404L12.1829 17.5322C12.1268 17.5006 12.0635 17.4841 11.9991 17.4841C11.9348 17.4841 11.8715 17.5006 11.8154 17.5322L6.97788 20.2397C6.9154 20.2747 6.84419 20.2911 6.77269 20.2869C6.70118 20.2827 6.63237 20.2582 6.57438 20.2161C6.5164 20.1741 6.47167 20.1163 6.44548 20.0496C6.4193 19.983 6.41276 19.9102 6.42663 19.8399L7.50663 14.4017C7.51928 14.3388 7.51558 14.2737 7.49588 14.2126C7.47618 14.1516 7.44115 14.0966 7.39413 14.0529L3.32313 10.2879C3.27068 10.2393 3.23324 10.1767 3.21522 10.1074C3.1972 10.0382 3.19935 9.96527 3.22143 9.89723C3.24351 9.82919 3.28458 9.76888 3.33981 9.72342C3.39504 9.67796 3.46212 9.64925 3.53313 9.64067L9.03963 8.98817C9.10342 8.98073 9.16424 8.95702 9.21624 8.91933C9.26825 8.88164 9.3097 8.83123 9.33663 8.77292L11.6586 3.73742Z" fill="#292929" stroke="#292929" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="review__info">
                                <div v-if="post.text" class="comment">{{ post.text }}</div>
                                <!-- <video v-if="post.video" :src='this.$store.state.base_directory + "reviews/"+ this.id + "/"+ post.video' controls></video> -->
                                <div v-if="post.video" class="video-wrapper">
                                <video :ref="'video-' + postIndex" :src='this.$store.state.base_directory + "reviews/"+ post.event_id + "/"+ post.video' @click="togglePlay(postIndex)" @play="onPlay(postIndex)" @pause="onPause(postIndex)"></video>
                                <div :ref="'playButton-' + postIndex" class="play-button" @click="playVideo(postIndex)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="63" viewBox="0 0 57 63" fill="none">
                                    <g filter="url(#filter0_bdd_2891_134431)">
                                    <path d="M46.0284 24.0392C48.6875 25.5803 48.6875 29.4198 46.0284 30.9608L14.0057 49.5194C11.339 51.0649 8 49.1407 8 46.0586L8 8.94138C8 5.85925 11.339 3.93512 14.0057 5.48057L46.0284 24.0392Z" fill="white" fill-opacity="0.8"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_bdd_2891_134431" x="-26" y="-29.0664" width="108.023" height="113.133" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="17"/>
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2891_134431"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="4"/>
                                        <feGaussianBlur stdDeviation="4"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
                                        <feBlend mode="normal" in2="effect1_backgroundBlur_2891_134431" result="effect2_dropShadow_2891_134431"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="2"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                                        <feBlend mode="normal" in2="effect2_dropShadow_2891_134431" result="effect3_dropShadow_2891_134431"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2891_134431" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                                </div>
                                
                            </div>
                            <div v-if="post.video" class="range-wrap">
                                <input v-if="post.video" type="range" min="0" max="1" step="0.01" 
                                :ref="'volume-' + postIndex" @input="setVolume(postIndex, $event)" 
                                class="volume-slider">
                            </div>
                            </div>
                        </div>
                        </template>
                    </div>
                    <div :class="{ active: showTopGradient }" class="gradient-top"></div>
                    <div :class="{ active: showBottomGradient }" class="gradient-bottom"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';
import '@splidejs/vue-splide/css/core';
import '@splidejs/vue-splide/css';
import axios from 'axios';
export default {
    components: { Splide, SplideSlide, SplideTrack },
    props: {
        steps: Number,
        reviews: Object, Array,
        company_id: Number,
        id: Number,
        viewCurrentImage: Number,
        event_name: String,
    },
    data() {
        return {
            mainOptions: {
                padding: '0px',
                perPage: 1,
                perMove: 1,
                gap: 12,
                height: '620px',
                pagination: false,
                arrows: true,
                cover: true,
            },
            thumbsOptions: {
                perPage: 4,
                fixedHeight: 113,
                isNavigation: true,
                gap: 12,
                arrows: false,
                pagination: false,
                cover: true,
                drag: false,
                updateOnMove: true,
                dragMinThreshold: {
                    mouse: 4,
                    touch: 10,
                },
            },
            images: [],
            step: 1,
            showTopGradient: false,
            showBottomGradient: true,
            currentSlide: 0,
            totalSlides: 0,
        }
    },
    methods: {
        getVideoRef(index) {
        return this.$refs[`video-${index}`][0];
        },
        getPlayButtonRef(index) {
        return this.$refs[`playButton-${index}`][0];
        },
        getVolumeRef(index) {
        return this.$refs[`volume-${index}`][0];
        },
        playVideo(index) {
        const video = this.getVideoRef(index);
        if (video) {
            video.play();
        }
        },
        togglePlay(index) {
        const video = this.getVideoRef(index);
        if (video) {
            if (video.paused) {
            video.play();
            } else {
            video.pause();
            }
        }
        },
        onPlay(index) {
        const playButton = this.getPlayButtonRef(index);
        if (playButton) {
            playButton.classList.add('hidden');
        }
        },
        onPause(index) {
        const playButton = this.getPlayButtonRef(index);
        if (playButton) {
            playButton.classList.remove('hidden');
        }
        },
        setVolume(index, event) {
        const video = this.getVideoRef(index);
        if (video) {
            video.volume = event.target.value;
        }
        },
        updateRangeValue(index, event) {
        const input = this.getVolumeRef(index);
        const value = input.value;
        },
        handleScroll(event) {
            const scrollTop = event.target.scrollTop;
            const scrollHeight = event.target.scrollHeight;

            const clientHeight = event.target.clientHeight;
            if (scrollTop === 0) this.showTopGradient = false;
            else if (scrollTop >= 30) this.showTopGradient = true;

            if (scrollTop + clientHeight > scrollHeight - 5) this.showBottomGradient = false;
            else if (scrollTop + clientHeight <= scrollHeight - 30) this.showBottomGradient = true;
        },
        handleMainSlideMoved() {
            this.currentSlide = this.$refs.main.splide.index;
            this.totalSlides = this.$refs.main.splide.length;
        },
        changeStatus(status,index){
                this.step = status;
                this.$refs.main.splide.go(0);
                let fData = new FormData();
                fData.append("id", this.id);
                fData.append("status", this.step);
                axios.post("/get/event/all-photo", fData).then(resp => {
                    if (resp.data.result == true) {
                    this.images = resp.data.data;
                    }
                    
                }).catch(() => {
                }).finally(() => {
                    if(index) this.$refs.main.splide.go(index);
                    this.handleMainSlideMoved();
                });
        },
    },
    mounted() {
        this.changeStatus(1);
        this.totalSlides = this.$refs.main.splide.length;
        const thumbsSplide = this.$refs.thumbs?.splide;
        if (thumbsSplide) {
            this.$refs.main?.sync(thumbsSplide);
        }
    },
    watch: {
        viewCurrentImage: {
            handler(val, oldVal) {
                this.changeStatus(2,val);
                // this.step = this.steps;
                // this.$refs.main.splide.go(this.viewCurrentImage);
            },
            deep: true
        },
    },
}

</script>

<style scoped>
.range-wrap {
  position: relative;
  width: 100%;
}
.volume-slider {
width: 100%;
accent-color: #333333;
background-color: #E5E5EA;
height: 6px;
}
.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #333333;
  border-radius: 8px;
  height: 0;
  width: 0;
}
.review__info {
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
}
.video-wrapper {
  position: relative;
   
}
video {
  width: 100%;
  cursor: pointer;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.play-button.hidden {
  display: none;
}
.play-button svg{
  max-width: 51px;
  max-height: 44px;
}

.splide{
    padding: 0;
}
.exit-dialog{
    position: absolute;
  right: 24px;
  top: 20px;
}
.review__info video{
  width: 180px;
  height: 180px;
  border-radius: 180px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray -502.204px -176.479px / 459.222% 321.387% no-repeat;
  object-fit: cover;
}
.splide__arrow--prev {
    left: 32px;
}

.splide__arrow--next {
    right: 32px;
}

.splide__arrow--prev svg {
    transform: rotate(0deg);
}

.splide__arrow:disabled {
    visibility: hidden;
    opacity: 0;
}

.splide__arrow {
    transition: all 0.1s;
    opacity: 1;
    border-radius: 500px;
    border: 1.5px solid #E5E5EA;
    background: rgba(255, 255, 255, 0.16);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(9.5px);
    width: 40px;
    height: 40px;
}

.splide__arrow svg {
    width: auto;
    height: auto;
}

.slide-counter {
    position: absolute;
    right: 24px;
    bottom: 24px;
    border-radius: 12px;
    border: 1.5px solid #E5E5EA;
    background: rgba(255, 255, 255, 0.16);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(9.5px);
    height: 41px;
    padding: 0 24px;
    width: 87px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
}

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #fff;

    padding: 0 58px;
    padding-top: 25px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    display: none;
}

.wrapper.active {
    display: unset;
    visibility: visible;
    opacity: 1;
}

.wrapper .title-wrapper {
    position: relative;
    padding: 24px;
    padding-bottom: 16px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23px;
}

.title-wrapper .title {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.168px;
}

.wrapper .content {
    display: flex;
    gap: 32px;
    margin-top: 25px;
}

.content .splide__slide {
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(41, 41, 41, 0.20) 0%, rgba(41, 41, 41, 0.20) 100%) lightgray 50% / cover no-repeat;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.title-wrapper .tabs {
    display: flex;
}
.title-wrapper .tabs div {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 17px */
    letter-spacing: -0.374px;
    padding: 8px 10px
}

.title-wrapper .tabs div.active {
    border-bottom: 1.5px solid var(--Black-400, #292929);
}

.wrapper .content .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.wrapper .content .right {
    width: calc(100% - 51%);
    position: relative;
    max-height: 800px;
}

.right .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    max-height: 100%;
}
.right .content-wrapper::-webkit-scrollbar {
    width: 0;
    /* Для WebKit (Chrome, Safari) */
    display: none;
    /* Для WebKit (Chrome, Safari) */
}
.right .block {
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 16px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.block .user-wrapper {
    display: flex;
    gap: 16px;
}

.block .user-wrapper img {
    width: 52px;
    height: 52px;
    border-radius: 52px;
}

.block .comment {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
    letter-spacing: 0.12px;
}

.user-wrapper .info-wrapper {}

.user-wrapper .info-wrapper .name {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 30.8px */
    letter-spacing: 0.132px;
}

.user-wrapper .info-wrapper .date {
    color: var(--Gray-400, #606060);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.108px;
}

.gradient-top,
.gradient-bottom {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    width: 100%;
    height: 162px;
    transition: 0.5s;
}

.gradient-top.active,
.gradient-bottom.active {
    opacity: 1;
    visibility: visible;
}

.gradient-top {
    top: 0;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.00) -8.04%, #FFF 100%);
}

.gradient-bottom {
    bottom: -3px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -8.04%, #FFF 100%);
}
@media screen and (max-width: 1024px) {
.tablet-none {
    display: none !important;
  }

}
</style>